import React, {Fragment} from "react";
import {Box as FormBox} from "../../../LEGOS/DynamicForm/dynamicForm.styled";
import {DynamicFormWrapper} from "../../../LEGOS/DynamicForm/DynamicFormWrapper";
import {DynamicTable} from "../../../LEGOS/DynamicTable";
import {DocSummaryTableQuestionGroup} from "./DocSummaryTableQuestionGroup";
import dayjs from 'dayjs';
import {Typography} from "@mui/material";

const cleanDate = (date) => date.replace('[UTC]', '');

export const DocSummaryTable = ({documentationData, onClickActionRow}) => {

  const docsRows = documentationData.flatMap(documentosTramite => documentosTramite.documentacionOperacion);

  docsRows.forEach(documento => {
    documento["subtipoDocumento"] = "Subtipo Nombre";
    documento["esObligatorio"] = documento.obligatorio? "Si" : "No";
    documento.fechaFinVigencia
      ? documento["fechaVigencia"] = dayjs(cleanDate(documento.fechaFinVigencia)).format('YYYY-MM-DD HH:mm:ss')
      : documento["fechaVigencia"] = 'Fecha no disponible';
  })

  DocSummaryTableQuestionGroup[0].groupTitle = <Typography>{DocSummaryTableQuestionGroup[0].title}</Typography>;

  return(
    <Fragment>
      <FormBox>
            <DynamicFormWrapper
              questionsGroups={DocSummaryTableQuestionGroup}
              isExpanded={true}
              render={(question) => (
                <DynamicTable
                  key={question.Id}
                  id={question.Id}
                  headers={question.TableHeaders}
                  rows={docsRows}
                  onClickActionRow={onClickActionRow}
                />
              )}
            />
      </FormBox>
    </Fragment>
  );
}