import { productConstants } from '../constants/index.js'
import { getGenericControls } from '../../utils'

export const ProductRemote = (state = {}, action) => {
    switch (action.type) {
        case productConstants.PRODUCT_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case productConstants.PRODUCT_SUCCESS:
            return {
                loading: false,
                items: action.product.Body,
                productId: action.product.Body[0].Id,
                controls: getGenericControls(action.product.Body[0]),
                error: undefined
            }
            //break;
        case productConstants.PRODUCT_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}

export const ProductHiringData = (state = {}, action) => {
    switch (action.type) {
        case productConstants.PRODUCT_HIRING_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case productConstants.PRODUCT_HIRING_SUCCESS:
            return {
                loading: false,
                items: action.productData.Body,
                error: undefined
            }
            //break;
        case productConstants.PRODUCT_HIRING_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}