import { BASE_MULTIVA_URL } from './baseMultivaURL';

const SIMULADORES_PATH = `${BASE_MULTIVA_URL}/fcsimuladores/api/fabricadecredito/simulador/credito`;
const CONSULTA_XID_PATH = `${BASE_MULTIVA_URL}/fcsimuladores/api/fabricadecredito/simulador/consulta-solicitud`;
const CONSULTA_XID_INDIVIDUAL_PATH = `${BASE_MULTIVA_URL}/fcsimuladores/api/fabricadecredito/simulador/consulta-individual`;
const GUARDAR_SIMULACION_PATH = `${BASE_MULTIVA_URL}/fcsimuladores/api/fabricadecredito/simulador/guardar`;
const IMPRIMIR_TABLA_AMORTIZACION_PATH = `${BASE_MULTIVA_URL}/fcsimuladores/api/fabricadecredito/simulador/imprimir-tabla-amortizacion`;


export { SIMULADORES_PATH, CONSULTA_XID_PATH, GUARDAR_SIMULACION_PATH, CONSULTA_XID_INDIVIDUAL_PATH, IMPRIMIR_TABLA_AMORTIZACION_PATH };
