import { Fab as MUIFab, styled } from "@mui/material";

export const Fab = styled(MUIFab)(({ theme }) => ({
  position: 'fixed',
  bottom: '6rem',
  right: '3rem',
  flexDirection: 'column',
  backgroundColor: theme.palette.primary.main,

  '&:hover': {
    backgroundColor: theme.palette.primary.dark
  }
}));
