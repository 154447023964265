import { msGestorDocumentalConstants } from '../../constants/msMultiva/msGestorDocumentalConstants';

export const AltaDocumento = (state = {}, action) => {
  switch (action.type) {
    case msGestorDocumentalConstants.ALTA_DOCUMENTO_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msGestorDocumentalConstants.ALTA_DOCUMENTO_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msGestorDocumentalConstants.ALTA_DOCUMENTO_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    default:
      return state;
  }
};

export const ModificarDocumento = (state = {}, action) => {
  switch (action.type) {
    case msGestorDocumentalConstants.MODIFICAR_DOCUMENTO_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msGestorDocumentalConstants.MODIFICAR_DOCUMENTO_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msGestorDocumentalConstants.MODIFICAR_DOCUMENTO_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    default:
      return state;
  }
};

export const ConsultarDocumentoAtributo = (state = {}, action) => {
  switch (action.type) {
    case msGestorDocumentalConstants.CONSULTAR_DOCUMENTO_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msGestorDocumentalConstants.CONSULTAR_DOCUMENTO_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msGestorDocumentalConstants.CONSULTAR_DOCUMENTO_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    default:
      return state;
  }
};

export const DescargarDocumento = (state = {}, action) => {
  switch (action.type) {
    case msGestorDocumentalConstants.DESCARGAR_DOCUMENTO_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msGestorDocumentalConstants.DESCARGAR_DOCUMENTO_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msGestorDocumentalConstants.DESCARGAR_DOCUMENTO_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    default:
      return state;
  }
};
