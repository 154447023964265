export const DETAILS_REQUEST_PAYLOAD = (id) => {
  return {
    "solicitud": {
        "idSolicitud": id,
        "tipoTransaccion": "consulta",
        "idUsuario": 12343,
        "usuarioGeolocalizacion": "40.689247, -74.044502",
        "seccion": [
          "c12"
        ],
        "c12": 
        {
          "idSolicitud": id
        }
    }
  }
};
