import { biometricConstants } from '../constants/index.js'
import { setSelfieArray } from '../../utils'

export const BiometricData = (state = {}, action) => {
    switch (action.type) {
        case biometricConstants.BIOMETRIC_DATA_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case biometricConstants.BIOMETRIC_DATA_SUCCESS:
            return {
                loading: false,
                items: action.biometricData.Body,
                itemsSelfie: setSelfieArray(action.biometricData.Body),
                error: undefined
            }
            //break;
        case biometricConstants.BIOMETRIC_DATA_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}