import axios from "axios";
import axiosRetry from "axios-retry";

const axiosMultivaClient = axios.create({});

axiosRetry(axiosMultivaClient, {
  retries: process.env.REACT_AXIOS_RETRIES,
  retryDelay: (retryCount) => {
    return retryCount * process.env.REACT_AXIOS_DELAY;
  },
  retryCondition: (error) => {
    return error?.response?.status >= 400;
  },
});

export { axiosMultivaClient };
