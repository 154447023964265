export const msHabilitadoresConstants = {
  CONSULTA_HABILITADORES_REQUEST: 'CONSULTA_HABILITADORES_REQUEST',
  CONSULTA_HABILITADORES_SUCCESS: 'CONSULTA_HABILITADORES_SUCCESS',
  CONSULTA_HABILITADORES_FAILURE: 'CONSULTA_HABILITADORES_FAILURE',
  CONSULTA_HABILITADORES_CLEAR_STATE: 'CONSULTA_HABILITADORES_CLEAR_STATE',
  ACTUALIZA_HABILITADORES_REQUEST: 'ACTUALIZA_HABILITADORES_REQUEST',
  ACTUALIZA_HABILITADORES_SUCCESS: 'ACTUALIZA_HABILITADORES_SUCCESS',
  ACTUALIZA_HABILITADORES_FAILURE: 'ACTUALIZA_HABILITADORES_FAILURE',
  ALTA_HABILITADORES_REQUEST: 'ALTA_HABILITADORES_REQUEST',
  ALTA_HABILITADORES_SUCCESS: 'ALTA_HABILITADORES_SUCCESS',
  ALTA_HABILITADORES_FAILURE: 'ALTA_HABILITADORES_FAILURE',
};
