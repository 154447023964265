import {useDispatch, useSelector} from "react-redux";
import {Box as FormBox} from "../DynamicForm/dynamicForm.styled";
import React, {Fragment, useEffect, useState} from "react";
import {DynamicFormWrapper} from "../DynamicForm/DynamicFormWrapper";
import {DynamicTable} from "../DynamicTable";
import {RequestErrorAlert} from "../../RequestErrorAlert";
import {GenericContainer} from "../../GenericContainer";
import {msSolicitudActions} from "store/actions/msMultiva/msSolicitudActions";
import {BIOMETRICS_REQUEST_PAYLOAD, CONSULTA_CATALOGO_REQUEST_PAYLOAD} from "./BiometricsResultsRequestPayload";
import {BiometricsQuestionGroup} from "./BiometricsQuestionGroup";
import CancelIcon from "@mui/icons-material/Cancel";
import {Accordion} from "../../Accordion.jsx";
import {msCatalogosActions} from "../../../store/actions/msMultiva/msCatalogosActions";
import {Typography} from "@mui/material";

export const BiometricResults = () => {

  const [BiometricsData, setBiometricsData] = useState([]);
  const [BiometricsDataLoading, setBiometricsDataLoading] = useState(false);
  const [catalogoEstadoBiometrico, setCatalogoEstadoBiometrico] = useState([]);
  const [catalogoResultadoBiometrico, setCatalogoResultadoBiometrico] = useState([]);
  const [catalogoFiguras, setCatalogoFiguras] = useState([]);
  const [fetchError, setFetchError] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    clearSolicitudData();
  }, []);

  useEffect(() => {
    getAllBiometricsData();
  }, []);

  const idSolicitud = 9;
  const claveCatalogoEstadoBiometrico = "ESTADOBIOMETRICO";
  const claveCatalogoResultadoBiometrico = "BIOMETRICORESULTADO";
  const claveCatalogoFiguras = "FIGURAS";

  const clearSolicitudData = () => {
    dispatch(msSolicitudActions.SolicitudOperacionClearState());
  }

  const getAllBiometricsData = async () => {
    try {
      setBiometricsDataLoading(true);
      await Promise.all([
        getBiometricsDataDispatch(),
        getCatalogoEstadoBiometricoDispatch(),
        getCatalogoResultadoBiometricoDispatch(),
        getCatalogoFigurasDispatch(),
      ]);
      setBiometricsDataLoading(false);
    } catch (error) {
      setFetchError(true);
    }
  }

  const getBiometricsDataDispatch = async () => {
    BIOMETRICS_REQUEST_PAYLOAD.solicitud.biometricos[0].idSolicitud = idSolicitud; //Para pruebas 9
    const response = await dispatch(msSolicitudActions.SolicitudOperacion(BIOMETRICS_REQUEST_PAYLOAD));
    setBiometricsData(response?.solicitud?.biometricos || []);
    setFetchError(!response?.solicitud?.biometricos);
  };

  const getCatalogoEstadoBiometricoDispatch = async () => {
    CONSULTA_CATALOGO_REQUEST_PAYLOAD.claveCatalogo = claveCatalogoEstadoBiometrico;
    const response = await dispatch(msCatalogosActions.ConsultaCatalogoIndividual(CONSULTA_CATALOGO_REQUEST_PAYLOAD));
    setFetchError(!response?.catalogo);
    setCatalogoEstadoBiometrico(response?.catalogo?.subCatalogo || []);
  };

  const getCatalogoResultadoBiometricoDispatch = async () => {
    CONSULTA_CATALOGO_REQUEST_PAYLOAD.claveCatalogo = claveCatalogoResultadoBiometrico;
    const response = await dispatch(msCatalogosActions.ConsultaCatalogoIndividual(CONSULTA_CATALOGO_REQUEST_PAYLOAD));
    setFetchError(!response?.catalogo);
    setCatalogoResultadoBiometrico(response?.catalogo?.subCatalogo || []);
  };

  const getCatalogoFigurasDispatch = async () => {
    CONSULTA_CATALOGO_REQUEST_PAYLOAD.claveCatalogo = claveCatalogoFiguras;
    const response = await dispatch(msCatalogosActions.ConsultaCatalogoIndividual(CONSULTA_CATALOGO_REQUEST_PAYLOAD));
    setFetchError(!response?.catalogo);
    setCatalogoFiguras(response?.catalogo?.subCatalogo || []);
  };

  BiometricsQuestionGroup[0].groupTitle = <Typography>{BiometricsQuestionGroup[0].title}</Typography>
  BiometricsData.forEach(row => {
    row["estatus"] = catalogoEstadoBiometrico.find(({idSubCatalogo}) => idSubCatalogo === row.idEstatus)?.valor || "Sin resultados";
    row["resultado"] = catalogoResultadoBiometrico.find(({idSubCatalogo}) => idSubCatalogo === row.idResultado)?.valor || "Sin resultados";
    row["figura"] = catalogoFiguras.find(({idSubCatalogo}) => idSubCatalogo === row.idFigura)?.valor || "Sin resultados";
  });

  console.log("CS_BiometricsData",BiometricsData);
  console.log("CS_EstadoBiometricos",catalogoEstadoBiometrico);
  console.log("CS_ResultadoBiometricos",catalogoResultadoBiometrico);

  return (
    <Fragment>
        <FormBox>
          <GenericContainer loading={BiometricsDataLoading} column>
            {!fetchError ?
              <DynamicFormWrapper
                questionsGroups={BiometricsQuestionGroup}
                render={(question) => (
                  <DynamicTable
                    key={question.Id}
                    id={question.Id}
                    headers={question.TableHeaders}
                    rows={BiometricsData}
                  />
                )}
              /> :
              <Accordion title={BiometricsQuestionGroup[0].groupTitle} isExpanded={true}>
                <CancelIcon style={{ color: 'red' }} />
              </Accordion>
            }
          </GenericContainer>
        </FormBox>
    </Fragment>
  );
}