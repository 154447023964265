import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  Circle,
  Polyline
} from "react-google-maps";

const GeoLocation = (props) => {
  console.log("pORPS GRAL--------------------------------: ", props)
  return (
    <GoogleMap defaultZoom={props.zoom}
      defaultCenter={props.center}
    >
      {props.polyPlaces &&
        <Polyline path={props.polyPlaces} />
      }
      {props.places.map(place => {
        return (
          <Fragment key={place.id}>
            <Marker
              position={{
                lat: parseFloat(place.latitude),
                lng: parseFloat(place.longitude)
              }}
            />
            {place.circle && <Circle
              defaultCenter={{
                lat: parseFloat(place.latitude),
                lng: parseFloat(place.longitude)
              }}
              radius={place.circle.radius}
              options={place.circle.options}
            />}
          </Fragment>
        );
      })}
    </GoogleMap>
  );
}

export default withScriptjs(
  withGoogleMap(
    GeoLocation
  )
)
