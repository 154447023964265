import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Fab } from './scrollTopButton.styled';

export const ScrollTopButton = ({ parentRef }) => {
  const [isVisible, setIsVisible] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    };

    const handleIntersect = (entries) => {
      entries.forEach(entry => {
        entry.isIntersecting ? setIsVisible(false) : setIsVisible(true);
      });
    };

    const observer = new IntersectionObserver(handleIntersect, options);
    if (parentRef.current) {
      observer.observe(parentRef.current);
    }

    return () => {
      if (parentRef.current) {
        observer.unobserve(parentRef.current);
      }
    };
  }, []);

  const scrollToTop = () => parentRef.current.scrollIntoView({ behavior: 'smooth' });

  return isVisible ? (
    ReactDOM.createPortal(
      <Fab onClick={scrollToTop} ref={buttonRef}>
        <KeyboardArrowUpIcon style={{ fontSize: '2em', color: 'white'}} />
      </Fab>,
      document.body
    )
  ) : null;
};
