import { CircularProgress } from '@mui/material';
import { Box } from './GenericContainer.styled';
import PropTypes from 'prop-types';

export const GenericContainer = ({ children, loading, column, shouldRender = true }) => {
  return (
    <Box loading={loading?.toString()} column={column?.toString()}>
      {loading ? (
        <CircularProgress
          color="inherit"
          size="1.4rem"
          sx={{
            marginRight: '0.5rem',
          }}
        />
      ) : shouldRender ? (
        <>{children}</>
      ) : null}
    </Box>
  );
};

GenericContainer.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool.isRequired,
  column: PropTypes.bool,
};
