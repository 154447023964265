import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/styles';
import { withStyles } from '@mui/styles';
import {
  AppBar,
  Button,
  Tabs,
  Tab,
  Typography,
  Box,
  CircularProgress,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import HelpIcon from '@mui/icons-material/Help';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useForm, FormProvider } from 'react-hook-form';
import CircleIcon from '@mui/icons-material/Circle';

import noBio from '../../assets/NoBiometricos.png';
import loadDoc from '../../assets/loadDoc.svg';
import noDataToShow from '../../assets/noDataToShow.svg';

import {
  workFlowActions,
  fileActions,
  biometricActions,
  validationActions,
  documentActions,
} from '../../store/actions';
// import { docRequest } from '../../utils';
import { useStyles } from './TabsStyle';
import { mainStyles } from '../../theme/mainStyles';
import {
  GeneralInfo,
  ActorGralInfo,
  Documentation,
  RecordInfo,
  Biometrics,
  // InformationMessage,
  // GeoLocalizacionInfo,
  EquivalenciasTables,
} from './index';
import { TableBinnacle } from '../table';
import {
  UseImage,
  FormDynamic,
  CustomLoadingOverlay,
  DocumentUploadComponent,
  InformationalMessageComponent,
  GenericHeaderActions,
} from '../index';
import { CustomAlert } from '../CustomAlert';
import moment from 'moment';
import NoShares from '../NoShares';
import { FileInfo } from '../../store/reducers/FileReducer';
import Summary from '../Summary';
import StageStepper from '../StageStepper/StageStepper';
import { DynamicForm } from '../LEGOS/DynamicForm';
import { Simulation } from '../LEGOS/Simulation';
import {CustomerSearch} from "components/LEGOS/CustomerSearch";
import {AccountSelectionForm} from "components/LEGOS/AccountSelection";
import { SupplementaryData } from 'components/LEGOS/SupplementaryData';
import { SelectOptionsComponents } from 'components/LEGOS/SelectOptions';
import {XM3Results} from "../LEGOS/XM3Results";
import {BiometricResults} from "../LEGOS/BiometricResults";
import {RequestDocumentationViewer} from "../LEGOS/RequestDocumentationViewer";
import { DocumentaryUpload } from 'components/LEGOS/DocumentaryUpload';

//*TABS*
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      style={{ height: '100%', minHeight: '60vh' }}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export function FullWidthTabs({ currentActor, actorId, multipleActors }) {
  const theme = useTheme();
  const localStyles = useStyles(theme);
  const navigation = useNavigate();
  const cardStyle = mainStyles.cards();
  const noInfo = mainStyles.noDataSections();

  const [actorCards, setActorCards] = useState([]);
  const [infoFileItems, setInfoFileItems] = useState([]);
  const [docsCard, setDocsCard] = useState([]);
  const [validation, setValidation] = useState({});
  const [docsApproved, setDocsApproved] = useState([]);
  const [listEquivalencias, setListEquivalencias] = useState([]);
  const [value, setValue] = useState(0);

  const [openHelpFinalice, setOpenHelpFinalice] = useState(false);
  const [openHelpFinReject, setOpenHelpFinReject] = useState(false);
  const [wfContinueRequest, setWFContinueRequest] = useState(false);
  const [fileUpdateMetFile, setFileUpdateMetFile] = useState(false);
  const [approvedValideProcess, setApprovedValideProcess] = useState(false);
  const [activeButtonFinalize, setActiveButtonFinalize] = useState(false);
  const [viewButtonFinalice, setViewButtonFinalice] = useState(true);

  const [idUsuario, setIdUsuario] = useState('');

  const [sendCuestionarioContinue, setSendCuestionarioContinue] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(-1);

  const dispatch = useDispatch();

  // Datos dummy de entrada para GeneralInfo
  const dataDummy = {
    Request: 'N-0000225052',
    RequestNumber: '0000225052',
    Status: 'En progreso',
    ProcedureType: 'Renovación',
    Stage: 'Revisión',
    Substage: 'Documentación',
    LegalEntity: 'Persona Física',
    RFC: 'ABC123456XYZ',
    FullName: 'John Doe',
    BusinessName: 'DS Corporation',
    Type: 'Individual',
    SubProcedureType: 'Proceso',
    CreditType: 'Negocio',
    Program: 'Business Credit',
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.secondary.light,
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  //*ACTORS*
  // this is used for 1 actor and it goes for GetBinnacleInfo and GetRecordInfo
  const { id } = useParams();

  //this is used for one actor in a group of multiple actors and it goes for GetBinnacleInfo and GetRecordInfo
  const actorBody = {
    File_Id: id,
    Actor_Id: actorId,
  };

  const validateAuth = useSelector((state) => state.Authentication);
  const actor = useSelector((state) => state.ActorInfo);
  const fileUpdateData = useSelector((state) => state.FileUpdateMetData);
  const processRulesData = useSelector((state) => state.ProcessValRulesData);
  const userCurrentRol = useSelector((state) => state.FileInfo?.items?.Stage?.Roles[0]?.Name);
  const userCurrentEmail = useSelector((state) => state.FileInfo?.items?.CurrentUser);
  const rolUser = useSelector(
    (state) => state.Authentication.items?.UserLoginData?.SecurityLoginData?.Roles[0]?.Name,
  );
  const userEmail = useSelector((state) => state.Authentication.items?.UserLoginData?.Username);
  const documentsRolUser = useSelector((state) => state.GetDocumentsGroupByRol);
  const selection = useSelector((state) => state.OptionsToSelect);
  // console.log(rolUser, userCurrentRol, 'rolUser');
  const state = useSelector((state) => state);
  console.log('REDUX', state);

  console.log('validate', validateAuth);
  const [loadView, setLoadView] = useState(false);

  const getInfoDispatch = () => {
    dispatch(fileActions.GetFileInfo(id));
  };
  const getActorInfoDispatch = () => {
    dispatch(fileActions.GetActorInfo(actorId));
  };
  const setBioDispatch = () => {
    dispatch(biometricActions.GetBiometricData(actorBody));
  };

  const getGeoLocationDispatch = () => {
    dispatch(
      validationActions.GetRegisterGeolocationFile({
        File_Id: id,
      }),
    );
  };

  const regisGeoLocData = useSelector((state) => state.RegisGeolocationData);
  useEffect(() => {
    if (selection.items) {
      getInfoDispatch();
    }
  }, [selection]);
  useEffect(() => {
    dispatch(workFlowActions.GetBinnacleInfo(actorBody));
    dispatch(fileActions.GetRecordInfo(actorBody));
    dispatch(fileActions.GetFileInfo(id));
    dispatch(
      validationActions.GetRegisterGeolocationFile({
        File_Id: id,
      }),
    );
    dispatch(validationActions.GetCustomValidationFile(actorBody));
    getInfoDispatch();
    getActorInfoDispatch();
    setBioDispatch();
    // console.log('currentAct', currentActor)
  }, []);

  useEffect(() => {
    if (Object.keys(currentActor).length !== 0) {
      dispatch(workFlowActions.GetBinnacleInfo(actorBody));
      dispatch(fileActions.GetRecordInfo(actorBody));
      dispatch(validationActions.GetCustomValidationFile(actorBody));
      getActorInfoDispatch();
      setBioDispatch();
    }
  }, [currentActor]);

  useEffect(() => {
    if (actor.itemsCardActor !== undefined) {
      setActorCards(actor.itemsCardActor);
    }
  }, [actor]);

  //Binnacle
  const info = useSelector((state) => state.BinnacleInfo);
  const [infoItems, setInfoItems] = useState([]);

  useEffect(() => {
    if (info.items !== undefined) {
      setInfoItems(info.items);
    }
  }, [info]);

  //RecodFile
  const record = useSelector((state) => state.RecordInfo);
  const [infoRecordItems, setInfoRecordItems] = useState([]);

  console.log('RecordInfo', record);
  useEffect(() => {
    if (record.items !== undefined) {
      // console.log("setInfoRecordItems", record.items)
      setInfoRecordItems(record.items);
    }
  }, [record]);

  useEffect(() => {
    getInfoDispatch();
    getActorInfoDispatch();
  }, [fileUpdateData]);

  //infoFile
  // Biometricos
  const [biometricInfo, setBiometricInfo] = useState({});

  const infoFile = useSelector((state) => state.FileInfo);
  const biometrics = useSelector((state) => state.BiometricData);
  const wfContinueData = useSelector((state) => state.ContinueWorkFlowData);

  console.log('infoFileinfoFile', infoFile);

  useEffect(() => {
    setLoadView(infoFile.loading);
    if (infoFile.items !== undefined) {
      console.log('IDROLES', infoFile);
      dispatch(documentActions.GetDocumentsGroupByRol({ File_Id: infoFile.items.Id }));
      // console.log("infoFile.items************************************: ", infoFile)
      setInfoFileItems(infoFile.items);
      // console.log("setDocsCard(infoFile.itemsDocCard)", infoFile.itemsDocCard)
      setDocsCard(infoFile.itemsDocCard);
      setValidation(infoFile.itemsValidation);
      setDocsApproved(infoFile.itemsDocsForApproval);
      setListEquivalencias(infoFile.itemsEquivalencias);

      let validaCount = infoFile.itemsEquivalencias.filter((item) => item.validacion === null);
      // console.log("validaCount*********************: ", validaCount)

      if (validaCount.length > 0) {
        setActiveButtonFinalize(false);
      } else {
        setActiveButtonFinalize(true);
      }

      if (
        infoFile.items?.Status === 'Validación de Mesa de control' ||
        infoFile.items?.Status === 'Validación documental' ||
        infoFile.items?.Status === 'Validación de documentos'
      ) {
        setViewButtonFinalice(true);
      } else {
        setViewButtonFinalice(false);
      }
    }
  }, [infoFile]);

  useEffect(() => {
    // console.log("regisGeoLocData.items--------------------------------------------: ", regisGeoLocData)
    if (regisGeoLocData.items !== undefined) {
      // console.log("regisGeoLocData.items--------------------------------------------: ", regisGeoLocData.items)
    } else if (regisGeoLocData.error) {
      // console.log("Error: ", regisGeoLocData.error)
    }
  }, [regisGeoLocData]);

  useEffect(() => {
    // console.log("biometrics.items***********-------- ", biometrics)
    if (biometrics.items !== undefined) {
      // console.log("biometrics.items-------- ", biometrics.items)
      setBiometricInfo(biometrics.items);
    }
  }, [biometrics]);

  useEffect(() => {
    console.log(
      'biometrics.items***********-------- wfContinueRequest',
      wfContinueRequest,
      wfContinueData.items,
    );
    if (wfContinueData.items !== undefined && wfContinueRequest) {
      console.log(
        'Se recibe stage de confirmacion******wfContinueData.items ********: ',
        wfContinueData.items,
      );
      setWFContinueRequest(false);
      getInfoDispatch();
    } else if (wfContinueData.error !== undefined && wfContinueRequest) {
      setWFContinueRequest(false);
      console.log('wfContinueData.error-------- ', wfContinueData.error);
    }
  }, [wfContinueData]);

  const customValidFile = useSelector((state) => state.CustomValidationFile);
  const [infoValidationFile, setInfoValidationFile] = useState([]);

  useEffect(() => {
    if (customValidFile.items !== undefined) {
      // console.log("customValidFile.items: ", customValidFile.items)
      setInfoValidationFile(customValidFile.items);
    }
  }, [customValidFile]);

  useEffect(() => {
    if (fileUpdateData.items !== undefined) {
      console.log('333');

      getInfoDispatch();
    }
  }, [fileUpdateData]);

  useEffect(() => {
    setLoadView(fileUpdateData.loading);
    console.log('fileUpdate', sendCuestionarioContinue);
    if (fileUpdateData.items !== undefined && fileUpdateMetFile) {
      console.log('fileUpdate2', sendCuestionarioContinue);

      // console.log("fileUpdateData.items: ", fileUpdateData.items)
      setFileUpdateMetFile(false);
      if (sendCuestionarioContinue) {
        console.log(
          '****-----sendCuestionarioContinue-----******************',
          sendCuestionarioContinue,
        );
        setSendCuestionarioContinue(false);
        setLoadView(false);
        setTimerSeconds(3);
        getInfoDispatch();
      } else {
        console.log('fileUpdate1', sendCuestionarioContinue);

        // console.log("excute ProcessValidationRules fileId: ", id)
        setApprovedValideProcess(true);
        dispatch(validationActions.ProcessValidationRules(id));
      }
    } else if (fileUpdateData.error !== undefined && fileUpdateMetFile) {
      console.log('fileUpdateData.error: ', fileUpdateData.error);
      setLoadView(false);
      setFileUpdateMetFile(false);
    }
  }, [fileUpdateData]);

  useEffect(() => {
    if (processRulesData.error !== undefined && approvedValideProcess) {
      setLoadView(false);
      setApprovedValideProcess(false);
      alert(processRulesData.error);
    } else if (processRulesData.items !== undefined && approvedValideProcess) {
      setLoadView(false);
      setApprovedValideProcess(false);
      console.log('excute processRulesData.items  refresh: ', processRulesData.items);
      getInfoDispatch();
    }
  }, [processRulesData]);

  // useEffect(() => {
  //   // console.log("**********Ejecutar timer, timerSecond************s: ", timerSeconds)

  //   if (timerSeconds == 0) {
  //     //TODO:Ejecutar la accionb timer.
  //     // console.log("Ejecutar timer, timerSeconds: ", timerSeconds)
  //     if (infoFile.items?.Stage?.Description === "Mensaje de asignación al Representante Legal") {
  //       setTimerSeconds(-2);
  //       setLoadView(false)
  //     } else {
  //       if (infoFile.items?.Stage?.Name === "Información de la Solicitud" && infoFile.items?.Stage?.Properties?.filter(item => item.Name === "GoToDashboard" && item.Value === "1")?.length > 0) {
  //         setTimerSeconds(-2);
  //         setLoadView(false)
  //       } else {
  //         setLoadView(true)
  //         setTimerSeconds(5);
  //         getInfoDispatch()
  //       }
  //     }
  //   }
  //   // salir cuando lleguemos a 0
  //   if (!timerSeconds) return;
  //   if (timerSeconds < -1) return;

  //   // save intervalId para borrar el intervalo cuando el componente vuelve a renderizar
  //   const intervalId = setInterval(() => {
  //     setTimerSeconds(timerSeconds - 1);
  //   }, 1500);

  //   // clear interval al volver a renderizar para evitar pérdidas de memoria
  //   return () => clearInterval(intervalId);
  // }, [timerSeconds]);

  // useEffect(() => {
  //   if (validate.error !== undefined) {
  //     alert(validate.error)
  //   }
  //   if (validate.items !== undefined) {

  //   }
  // }, [validate])

  const handleSendFinaliceProcess = (e) => {
    // console.log("listEquivalencias:****** ", listEquivalencias)

    let pedingValidation = listEquivalencias.filter((item) => item.validacion == false);
    // console.log("pedingValidation:****** ", pedingValidation)
    //TOdo se agregara validacion para verificar que no haya documentos faltantes de validar o que fueron marcados como incorrectos.
    // si todo es correcto se manda el codigo siguiente. de lo contrario se manda el Modal de seleccion
    if (pedingValidation.length > 0) {
      setOpenHelpFinalice(false);
      setOpenHelpFinReject(true);
    } else {
      let objRequest = {
        File_Id: id,
        Status: 'Completo',
        Result: 'Completo',
      };
      dispatch(workFlowActions.ContinueWorkFlow(objRequest));
      setWFContinueRequest(true);
      setOpenHelpFinalice(false);
      console.log('ENTRO AQUI A FINALIZAR');
      // navigation('/inbox/homeComponent');
      // console.log("objRequest: Finalice: ", objRequest)
    }
  };

  const handleSendLeftFinalice = (e) => {
    let objRequest = {
      File_Id: id,
      Status: 'Completo',
      Result: 'Rechazado',
    };

    // console.log("objRequest: Finalice:  Rechazado", objRequest)
    dispatch(workFlowActions.ContinueWorkFlow(objRequest));
    setWFContinueRequest(true);
    setOpenHelpFinReject(false);
  };

  const handleSendRightFinalice = (e) => {
    let objRequest = {
      File_Id: id,
      Status: 'Completo',
      Result: 'Recaptura',
    };

    dispatch(workFlowActions.ContinueWorkFlow(objRequest));
    setWFContinueRequest(true);
    // console.log("objRequest: Finalice Recaptura: ", objRequest)
    setOpenHelpFinReject(false);
  };

  console.log('infoFile.items?.Stage?.QuestionGroups', infoFile.items?.Stage?.QuestionGroups);

  const handleSaveFile = async (formData, e) => {
    // console.log("formData, e**********: ", formData, e)

    // let currentMetadata = infoFile.items?.Stage?.QuestionGroups?.find(itemDoc => itemDoc.Name === )
    // console.log("currentMetadata: ", currentMetadata)
    let ListMetdata = [];

    infoFile.items?.Stage?.QuestionGroups?.forEach((meta, i) => {
      meta?.Questions.forEach((itemQ) => {
        // console.log("itemQ********************: ", itemQ)
        ListMetdata.push({
          GroupName: meta.Name,
          Name: itemQ.Name,
          Value:
            itemQ.QuestionType === 'Fecha'
              ? formData[itemQ.Id] === ''
                ? null
                : moment(formData[itemQ.Id]).format('DD/MM/YYYY')
              : formData[itemQ.Id],
          ApprovedConfidence: null,
          Confidence: null,
          MetadataType: null,
          ValueType: null,
        });
      });
    });

    // console.log("ListMetdata: ", ListMetdata)

    let Request = {
      ObjectId: id,
      Metadata: ListMetdata,
      ContinueWorkFlow: false,
    };
    console.log('Request Update metadata**********************: ', Request);
    dispatch(fileActions.FileUpdateMetadata(Request));
    setFileUpdateMetFile(true);
    setLoadView(true);
  };

  const handleOneClickFinaliceProcess = (e) => {
    // console.log("listEquivalencias:****** ", listEquivalencias)
    let pedingValidation = listEquivalencias.filter((item) => item.validacion == false);
    // console.log("pedingValidation:****** ", pedingValidation)
    if (pedingValidation.length > 0) {
      setOpenHelpFinalice(false);
      setOpenHelpFinReject(true);
    } else {
      setOpenHelpFinalice(true);
    }
  };

  const handleFinaliceValidaction = (IsRechazed, e) => {
    console.log('handleFinaliceValidaction sRechazed:****** ', IsRechazed);

    let objRequest = {
      File_Id: id,
      Status: 'Completo',
      Result: IsRechazed === true ? 'Rechazado' : 'Completo',
    };

    console.log('handleFinaliceValidaction**** objRequest: ', objRequest);
    dispatch(workFlowActions.ContinueWorkFlow(objRequest));
    setWFContinueRequest(true);
    console.log('ENTRO AQUI A FINALIZAR');
    // navigation('/inbox/homeComponent');
  };

  const handleContinueMessage = (e) => {
    setTimerSeconds(-1);
    // console.log("Ejecuta action handleContinueMessage:")
    navigation('/inbox/homeComponent');
    // setDataCurrent(null)
    // Alert({
    //   icon: "success",
    //   title: "Usuario activado.",
    // });
    // dispatch(productActions.GetProductHiring(""))
  };

  // console.log('documentsRolUser 1', documentsRolUser?.Groups)
  // console.log('documentsRolUser 2', actorCards)
  // console.log('documentsRolUser 3', docsCard)

  useEffect(() => {
    console.log('infoFileinfoFile', infoFile.items?.Stage?.Name);
    let intervalId;

    if (
      infoFile.items !== undefined &&
      (infoFile.items.Stage.Name === 'RegisterDocumentMatches' ||
        infoFile.items.Stage.Name === 'Notificación' ||
        infoFile.items.Stage.Name === 'Generación de documentos' ||
        infoFile.items.Stage.Name === 'AsignacionMetadatos' ||
        infoFile.items.Stage.Name === 'Resumen' ||
        infoFile.items.Stage.Name === 'AsignarSolicitud')
    ) {
      if (!intervalId) {
        intervalId = setInterval(() => {
          console.log('infoFileinfoFile', id);
          dispatch(fileActions.GetFileInfo(id));
        }, 7000);
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [infoFile.items]);

  // useEffect(() => {
  //   let intervalId;

  //   if (infoFile.items !== undefined && infoFile.items.Stage.Name === "Cuestionario dinamico" && infoFile.items.Stage.Description === "Datos Kit de contratación") {
  //     // Verificar si ya hay un intervalo en ejecución
  //     setIdUsuario(infoFile.items.Id)
  //     console.log("infoFileinfoFile entra a guardar", infoFile.items?.Stage?.Name)
  //   } else if (infoFile.items == undefined && infoFile.error != undefined && (idUsuario != undefined || idUsuario != null || idUsuario != "")) {
  //     console.log("idUsuario entro aqui a refrescar", idUsuario)
  //     intervalId = setInterval(() => {
  //       console.log("idUsuario", idUsuario, id)
  //       dispatch(fileActions.GetFileInfo(idUsuario))
  //     }, 15000);
  //   }

  //   return () => {
  //     clearInterval(intervalId);
  //   };

  // }, [infoFile.items, infoFile.error]);

  console.log('idUsuario', idUsuario, infoFile.items);

  return (
    <div className={localStyles.container}>
      {loadView == true ? (
        <CustomLoadingOverlay active={loadView} text="Espere un momento por favor..." />
      ) : null}

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{
            indicator: localStyles.indicator,
          }}
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab
            label="Datos"
            {...a11yProps(0)}
            classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }}
          />
          <Tab
            label="Información de expediente"
            {...a11yProps(1)}
            classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }}
          />
          <Tab
            label="Acciones"
            {...a11yProps(2)}
            classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }}
          />
          <Tab
            label="Documentación"
            {...a11yProps(3)}
            classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }}
          />
          <Tab label="Validaciones" {...a11yProps(4)} classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }} />
          {/* <Tab label="Resultados biométricos" {...a11yProps(5)} classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }} /> */}
          {/* <Tab label="Geolocalización" {...a11yProps(6)} classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }} />  */}
          <Tab
            label="Bitácora de auditoría"
            {...a11yProps(4)}
            classes={{ root: localStyles.tabRoot, selected: localStyles.selectedTab }}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div>
            {infoFile.error && <p>{infoFile.error}</p>}
            {
              infoFile.loading || actor.loading ? (
                <div className={localStyles.spinSection}>
                  <CustomLoadingOverlay
                    active={infoFile.loading}
                    text="Espere un momento por favor..."
                  />
                </div>
              ) : Object.keys(currentActor).length !== 0 && actor.items !== undefined ? (
                <div className={localStyles.scroll}>
                  {/*Cambiar por los datos de la API: data={infoFile.items}*/}
                  <GeneralInfo
                    data={dataDummy}
                    docs={docsCard}
                    val={validation}
                    actors={multipleActors}
                    getInfoDispatch={getInfoDispatch}
                  />
                </div>
              ) : (
                infoFile.items !== undefined && (
                  <div className={localStyles.scroll}>
                    <GeneralInfo
                      data={dataDummy}
                      docs={docsCard}
                      val={validation}
                      actors={multipleActors}
                      getInfoDispatch={getInfoDispatch}
                    />
                  </div>
                )
              )

              // (validation.ManualDocValidation !== undefined && validation.ManualDocValidation !== null
              //   && validation.ManualDocValidation === "1" && validate.items !== undefined)
              //   ?
              //   <InformationMessage data={infoFile.items} />
              //   :
              //   Object.keys(currentActor).length !== 0
              //     && actor.items !== undefined
              //     ? <div className={localStyles.scroll}>
              //       <ActorGralInfo data={actor.items} docs={actorCards} val={validation} getInfoDispatch={getActorInfoDispatch} />
              //     </div> : infoFile.items !== undefined &&
              //     <div className={localStyles.scroll}>
              //       <GeneralInfo data={infoFile.items} docs={docsCard} val={validation} actors={multipleActors} getInfoDispatch={getInfoDispatch} />
              //     </div>
            }
          </div>
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <div>
            <div className={localStyles.scroll}>
              <GenericHeaderActions
                LabelTitle="Información de expediente"
                onClickConfirm={() => {
                  dispatch(fileActions.GetRecordInfo(actorBody));
                  dispatch(validationActions.GetCustomValidationFile(actorBody));
                }}
                ConfirmButtonText="Actualizar"
              />

              <StageStepper />

              {console.log('infoRecordItemsAll', infoRecordItems)}
              {record.error && <p>{record.error}</p>}
              {record.loading ? (
                <div className={localStyles.spinSection}>
                  <CustomLoadingOverlay
                    active={record.loading}
                    text="Espere un momento por favor..."
                  />
                </div>
              ) : infoRecordItems.length > 0 || infoValidationFile.length > 0 ? (
                <RecordInfo data={infoRecordItems} dataValidationFile={infoValidationFile} />
              ) : infoRecordItems.length === 0 ? (
                <div className={noInfo.noData}>
                  <UseImage src={noDataToShow} type="img" />
                  <Typography variant="h6" className={noInfo.h6}>
                    Sin datos para mostrar
                  </Typography>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </TabPanel>

        <TabPanel value={value} index={2} dir={theme.direction}>
          {infoFile.items?.Stage?.Name === 'Información de la Solicitud' ||
          infoFile.items?.Stage?.Name === 'Cuestionario dinamico' ||
          infoFile.items?.Stage?.Name === 'Validación Documental' ||
          infoFile.items?.Stage?.Name === 'Simulacion' ||
          infoFile.items?.Stage?.Name === 'Carga documental' ||
          infoFile.items?.Stage?.Name === 'Busqueda Cliente' ||
          infoFile.items?.Stage?.Name === 'Seleccion Cuentas' ||
          infoFile.items?.Stage?.Name === 'Seleccion' ? (
            <>
              {console.log(
                'ROLES',
                infoFile.items?.Stage?.Name,
                infoFile.items?.Status,
                userCurrentRol === rolUser || userCurrentEmail === userEmail,
              )}
              {console.log('infoFile.items?, infoFile..............', infoFile.items, infoFile)}
              {/* {console.log("userCurrentRol---------: ", userCurrentRol, 'rolUser: ', rolUser, "userCurrentEmail: ", userCurrentEmail, "userEmail: ", userEmail)} */}

              {/* <GenericHeaderActions
                  LabelTitle={
                    infoFile.items?.Status === 'Validación Documental por HR Analyst'
                      ? 'Verifica que la documentación cargada por el candidato sea correcta y aprueba o rechaza según sea el caso.'
                      : infoFile.items?.Status ===
                        'Validación Documental por HR Analyst 2da validación'
                      ? 'Verifica que la documentación cargada por el verificador de antecedentes sea correcta y aprueba o rechaza según sea el caso.'
                      : infoFile.items?.Stage?.Description
                  }
                  ConfirmButtonStartIcon={<RefreshIcon />}
                  onClickConfirm={() => {
                    getInfoDispatch();
                  }}
                  ConfirmButtonText="Actualizar"
                /> */}

              {infoFile.items !== undefined &&
              infoFile.items?.Stage?.Name === 'Carga documental' ? (
                // <DocumentUploadComponent Data={infoFile.items} />
                <DocumentaryUpload Data={infoFile.items} />
              ) : null}

              {infoFile.items?.Stage?.Name === 'Información de la Solicitud' ? (
                // && (infoFile.items?.Status === "Mensaje de asignación a Background Check Vendor"
                //   || infoFile.items?.Status === "Mensaje de asignación a Candidato, Rechazo" || infoFile.items?.Status === "Mensaje de asignación a HR Analyst 2da validación")
                <InformationalMessageComponent
                  actionResset={handleContinueMessage}
                  Data={infoFile.items}
                  refresh={getInfoDispatch}
                />
              ) : null}
              {console.log('infoFile.items?.Status', infoFile.items)}
              {infoFile.items !== undefined &&
              (infoFile.items?.StageName === 'Validación de Mesa de control' ||
                infoFile.items?.StageName === 'Validación Documental' ||
                infoFile.items?.StageName === 'Validación de documentos' ||
                infoFile.items?.StageName === 'Captura de datos por HR Analyst' ||
                infoFile.items?.StageName === 'Validación KIT contratacion' ||
                infoFile.items?.StageName === 'Cuestionario dinamico' ||
                infoFile.items?.StageName === 'Busqueda Cliente' ||
                infoFile.items?.StageName === 'Seleccion Cuentas' ||
                infoFile.items?.StageName === 'Simulacion' ||
                infoFile.items?.Stage?.Name === 'Seleccion' ||
                infoFile.items?.StageName === 'Validación Documental' ||
                infoFile.items?.StageName === 'Validaciones' ||
                infoFile.items?.StageName === 'Seleccion') ? (
                <>
                  {console.log(
                    '-----!!!!infoFile.itemsDocCardValidation------: ',
                    infoFile.itemsDocCardValidation,
                  )}
                  {infoFile.itemsDocCardValidation?.length > 0 ? (
                    <Documentation
                      IsValidate={true}
                      cards={infoFile.itemsDocCardValidation}
                      val={validation}
                      docsA={docsApproved}
                      cActor={currentActor}
                      refresh={getInfoDispatch}
                      bioData={biometricInfo}
                      FinaliceValidaction={handleFinaliceValidaction}
                      fileId={id}
                      activaFinalizaValidacion={true}
                    />
                  ) : null}
                  {infoFile.items?.Stage?.QuestionGroups?.length > 0 &&
                  infoFile.items?.Stage?.Name === 'Cuestionario dinamico' ? (

                    <DynamicForm
                      StageCurrent={infoFile.items}
                      handleSave={(formData, e) => {
                        let ListMetdata = [];
                        console.log('FormDynamic formData: ', formData);

                        infoFile.items?.Stage?.QuestionGroups?.forEach((meta, i) => {
                          meta?.Questions.forEach((itemQ) => {
                            // console.log("itemQ********************: ", itemQ)
                            ListMetdata.push({
                              GroupName: meta.Name,
                              Name: itemQ.Name,
                              Value:
                                itemQ.QuestionType === 'Fecha'
                                  ? formData[itemQ.Id] === ''
                                    ? null
                                    : moment(formData[itemQ.Id]).format('DD/MM/YYYY')
                                  : itemQ.QuestionType === 'Currency'
                                    ? formData[itemQ.Id]
                                      ?.replace('$', '')
                                      ?.replace(',', '')
                                      ?.replace(',', '')
                                    : formData[itemQ.Id],
                              ApprovedConfidence: null,
                              Confidence: null,
                              MetadataType: null,
                              ValueType: null,
                            });
                          });
                        });

                        console.log('ListMetdata: ', ListMetdata);

                        let Request = {
                          ObjectId: id,
                          Metadata: ListMetdata,
                          ContinueWorkFlow: true,
                        };
                        console.log('Request Update metadata**********************: ', Request);
                        setSendCuestionarioContinue(true);
                        dispatch(fileActions.FileUpdateMetadata(Request));
                        setFileUpdateMetFile(true);
                        setLoadView(true);
                      }}
                    />
                  ) : infoFile.items?.Stage?.Name === 'Busqueda Cliente' ? (
                    <CustomerSearch/>
                  ) : infoFile.items?.Stage?.Name === 'Seleccion Cuentas' ? (
                      <AccountSelectionForm/>
                    ) :
                    infoFile.items?.Stage?.QuestionGroups?.length > 0 &&
                    infoFile.items?.Stage?.Name === 'Simulacion' ? (
                      <Simulation getInfoDispatch={getInfoDispatch} />
                    ) : infoFile.items?.Stage?.Name === 'Seleccion' ? (
                        <SelectOptionsComponents folioId={id} actorId={actorId} />
                      ) :
                      infoFile.items?.Stage?.QuestionGroups?.length > 0 ? (
                        <Accordion classes={{ root: localStyles.group }}>
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={localStyles.textGroup}>Cuestionario</Typography>
                          </AccordionSummary>
                          <AccordionDetails className={localStyles.details}>
                            <FormDynamic StageCurrent={infoFile.items} handleSave={handleSaveFile} />
                          </AccordionDetails>
                        </Accordion>
                      ) : null}
                </>
              ) : null}

              {infoFile.error && <p>{infoFile.error}</p>}
              {(infoFile.loading || actor.loading) && listEquivalencias?.length <= 0 ? (
                <div className={localStyles.spinSection}>
                  <CustomLoadingOverlay
                    active={infoFile.loading}
                    text="Espere un momento por favor..."
                  />
                </div>
              ) : infoFile.items?.Stage?.Name !== 'Carga documental' &&
              infoFile.items?.Stage?.Name !== 'Información de la Solicitud' &&
              infoFile.items?.Stage?.Name !== 'Validación Documental' &&
              infoFile.items?.Status !== 'Validación Documental por HR Analyst' &&
              infoFile.items?.Stage?.Name !== 'Cuestionario dinamico' &&
              infoFile.items?.Stage?.Name !== 'Simulacion' &&
              infoFile.items?.Stage?.Name !== 'Seleccion' &&
              infoFile.items?.Stage?.Name !== 'Busqueda Cliente' &&
              infoFile.items?.Stage?.Name !== 'Seleccion Cuentas' &&
              infoFile.items?.Status !== 'Captura de datos por HR Analyst' ? (
                <EquivalenciasTables
                  items={listEquivalencias}
                  val={validation}
                  refresh={getInfoDispatch}
                  fileId={id}
                  status={infoFile.items?.Status}
                />
              ) : null}

              <CustomAlert
                closeAction={() => {
                  setOpenHelpFinalice(false);
                }}
                open={openHelpFinalice}
                title="Finalizar validación"
                body="El proceso de validación será definido como correcto y avanzará a los siguientes pasos."
                leftButtonText="Cancelar"
                leftButtonAction={() => {
                  setOpenHelpFinalice(false);
                }}
                rightButtonText="Aceptar"
                rightButtonAction={handleSendFinaliceProcess}
              />

              <CustomAlert
                closeAction={() => {
                  setOpenHelpFinReject(false);
                }}
                open={openHelpFinReject}
                title="Finalizar validación"
                body="Tu expediente contiene documento(s) marcado(s) como incorrecto, ¿Deseas finalizar el expediente con estatus incorrecto? o solicitar los documentos incorrectos al candidato."
                leftButtonText="Rechazar Solicitud"
                leftButtonAction={handleSendLeftFinalice}
                rightButtonText="Solicitar Documentos"
                rightButtonAction={handleSendRightFinalice}
              />
            </>
          ) : (infoFile.items == undefined &&
            (infoFile.error != undefined || infoFile.error != null || infoFile.error != '')) ||
          infoFile.items.Stage.Name === 'Firma de documentos' ||
          infoFile.items.Stage.Name === 'Ejecución paralela' ||
          infoFile.items.Stage.Name === 'Finalizar Paralelo' ? (
            <div>
              {/* (infoFile.items?.Stage?.Name === "RegisterDocumentMatches" && (validateAuth.usuario !== "oficial.cumplimiento"  validateAuth.usuario !== "talent.acquisition")  */}
              <NoShares />
            </div>
          ) : (
            <Summary />
          )}
        </TabPanel>

        <TabPanel value={value} index={3} dir={theme.direction}>
          <div className={localStyles.scroll}>
            {/*<div className={localStyles.scroll}>*/}
            {/*  <GenericHeaderActions*/}
            {/*    LabelTitle="Documentación cargada"*/}
            {/*    toolTip={*/}
            {/*      <HtmlTooltip*/}
            {/*        title={*/}
            {/*          <React.Fragment>*/}
            {/*            <Typography variant="p" className={localStyles.docs}>*/}
            {/*              Estatus de la documentación:*/}
            {/*            </Typography>*/}
            {/*            <br></br>*/}
            {/*            <Typography variant="p" className={localStyles.docs}>*/}
            {/*              {' '}*/}
            {/*              <CheckCircleIcon style={{ color: '#28A745' }} />*/}
            {/*              Documento aprobado*/}
            {/*            </Typography>*/}
            {/*            <br></br>*/}
            {/*            <Typography variant="p" className={localStyles.docs}>*/}
            {/*              {' '}*/}
            {/*              <CancelIcon style={{ color: 'red' }} />*/}
            {/*              Documento rechazado*/}
            {/*            </Typography>*/}
            {/*            <br></br>*/}
            {/*            <Typography variant="p" className={localStyles.docs}>*/}
            {/*              {' '}*/}
            {/*              <CircleIcon style={{ color: '#28A745' }} />*/}
            {/*              Documento cargado*/}
            {/*            </Typography>*/}
            {/*          </React.Fragment>*/}
            {/*        }*/}
            {/*        placement="bottom-start"*/}
            {/*      >*/}
            {/*        <HelpIcon />*/}
            {/*      </HtmlTooltip>*/}
            {/*    }*/}
            {/*    onClickConfirm={() => {*/}
            {/*      getInfoDispatch();*/}
            {/*      getActorInfoDispatch();*/}
            {/*    }}*/}
            {/*    ConfirmButtonText="Actualizar"*/}
            {/*    ConfirmButtonStartIcon={<RefreshIcon />}*/}
            {/*  />*/}

            {/*  {console.log('docsCard---------- ', docsCard)}*/}
            {/*  {infoFile.error && <p>{infoFile.error}</p>}*/}
            {/*  {*/}
            {/*    infoFile.loading || actor.loading ? (*/}
            {/*      <div className={localStyles.spinSection}>*/}
            {/*        <CustomLoadingOverlay*/}
            {/*          active={infoFile.loading}*/}
            {/*          text="Espere un momento por favor..."*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    ) : actorCards?.length > 0 && Object.keys(currentActor).length !== 0 ? (*/}
            {/*      <Documentation*/}
            {/*        cards={actorCards}*/}
            {/*        val={validation}*/}
            {/*        docsA={docsApproved}*/}
            {/*        cActor={currentActor}*/}
            {/*        refresh={getActorInfoDispatch}*/}
            {/*        fileId={id}*/}
            {/*      />*/}
            {/*    ) : (*/}
            {/*      <Documentation*/}
            {/*        IsValidate={false}*/}
            {/*        cards={*/}
            {/*          documentsRolUser?.itemsReorder?.length == 0*/}
            {/*            ? docsCard*/}
            {/*            : documentsRolUser?.itemsReorder*/}
            {/*        }*/}
            {/*        val={validation}*/}
            {/*        docsA={docsApproved}*/}
            {/*        cActor={currentActor}*/}
            {/*        refresh={getInfoDispatch}*/}
            {/*        bioData={biometricInfo}*/}
            {/*        fileId={id}*/}
            {/*      />*/}
            {/*    )*/}
            {/*    // <Documentation IsValidate={false} cards={documentsRolUser?.itemsReorder ?? docsCard} val={validation} docsA={docsApproved} cActor={currentActor} refresh={getInfoDispatch} bioData={biometricInfo} fileId={id} />*/}
            {/*  }*/}
            {/*</div>*/}

            <RequestDocumentationViewer/>

          </div>
        </TabPanel>

        <TabPanel value={value} index={4} dir={theme.direction}>
          <div>
            <XM3Results/>
            <BiometricResults/>
          </div>
        </TabPanel>

        {/* <TabPanel value={value} index={4} dir={theme.direction}>
          <div>
            <div className={localStyles.scroll}>
              <GenericHeaderActions LabelTitle="Equivalencias" onClickConfirm={handleOneClickFinaliceProcess}
                ConfirmButtonText="Finalizar" ConfirmButtonDisabled={!viewButtonFinalice} />

              {infoFile.error && <p>{infoFile.error}</p>}
              {(infoFile.loading || actor.loading) && listEquivalencias?.length <= 0 ?
                <div className={localStyles.spinSection}>
                  <CustomLoadingOverlay
                    active={infoFile.loading} text="Espere un momento por favor..."
                  />
                </div> : <EquivalenciasTables items={listEquivalencias}
                  val={validation} refresh={getInfoDispatch}
                  fileId={id}
                  status={infoFile.items?.Status} />
              }

              {infoFile.items !== undefined
                && (infoFile.items?.Status === "Validación de Mesa de control" ||
                  infoFile.items?.Status === "Validación documental" ||
                  infoFile.items?.Status === "Validación de documentos")
                && infoFile.items?.Stage?.QuestionGroups?.length > 0 ?
                <Accordion classes={{ root: localStyles.group }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={localStyles.textGroup} >Cuestionario</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={localStyles.details}>
                    <FormDynamic
                      StageCurrent={infoFile.items}
                      handleSave={handleSaveFile}
                    />
                  </AccordionDetails>
                </Accordion>
                : null}
            </div>

            <CustomAlert
              closeAction={() => {
                setOpenHelpFinalice(false);
              }}
              open={openHelpFinalice}
              title="Finalizar validación"
              body="El proceso de validación será definido como correcto y avanzará a los siguientes pasos."
              leftButtonText="Cancelar"
              leftButtonAction={() => {
                setOpenHelpFinalice(false);
              }}
              rightButtonText="Aceptar"
              rightButtonAction={handleSendFinaliceProcess}
            />

            <CustomAlert
              closeAction={() => {
                setOpenHelpFinReject(false);
              }}
              open={openHelpFinReject}
              title="Finalizar validación"
              body="Tu expediente contiene documento(s) marcado(s) como incorrecto, ¿Deseas finalizar el expediente con estatus incorrecto? o solicitar los documentos incorrectos al candidato."
              leftButtonText="Rechazar Solicitud"
              leftButtonAction={handleSendLeftFinalice}
              rightButtonText="Solicitar Documentos"
              rightButtonAction={handleSendRightFinalice}
            />

          </div>
        </TabPanel> */}

        {/* <TabPanel value={value} index={5} dir={theme.direction}>
          <div>
            {biometrics.loading
              ? <div className={localStyles.spinSection}>
                <CustomLoadingOverlay
                  active={biometrics.loading} text="Espere un momento por favor..."
                />
              </div>
              : biometrics.items !== undefined
                && (biometrics.items?.Face.Selfie !== null ||
                  biometrics.items?.SpeechToText.Audio !== null ||
                  biometrics.items?.SpeechToText.Video !== null ||
                  biometrics.items?.Fingerprints.Fingerprint2 !== null ||
                  biometrics.items?.Fingerprints.Fingerprint7 !== null
                ) ?
                <div className={localStyles.scroll}>
                  <Biometrics bioData={biometricInfo} selfArray={biometrics.itemsSelfie}
                    setBioDispatch={setBioDispatch} docMetAdic={docsCard} />
                </div>
                :
                <div className={noInfo.noData}>
                  <UseImage src={noBio} type='img' style={{ maxWidth: 260 }} />
                  <Typography variant="h6" className={noInfo.h6}>Sin datos biométricos</Typography>
                </div>
            }
          </div>
        </TabPanel> */}

        {/* <TabPanel value={value} index={6} dir={theme.direction}>
          <div>
            {regisGeoLocData.error && <p>{regisGeoLocData.error}</p>}
            {regisGeoLocData.loading ?
              <div className={localStyles.spinSection}>
                <CustomLoadingOverlay
                  active={regisGeoLocData.loading} text="Espere un momento por favor..."
                />
              </div> : regisGeoLocData.items !== undefined &&
              <div className={localStyles.scroll}>
                {console.log("Entro data de localizacion:-----------------------: ", regisGeoLocData.items)}
                <GeoLocalizacionInfo data={regisGeoLocData.items} getInfoDispatch={getGeoLocationDispatch} />
              </div>
            }
          </div>
        </TabPanel>  */}

        <TabPanel value={value} index={5} dir={theme.direction}>
          <div>
            <GenericHeaderActions
              LabelTitle="Bitácora de auditoría"
              onClickConfirm={() => {
                dispatch(workFlowActions.GetBinnacleInfo(actorBody));
              }}
              ConfirmButtonText="Actualizar"
              ConfirmButtonStartIcon={<RefreshIcon />}
            />
            {info.error && <p>{info.error}</p>}
            {info.loading ? (
              <div className={localStyles.spinSection}>
                <CustomLoadingOverlay active={info.loading} text="Espere un momento por favor..." />
              </div>
            ) : infoItems ? (
              <div className={localStyles.scroll1}>
                <TableBinnacle data={infoItems} />
              </div>
            ) : null}
          </div>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
