import {Grid, Typography} from "@mui/material";
import dayjs from "dayjs";
import React from "react";

export const DocumentDetails = ({documentMetadata}) => {

  console.log("CS_Metadata",documentMetadata);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography><strong>Fecha Aceptación:</strong></Typography>
          <Typography>{dayjs(documentMetadata.fechaCreacion).format('DD-MM-YYYY HH:mm:ss')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography><strong>Longitud Coordenadas:</strong></Typography>
          <Typography>89.136542</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography><strong>Latitud Coordenadas:</strong></Typography>
          <Typography>64.32167</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography><strong>Tracking IMEI</strong></Typography>
          <Typography>64.32167</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography><strong>Tracking Referencia</strong></Typography>
          <Typography>4323253463</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography><strong>Autorizo el tratamiento de mis datos personales en mi tramite de
            apertura de cuenta.</strong></Typography>
          <Typography>Sí</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography><strong>Doy consentimiento para que durante el proceso se recabe mi
            geolocalización, voz e imagen.</strong></Typography>
          <Typography>Sí</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography><strong>He leido y acepto el Aviso de privacidad, terminos y condiciones de
            DocSolutions.</strong></Typography>
          <Typography>Sí</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography><strong>Manifiesto que estoy actuando por cuenta propia</strong></Typography>
          <Typography>Sí</Typography>
        </Grid>
      </Grid>

      <hr style={{margin: '20px 0', border: 'none', borderBottom: '2px solid grey'}}/>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography><strong>Fecha firma:</strong></Typography>
          <Typography>{dayjs(documentMetadata.fechaCreacion).format('DD-MM-YYYY HH:mm:ss')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography><strong>Tracking IMEI:</strong></Typography>
          <Typography>{documentMetadata.estatus || "64.32167"}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography><strong>Tipo:</strong></Typography>
          <Typography>{documentMetadata.properties.idTipoDocumento || ""}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography><strong>Subtipo:</strong></Typography>
          <Typography>{documentMetadata.properties.idSubtipoDocumento || ""}</Typography>
        </Grid>
      </Grid>
    </>
  )
}