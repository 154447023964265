export const executeSearchConstants = {
    SEARCH_REQUEST: 'SEARCH_REQUEST',
    SEARCH_SUCCESS: 'SEARCH_SUCCESS',
    SEARCH_FAILURE: 'SEARCH_FAILURE',

    SELECTED_OPTION_REQUEST: 'SELECTED_OPTION_REQUEST',
    SELECTED_OPTION_SUCCESS: 'SELECTED_OPTION_SUCCESS',
    SELECTED_OPTION_FAILURE: 'SELECTED_OPTION_FAILURE',

    SEARCH_AUTOCOMPLETE_REQUEST: 'SEARCH_AUTOCOMPLETE_REQUEST',
    SEARCH_AUTOCOMPLETE_SUCCESS: 'SEARCH_AUTOCOMPLETE_SUCCESS',
    SEARCH_AUTOCOMPLETE_FAILURE: 'SEARCH_AUTOCOMPLETE_FAILURE',
}