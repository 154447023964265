import { biometricConstants } from '../constants'
import {biometricRequest} from '../../services'

export const biometricActions = {}

biometricActions.GetBiometricData = (data) => {
  return dispatch => {
    dispatch(request())
    biometricRequest.GetBiometricData(data)
      .then(
        biometricData => dispatch(success(biometricData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: biometricConstants.BIOMETRIC_DATA_REQUEST }
  }

  function success(biometricData) {
    return { type: biometricConstants.BIOMETRIC_DATA_SUCCESS, biometricData }
  }

  function failure(error) {
    return { type: biometricConstants.BIOMETRIC_DATA_FAILURE, error }
  }

}