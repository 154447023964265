import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const useDynamicForm = (StageCurrent, DataControls = null) => {
  const [properties, setProperties] = useState('');
  const [questionsGroups, setQuestionsGroups] = useState([]);
  // const [dataControls, setDataControls] = useState([]);
  const formMethods = useForm({
    mode: 'onBlur',
  });

  // const parseClearObject = () => {
  // const currentFormValues = getValues();
  //   let key;
  //   let clearedObject = {};
  //   for (key in currentFormValues) {
  //     clearedObject = {
  //       ...clearedObject,
  //       [key]: null,
  //     };
  //   }
  //   return clearedObject;
  // };
  // const clearObject = useMemo(() => parseClearObject(), [getValues()]);

  // useEffect(() => {
  //   console.log('*********', getValues());
  // }, [formState]);

  useEffect(() => {
    if (!StageCurrent?.Stage) return;
    setProperties(StageCurrent.Stage);
  }, [StageCurrent]);

  useEffect(() => {
    if (!DataControls) return;
    setQuestionsGroups([]);
    const parsedQuestions = [
      {
        llave: DataControls?.Id,
        groupTitle: DataControls?.Name,
        questions: DataControls?.QuestionDefinitions,
      },
    ];
    setQuestionsGroups(parsedQuestions);
    // setDataControls(parsedQuestions);
  }, [DataControls]);

  useEffect(() => {
    if (properties !== '') {
      if (properties.Description !== undefined) {

        let newArray = setGroupNames(properties.QuestionGroups);
        setQuestionsGroups(newArray);
        // setDataControls(newArray);
      }
    }
  }, [properties]);

  const setGroupNames = (array) => {
    return array.map((group) => {
      const questions = group.Questions.map((qn) => {
        let commonProps = {
          GroupName: group.Name,
          Name: qn.Name,
          Value: qn.Metadata !== null ? qn.Metadata?.Value : '',
          QuestionType: qn.QuestionType,
          Options: qn.Options,
          Required: qn.Required,
          Id: qn.Id,
          DefaultValue: null,
          Visible: qn.Visible === undefined || qn.Visible === null ? true : qn.Visible,
          Captured: null,
        };

        // if (qn.QuestionType === 'Fecha') {
        //   formMethods.setValue(`${qn.Name}`, Date.now());
        // }

        if (qn.QuestionType === 'Tabla') {
          let a;
          if (qn.TableHeaders[0]?.CellType === 'Checkbox') {
            a = JSON.parse(qn.TableHeaders[0].AdditionalData)?.MaximumNumberRowsSelect;
          }
          return {
            ...qn,
            ...commonProps,
            HasCheckbox: qn.TableHeaders[0]?.CellType === 'Checkbox',
            MaximumNumberRowsSelect: a ? a : null,
          };
        }

        return {
          ...qn,
          ...commonProps,
        };
      });

      return {
        groupTitle: group.Name,
        llave: group.Id,
        questions,
      };
    });
  };

  return {
    FormProvider,
    formMethods,
    questionsGroups,
    // dataControls,
  };
};

export { useDynamicForm };
