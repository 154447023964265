import { useState } from 'react';
import { SimulationContext } from './SimulationContext';
import { FormOfertaComercial } from './FormOfertaComercial';
import { FormSimulation } from './FormSimulation';
import { TableSimulations } from './TableSimulations';
import { FormSimulationDetail } from './FormSimulationDetail';
import { Box } from '@mui/material';

const simulationTypes = {
  86: 'PyME',
  88: 'Hipotecario',
  89: 'Automotriz',
  143: 'Nomina',
  90: 'Personal',
};

export const Simulation = () => {
  const [typeFormSimulation, setTypeFormSimulation] = useState();
  const [idSimulacion, setIdSimulacion] = useState();
  const [mode, setMode] = useState('OFERTA_COMERCIAL');

  const handleShowOfertaComercialForm = () => setMode('OFERTA_COMERCIAL');

  const handleSimulate = (formData) => {
    const valueSimulationType = simulationTypes[formData[4013]] || simulationTypes[formData[4014]];
    setMode('SIMULATION');
    setTypeFormSimulation(valueSimulationType);
  };

  const handleShowSimulationDetailForm = (idSimulador, creditType) => {
    setMode('SIMULATION_DETAIL');
    setIdSimulacion(idSimulador);
    setTypeFormSimulation(creditType);
  };

  const renderForms = () => {
    switch (mode) {
      case 'OFERTA_COMERCIAL':
        return (
          <>
            <FormOfertaComercial onHandleOfertaComercial={handleSimulate} />
            <TableSimulations onShowSimulationDetailForm={handleShowSimulationDetailForm} />
          </>
        );
      case 'SIMULATION':
        return (
          <FormSimulation
            simulationType={typeFormSimulation}
            onReturn={handleShowOfertaComercialForm}
          />
        );
      case 'SIMULATION_DETAIL':
        return (
          <>
            <FormSimulationDetail
              simulationType={typeFormSimulation}
              idSimulacion={idSimulacion}
              onReturn={handleShowOfertaComercialForm}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        height: '100%',
        padding: '1rem 2.5rem',
      }}
    >
      <SimulationContext>{renderForms()}</SimulationContext>
    </Box>
  );
};
