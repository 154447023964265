import { msSimuladoresConstants } from '../../constants/msMultiva/msSimuladoresConstants';
import { msSimuladoresRequests } from '../../../services/msMultiva/msSimuladores';

const {
  SIMULADOR_REQUEST,
  SIMULADOR_SUCCESS,
  SIMULADOR_FAILURE,
  CONSULTA_XID_SOLICITUD_REQUEST,
  CONSULTA_XID_SOLICITUD_SUCCESS,
  CONSULTA_XID_SOLICITUD_FAILURE,
  GUARDAR_SIMULACION_REQUEST,
  GUARDAR_SIMULACION_SUCCESS,
  GUARDAR_SIMULACION_FAILURE,
  CONSULTA_XID_INDIVIDUAL_REQUEST,
  CONSULTA_XID_INDIVIDUAL_SUCCESS,
  CONSULTA_XID_INDIVIDUAL_FAILURE,
  IMPRIMIR_TABLA_AMORTIZACION_REQUEST,
  IMPRIMIR_TABLA_AMORTIZACION_SUCCESS,
  IMPRIMIR_TABLA_AMORTIZACION_FAILURE,
} = msSimuladoresConstants;

function request(constantName) {
  return { type: msSimuladoresConstants[constantName] };
}

function success(constantName, data) {
  return { type: msSimuladoresConstants[constantName], payload: data };
}

function failure(constantName, error) {
  return { type: msSimuladoresConstants[constantName], error };
}

export const msSimuladoresActions = {};

msSimuladoresActions.SimuladorCredito = (data, signal = null) => {
  return (dispatch) => {
    dispatch(request(SIMULADOR_REQUEST));
    msSimuladoresRequests
      .SimuladorCredito(data, signal)
      .then((response) => dispatch(success(SIMULADOR_SUCCESS, response)))
      .catch((error) => {
        dispatch(failure(SIMULADOR_FAILURE, error.message));
      });
  };
};

msSimuladoresActions.GuardarSimulacion = (data, signal = null) => {
  return (dispatch) => {
    dispatch(request(GUARDAR_SIMULACION_REQUEST));
    return msSimuladoresRequests
      .GuardarSimulacion(data, signal)
      .then((response) => dispatch(success(GUARDAR_SIMULACION_SUCCESS, response.shortCode)))
      .catch((error) => {
        dispatch(failure(GUARDAR_SIMULACION_FAILURE, error.message));
        throw error;
      });
  };
};

msSimuladoresActions.ConsultaXidSolicitud = (data, signal = null) => {
  return (dispatch) => {
    dispatch(request(CONSULTA_XID_SOLICITUD_REQUEST));
    msSimuladoresRequests
      .ConsultaXidSolicitud(data, signal)
      .then((response) =>
        dispatch(success(CONSULTA_XID_SOLICITUD_SUCCESS, response.simulacionesSolicitud)),
      )
      .catch((error) => {
        dispatch(failure(CONSULTA_XID_SOLICITUD_FAILURE, error.message));
      });
  };
};

msSimuladoresActions.ConsultaXidIndividual = (data, signal = null) => {
  return (dispatch) => {
    dispatch(request(CONSULTA_XID_INDIVIDUAL_REQUEST));
    msSimuladoresRequests
      .ConsultaXidIndividual(data, signal)
      .then((response) => dispatch(success(CONSULTA_XID_INDIVIDUAL_SUCCESS, response)))
      .catch((error) => {
        dispatch(failure(CONSULTA_XID_INDIVIDUAL_FAILURE, error.message));
      });
  };
};

msSimuladoresActions.ImprimirTablaAmortizacion = (data, signal = null) => {
  return (dispatch) => {
    dispatch(request(IMPRIMIR_TABLA_AMORTIZACION_REQUEST));
    msSimuladoresRequests
      .ImprimirTablaAmortizacion(data, signal)
      .then((response) => dispatch(success(IMPRIMIR_TABLA_AMORTIZACION_SUCCESS, response)))
      .catch((error) => {
        dispatch(failure(IMPRIMIR_TABLA_AMORTIZACION_FAILURE, error.message));
      });
  };
};
