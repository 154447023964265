import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './TableStyle'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel, styled, useTheme
} from '@mui/material'

import { constPathRoot, paths, routes } from '../../utils'
import { ThemeConfigMake } from "../../theme/ThemeConfig.jsx";

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: `${theme.palette.primary.tableMain} !important`,
  color: `${theme.palette.primary.tableText} !important`,
}));

const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
  }
}));

export function MainTable({ data }) {
  const theme = useTheme()
  const history = useNavigate()
  const localStyle = useStyles(theme)
  let path = window.location.pathname

  console.log("data: INFOooo", data)

  const newData = data?.map((col) => {
    console.log('col:::', col)
    console.log("datosmapeados", col.Metadata?.find((dato) => dato.Name == "CURP")?.Value)
    return {
      Folio: col.Folio,
      Solicitante: col.FullName,

      Curp: col.Metadata?.find((dato) => dato.Name == "CURP")?.Value,
      Perfil: col.Metadata?.find((dato) => dato.Name == "Perfil")?.Value,
      NumeroPoliza: col.Metadata?.find((dato) => dato.Name == "NumeroPoliza")?.Value,
      Producto: col.Metadata?.find((dato) => dato.Name == "Producto")?.Value,
      CoberturaReclamada: col.Metadata?.find((dato) => dato.Name == "CoberturaReclamada")?.Value,

      Correo: col.Email,
      // CreationDate: col.CreationDateFormatted,
      Estatus: col.Stage.Description,
      IdFolio: col.Id,
      TipoProducto: col.WorkFlowName
    }
  })

  const columns = [
    { id: 'folio', label: 'Folio', minWidth: 170 },
    { id: 'solicitante', label: 'Nombre', minWidth: 170 },
    { id: 'curp', label: 'CURP', minWidth: 170 },
    // { id: 'tipoProducto', label: 'Tipo de producto', minWidth: 170 },
    // { id: 'creationDate', label: 'Fecha', minWidth: 170 },
    { id: 'numPoliza', label: 'Número de póliza', minWidth: 170 },
    { id: 'producto', label: 'Producto', minWidth: 170 },
    { id: 'cobeReclamada', label: 'Cobertura Reclamada', minWidth: 170 },
    { id: 'perfil', label: 'Perfil', minWidth: 170 },
    { id: 'status', label: 'Estatus', minWidth: 170 },

  ]

  const createData = (folio, solicitante, curp, numPoliza, producto, cobeReclamada, perfil, status, idOfFolio) => {
    return { folio, solicitante, curp, numPoliza, producto, cobeReclamada, perfil, status, idOfFolio }
  }

  const rows = newData?.map((r) => {
    return createData(r.Folio, r.Solicitante, r.Curp, r.NumeroPoliza, r.CoberturaReclamada, r.Producto, r.Perfil, r.Estatus, r.IdFolio)
  })

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [orderDirection, setOrderDirection] = useState('')
  const [valueToOrderBy, setValueToOrderBy] = useState('')

  const handlerSort = (event, property) => {
    const isAscending = (valueToOrderBy === property && orderDirection === 'asc')
    setValueToOrderBy(property)
    setOrderDirection(isAscending ? 'desc' : 'asc')
  }

  const createSortHandler = (property) => (event) => {
    handlerSort(event, property)
  }

  // const descendingCompatador = (a, b, orderBy) => {
  //   if (b[orderBy] < a[orderBy]) {
  //     return -1
  //   } else if (b[orderBy] < a[orderBy]) {
  //     return 1
  //   } else {
  //     return 0
  //   }

  // }

  // const getComparator = (order, orderBy) => {
  //   return order === 'desc' ? (a, b) => descendingCompatador(a, b, orderBy) : (a, b) => -descendingCompatador(a, b, orderBy)
  // }

  // const sortRowInformation = (newArray, comparator) => {
  //   const stabilizedRowArray = newArray.map((element, index) => [element, index])
  //   stabilizedRowArray.sort((a, b) => {
  //     const order = comparator(a[0], b[0])
  //     if (order !== 0) {
  //       return order
  //     } else {
  //       return stabilizedRowArray.map((element) => element[0])
  //     }
  //   })
  // }

  console.log('rows', rows)

  return (
    <div style={{ margin: '0 1em 0 1em' }}>
      <TableContainer sx={{ width: '100%' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                // path !== `${constPathRoot.pathRoot}/inbox/search` && (column.id === 'idGrupo' || column.id === 'puesto') ? null :
                <TableCellHead
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                  onClick={createSortHandler(column.label)}
                >
                  {column.label}
                </TableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => { //sortRowInformation(rows, getComparator(orderDirection,valueToOrderBy))
                return (
                  <TableRowCustom hover role="checkbox" tabIndex={-1} key={row.code}
                    onClick={() => {
                      console.log("event table1: ", path)
                      console.log("event table2: ", paths)
                      if (path === paths.search) { //'/inbox/search') {
                        console.log("row.idOfFolio: ", row.idOfFolio)
                        history(`${routes.busqueda}/${row.idOfFolio}`)
                      } else if (path === paths.homeComponentSingle || path === paths.homeComponent) {
                        history(`${routes.asignacion}/${row.idOfFolio}`)
                      }
                    }}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      // if (path !== `${constPathRoot.pathRoot}/inbox/search` && (column.id === 'idGrupo' || column.id === 'puesto')) {
                      //   return null
                      // } else {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                      // }
                    })}
                  </TableRowCustom>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={localStyle.tablePaginacion}
        rowsPerPageOptions={[10]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
      />
    </div>
  )
}