import { msSolicitudConstants } from '../../constants/msMultiva/msSolicitudConstants';
import { msSolicitudRequests } from '../../../services/msMultiva/msSolicitud';
import { fileActions } from '../FileActions';

const {
  OPERACION_ALTA_REQUEST,
  OPERACION_ALTA_SUCCESS,
  OPERACION_ALTA_FAILURE,
  OPERACION_ALTA_CLEAR_STATE,
  OPERACION_ASOCIACION_REQUEST,
  OPERACION_ASOCIACION_SUCCESS,
  OPERACION_ASOCIACION_FAILURE,
  OPERACION_ASOCIACION_CLEAR_STATE,
  OPERACION_SOLICITUD_REQUEST,
  OPERACION_SOLICITUD_SUCCESS,
  OPERACION_SOLICITUD_FAILURE,
} = msSolicitudConstants;

function request(constantName) {
  return { type: msSolicitudConstants[constantName] };
}

function success(constantName, data) {
  return { type: msSolicitudConstants[constantName], payload: data };
}

function failure(constantName, error) {
  return { type: msSolicitudConstants[constantName], error };
}

function clear(constantName) {
  return { type: msSolicitudConstants[constantName] };
}

export const msSolicitudActions = {};

msSolicitudActions.SolicitudOperacion = (data, signal = null) => {
  return async (dispatch) => {
    dispatch(request(OPERACION_ALTA_REQUEST));
    try {
      const response = await msSolicitudRequests.SolicitudOperacion(data, signal);
      dispatch(success(OPERACION_ALTA_SUCCESS, response));
      return response;
    } catch (error) {
      dispatch(failure(OPERACION_ALTA_FAILURE, error.message));
    }
  };
};

msSolicitudActions.SolicitudOperacionC61 = (data, signal = null) => {
  return async (dispatch) => {
    try {
      dispatch(request(OPERACION_ALTA_REQUEST));
      const response = await msSolicitudRequests.SolicitudOperacion(data, signal);
      const mappedResponse = mapC61(response);
      dispatch(success(OPERACION_ALTA_SUCCESS, mappedResponse));
      return mappedResponse;
    } catch (error) {
      dispatch(failure(OPERACION_ALTA_FAILURE, error.message));
    }
  };

  function mapC61(data) {
    const clientsData = data.solicitud.c61;
    clientsData.forEach((cliente) => {
      cliente['nombreCompleto'] =
        cliente.apellidoPaterno +
        ' ' +
        cliente.apellidoMaterno +
        ' ' +
        cliente.primerNombre +
        ' ' +
        cliente.segundoNombre;
      cliente['domicilio'] = `${cliente.calle} ${cliente.numeroExterior}${
        cliente.numeroInterior ? ' ' + cliente.numeroInterior : ''
      },
      Entre ${cliente.entreCalle},
      CP ${cliente.codigoPostal},
      ${cliente.delegacionMunicipio},
      ${cliente.ciudadNacional}`;
    });
    return data;
  }
};

msSolicitudActions.SolicitudConsultaC12 = (data, signal = null) => {
  return async (dispatch) => {
    dispatch(request(OPERACION_SOLICITUD_REQUEST));
    try {
      const response = await msSolicitudRequests.SolicitudOperacion(data, signal);
      dispatch(success(OPERACION_SOLICITUD_SUCCESS, response));
      return response;
    } catch (error) {
      dispatch(failure(OPERACION_SOLICITUD_FAILURE, error.message));
    }
  };
};

msSolicitudActions.SolicitudAsociacion = (data, signal = null) => {
  return (dispatch) => {
    dispatch(request(OPERACION_ASOCIACION_REQUEST));
    msSolicitudRequests
      .SolicitudOperacion(data, signal)
      .then((response) => {
        dispatch(success(OPERACION_ASOCIACION_SUCCESS, response));
      })
      .catch((error) => {
        dispatch(failure(OPERACION_ASOCIACION_FAILURE, error.message));
      });
  };
};

msSolicitudActions.CreateRequestMultivaOnboarding = (data) => {
  return async (dispatch) => {
    const response = await dispatch(msSolicitudActions.SolicitudOperacion(data));
    const GENERATE_FOLIO_PAYLOAD = {
      ProductId: 22,
      Metadata: [
        {
          Name: 'IdSolicitud',
          Value: response.solicitud.idSolicitud,
        },
      ],
    };
    const docSolutionsId = dispatch(fileActions.GenerateFolio(GENERATE_FOLIO_PAYLOAD));
    return docSolutionsId;
  };
};

msSolicitudActions.UpdateMultivaRequestAndOnboardingMetadata = (
  UPDATE_REQUEST_MULTIVA_PAYLOAD,
  UPDATE_METADATA_ONBOARDING,
) => {
  return async (dispatch) => {
    try {
      dispatch(request(OPERACION_ALTA_REQUEST));
      const response = await msSolicitudRequests.SolicitudOperacion(UPDATE_REQUEST_MULTIVA_PAYLOAD);
      dispatch(success(OPERACION_ALTA_SUCCESS, response));
      await dispatch(fileActions.FileUpdateMetadata(UPDATE_METADATA_ONBOARDING));
    } catch (error) {
      dispatch(failure(OPERACION_ALTA_FAILURE, error.message));
    }
  };
};

msSolicitudActions.SolicitudAsociacionClearState = () => {
  return (dispatch) => {
    dispatch(clear(OPERACION_ASOCIACION_CLEAR_STATE));
  };
};

msSolicitudActions.SolicitudOperacionClearState = () => {
  return (dispatch) => {
    dispatch(clear(OPERACION_ALTA_CLEAR_STATE));
  };
};
