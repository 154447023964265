import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReturnButton, DownloadButton } from 'components/Buttons';
import { ResultsFormSimulation } from 'components/LEGOS/Simulation/FormSimulation/SimulationResults.jsx';
import { InputField } from 'components/InputField/inputField.styled.js';
import { DynamicFormWrapper } from 'components/LEGOS/DynamicForm/DynamicFormWrapper.jsx';
import { msSimuladoresActions } from 'store/actions/msMultiva/msSimuladoresActions.js';
import { getQuestionGroupForSimulationType } from 'utils/LegosUtils/Simulation/index.js';
import { pdf } from '../pdfTest';
import { useFormContext } from 'react-hook-form';
import { RequestErrorAlert } from 'components/RequestErrorAlert';
import { CustomLoadingOverlay } from 'components/CustomLoadingOverlay';

export const FormSimulationDetail = ({ simulationType, idSimulacion, onReturn }) => {
  const simulationDetailState = useSelector((state) => state.ConsultaXidIndividual);
  const infoTablaAmortizacion = useSelector((state) => state.ImprimirTablaAmortizacion);
  const dispatch = useDispatch();
  const { questionsGroups } = useFormContext();
  console.log(infoTablaAmortizacion);

  const render = (question) => {
    return (
      <InputField
        key={question.Id}
        label={question.Description}
        name={question.Name}
        value={simulationDetailState.items.resultadoSimulador.jsonEntrada[question.Id]}
        InputProps={{
          readOnly: true,
          shrink: true,
        }}
        fullWidth
        disabled
      />
    );
  };

  useEffect(() => {
    dispatch(msSimuladoresActions.ConsultaXidIndividual({ idSimulacion: idSimulacion }));
    dispatch(msSimuladoresActions.ImprimirTablaAmortizacion({ idSimulacion: idSimulacion }));
    return () => dispatch({ type: 'CONSULTA_XID_INDIVIDUAL_CLEAR_STATE' });
  }, [dispatch, idSimulacion]);

  if (simulationDetailState.loading)
    return <CustomLoadingOverlay active={simulationDetailState.loading} />;
  if (simulationDetailState.error)
    return <RequestErrorAlert error={simulationDetailState.error} showConfim showCancel />;
  if (!simulationDetailState.items) return null;

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          paddingTop: '1.5rem',
        }}
      >
        <ReturnButton onHandleClick={onReturn} />
        <DownloadButton documentName={'TablaAmortizacion'} b64={pdf}>
          Impr. Tabla Amortización
        </DownloadButton>
      </div>
      <DynamicFormWrapper
        questionsGroups={getQuestionGroupForSimulationType(questionsGroups, simulationType)}
        render={render}
      />
      <ResultsFormSimulation
        questionsGroups={questionsGroups}
        simulationType={simulationType}
        simulationResult={simulationDetailState.items.resultadoSimulador.jsonSalida}
      />
    </>
  );
};
