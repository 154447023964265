export const msSimuladoresConstants = {
  SIMULADOR_REQUEST: 'SIMULADOR_REQUEST',
  SIMULADOR_SUCCESS: 'SIMULADOR_SUCCESS',
  SIMULADOR_FAILURE: 'SIMULADOR_FAILURE',
  SIMULADOR_CLEAR_STATE: 'SIMULADOR_CLEAR_STATE',
  CONSULTA_XID_SOLICITUD_REQUEST: 'CONSULTA_XID_SOLICITUD_REQUEST',
  CONSULTA_XID_SOLICITUD_SUCCESS: 'CONSULTA_XID_SOLICITUD_SUCCESS',
  CONSULTA_XID_SOLICITUD_FAILURE: 'CONSULTA_XID_SOLICITUD_FAILURE',
  GUARDAR_SIMULACION_REQUEST: 'GUARDAR_SIMULACION_REQUEST',
  GUARDAR_SIMULACION_SUCCESS: 'GUARDAR_SIMULACION_SUCCESS',
  GUARDAR_SIMULACION_FAILURE: 'GUARDAR_SIMULACION_FAILURE',
  GUARDAR_SIMULACION_CLEAR_STATE: 'GUARDAR_SIMULACION_CLEAR_STATE',
  CONSULTA_XID_INDIVIDUAL_REQUEST: 'CONSULTA_XID_INDIVIDUAL_REQUEST',
  CONSULTA_XID_INDIVIDUAL_SUCCESS: 'CONSULTA_XID_INDIVIDUAL_SUCCESS',
  CONSULTA_XID_INDIVIDUAL_FAILURE: 'CONSULTA_XID_INDIVIDUAL_FAILURE',
  CONSULTA_XID_INDIVIDUAL_CLEAR_STATE: 'CONSULTA_XID_INDIVIDUAL_CLEAR_STATE',
  IMPRIMIR_TABLA_AMORTIZACION_REQUEST: 'IMPRIMIR_TABLA_AMORTIZACION_REQUEST',
  IMPRIMIR_TABLA_AMORTIZACION_SUCCESS: 'IMPRIMIR_TABLA_AMORTIZACION_SUCCESS',
  IMPRIMIR_TABLA_AMORTIZACION_FAILURE: 'IMPRIMIR_TABLA_AMORTIZACION_FAILURE',
};
