import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DynamicFormWrapper } from 'components/LEGOS/DynamicForm/DynamicFormWrapper';
import { DynamicTable } from 'components/LEGOS/DynamicTable';
import { GenericContainer } from 'components/GenericContainer';
import { msSimuladoresActions } from 'store/actions/msMultiva/msSimuladoresActions';
import { CREDIT_TYPES } from 'mappings/SimulationFormMappings';
import { useFormContext } from 'react-hook-form';
import { RequestErrorAlert } from 'components/RequestErrorAlert';
import { useParams } from 'react-router-dom';
import { msSolicitudActions } from 'store/actions/msMultiva/msSolicitudActions';
import { GenericConfirmationModal } from 'components/modals/GenericConfirmationModal';

export const TableSimulations = ({ onShowSimulationDetailForm }) => {
  const { id } = useParams();
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const savedSimulationsState = useSelector((state) => state.ConsultaXidSolicitud);
  const infoSolicitud = useSelector((state) => state.SolicitudOperacion);
  const dispatch = useDispatch();
  const { questionsGroups } = useFormContext();
  const loggedUser = useSelector((state) => state.Authentication);
  const infoFolio = useSelector((state) => state.Folio);

  const handleSelectRow = (selectedRows) => {
    if (selectedRows.length > 0) {
      setSelectedRows(selectedRows);
      setOpen(true);
    }
  };

  const handleClickActionRow = (row) => {
    onShowSimulationDetailForm(row.idSimulador, CREDIT_TYPES[row.tipoOperacion]);
  };

  const handleConfirm = async () => {
    const UPDATE_METADATA_PAYLOAD = {
      ObjectId: id,
      Metadata: [
        {
          Name: 'IdSimulacion',
          Value: selectedRows[0].idSimulador,
        },
        {
          Name: 'tipoCredito',
          Value: CREDIT_TYPES[selectedRows[0].tipoCredito],
        },
      ],
      ContinueWorkFlow: true,
    };

    const UPDATE_REQUEST_MULTIVA_PAYLOAD = {
      solicitud: {
        idSolicitud: infoSolicitud.items?.solicitud?.idSolicitud,
        idEstatus: 1,
        idEtapa: 1,
        idSubetapa: 1,
        idPersonalidadJuridica: 1,
        idTipoTramite: 94, //NO CAMBIA
        idOperacionSolicitud: 100, //NO CAMBIA
        /****New Values ****/
        idSubDireccionRegional: 1,
        idDireccionRegional: 1,
        idEstatusVencimiento: 1,
        idAreaAsignada: 1,
        idUsuarioAsignado: 1,
        /****New Values ****/
        tipoTransaccion: 'actualizacion',
        idUsuario: 1,
        usuario: loggedUser?.usuario,
        idDao: 3,
        idArea: 5,
        idRol: 7,
        idSucursal: 9,
        idSubDireccionGeneral: 7,
        idDireccionGeneral: 8,
        folioDocsolution: infoFolio.items.Result.Id,
        usuarioGeolocalizacion: '40.689247, -74.044502',
        seccion: ['solicitud'],
      },
    };
    dispatch(
      msSolicitudActions.UpdateMultivaRequestAndOnboardingMetadata(
        UPDATE_REQUEST_MULTIVA_PAYLOAD,
        UPDATE_METADATA_PAYLOAD,
      ),
    ).finally(() => setOpen(false));
  };

  const render = (question) => {
    return (
      <DynamicTable
        key={question.Id}
        id={question.Id}
        headers={question.TableHeaders}
        rows={savedSimulationsState.items}
        hasCheckbox={question.HasCheckbox}
        maximumNumberRowsSelect={question.MaximumNumberRowsSelect}
        onSelectRow={handleSelectRow}
        onClickActionRow={handleClickActionRow}
      />
    );
  };

  useEffect(() => {
    dispatch(
      msSimuladoresActions.ConsultaXidSolicitud({
        idSolicitud: infoSolicitud.items?.solicitud?.idSolicitud,
      }),
    );
    return () => dispatch({ type: 'OPERACION_ALTA_CLEAR_STATE' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (savedSimulationsState.error)
    return <RequestErrorAlert error={savedSimulationsState.error} showConfim showCancel />;

  return (
    <>
      {infoSolicitud.error ? (
        <RequestErrorAlert error={infoSolicitud.error} showConfim showCancel />
      ) : null}
      <GenericContainer
        loading={savedSimulationsState.loading}
        shouldRender={savedSimulationsState.items?.length > 0}
        column
      >
        <DynamicFormWrapper
          questionsGroups={questionsGroups.filter((questionGroup) => questionGroup.llave === 604)}
          render={render}
        />
        <GenericConfirmationModal
          title="¿Desea continuar con esta simulación?"
          returnText="Cancelar"
          confirmText="Continuar"
          loading={infoSolicitud.loading}
          open={open}
          handleCancel={() => setOpen(false)}
          handleConfirm={handleConfirm}
        />
      </GenericContainer>
    </>
  );
};
