export const ACCOUNT_SEARCH_PAYLOAD = {
  solicitud: {
    idSolicitud: 117,
    tipoTransaccion: "consulta",
    idUsuario: 12343,
    usuarioGeolocalizacion: "40.689247, -74.044502",
    seccion: [
      "c71"
    ],
    c71: [
      {
        idCliente: ""
      }
    ]
  }
}


export const SEARCH_ACCOUNT_DETAILS_PAYLOAD = {
  solicitud: {
    idSolicitud: 9,
    tipoTransaccion: "consulta",
    idUsuario: 12343,
    usuarioGeolocalizacion: "40.689247, -74.044502",
    seccion: [
      "cuentas"
    ],
    cuentas: [
      {
        cuenta: {
          idCuenta: "" //Se sustituye
        }
      }
    ]
  }
};

export const CUSTOMER_UPDATE_ACCOUNT_PAYLOAD = {
  solicitud: {
    idSolicitud: 9,
    idEstatus: 1,
    idEtapa: 1,
    idSubetapa: 1,
    idPersonalidadJuridica: 1,
    idTipoTramite: 4,
    idOperacionSolicitud: 4,
    tipoTransaccion: "actualizacion",
    idUsuario: 12343,
    usuario: "multiva",
    idDao: 3,
    idArea: 5,
    idRol: 7,
    idSucursal: 9,
    idSubDireccionGeneral: 7,
    idDireccionGeneral: 8,
    folioDocsolution: "HGJGHJ4564564",
    usuarioGeolocalizacion: "40.689247, -74.044502",
    seccion: [
      "cuentas"
    ],
    cuentas: [
      {
        cuenta: ""
      }
    ]
  }
};