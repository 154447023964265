import React, { useEffect, useState } from 'react';
import { Accordion } from 'components/Accordion.jsx';
import { DocumentarySlotBody } from './DocumentarySlotBody.jsx';
import { DocumentarySlotDetail } from './DocumentarySlotDetail.jsx';
import { DocumentarySlotControls } from './DocumentarySlotControls.jsx';
import { B64toBlob } from 'utils';

export const DocumentarySlot = ({ allowedExtensions, onSendData, documentaryGroup }) => {
  const [fileB64, setFileB64] = useState(null);
  const [fileUrl, setfileUrl] = useState(null);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [extensionCurrent, setExtensionCurrent] = useState('');

  const handleFileChange = (fileB64Response, extension) => {
    if (!fileB64Response) return;
    setFileB64(fileB64Response);
    setExtensionCurrent(extension);
  };

  const handleUpload = () => {
    onSendData(fileB64, extensionCurrent, documentaryGroup);
    resetFileAndExtension();
    setIsAccordionExpanded(false)
  };

  const resetFileAndExtension = () => {
    setFileB64(null);
    setExtensionCurrent(null);
  };

  const handleAccordionChange = (expanded) => {
    setIsAccordionExpanded(expanded);
  };

  useEffect(() => {
    if (fileB64) {
      const mimeType =
        extensionCurrent === '.pdf' ? 'application/pdf' : `image/${extensionCurrent}`;
      const blob = B64toBlob(fileB64, mimeType);
      const blobUrl = URL.createObjectURL(blob);
      setfileUrl(blobUrl);
    }
  }, [fileB64, extensionCurrent]);

  return (
    <Accordion
      isExpanded={isAccordionExpanded}
      handleChange={handleAccordionChange}
      title={
        <DocumentarySlotDetail
          name={documentaryGroup.tipoDocumentoDesc}
          required={documentaryGroup.obligatorio}
          isCaptured={!!documentaryGroup?.identificadorDocumento}
        />
      }
    >
      {fileB64 && (
        <DocumentarySlotControls
          handleUpload={handleUpload}
          resetFileAndExtension={resetFileAndExtension}
        />
      )}
      <DocumentarySlotBody
        fileB64={fileB64}
        extensionCurrent={extensionCurrent}
        handleFileChange={handleFileChange}
        allowedExtensions={allowedExtensions}
        fileUrl={fileUrl}
      />
    </Accordion>
  );
};
