export const msGestorDocumentalConstants = {
  ALTA_DOCUMENTO_REQUEST: 'ALTA_DOCUMENTO_REQUEST',
  ALTA_DOCUMENTO_SUCCESS: 'ALTA_DOCUMENTO_SUCCESS',
  ALTA_DOCUMENTO_FAILURE: 'ALTA_DOCUMENTO_FAILURE',
  MODIFICAR_DOCUMENTO_REQUEST: 'MODIFICAR_DOCUMENTO_REQUEST',
  MODIFICAR_DOCUMENTO_SUCCESS: 'MODIFICAR_DOCUMENTO_SUCCESS',
  MODIFICAR_DOCUMENTO_FAILURE: 'MODIFICAR_DOCUMENTO_FAILURE',
  CONSULTAR_DOCUMENTO_REQUEST: 'CONSULTAR_DOCUMENTO_REQUEST',
  CONSULTAR_DOCUMENTO_SUCCESS: 'CONSULTAR_DOCUMENTO_SUCCESS',
  CONSULTAR_DOCUMENTO_FAILURE: 'CONSULTAR_DOCUMENTO_FAILURE',
  DESCARGAR_DOCUMENTO_REQUEST: 'DESCARGAR_DOCUMENTO_REQUEST',
  DESCARGAR_DOCUMENTO_SUCCESS: 'DESCARGAR_DOCUMENTO_SUCCESS',
  DESCARGAR_DOCUMENTO_FAILURE: 'DESCARGAR_DOCUMENTO_FAILURE',
};
