export const notificationConstants = {
    NEW_PIN_REQUEST: 'NEW_PIN_REQUEST',
    NEW_PIN_SUCCESS: 'NEW_PIN_SUCCESS',
    NEW_PIN_FAILURE: 'NEW_PIN_FAILURE',

    GET_NOTIFICATIONS_USER_REQUEST: 'GET_NOTIFICATIONS_USER_REQUEST',
    GET_NOTIFICATIONS_USER_SUCCESS: 'GET_NOTIFICATIONS_USER_SUCCESS',
    GET_NOTIFICATIONS_USER_FAILURE: 'GET_NOTIFICATIONS_USER_FAILURE',
  
  }
  