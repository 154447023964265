import React, {Fragment} from "react";
import {useSelector} from "react-redux";
import {GenericContainer} from "../../GenericContainer";
import {DynamicFormWrapper} from "../../LEGOS/DynamicForm/DynamicFormWrapper";
import {DynamicFormBody} from "../../LEGOS/DynamicForm/DynamicFormBody";
import {FormProvider} from "react-hook-form";
import {useDynamicForm} from "../../../hooks/useDynamicForm";

export const CustomerDetails = ({customerDetails}) => {

  const infoFile = useSelector((state) => state.FileInfo);
  const { FormProvider, formMethods, questionsGroups } = useDynamicForm(infoFile.items, null);

  //QuestionGroups que no son los del formulario principal
  const customerDetailsQuestionGroups = questionsGroups.filter((questionGroup) => {
    return questionGroup.llave !== 563 && questionGroup.llave !== 624;
  });

  console.log("CS_CustomerDetailsComponent",customerDetails);

  //Desabilita los campos de los questionGroups
  customerDetailsQuestionGroups.forEach(questionGroup => {
    questionGroup.questions.forEach(question => {
      question.Disabled = true;
      question.QuestionType = question.QuestionType === "Combo" ? "Texto" : question.QuestionType;
    });
  });

  return (
    <Fragment>
        <FormProvider {...formMethods}>
          <DynamicFormWrapper
            questionsGroups={customerDetailsQuestionGroups}
            render={(question, index) => {
              return (
                <DynamicFormBody
                  key={question.Id}
                  question={question}
                  value={customerDetails[question.DataType]}
                  disabled={false}
                />
              )
            }}
          />
        </FormProvider>
    </Fragment>
  )
}