export const msClienteConstants = {
  FILTER_SEARCH_PROCESS_ACCOUNT_REQUEST: 'FILTER_SEARCH_PROCESS_ACCOUNT_REQUEST',
  FILTER_SEARCH_PROCESS_ACCOUNT_SUCCESS: 'FILTER_SEARCH_PROCESS_ACCOUNT_SUCCESS',
  FILTER_SEARCH_PROCESS_ACCOUNT_FAILURE: 'FILTER_SEARCH_PROCESS_ACCOUNT_FAILURE',
  FILTER_SEARCH_PROCESS_ACCOUNT_CLEAR_STATE: 'FILTER_SEARCH_PROCESS_ACCOUNT_CLEAR_STATE',
  FILTER_SEARCH_PROCESS_CUSTOMER_RFC_REQUEST: 'FILTER_SEARCH_PROCESS_CUSTOMER_RFC_REQUEST',
  FILTER_SEARCH_PROCESS_CUSTOMER_RFC_SUCCESS: 'FILTER_SEARCH_PROCESS_CUSTOMER_RFC_SUCCESS',
  FILTER_SEARCH_PROCESS_CUSTOMER_RFC_FAILURE: 'FILTER_SEARCH_PROCESS_CUSTOMER_RFC_FAILURE',
  FILTER_SEARCH_PROCESS_CUSTOMER_RFC_CLEAR_STATE: 'FILTER_SEARCH_PROCESS_CUSTOMER_RFC_CLEAR_STATE',
  FILTER_SEARCH_PROCESS_CUSTOMER_CUENTA_REQUEST: 'FILTER_SEARCH_PROCESS_CUSTOMER_CUENTA_REQUEST',
  FILTER_SEARCH_PROCESS_CUSTOMER_CUENTA_SUCCESS: 'FILTER_SEARCH_PROCESS_CUSTOMER_CUENTA_SUCCESS',
  FILTER_SEARCH_PROCESS_CUSTOMER_CUENTA_FAILURE: 'FILTER_SEARCH_PROCESS_CUSTOMER_CUENTA_FAILURE',
  FILTER_SEARCH_PROCESS_LENDING_REQUEST: 'FILTER_SEARCH_PROCESS_LENDING_REQUEST',
  FILTER_SEARCH_PROCESS_LENDING_SUCCESS: 'FILTER_SEARCH_PROCESS_LENDING_SUCCESS',
  FILTER_SEARCH_PROCESS_LENDING_FAILURE: 'FILTER_SEARCH_PROCESS_LENDING_FAILURE',
};
