import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {GenericContainer} from "../../GenericContainer";
import {DynamicFormWrapper} from "../../LEGOS/DynamicForm/DynamicFormWrapper";
import {DynamicFormBody} from "../../LEGOS/DynamicForm/DynamicFormBody";
import {FormProvider} from "react-hook-form";
import {useDynamicForm} from "../../../hooks/useDynamicForm";


export const AccountDetails = ({accountDetails}) => {

  const infoFile = useSelector((state) => state.FileInfo);
  const solicitudOperacion = useSelector((state) => state.SolicitudOperacion);
  const dispatch = useDispatch();

  const { FormProvider, formMethods, questionsGroups } = useDynamicForm(infoFile.items, null);

  const generateRows = (data, setterFunction, fields) => {
    const rows = data.map((item) => fields.map((field) => item[field]));
    setterFunction(rows);
  };

  const detallesCuentaQuestionGroups = questionsGroups.filter((questionGroup) => questionGroup.llave !== 626);
  console.log("CS_DetallesCuenta",detallesCuentaQuestionGroups);

  //Desabilita los campos de los questionGroups
  detallesCuentaQuestionGroups.forEach(questionGroup => {
    questionGroup.questions.forEach(question => {
      question.Disabled = true;
      question.QuestionType = question.QuestionType === "Combo" ? "Texto" : question.QuestionType;
    });
  });

  return (
    <Fragment>
      <GenericContainer loading={infoFile.loading || solicitudOperacion.loading} column>
        <FormProvider {...formMethods}>
          <DynamicFormWrapper
            questionsGroups={detallesCuentaQuestionGroups}
            render={(question, index) => {
              return (
                <DynamicFormBody
                  key={question.Id}
                  question={question}
                  value={accountDetails[question.DataType]}
                  disabled={false}
                />)}
            }
          />
        </FormProvider>
      </GenericContainer>
    </Fragment>
  )
}