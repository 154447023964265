import docGenerico from "assets/docGenerico.svg"
import CURPImage from "assets/CURP.svg"

export const DocImageMappings = {
  "IDENTIFICACION_OFICIAL": docGenerico,
  "CURP": CURPImage,
  "RFC": docGenerico,
  "INE": docGenerico,
  "PASAPORTE_MEXICANO": docGenerico,
  "CARTILLA_MILITAR": docGenerico,
  "CÉDULA_PROFESIONAL": docGenerico,
  "ACTA_DE_NACIMIENTO": docGenerico,
  "COMPROBANTE_DE_DOMICILIO": docGenerico,
  "ESTADO_DE_CUENTA_BANCARIO": docGenerico,
  "COMPROBANTE_DE_PAGO_DE_SERVICIOS": docGenerico,
  "LICENCIA_CONDUCIR": docGenerico,
  "CREDENCIAL_ESTUDIANTE": docGenerico,
  "CARTA_ANTECEDENTES_PENALES": docGenerico,
  "TARJETA_CRÉDITO": docGenerico,
  "TARJETA_DÉBITO": docGenerico,
  "CREDENCIAL_PENSIONADO": docGenerico,
  "CREDENCIAL_IDENTIFICACIÓN_LABORAL": docGenerico,
  "CREDENCIAL_ISSSTE": docGenerico,
  "CREDENCIAL_IMSS": docGenerico,
  "CREDENCIAL_INEA": docGenerico,
  "CREDENCIAL_INAPAM": docGenerico,
};