import { useEffect, useState } from 'react';
import { Accordion as MUIAccordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

export const Accordion = ({ title, children, handleChange = null, isExpanded = null }) => {
  const [expanded, setExpanded] = useState(isExpanded);

  const handleToggle = () => {
    const newIsExpanded = !expanded;
    setExpanded(newIsExpanded);
    if (handleChange) {
      handleChange(newIsExpanded);
    }
  };

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <MUIAccordion sx={{ width: '100%' }} expanded={expanded} onChange={handleToggle}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
      >
        {title || ''}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </MUIAccordion>
  );
};
