import React, { Fragment, useState } from "react";
import {Button, Typography, Tooltip, Grid} from "@mui/material";
import { Icon } from "@iconify/react";
import { DetailsModal } from "./DetailsModal/";
import { UploadModal } from "./UploadModal";
import { DocImageMappings } from "./DocImageMappings";
import { UseImage } from "../../../UseImage";
import docGenerico from "assets/docGenerico.svg";
import { Card } from "../requestDocumentationViewer.styled";
import {
  DOCUMENT_STATUS_APPROVED,
  DOCUMENT_STATUS_APPROVED_TOOLTIP_LABEL,
  DOCUMENT_STATUS_PENDING, DOCUMENT_STATUS_PENDING_TOOLTIP_LABEL,
  DOCUMENT_STATUS_REJECTED,
  DOCUMENT_STATUS_REJECTED_TOOLTIP_LABEL,
  UPDATE_DOCUMENT_TOOLTIP_LABEL,
  UPLOAD_DOCUMENT_TOOLTIP_LABEL,
  VIEW_DOCUMENT_TOOLTIP_LABEL
} from "../RequestDocumentationConstants";
import {useSelector} from "react-redux";

export const DocCard = ({ docInfo, refreshData }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showUpload, setShowUpload] = useState(false);

  const handleDetailsOpen = () => setShowDetails(true);
  const handleDetailsClose = () => setShowDetails(false);
  const handleUploadOpen = () => setShowUpload(true);
  const handleUploadClose = () => setShowUpload(false);

  const imageDoc = DocImageMappings[docInfo.tipoDocumentoDesc] || docGenerico;

  const statusIconsMapping = {
    [DOCUMENT_STATUS_APPROVED]:
      <Tooltip title={DOCUMENT_STATUS_APPROVED_TOOLTIP_LABEL}>
        <Icon icon={"material-symbols:check-circle"} color={"green"} fontSize={'1.5em'} minWidth={'20%'} />
      </Tooltip>,
    [DOCUMENT_STATUS_REJECTED]:
      <Tooltip title={DOCUMENT_STATUS_REJECTED_TOOLTIP_LABEL}>
        <Icon icon={"material-symbols:cancel"} color={"red"} fontSize={'1.5em'} minWidth={'20%'} />
      </Tooltip>,
    [DOCUMENT_STATUS_PENDING]:
      <Tooltip title={DOCUMENT_STATUS_PENDING_TOOLTIP_LABEL}>
        <Icon icon={"ic:twotone-pending-actions"} color={"orange"} fontSize={'1.5em'} minWidth={'20%'} />
      </Tooltip>,
  };

  const statusButtonsMapping = {
    [DOCUMENT_STATUS_APPROVED]:
      <>
        <Tooltip title={VIEW_DOCUMENT_TOOLTIP_LABEL}>
          <Button sx={{ minWidth: 'auto', minHeight: 'auto' }} onClick={handleDetailsOpen}>
            <Icon fontSize={'1.5em'} icon={"mdi:eye"} />
          </Button>
        </Tooltip>
        <Tooltip title={UPDATE_DOCUMENT_TOOLTIP_LABEL}>
          <Button sx={{ minWidth: 'auto', minHeight: 'auto' }} onClick={handleUploadOpen}>
            <Icon fontSize={'1.5em'} icon={"tabler:replace-filled"} />
          </Button>
        </Tooltip>
      </>,
    [DOCUMENT_STATUS_REJECTED]:
      <>
        <Tooltip title={VIEW_DOCUMENT_TOOLTIP_LABEL}>
          <Button sx={{ minWidth: 'auto', minHeight: 'auto' }} onClick={handleDetailsOpen}>
            <Icon fontSize={'1.5em'} icon={"mdi:eye"} />
          </Button>
        </Tooltip>
        <Tooltip title={UPDATE_DOCUMENT_TOOLTIP_LABEL}>
          <Button sx={{ minWidth: 'auto', minHeight: 'auto' }} onClick={handleUploadOpen}>
            <Icon fontSize={'1.5em'} icon={"tabler:replace-filled"} />
          </Button>
        </Tooltip>
      </>,
    [DOCUMENT_STATUS_PENDING]:
      <Tooltip title={UPLOAD_DOCUMENT_TOOLTIP_LABEL}>
        <Button sx={{ minWidth: 'auto', minHeight: 'auto' }} onClick={handleUploadOpen}>
          <Icon fontSize={'1.5em'} icon={"material-symbols:upload"} />
        </Button>
      </Tooltip>
  };

  console.log("CS_DocCard",docInfo)

  return (
    <Fragment>
      <Card style={{ width: '15em' }}>
        <Grid container style={{ maxWidth: '90%' }}>
          <Grid item xs={2}>
            {statusIconsMapping[docInfo.estadoDocumento] || statusIconsMapping["default"]}
          </Grid>
          <Grid item xs={8}>
            <UseImage src={imageDoc} type="img" />
          </Grid>
          <Grid item xs={2}>
            {statusButtonsMapping[docInfo.estadoDocumento] || statusButtonsMapping["default"]}
          </Grid>
        </Grid>
        <Typography>{docInfo.tipoDocumentoDesc}</Typography>
      </Card>
      {showDetails && <DetailsModal docInfo={docInfo} isOpen={showDetails} onClose={handleDetailsClose} refreshData={refreshData} />}
      {showUpload &&
        <UploadModal isOpen={showUpload} onClose={handleUploadClose} idHabilitador={docInfo.idHabilitador} statusDocument={docInfo.estadoDocumento} refreshData={refreshData}/>
      }
    </Fragment>
  );
};