import { useDispatch, useSelector } from 'react-redux';
import { msSolicitudActions } from 'store/actions/msMultiva/msSolicitudActions';
import { Box as FormBox, Button as FormButton } from '../../LEGOS/DynamicForm/dynamicForm.styled';
import React, { Fragment, useEffect, useState } from 'react';
import { GenericConfirmationModal } from '../../modals/GenericConfirmationModal';
import { RequestErrorAlert } from '../../RequestErrorAlert';
import { buildMetadataRequest } from 'utils/buildMetadataRequest';
import { useParams } from 'react-router-dom';
import { fileActions } from 'store/actions';
import { GenericHeaderActions } from '../../GenericHeaderActions';
import { useDynamicForm } from 'hooks/useDynamicForm';
import { DynamicFormWrapper } from '../DynamicForm/DynamicFormWrapper';
import { DynamicTable } from '../DynamicTable';
import { DynamicFormBody } from '../DynamicForm/DynamicFormBody';
import { GenericContainer } from '../../GenericContainer';
import {
  CUSTOMER_SEARCH_PAYLOAD,
  CUSTOMER_REQUEST_BIND_PAYLOAD,
  SEARCH_CUSTOMER_DETAILS_PAYLOAD,
} from './CustomerSearchRequestPayloads';
import { CustomerDetails } from './CustomerDetails';
import {ReturnButton} from "../../Buttons";

export const CustomerSearch = () => {

  const [selectedRows, setSelectedRows] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerListLoading, setCustomerListLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});
  const [showCustomerDetails, setShowCustomerDetails] = useState();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState();
  const [, setUpdateForm] = useState();

  const infoFile = useSelector((state) => state.FileInfo);
  const solicitudOperacion = useSelector((state) => state.SolicitudOperacion);
  const dispatch = useDispatch();
  const { id } = useParams();

  const { FormProvider, formMethods, questionsGroups } = useDynamicForm(infoFile.items, null);

  useEffect(() => {
    clearSolicitudData();
  }, []);

  const formData = formMethods.getValues();

  const busquedaClienteQuestionGroup = questionsGroups.find(
    (questionGroup) => questionGroup.llave === 563,
  );

  const clearSolicitudData = () => dispatch(msSolicitudActions.SolicitudOperacionClearState());

  const getCustomerListDispatch = async (filledFields) => {
    CUSTOMER_SEARCH_PAYLOAD.solicitud.c61 = [filledFields];
    setCustomerListLoading(true);
    const response = await dispatch(msSolicitudActions.SolicitudOperacionC61(CUSTOMER_SEARCH_PAYLOAD));
    if (response?.solicitud?.c61){
      setCustomerList(response.solicitud.c61);
    }
    setCustomerListLoading(false);
  };

  const getCustomerDetailsDispatch = async (idCustomer) => {
    SEARCH_CUSTOMER_DETAILS_PAYLOAD.solicitud.cliente.idCliente = idCustomer;
    const response = await dispatch(msSolicitudActions.SolicitudOperacion(SEARCH_CUSTOMER_DETAILS_PAYLOAD));
    if (response?.solicitud?.cliente){
      setCustomerDetails(response.solicitud.cliente);
      setShowCustomerDetails(true);
    }
  };

  const getCustomerRequestBindDispatch = async (idCustomer) => {
    CUSTOMER_REQUEST_BIND_PAYLOAD.solicitud.solicitudRelacionCliente.idCliente = idCustomer;
    const response = await dispatch(msSolicitudActions.SolicitudOperacion(CUSTOMER_REQUEST_BIND_PAYLOAD));
    if (response?.solicitud?.solicitudRelacionCliente){
      const idCliente = selectedRows[0].idCliente ?? null;
      const groupName = infoFile?.items?.StageName ?? null;
      const name = 'idCliente';
      const metadataRequest = buildMetadataRequest(id, groupName, name, idCliente);
      dispatch(fileActions.FileUpdateMetadata(metadataRequest));
    }
    setShowConfirmationDialog(false);
  };

  const onInputChange = () => {
    const formData = formMethods.getValues();
    if (busquedaClienteQuestionGroup.questions) {
      Object.keys(formData).forEach((key) => {
        if (formData[key]) {
          const unfilledQuestions = busquedaClienteQuestionGroup.questions.filter(
            (question) => question.Id !== parseInt(key),
          );
          unfilledQuestions.forEach((question) => (question.Disabled = true));
        }
      });
      if (Object.values(formData).every((value) => !value)) {
        busquedaClienteQuestionGroup.questions.forEach((question) => (question.Disabled = false));
      }
    }
    setUpdateForm({});
  };

  const handleCustomerSearch = async () => {
    let filledFields = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key]) {
        const field = busquedaClienteQuestionGroup.questions.find(
          (question) => question.Id === parseInt(key),
        ).DataType;
        filledFields[field] = formData[key];
      }
    });
    await getCustomerListDispatch(filledFields);
  };

  const handleRowSelect = (row) => setSelectedRows(row);

  const handleClickActionRow = (row) => getCustomerDetailsDispatch(row.idCliente);

  const handleNewCustomer = () => {};

  const handleBackButton = () => setShowCustomerDetails(false);

  const handleSaveRequest = () => setShowConfirmationDialog(true);

  const handleDialogCancel = () => setShowConfirmationDialog(false);

  const handleDialogConfirm = async () => await getCustomerRequestBindDispatch(selectedRows[0].idCliente);

  if (!busquedaClienteQuestionGroup) return null;

  return (
    <Fragment>
      <div style={{display: 'flex', gap: '1rem'}}>
        <ReturnButton label=" " onHandleClick={handleBackButton} isDisabled={!showCustomerDetails}/>
        <GenericHeaderActions LabelTitle="Información de la solicitud"/>
      </div>

      {solicitudOperacion.error && <RequestErrorAlert timer={2500} text={solicitudOperacion.message}/>}

      {!showCustomerDetails ? (
        <FormBox>
          <GenericContainer loading={infoFile.loading} column>
            <FormProvider {...formMethods}>
              <DynamicFormWrapper
                questionsGroups={questionsGroups.filter(
                  (questionGroup) => questionGroup.llave === 563,
                )}
                render={(question, index) => {
                  return (
                    <DynamicFormBody
                      key={question.Id}
                      question={question}
                      handleInputSelect={onInputChange}
                    />
                  );
                }}
              />
              <FormButton
                variant="contained"
                disabled={Object.values(formData).every((value) => !value) || solicitudOperacion.loading}
                onClick={handleCustomerSearch}
              >
                Buscar Cliente
              </FormButton>
              <FormButton variant="contained" disabled={true} onClick={handleNewCustomer}>
                Alta Cliente
              </FormButton>
            </FormProvider>
          </GenericContainer>
          <GenericContainer loading={customerListLoading} column>
            {customerList && customerList.length > 0 && (
              <FormProvider {...formMethods}>
                <DynamicFormWrapper
                  questionsGroups={questionsGroups.filter(
                    (questionGroup) => questionGroup.llave === 624,
                  )}
                  render={(question) => (
                    <DynamicTable
                      key={question.Id}
                      id={question.Id}
                      headers={question.TableHeaders}
                      rows={customerList}
                      hasCheckbox={question.HasCheckbox}
                      maximumNumberRowsSelect={question.MaximumNumberRowsSelect}
                      onSelectRow={handleRowSelect}
                      onClickActionRow={handleClickActionRow}
                      ActionRowDisabled={solicitudOperacion.loading}
                    />
                  )}
                />
                <FormButton
                  variant="contained"
                  onClick={handleSaveRequest}
                  disabled={!selectedRows.length > 0}
                >
                  Guardar Solicitud
                </FormButton>
              </FormProvider>
            )}
          </GenericContainer>
          <GenericConfirmationModal
            open={showConfirmationDialog}
            handleCancel={handleDialogCancel}
            handleConfirm={handleDialogConfirm}
            confirmText={'Confirmar'}
            loading={solicitudOperacion.loading}
          />
        </FormBox>
      ) : (
        <GenericContainer loading={solicitudOperacion.loading} column>
          <CustomerDetails customerDetails={customerDetails}/>
        </GenericContainer>
      )}
    </Fragment>
  );
};