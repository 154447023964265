import { flujoMasivoConstants } from '../constants/index.js'

export const UploaBatchFile2Data = (state = {}, action) => {
    // console.log('actionaction', action)
    switch (action.type) {
        case flujoMasivoConstants.UPLOAD_BATCHFILE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case flujoMasivoConstants.UPLOAD_BATCHFILE_SUCCESS:
            return {
                loading: false,
                items: action.dataExcel.Body,
                error: undefined
            }
        case flujoMasivoConstants.UPLOAD_BATCHFILE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}
export const InitializeBatchFileData = (state = {}, action) => {
    switch (action.type) {
        case flujoMasivoConstants.INITIALIZATE_BATCHFILE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
        case flujoMasivoConstants.INITIALIZATE_BATCHFILE_SUCCESS:
            return {
                loading: false,
                items: action.dataFile,
                error: undefined
            }
        case flujoMasivoConstants.INITIALIZATE_BATCHFILE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
        default:
            return state
    }
}