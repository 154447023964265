//Variable que indica la activacion de encriptacion a todas las peticiones
export const algorithm = process.env.REACT_APP_ENCRIPTED_ALGORITHM
export const prPublicKeyDSC = process.env.REACT_APP_ENCRIPTED_PUBLICDSC_KEY
export const prPublicKey = process.env.REACT_APP_ENCRIPTED_PUBLIC_KEY
export const prPrivateKey = process.env.REACT_APP_ENCRIPTED_PRIVATE_KEY
export const APIKEYGOOGLE = process.env.REACT_APP_GOOGLE_API_KEY
// export const multivaPublicKey = process.env.REACT_APP_MULTIVA_API_PUBLIC_KEY
// export const multivaAPIAlgorithm = process.env.REACT_APP_MULTIVA_API_ENCRYPT_ALGORITHM
// export const encryptedEnabled = process.env.REACT_APP_ENCRYPTED_ENABLED

export const encryptedEnabled = true

//Variable que indica la activacion de encriptacion a todas las peticiones
export const constEncripted = {
  encriptedBody: encryptedEnabled || false
};

export const constPathRoot = {
  pathRoot: process.env.NODE_ENV === "development" ? '' : `${process.env.REACT_APP_BASENAME}`
}

export const ApiUrl = {
  ambient: `${process.env.REACT_APP_DSONBOARDING_API}`
}

export const constUrlGraficas = {
  ambient: `${process.env.REACT_APP_GRAFICAS}`
};