import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({

    inputs: {
        margin: theme.spacing(2),
        paddingTop: 3,
        padding: 0,
        backgroundColor: theme.palette.secondary.light,
        width: '17%',
        borderRadius: '5px',

    },

    tempInput: {
        // margin: theme.spacing(2),
        height: 40,
        paddingTop: 3,
        padding: 0,
        border: '1px solid #DADADA',
        // backgroundColor: `${theme.palette.secondary.light} !important`,
        width: '100% !important',
        borderRadius: '5px !important'
    },

    form: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },


    spinSection: {
        height: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    icon: {
        marginLeft: theme.spacing(1)
    },
    iconMaterial: {
        color: `${theme.palette.primary.main} !important`,
    }

}))