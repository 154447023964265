export const CONSULTA_HABILITADORES_REQUEST_PAYLOAD = {
  "idSolicitud":"66"
};

export const CONSULTA_CATALOGO_REQUEST_PAYLOAD = {
  //"idCatalogo": "11"
  "claveCatalogo": ""
  // ,"idSubCatalogo": 196
}

export const ACTUALIZACION_HABILITADORES_REQUEST_PAYLOAD = {
  "usuario": "USER9",
  "idHabilitador": 1045,
  "idTipoTramite": 94,
  "idOperacionTramite": 97,
  "idTipoDocumento": 115,
  "idPersonalidadJuridica": 139,
  "idProductoCredito": null,
  "tiempoVigenciaDiasNaturales": 33,
  "tieneVigencia": false,
  "estatusHabilitador": "Activo",
  "etapas": [
    {
      "idEtapa": 1042,
      "etapa": 130,
      "subetapa": 134,
      "obligatorio": false
    }
  ]
}

export const GET_DOWNLOAD_DOCUMENT_REQUEST_PAYLOAD = {
  "FileId": 142462,
  "Metadata": [
    // {
    //   "Name": "ValorIdentificador",
    //   "Value": "ABCD850817UJ9"
    // },
    {
      "Name": "idGestorDocumental",
      // "Value": "8ac105a2-14be-4514-a720-b5736a4eed37"
      "Value":""
    },
  ]
};

export const GET_DATA_DOCUMENT_REQUEST_PAYLOAD = {
  "FileId": 142462,
  "Metadata": [
    {
      "Name": "idTipoIdentificador",
      "Value": "ID_RFC"
    },
    {
      "Name": "valorIdentificador",
      "Value": "ABCD850817UJ9"
    },
    {
      "Name": "idTipoDocumento",
      "Value": "67890"
    },
    {
      "Name": "vigente",
      "Value": "true"
    }
  ]
};
