import { useCallback, useEffect, useState } from 'react';
import { Box } from '../FormSimulation/formsSimulations.styled';
import { Button, Typography } from '@mui/material';
import { InputField } from 'components/InputField';
import { useSelector, useDispatch } from 'react-redux';
import { DynamicFormWrapper } from '../../DynamicForm/DynamicFormWrapper';
import { msCatalogosActions } from 'store/actions/msMultiva/msCatalogosActions';
import { InputTypeMappings } from 'mappings/InputTypesMappings';
import { GenericContainer } from 'components/GenericContainer';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { RequestErrorAlert } from 'components/RequestErrorAlert';
import { msSolicitudActions } from 'store/actions/msMultiva/msSolicitudActions';
import { GenericConfirmationModal } from 'components/modals/GenericConfirmationModal';

export const FormOfertaComercial = ({ onHandleOfertaComercial }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const infoCatalogos = useSelector((state) => state.ConsultaCatalogos);
  const [open, setOpen] = useState(false);
  const [groupOfertaComercial, setGroupOfertaComercial] = useState([]);
  const [isNotApplicabeCatalog, setIsNotApplicabeCatalog] = useState(false);
  const [type, setType] = useState([]);
  const [creditType, setCreditType] = useState([]);
  const [program, setProgram] = useState([]);
  const infoSolicitud = useSelector((state) => state.SolicitudOperacion);
  const loggedUser = useSelector((state) => state.Authentication);
  const infoFolio = useSelector((state) => state.Folio);

  const { questionsGroups, control, register, formState, getValues } = useFormContext();

  const handleSubmitForNotApplicableSimulation = () => {
    const formData = getValues();
    const UPDATE_METADATA_PAYLOAD = {
      ObjectId: id,
      Metadata: [
        {
          Name: 'categoria',
          Value: formData[4014],
        },
        {
          Name: 'programa',
          Value: formData[4015],
        },
        {
          Name: 'tipoOperacion',
          Value: formData[4016],
        },
        {
          Name: 'personalidadJuridica',
          Value: formData[4012],
        },
      ],
      ContinueWorkFlow: true,
    };

    const UPDATE_REQUEST_MULTIVA_PAYLOAD = {
      solicitud: {
        idSolicitud: infoSolicitud.items?.solicitud?.idSolicitud,
        idEstatus: 1,
        idEtapa: 1,
        idSubetapa: 1,
        idPersonalidadJuridica: 1,
        idTipoTramite: 94,
        idOperacionSolicitud: 100,
        /****New Values ****/
        idSubDireccionRegional: 1,
        idDireccionRegional: 1,
        idEstatusVencimiento: 1,
        idAreaAsignada: 1,
        idUsuarioAsignado: 1,
        /****New Values ****/
        tipoTransaccion: 'actualizacion',
        idUsuario: 1,
        usuario: loggedUser?.usuario,
        idDao: 3,
        idArea: 5,
        idRol: 7,
        idSucursal: 9,
        idSubDireccionGeneral: 7,
        idDireccionGeneral: 8,
        folioDocsolution: infoFolio.items.Result.Id,
        usuarioGeolocalizacion: '40.689247, -74.044502',
        seccion: ['solicitud'],
      },
    };
    dispatch(
      msSolicitudActions.UpdateMultivaRequestAndOnboardingMetadata(
        UPDATE_REQUEST_MULTIVA_PAYLOAD,
        UPDATE_METADATA_PAYLOAD,
      ),
    ).finally(() => setOpen(false));
  };

  const updateGroupOfertaComercial = (options, questionIndex) => {
    const currentGroup = [...groupOfertaComercial];
    currentGroup[0].questions[questionIndex].Options = options;
    setGroupOfertaComercial(currentGroup);
  };

  const handleSelectType = (name) => {
    const currentCatalogue = infoCatalogos.items[1].subCatalogo.find(
      (value) => value.Value === name,
    );
    updateGroupOfertaComercial(currentCatalogue.subCatalogo, 2);
    setType(currentCatalogue);
  };
  const handleSelectCreditType = (name) => {
    const currentCatalogue = type.subCatalogo.find((value) => value.Value === name);
    updateGroupOfertaComercial(currentCatalogue.subCatalogo, 3);
    setCreditType(currentCatalogue);
  };

  const handleSelectProgram = (name) => {
    const currentCatalogue = creditType.subCatalogo.find((value) => value.Value === name);
    updateGroupOfertaComercial(currentCatalogue.subCatalogo, 4);
    setProgram(currentCatalogue);
  };

  const handleSelectProcedureType = (name) => {
    const currentCatalogue = program.subCatalogo.find((value) => value.Value === name);
    const isNotApplicabeCatalog = currentCatalogue.subCatalogo.length > 0;
    setIsNotApplicabeCatalog(isNotApplicabeCatalog);
  };

  const generateInitialOptions = useCallback(() => {
    const { items } = infoCatalogos || {};
    const ofertaComercial = questionsGroups.find((questionGroup) => questionGroup.llave === 580);
    if (!items || !ofertaComercial) return;
    const newQuestions = ofertaComercial.questions.map((question, index) => ({
      ...question,
      Options: items[index]?.subCatalogo || [],
    }));
    setGroupOfertaComercial([
      {
        ...ofertaComercial,
        questions: newQuestions,
      },
    ]);
  }, [infoCatalogos, questionsGroups]);

  useEffect(() => {
    dispatch(msCatalogosActions.ConsultaCatalogos({}));
    return () => dispatch({ type: 'CONSULTA_CATALOGOS_CLEAR_STATE' });
  }, [dispatch]);

  useEffect(() => {
    generateInitialOptions();
  }, [generateInitialOptions]);

  if (infoCatalogos.error)
    return <RequestErrorAlert error={infoCatalogos.error} showConfim showCancel />;

  return (
    <Box>
      <Typography variant="h5">Captura de Información</Typography>
      <GenericContainer loading={infoCatalogos.loading} column shouldRender={!!infoCatalogos.items}>
        <DynamicFormWrapper
          questionsGroups={groupOfertaComercial}
          render={(question, index) => {
            const InputType = InputTypeMappings[question.QuestionType.toUpperCase()];
            return (
              <InputField
                inputType={InputType}
                control={control}
                register={register}
                name={`${question.Id}`}
                label={question.Description}
                validations={{ required: question.Required }}
                error={!!formState.errors[`${question.Id}`]}
                helperText={formState.errors[`${question.Id}`]?.message}
                required={question.Required}
                optionsList={question.Options}
                onSelect={(e) => {
                  if (index === 1) handleSelectType(e.target.value);
                  if (index === 2) handleSelectCreditType(e.target.value);
                  if (index === 3) handleSelectProgram(e.target.value);
                  if (index === 4) handleSelectProcedureType(e.target.value);
                }}
                disabled={!question.Options?.length > 0}
              />
            );
          }}
        />
        <Button
          variant="contained"
          onClick={
            isNotApplicabeCatalog ? () => setOpen(true) : () => onHandleOfertaComercial(getValues())
          }
        >
          {isNotApplicabeCatalog ? 'Continuar flujo' : 'Simular'}
        </Button>
      </GenericContainer>
      <GenericConfirmationModal
        title="¿Desea continuar con esta simulación?"
        returnText="Cancelar"
        confirmText="Continuar"
        loading={infoSolicitud.loading}
        open={open}
        handleCancel={() => setOpen(false)}
        handleConfirm={handleSubmitForNotApplicableSimulation}
      />
    </Box>
  );
};
