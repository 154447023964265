import { BASE_MULTIVA_URL } from './baseMultivaURL';

const BASE_PATH_HABILITADORES = `${BASE_MULTIVA_URL}/fchabilitadores/api/fabricadecredito/habilitadores`;
const CONSULTA_HABILITADORES_PATH = `${BASE_PATH_HABILITADORES}/consultaHabilitadores`;
const ACTUALIZA_HABILITADORES_PATH = `${BASE_PATH_HABILITADORES}/actualizaHabilitadores`;
const ALTA_HABILITADORES_PATH = `${BASE_PATH_HABILITADORES}/altaHabilitadores`;
const ASOCIAR_GESTOR_FOLIO_PATH = `${BASE_PATH_HABILITADORES}/asociar-foliosolicitud-gestor`;


export { CONSULTA_HABILITADORES_PATH, ACTUALIZA_HABILITADORES_PATH, ALTA_HABILITADORES_PATH, ASOCIAR_GESTOR_FOLIO_PATH };
