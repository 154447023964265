import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, styled } from '@mui/material';
import { CustomLoadingOverlay } from 'components/CustomLoadingOverlay';
import { GenericButtonList } from 'components';
import { GenericStatusElement } from 'components/GenericStatusElement';
import { fileActions } from 'store/actions';

export const GenericContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'left',
  width: '100%',
  minHeight: 'calc(100vh - 70px)',
  margin: '0px',
  marginBottom: '0px',
  overflowX: 'hidden !important',
  background: theme.palette.primary.mainLyrics,
  overflowY: 'auto !important',
  [theme.breakpoints.only('xs')]: {
    padding: '3px 0.5em',
  },
  [theme.breakpoints.only('sm')]: {
    padding: '3px 0.5em',
  },
  [theme.breakpoints.only('md')]: {
    padding: '1.5rem 7em',
  },
  [theme.breakpoints.only('lg')]: {
    padding: '6px 9em',
  },
  [theme.breakpoints.only('xl')]: {
    padding: '8px 11em',
  },
}));

export const SelectOptionsComponents = ({ folioId, actorId }) => {
  const [loadView, setLoadView] = useState(false);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const file = useSelector((state) => state.FileInfo);
  const selection = useSelector((state) => state.OptionsToSelect);

  const handleSelectOption = (selectedOp) => {
    setLoadView(true);
    dispatch(
      fileActions.SetOptionsToSelect({
        File_Id: folioId,
        Actor_Id: actorId,
        Selection: selectedOp,
      }),
    );
  };

  const getSelectDescription = () => {
    return file.items.Stage.Description;
  };

  useEffect(() => {
    if (file.items !== undefined) {
      const optionsValues = file.items.Stage?.Properties?.find((item) => item.Name === 'Options');
      const optionsArray = JSON.parse(optionsValues?.Value);
      setLoadView(false);
      setOptions(optionsArray);
    }
  }, [file]);

  return (
    <GenericContainer>
      {selection.loading ? <CustomLoadingOverlay active={loadView} /> : null}
      <Typography variant="h6" sx={{ textAlign: 'center' }}>
        {getSelectDescription()}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {options.map((op, index) => {
          return (
            <GenericButtonList
              key={`${index}`}
              onClick={() => {
                handleSelectOption(op.Status);
              }}
            >
              <GenericStatusElement key={`${index}_${op.Title}`} icon={op.Icon} text={op.Title} />
            </GenericButtonList>
          );
        })}
      </Box>
    </GenericContainer>
  );
};
