import React, { useState, useEffect } from 'react'
import { useStyles } from './TableStyle'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel, styled,useTheme
} from '@mui/material'

import { paths } from '../../utils'

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  backgroundColor: `${theme.palette.primary.tableMain} !important`,
  color: `${theme.palette.primary.tableText} !important`,
}));

const TableRowCustom = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: `${theme.palette.primary.tableHover} !important`,
}
}));

export const TableRowControl = (props) => {
  const theme = useTheme()
  const localStyle = useStyles(theme)
  const [dataRows, setDataRows] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderDirection, setOrderDirection] = useState('')
  const [valueToOrderBy, setValueToOrderBy] = useState('')

  let path = window.location.pathname


  useEffect(() => {
    console.log("props new controls*******: ", props)
    let dataObject = JSON.parse(props.dataItems.Value)
    setDataRows(dataObject)
    console.log("props new dataObject*******: ", dataObject)
    // console.log("props new dataObject***Columns****: ", dataObject.Columns)
    // console.log("props new dataObject***Rows****: ", dataObject.Rows)
    // const [comment, setComment] = useState('')

    // dataObject.Columns.forEach(element => {
    //   console.log("items-----: ", element)
    // });
  }, [props.dataItems])





  // console.log("data: INFO", data)

  // const newData = data?.map((col) => {
  //   return {
  //     Folio: col.Folio,
  //     Solicitante: col.FullName,
  //     // Producto: col.Product,
  //     Correo: col.Email,
  //     // CreationDate: col.CreationDate,
  //     CreationDate: col.CreationDateFormatted,
  //     Estatus: col.Status,
  //     IdFolio: col.Id,
  //     TipoProducto: col.Metadata?.find(item => item.Name === "Tipo de crédito" && item.GroupName === "Solicitud")?.Value,
  //     // TipoProducto: col.Metadata?.find(item => item.Name === "Origen del servicio" && item.GroupName === "Servicio")?.Value
  //   }
  // })

  // const columns = [
  //   { id: 'folio', label: 'Folio', minWidth: 170 },
  //   { id: 'solicitante', label: 'Suscriptor', minWidth: 170 },
  //   // { id: 'producto', label: 'Producto', minWidth: 170 },
  //   // { id: 'correo', label: 'Correo', minWidth: 170 },
  //   { id: 'tipoProducto', label: 'Tipo de proceso', minWidth: 170 },
  //   { id: 'creationDate', label: 'Fecha de suscripción', minWidth: 170 },
  //   { id: 'status', label: 'Estatus', minWidth: 170 },

  // ]

  // const createData = (folio, solicitante, tipoProducto, creationDate, status, idOfFolio) => {
  //   return { folio, solicitante, tipoProducto, creationDate, status, idOfFolio }
  // }

  // const rows = newData?.map((r) => {
  //   return createData(r.Folio, r.Solicitante, r.TipoProducto, r.CreationDate, r.Estatus, r.IdFolio)
  // })


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };


  // const handlerSort = (event, property) => {
  //   const isAscending = (valueToOrderBy === property && orderDirection === 'asc')
  //   setValueToOrderBy(property)
  //   setOrderDirection(isAscending ? 'desc' : 'asc')
  // }

  // const createSortHandler = (property) => (event) => {
  //   handlerSort(event, property)
  // }

  // const descendingCompatador = (a, b, orderBy) => {
  //   if (b[orderBy] < a[orderBy]) {
  //     return -1
  //   } else if (b[orderBy] < a[orderBy]) {
  //     return 1
  //   } else {
  //     return 0
  //   }

  // }

  // const getComparator = (order, orderBy) => {
  //   return order === 'desc' ? (a, b) => descendingCompatador(a, b, orderBy) : (a, b) => -descendingCompatador(a, b, orderBy)
  // }


  // const sortRowInformation = (newArray, comparator) => {
  //   const stabilizedRowArray = newArray.map((element, index) => [element, index])
  //   stabilizedRowArray.sort((a, b) => {
  //     const order = comparator(a[0], b[0])
  //     if (order !== 0) {
  //       return order
  //     } else {
  //       return stabilizedRowArray.map((element) => element[0])
  //     }
  //   })
  // }

  return (
    <div className={localStyle.tableRowControl}>
      <TableContainer sx={{ width: "100%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {dataRows?.Columns?.map((column, index) => (
                <TableCellHead
                  key={index}
                >
                  {column}
                </TableCellHead>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows?.Rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => {
                return (
                  <TableRowCustom hover role="checkbox" tabIndex={-1} key={row.code} >
                    {dataRows?.Columns?.map((column, index) => {
                      // console.log("Values*******: ", row[index])
                      const valueRows = row[index];
                      return (
                        <TableCell key={index} //align={column.align}
                        >
                          {valueRows.TextValue}
                        </TableCell>
                      );
                    })}
                  </TableRowCustom>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        className={localStyle.tablePaginacion}
        rowsPerPageOptions={[10]}
        component="div"
        count={dataRows?.Rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
      />
    </div>
  )
}