export const msSolicitudConstants = {
  OPERACION_ALTA_REQUEST: 'OPERACION_ALTA_REQUEST',
  OPERACION_ALTA_SUCCESS: 'OPERACION_ALTA_SUCCESS',
  OPERACION_ALTA_FAILURE: 'OPERACION_ALTA_FAILURE',
  OPERACION_ALTA_CLEAR_STATE: 'OPERACION_ALTA_CLEAR_STATE',
  OPERACION_ASOCIACION_REQUEST: 'OPERACION_ASOCIACION_REQUEST',
  OPERACION_ASOCIACION_SUCCESS: 'OPERACION_ASOCIACION_SUCCESS',
  OPERACION_ASOCIACION_FAILURE: 'OPERACION_ASOCIACION_FAILURE',
  OPERACION_ASOCIACION_CLEAR_STATE: 'OPERACION_ASOCIACION_CLEAR_STATE',
  OPERACION_SOLICITUD_REQUEST: 'OPERACION_SOLICITUD_REQUEST',
  OPERACION_SOLICITUD_SUCCESS: 'OPERACION_SOLICITUD_SUCCESS',
  OPERACION_SOLICITUD_FAILURE: 'OPERACION_SOLICITUD_FAILURE',
  OPERACION_SOLICITUD_CLEAR_STATE: 'OPERACION_SOLICITUD_CLEAR_STATE'
};
