export const SIMULATION_GROUPS_KEYS = {
  PyME: [599, 598],
  Hipotecario: [586, 587],
  Automotriz: [592, 587],
  Nomina: [597, 598],
  Personal: [600, 598, 601, 602, 603, 623],
};

export const SIMULATOR_TYPES = {
  PyME: 7,
  Hipotecario: 6,
  Automotriz: 5,
  Nomina: 3,
  Personal: 4,
};

export const COMISION_TABLE_QUESTION_GROUP_KEY = {
  Key: 617,
};

export const AMORTIZACION_TABLE_QUESTION_GROUP_KEY = {
  Key: 618,
};

export const COMISIONES_TABLES_QUESTION_IDS = {
  PyME: 4269,
  Hipotecario: 4267,
  Automotriz: 4256,
  Nomina: 4254,
  Personal: 4265,
};

export const AMORTIZACION_TABLES_QUESTION_IDS = {
  PyME: 4270,
  Hipotecario: 4268,
  Automotriz: 4257,
  Nomina: 4255,
  Personal: 4266,
};

export const RESULT_SIMULATIONS_KEYS = {
  PyME: 739,
  Hipotecario: 738,
  Automotriz: 736,
  Nomina: 735,
  Personal: 739,
};

export const CREDIT_TYPES = {
  3: 'Nomina',
  7: 'PyME',
  6: 'Hipotecario',
  5: 'Automotriz',
  4: 'Personal',
}

export const CREDIT_TYPES2 = {
  143: 'Nomina',
  86: 'PyME',
  88: 'Hipotecario',
  89: 'Automotriz',
  90: 'Personal',
}
