import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { IsDevice } from 'utils';
// import { workFlowActions } from 'store/actions';
import { CustomLoadingOverlay, Buttons } from 'components';
import { msHabilitadoresActions } from 'store/actions/msMultiva/msHabilitadoresActions';
import { DocumentarySlot } from 'components/DocumentarySlot';
import { RequestErrorAlert } from 'components/RequestErrorAlert';
const ID_DOCUMENTAL_GROUP = 114;

export const DocumentaryUpload = (props) => {
  // const [fileId, setFileId] = useState(0);
  // const actorId = props.ActorId;
  const consultaHabilitadoresState = useSelector((state) => state.ConsultaHabilitadores);

  const dispatch = useDispatch();
  const isLoading = consultaHabilitadoresState.loading;
  const isError = consultaHabilitadoresState.error;

  const handleUploadDocument = (fileB64, extension, documentaryGroup) => {
    // const UPLOAD_DOCUMENT_PAYLOAD = {
    //   Body: {
    //     B64Content: fileB64,
    //     FileId: 142462,
    //     Extension: extension,
    //     Metadata: [
    //       {
    //         Name: 'idTipoIdentificador',
    //         Value: 126,
    //       },
    //       {
    //         Name: 'valorIdentificador',
    //         Value: 'BARC850817JU9',
    //       },
    //       {
    //         Name: 'idTipoDocumento',
    //         Value: documentaryGroup.idTipoDocumento,
    //       },
    //       {
    //         Name: 'idSubtipoDocumento',
    //         Value: null,
    //       },
    //       {
    //         Name: 'idEstadoDocumento',
    //         Value: documentaryGroup?.idEstadoDocumento ?? 73,
    //       },
    //       {
    //         Name: 'nombreDocumento',
    //         Value: 'documento',
    //       },
    //       {
    //         Name: 'vigente',
    //         Value: documentaryGroup.tieneVigencia,
    //       },
    //       {
    //         Name: 'idCicloVidaDocumento',
    //         Value: 69,
    //       },
    //       {
    //         Name: 'fechaCreacion',
    //         Value: Date.now(),
    //       },
    //       {
    //         Name: 'usuarioCreacion',
    //         Value: 'MESA.CONTROL',
    //       },
    //       {
    //         Name: 'aplicacionCreacion',
    //         Value: 'fabricaCredito',
    //       },
    //       {
    //         Name: 'aplicacionUltimaModificacion',
    //         Value: 'fabricaCredito',
    //       },
    //       {
    //         Name: 'datosUbicacionFisicaExpediente',
    //         Value: null,
    //       },
    //       {
    //         Name: 'comentarios',
    //         Value: null,
    //       },
    //     ],
    //   },
    // };
    const UPLOAD_DOCUMENT_PAYLOAD = {
      B64Content: fileB64,
      FileId: 142462,
      Extension: 'pdf',
      Metadata: [
        {
          Name: 'idTipoIdentificador',
          Value: 'ID_RFC',
        },
        {
          Name: 'valorIdentificador',
          Value: 'ABCD850817UJ9',
        },
        {
          Name: 'idTipoDocumento',
          Value: '67890',
        },
        {
          Name: 'idSubtipoDocumento',
          Value: '54321',
        },
        {
          Name: 'idEstadoDocumento',
          Value: '73',
        },
        {
          Name: 'nombreDocumento',
          Value: 'Documento de Prueba',
        },
        {
          Name: 'vigente',
          Value: true,
        },
        {
          Name: 'idCicloVidaDocumento',
          Value: '3',
        },
        {
          Name: 'fechaCreacion',
          Value: '2023-12-31',
        },
        {
          Name: 'usuarioCreacion',
          Value: 'user1',
        },
        {
          Name: 'aplicacionCreacion',
          Value: 'appXYZ',
        },
        {
          Name: 'aplicacionUltimaModificacion',
          Value: 'appXYZ',
        },
        {
          Name: 'metadatosDocumento',
          Value: 'Metadatos de prueba',
        },
        {
          Name: 'datosUbicacionFisicaExpediente',
          Value: 'Ubicación física del expediente',
        },
        {
          Name: 'comentarios',
          Value:
            '[{"idDocumentoSolicitudComentario":null,"idSolicitudGestorDocumental":"IDGESTOR123DOCUMENTAL","nota":"Este es un ejemplo de una nota","usuario":"user1","fecha":null,"areaUsuario":"Fabrica"}]',
        },
      ],
    };
    const ASSOCIATE_PAYLOAD = {
      folioSolicitud: 66,
      idGestorDocumental: null,
      inicioVigenciaDocumento: '2024-04-10 17:40:42.560',
      finVigenciaDocumento: '2024-04-20 17:40:42.560',
      idHabilitador: documentaryGroup.idHabilitador,
      usuarioGeolocalizacion: '19.437550560543052, -99.1911370639357',
      folioDocSolution: '777',
    };
    dispatch(msHabilitadoresActions.UploadAndAssociate(UPLOAD_DOCUMENT_PAYLOAD, ASSOCIATE_PAYLOAD));
  };

  const getDocumentaryGroup = () => {
    const payload = consultaHabilitadoresState.items.find(
      (el) => el.idOperacionTramite === ID_DOCUMENTAL_GROUP,
    )?.documentacionOperacion;
    return payload;
  };

  const handleButtonContinue = () => {
    // setLoadView(true);
    // dispatch(workFlowActions.ContinueWorkFlow({ File_Id: fileId }));
  };

  useEffect(() => {
    dispatch(msHabilitadoresActions.ConsultaHabilitadores({ idSolicitud: 66 }));
  }, [dispatch]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        height: '100%',
        padding: '1rem 2.5rem',
      }}
    >
      {isLoading && <CustomLoadingOverlay active={isLoading} />}
      {isError && !isLoading ? <RequestErrorAlert error={isError} /> : null}

      {consultaHabilitadoresState.items.length > 0 && (
        <>
          {getDocumentaryGroup().map((docItem, index) => (
            <DocumentarySlot
              key={index}
              documentaryGroup={docItem}
              allowedExtensions={['pdf', 'png']}
              onSendData={handleUploadDocument}
            />
          ))}

          {getDocumentaryGroup().every((el) => el?.identificadorDocumento || !el.obligatorio) && (
            <Buttons
              onClickConfirm={handleButtonContinue}
              marginBottom={IsDevice().IsMobile ? 50 : 1}
            />
          )}
        </>
      )}
    </Box>
  );
};
