import CryptoJS from 'crypto-js';
// import { multivaPublicKey, multivaAPIAlgorithm } from '../PathPublish.js'

// Hardcodeadas hasta resolver problema con imports desde ENV
const multivaPublicKey = 'YLTWamRD0qDACLz3lz9pyG0RXTnd1LphgRbfhERGHC21eR/MxqaBWAGj/MABwBI0';
const multivaAPIAlgorithm = 'AES';

export function prepareRequestMultiva(data, encryptRequest) {

    console.log("PKey y Algoritmo:",multivaPublicKey,multivaAPIAlgorithm);
    if (encryptRequest) {
        if (typeof (data) === 'object')
            data = JSON.stringify(data)
            console.log("data stringify:", data);
        try {
            const encryptedData = encryptData(data, multivaPublicKey, multivaAPIAlgorithm);
            let objRequest = {
                EncryptedBody: encryptedData,
            }
            console.log("prepareRequest encrypted: ", objRequest)
            return (objRequest)
        }
        catch (error) {
            console.log("Error: (AES encrypt error) - " + error)
            return null
        }
    }
    else {
        let objRequest = { Body: data }
        return (objRequest)
    }
}

export function processRequestMultiva(data) {
    if (data.EncryptedBody) {
        try {

            const decryptedData =  decryptData(data.EncryptedBody, multivaPublicKey, multivaAPIAlgorithm);
            console.log("decryptedData:",decryptedData);

            const JSONData = JSON.parse(decryptedData);
            console.log("JSONData:",JSONData);

            let response = {
                ...JSONData,
                IsOK: data.IsOK,
                Messages: data.Messages
            }
            console.log("processRequest decrypted: ", response)

            return response;
        }
        catch (error) {
            console.error("Error: (Decrypt RSA error) - " + error)
        }
    }
    else if (data.Body) {
        return data
    }
    else if (data.Body === undefined || data.Body === null) {
        return data
    }
    else
        return data
}

const encryptData = (data, key, algorithm) => {
    console.log("Inicia encryptData");
    console.log("encryptData - Datos de entrada:",data,key,algorithm);
    const encrypted = CryptoJS[algorithm].encrypt(data, key);
    console.log("Texto cifrado:", encrypted.toString());
    return encrypted.toString();
};

const decryptData = (encryptedData, key, algorithm) => {
    console.log("Inicia decryptData")
    console.log("decryptData - Datos de entrada:",encryptedData,key,algorithm);
    const decrypted = CryptoJS[algorithm].decrypt(encryptedData, key);
    console.log("Texto descifrado:", decrypted.toString(CryptoJS.enc.Utf8));
    return decrypted.toString(CryptoJS.enc.Utf8);
};