import { prepareRequestMultiva, processRequestMultiva } from './SymetricEncription'

import { constEncripted } from '../'

export function BodySecurityRequestMultiva(data) {

  if (constEncripted.encriptedBody) {
    return prepareRequestMultiva(data, true);
  }
  else{
    if (data === null || data === '') {
      let objRequest = {
        Body: "",
        EncryptedBody: "",
        SecurityData: "",
        PKey: ""
      }
      return (objRequest)
    }
  }
}

export function BodySecurityResponseMultiva(data) {
  if (constEncripted.encriptedBody) {
    return processRequestMultiva(data);
  } else {
    return data;
  }
}