import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Button,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { FormProvider, useFormContext, Controller, useForm } from 'react-hook-form';

import { InputField, Alert, CustomLoadingOverlay } from '../index';
import { constPathRoot, ValidarSICumpleCondiciones } from '../../utils';
import { executeSearchRequests } from '../../services';
import { executeSearchActions } from '../../store/actions';
import { DynamicTable } from '../LEGOS/DynamicTable';
import { headersConfiguration, rowsConfiguration } from '../LEGOS/DynamicTable/mockedData';

export function FormDynamic(props) {
  const history = useNavigate();
  const formMethods = useForm();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState,
    register,
    getValues,
    setError,
    clearErrors,
    resetField,
    reset,
  } = useForm({
    mode: 'onBlur',
  });
  const [loadView, setLoadView] = useState(false); //Se muestra/oculta el gif loader
  const [properties, setProperties] = useState('');
  const [initLoad, setInitLoad] = useState(true);
  const [loadPostalCode, setLoadPostalCode] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [dataControls, setDataControls] = useState([]);

  const [expanded, setExpanded] = useState('');
  let path = window.location.pathname;

  const optionData = useSelector((state) => state.SelectOptionData);
  const dispatch = useDispatch();
  const parseClearObject = () => {
    const currentFormValues = getValues();
    let key;
    let clearedObject = {};
    for (key in currentFormValues) {
      clearedObject = {
        ...clearedObject,
        [key]: null,
      };
    }
    return clearedObject;
  };
  const clearObject = useMemo(() => parseClearObject(), [getValues()]);

  useEffect(() => {
    console.log('*********', getValues());
  }, [formState]);

  useEffect(() => {
    if (props.StageCurrent?.Stage !== undefined && props.StageCurrent?.Stage !== null) {
      console.log('props.StageCurrent*****************', props.StageCurrent);
      console.log('props.StageCurrent*****************', props.StageCurrent);
      setProperties(props.StageCurrent.Stage);
    }
  }, [props.StageCurrent]);

  useEffect(() => {
    console.log('props.DataControls*****************', props);
    if (props.DataControls !== undefined && props.DataControls !== null) {
      setQuestions([]);
      let newJson = [
        {
          llave: props.DataControls?.Id,
          groupTitle: props.DataControls?.Name,
          questions: props.DataControls?.QuestionDefinitions,
        },
      ];

      console.log('new json: ', newJson);
      setQuestions(newJson);
      setDataControls(newJson);
    }
  }, [props.DataControls]);

  useEffect(() => {
    if (properties !== '') {
      // console.log("properties*******: ", properties.Description, properties)
      if (properties.Description !== undefined) {
        setQuestions([]);
        let newArray = setGroupNames(properties.QuestionGroups);
        // console.log('TOMAESTO', newArray)
        setQuestions(newArray);
        setDataControls(newArray);
      }
    }
  }, [properties]);

  useEffect(() => {
    // console.log("_____...............questions........._______: ", questions)
    if (questions?.length > 0) {
      let listDocumentFilter = questions?.map((item) =>
        item.questions.filter(
          (itemDoc) => itemDoc.Value != undefined && itemDoc.Value != null && itemDoc.Value != '',
        ),
      );
      // console.log("_____.........questions......listDocumentFilter........._______: ", listDocumentFilter)
      listDocumentFilter.forEach((itemGroup) => {
        // console.log("itemGroup.....1.....: ", itemGroup)
        itemGroup?.forEach((itemQuestion) => {
          // console.log("itemQuestion.....1.....: ", itemQuestion)
          if (itemQuestion.QuestionType == 'Fecha') {
            if (itemQuestion.Metadata != null && itemQuestion.Metadata !== undefined) {
              setValue(
                `${itemQuestion.Id}`,
                itemQuestion.Metadata !== null ? new Date(itemQuestion.Metadata?.Value) : '',
              );
            }
          } else if (itemQuestion.QuestionType === 'FechaPrevia') {
            if (itemQuestion.Metadata != null && itemQuestion.Metadata !== undefined) {
              setValue(
                `${itemQuestion.Id}`,
                itemQuestion.Metadata !== null ? new Date(itemQuestion.Metadata?.Value) : '',
              );
            }
          } else if (itemQuestion.QuestionType === 'Radio') {
            if (itemQuestion.Metadata != null && itemQuestion.Metadata !== undefined) {
              setValue(`${itemQuestion.Id}`, itemQuestion.Metadata?.Value);
            }
          } else {
            setValue(`${itemQuestion.Id}`, itemQuestion.Value);
          }
        });
      });

      if (expanded == '') {
        console.log('++++++++');
        setExpanded(questions.length > 0 ? questions[0].llave : false);
      }
    }
  }, [questions]);

  const setGroupNames = (array) => {
    return array.map((group) => {
      const questions = group.Questions.map((qn) => {
        let commonProps = {
          GroupName: group.Name,
          Name: qn.Name,
          Value: qn.Metadata !== null ? qn.Metadata?.Value : '',
          QuestionType: qn.QuestionType,
          Options: qn.Options,
          Required: qn.Required,
          Id: qn.Id,
          DefaultValue: null,
          Visible: qn.Visible === undefined || qn.Visible === null ? true : qn.Visible,
          Captured: null,
        };

        if (qn.QuestionType === 'Fecha') {
          setValue(`${qn.Name}`, Date.now());
        }

        if (qn.QuestionType === 'Tabla') {
          let a;
          if (qn.TableHeaders[0].CellType === 'Checkbox') {
            a = JSON.parse(qn.TableHeaders[0].AdditionalData)?.MaximumNumberRowsSelect;
          }
          return {
            ...qn,
            ...commonProps,
            HasCheckbox: qn.TableHeaders[0].CellType === 'Checkbox',
            MaximumNumberRowsSelect: a ? a : null,
          };
        }

        return {
          ...qn,
          ...commonProps,
        };
      });

      return {
        groupTitle: group.Name,
        llave: group.Id,
        questions,
      };
    });
  };

  const handleCancel = () => {
    history('/EmployeesComponent');
  };

  let UpdateCtrlQuestionsAsync = async (itemCurrent, valueParam) => {
    // console.log("itemCurrent: ", itemCurrent)
    try {
      if (itemCurrent.ExecuteSearchFunction === 'GetBySelectedOption') {
        setLoadView(true);
        let value =
          valueParam === undefined || valueParam === null || valueParam === ''
            ? watch(`${itemCurrent.Id}`)
            : valueParam;
        let request = {
          File_Id: props.fileId,
          Document_Id: null,
          DocumentType_Id: null,
          QuestionGroup: itemCurrent.QuestionGroup,
          Question: itemCurrent.Name,
          Value: value,
        };
        await executeSearchRequests.GetBySelectedOption(request).then((response) => {
          let arrayOriginal = questions;
          response.Body?.forEach((itemQuestion) => {
            console.log('itemQuestion item: ', itemQuestion);
            let listOptions = itemQuestion?.Values.map((item) => {
              return {
                Option: item,
                Value: item,
              };
            });
            const updatedOSArray = arrayOriginal.map((itemGroup) =>
              itemGroup.groupTitle.toUpperCase() === itemQuestion.QuestionGroup.toUpperCase()
                ? {
                    ...itemGroup,
                    questions: itemGroup.questions.map((itemQ) =>
                      itemQ.Name.toUpperCase() === itemQuestion.Question.toUpperCase()
                        ? { ...itemQ, Options: listOptions }
                        : itemQ,
                    ),
                  }
                : itemGroup,
            );
            arrayOriginal = updatedOSArray;
          });
          setQuestions([]);
          setQuestions(arrayOriginal);
          setLoadView(false);
        });
        console.log('Execute fin de peticion');
      } else if (itemCurrent.ExecuteSearchFunction == 'GetPreguntasRequeridas') {
        console.log('arrayOriginalA', questions, itemCurrent, valueParam);
        let value =
          valueParam === undefined || valueParam === null || valueParam == ''
            ? watch(`${itemCurrent.Id}`)
            : valueParam;
        let arrayOriginal = questions;

        itemCurrent.ExecuteSearchDefinition?.filter(
          (itemOption) => itemOption.Option === value,
        )[0]?.Requiered?.forEach((itemQuestion) => {
          const updatedOSArray = arrayOriginal.map((itemGroup) =>
            itemGroup.groupTitle.toUpperCase() === itemQuestion.QuestionGroup.toUpperCase() ||
            path === `${constPathRoot.pathRoot}/inbox/new-file`
              ? {
                  ...itemGroup,
                  questions: itemGroup.questions.map((itemQ) =>
                    itemQ.Name.toUpperCase() === itemQuestion.Question.toUpperCase()
                      ? {
                          ...itemQ,
                          Required: itemQuestion.Requiered,
                          Visible: itemQuestion.Requiered,
                        }
                      : itemQ,
                  ),
                }
              : itemGroup,
          );
          arrayOriginal = updatedOSArray;
        });

        let newUpdatearray = arrayOriginal.map((itemGroup) =>
          itemGroup.groupTitle.toUpperCase() === itemCurrent?.GroupName?.toUpperCase() ||
          path === `${constPathRoot.pathRoot}/inbox/new-file`
            ? {
                ...itemGroup,
                questions: itemGroup.questions.map((itemQ) =>
                  itemQ.Name.toUpperCase() === itemCurrent.Name.toUpperCase()
                    ? { ...itemQ, Value: value }
                    : itemQ,
                ),
              }
            : itemGroup,
        );
        setQuestions([]);

        const gruposFiltrados = newUpdatearray.filter((grupo) => {
          return grupo.questions.some((pregunta) => pregunta.Visible);
        });
        console.log('gruposFiltrados 0', arrayOriginal, gruposFiltrados);
        setQuestions(newUpdatearray);
      } else if (itemCurrent.ExecuteSearchFunction === 'GetBySelectFilter') {
        let value =
          valueParam === undefined || valueParam === null || valueParam === ''
            ? watch(`${itemCurrent.Id}`)
            : valueParam;
        if (value !== undefined && value !== null && value !== '') {
          setLoadView(true);
          let requestConsult = {
            File_Id: props.fileId,
            QuestionGroup: itemCurrent?.ExecuteSearchDefinition[0]?.FilterQuestion?.QuestionGroup,
            Question: itemCurrent?.ExecuteSearchDefinition[0]?.FilterQuestion?.Question,
            FilterColumn: itemCurrent?.ExecuteSearchDefinition[0]?.MetadataFilter,
            FilterValue: value,
          };
          console.log('--*-----autocomplete------requestConsult.....', requestConsult);
          await executeSearchRequests.GetByCustomCatalogAsync(requestConsult).then((response) => {
            // console.log("-----autocomplete------requestConsult......Respuesta: ", response)
            var QuestionResponse = response.Body;
            console.log('-----autocomplete------QuestionResponse: ', QuestionResponse);
            console.log(
              '-----autocomplete------QuestionResponse262: ',
              QuestionResponse?.QuestionId,
              QuestionResponse?.Values[0],
            );

            let arrayOriginal = questions;
            console.log('-----autocomplete------arrayOriginal: ', arrayOriginal);
            let listOptions = QuestionResponse?.Values?.map((item) => {
              return {
                Option: item,
                Value: item,
              };
            });
            const updatedOSArray = arrayOriginal.map((itemGroup) =>
              itemGroup.groupTitle.toUpperCase() === QuestionResponse.QuestionGroup.toUpperCase()
                ? {
                    ...itemGroup,
                    questions: itemGroup.questions.map((itemQ) =>
                      itemQ.Name.toUpperCase() === QuestionResponse.Question.toUpperCase()
                        ? { ...itemQ, Options: listOptions, Value: QuestionResponse?.Values[0] }
                        : itemQ,
                    ),
                  }
                : itemGroup,
            );
            console.log('-----autocomplete------updatedOSArray: ', updatedOSArray);
            arrayOriginal = updatedOSArray;

            itemCurrent?.ExecuteSearchDefinition[0]?.OptionsRequiered?.forEach((itemRequired) => {
              console.log('OptionsRequiered----...itemRequired....----:', itemRequired);
              if (ValidarSICumpleCondiciones(itemRequired.Operador, value, itemRequired.Option)) {
                console.log(
                  '-----autocomplete------No cumple la condicion itemRequired?.Requiered: ',
                  itemRequired?.Requiered,
                );

                itemRequired?.Requiered?.forEach((itemQuestion) => {
                  const updatedOSArray = arrayOriginal.map((itemGroup) =>
                    itemGroup.groupTitle.toUpperCase() === itemQuestion.QuestionGroup.toUpperCase()
                      ? {
                          ...itemGroup,
                          questions: itemGroup.questions.map((itemQ) =>
                            itemQ.Name.toUpperCase() === itemQuestion.Question.toUpperCase()
                              ? { ...itemQ, Visible: itemQuestion.Requiered }
                              : itemQ,
                          ),
                        }
                      : itemGroup,
                  );
                  console.log('*****---------....itemQuestion.....---*****: ', itemQuestion);
                  arrayOriginal = updatedOSArray;
                });
                console.log('*****---------....arrayOriginal.....---*****: ', arrayOriginal);

                let newUpdatearray = arrayOriginal.map((itemGroup) =>
                  itemGroup.groupTitle.toUpperCase() === itemCurrent.GroupName.toUpperCase()
                    ? {
                        ...itemGroup,
                        questions: itemGroup.questions.map((itemQ) =>
                          itemQ.Name.toUpperCase() === itemCurrent.Name.toUpperCase()
                            ? { ...itemQ, Value: value }
                            : itemQ,
                        ),
                      }
                    : itemGroup,
                );
                arrayOriginal = newUpdatearray;
              }
            });
            console.log('-----autocomplete------updatedOSArray: ', arrayOriginal);
            setExpanded(itemCurrent.QuestionGroupId);
            setQuestions([]);
            setQuestions(arrayOriginal);
            setLoadView(false);
          });
        }
      }
    } catch (e) {
      console.log('.:UpdateCtrlQuestionsAsync:. Error: ', e);
    }
  };

  useEffect(() => {
    if (dataControls?.length > 0) {
      dataControls.forEach((itemGroup) => {
        itemGroup.questions?.forEach((itemQuestion) => {
          if (itemQuestion.QuestionType == 'Fecha') {
            if (itemQuestion.Metadata != null && itemQuestion.Metadata !== undefined) {
              setValue(
                `${itemQuestion.Id}`,
                itemQuestion.Metadata !== null ? new Date(itemQuestion.Metadata?.Value) : '',
              );
            }
          } else if (itemQuestion.QuestionType === 'FechaPrevia') {
            if (itemQuestion.Metadata != null && itemQuestion.Metadata !== undefined) {
              setValue(
                `${itemQuestion.Id}`,
                itemQuestion.Metadata !== null ? new Date(itemQuestion.Metadata?.Value) : '',
              );
            }
          } else if (itemQuestion.QuestionType === 'Radio') {
            if (itemQuestion.Metadata != null && itemQuestion.Metadata !== undefined) {
              setValue(`${itemQuestion.Id}`, itemQuestion.Metadata?.Value);
            }
          } else {
            // setValue(`${itemQuestion.Id}`, itemQuestion.Value);
          }
          // UpdateCtrlQuestionsAsync(itemQuestion, itemQuestion.Value);
        });
      });
      setInitLoad(false);
      clearErrors();
    } else if (props.StageCurrent !== null || props.StageCurrent !== undefined) {
      if (props.StageCurrent?.Stage !== undefined || props.StageCurrent?.Stage !== null) {
        // console.log("propspropsprops 1", dataControls, questions, props.StageCurrent?.Stage?.QuestionGroups)
        props.StageCurrent?.Stage?.QuestionGroups.forEach((itemGroup) => {
          itemGroup.Questions?.forEach((itemQuestion) => {
            // console.log('itemQuestionDataRevier', itemQuestion)
            if (itemQuestion.QuestionType == 'Fecha') {
              if (itemQuestion.Metadata != null || itemQuestion.Metadata !== undefined) {
                // console.log('ItemFecha', itemQuestion)
                setValue(
                  `${itemQuestion.Id}`,
                  itemQuestion.Metadata !== null ? new Date(itemQuestion.Metadata?.Value) : '',
                );
              }
            } else if (itemQuestion.QuestionType === 'FechaPrevia') {
              if (itemQuestion.Metadata != null || itemQuestion.Metadata !== undefined) {
                setValue(
                  `${itemQuestion.Id}`,
                  itemQuestion.Metadata !== null ? new Date(itemQuestion.Metadata?.Value) : '',
                );
              }
            } else if (itemQuestion.QuestionType === 'Radio') {
              setValue(
                `${itemQuestion.Id}`,
                itemQuestion.Metadata !== null ? itemQuestion.Metadata?.Value : '',
              );
            } else {
              setValue(
                `${itemQuestion.Id}`,
                itemQuestion.Metadata !== null ? itemQuestion.Metadata?.Value : '',
              );
            }
            UpdateCtrlQuestionsAsync(itemQuestion, itemQuestion.Value);
          });
        });
        setInitLoad(false);
        clearErrors();
      }
    }
  }, [dataControls, props.StageCurrent]);

  const handleChange = (panel) => (event, isExpanded) => {
    console.log('handleChange*************-----****: ', isExpanded, panel);
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        display: 'flex !important',
        flexDirection: 'column !important',
        alignItems: 'center !important',
        position: 'relative',
        width: '100% !important',
        padding: 3,
        overflowY: 'auto',
        ['@media only screen and (max-width: 812px)']: {
          height: '60vh',
        },
      }}
    >
      <FormProvider {...formMethods}>
        {loadView ? (
          <CustomLoadingOverlay active={loadView} text="Espere un momento por favor..." />
        ) : null}
        {/* {console.log("QuestionsFormProvider:", questions)} */}
        {questions.length > 0 &&
          questions.map((qn, firstIndex) =>
            qn.questions.every((questionInput) => !questionInput.Visible) &&
            path !== `${constPathRoot.pathRoot}/inbox/new-file` ? null : (
              <Accordion
                key={firstIndex}
                style={{
                  width: '75%',
                }}
                // defaultExpanded={true}
                expanded={questions.length > 1 ? expanded === qn.llave : true}
                onChange={handleChange(qn.llave)}
              >
                <AccordionSummary
                  expandIcon={questions.length > 1 ? <ExpandMore /> : null}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography key={qn.llave} style={{ fontSize: 18, fontWeight: 600 }}>
                    {path === `${constPathRoot.pathRoot}/inbox/new-file`
                      ? 'Completa el siguiente formulario con la información solicitada para registrar el alta del candidato.'
                      : questions.length >= 1
                      ? `${qn.groupTitle}`
                      : questions.length === 1
                      ? 'Completa el siguiente formulario con la información solicitada para registrar la contratación del candidato.'
                      : null}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {qn.questions?.map((itemInput, index) => {
                    // console.log('itemInputitemInput', itemInput)
                    if (itemInput.Visible === false) return null;
                    switch (itemInput.QuestionType.toUpperCase()) {
                      case 'TABLA':
                        return (
                          <DynamicTable
                            id={itemInput.Id}
                            headers={itemInput.TableHeaders}
                            rows={rowsConfiguration.Rows}
                            hasCheckbox={itemInput.HasCheckbox}
                            maximumNumberRowsSelect={itemInput.MaximumNumberRowsSelect}
                            clearErrors={clearErrors}
                            register={register}
                            formState={formState}
                            control={control}
                            setValue={setValue}
                            key={itemInput.Id}
                          />
                        );
                      case 'TEXTO':
                        return (
                          <Controller
                            key={itemInput.Id}
                            control={control}
                            name={`${itemInput.Id}`}
                            render={({ field: { ref, ...restFields } }) =>
                              itemInput.Name == 'Código Postal' ? (
                                <Box sx={{ mt: 1 }}>
                                  <Typography sx={{ fontSize: 14 }}>
                                    <b>CÓDIGO POSTAL</b>
                                  </Typography>
                                  <TextField
                                    {...restFields}
                                    {...register(`${itemInput.Id}`, {
                                      required: 'Requerido',
                                      minLength: { value: 5, message: 'Código Postal. ej. 54960' },
                                    })}
                                    required={true}
                                    error={!!formState.errors[`${itemInput.Id}`]}
                                    helperText={formState.errors[`${itemInput.Id}`]?.message}
                                    inputRef={ref}
                                    fullWidth
                                    autoComplete="new-password"
                                    variant="outlined"
                                    disabled={itemInput.Disabled}
                                    placeholder={itemInput.Name?.toUpperCase()}
                                    label={itemInput.Label?.toUpperCase()}
                                    // onBlur={e => GetFindPostalCode(e.target.value, `${itemInput.Id}`, itemInput.GroupName)}
                                    // onKeyDown={e => GetFindPostalCode(e.target.value, `${itemInput.Id}`, itemInput.GroupName)}
                                    InputProps={{
                                      inputProps: {
                                        maxLength: 5,
                                        style: { textTransform: 'uppercase' },
                                        outline: 'none',
                                        autoComplete: 'new-password',
                                        form: {
                                          autoComplete: 'off',
                                        },
                                      },
                                    }}
                                  />
                                </Box>
                              ) : (
                                <InputField
                                  key={itemInput.Id}
                                  inputType="text"
                                  control={control}
                                  register={register}
                                  name={`${itemInput.Name.replace(/\./g, '')}`}
                                  label={itemInput.Name.toUpperCase()}
                                  validations={{ required: itemInput.Required }}
                                  defaultValue={itemInput.Value}
                                  error={!!formState.errors[`${itemInput.Name}`]}
                                  helperText={formState.errors[`${itemInput.Name}`]?.message}
                                  required={itemInput.Required}
                                />
                              )
                            }
                          />
                        );
                      case 'PHONE':
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="phone"
                            defaultValue={itemInput.Value}
                            regexPhone={itemInput.RegularExpression}
                            control={control}
                            register={register}
                            name={`${itemInput.Id}`}
                            label={itemInput.Description}
                            validations={{ required: itemInput.Required }}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            required={itemInput.Required}
                          />
                        );
                      case 'RADIO':
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="radio"
                            control={control}
                            register={register}
                            name={`${itemInput.Id}`}
                            label={itemInput.Description}
                            radioValues={itemInput.Options}
                            required={itemInput.Required}
                            validations={{ required: true }}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            onSelect={(e, value) => {
                              console.log('RADIOe: ', itemInput, value, e);
                              UpdateCtrlQuestionsAsync(itemInput, value);
                            }}
                          />
                        );
                      case 'COMBO':
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="select"
                            control={control}
                            register={register}
                            defaultValue={itemInput.Value}
                            name={`${itemInput.Id}`}
                            label={itemInput.Description}
                            optionsList={itemInput.Options}
                            validations={{ required: itemInput.Required }}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            required={itemInput.Required}
                            onSelect={(e) => {
                              console.log('COMBO e: ', e.target.value);
                              console.log(
                                'COMBO e.target.id, itemInput.Id: ',
                                e.target.id,
                                itemInput.Id,
                              );
                              setValue(`${itemInput.Id}`, e.target.value);
                              clearErrors(`${itemInput.Id}`);
                              UpdateCtrlQuestionsAsync(itemInput, '');
                            }}
                          />
                        );
                      case 'AUTOCOMPLETE':
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="autocomplete"
                            control={control}
                            name={`${itemInput.Id}`}
                            label={itemInput.Description}
                            placeholder={itemInput.Description}
                            defaultValue={itemInput.Value}
                            // loadingParticipant={loadingParticipant}
                            optionsList={itemInput.Options}
                            validations={{ required: itemInput.Required }}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            required={itemInput.Required}
                            onSelect={(e) => {
                              console.log('....AUTOCOMPLETE COMBO e: ', e);
                              setValue(`${itemInput.Id}`, e);
                              UpdateCtrlQuestionsAsync(itemInput, '');
                              // clearErrors(`${itemInput.Id}`)
                              // UpdateCtrlQuestionsAsync(itemInput, "")
                            }}
                            onInputChange={(e, searchValue) => {
                              // console.log("--*-----autocomplete------itemInput.....", itemInput)
                              // console.log("--*-----autocomplete------v.....", searchValue, searchValue?.length)
                              console.log('--*-----autocomplete------e.....', e?.type, e);
                              if (searchValue?.length > 3 && e?.type == 'click') {
                                let requestConsult = {
                                  File_Id: 176,
                                  SearchText: searchValue,
                                  QuestionGroup: itemInput?.QuestionGroup,
                                  Question: itemInput?.Name,
                                };
                                console.log(
                                  '--*-----autocomplete------requestConsult.....',
                                  requestConsult,
                                );
                                executeSearchRequests
                                  .GetByCustomCatalogAsync(requestConsult)
                                  .then((response) => {
                                    console.log(
                                      '-----autocomplete------requestConsult......Respuesta: ',
                                      response,
                                    );
                                  });
                              }
                              // setsearchUser(v);
                            }}
                            // open={openAutocomplete}
                            // onOpen={() => {
                            //     setOpenAutocomplete(true);
                            // }}
                            // onClose={(e, r) => {
                            //     console.log("ONCLOSE.......r, e: ", r, e)
                            //     if (r === "removeOption") {
                            //         resetField(`${itemInput.Id}`);
                            //     }
                            //     setOpenAutocomplete(false);
                            // }}
                          />
                        );
                      case 'FECHA':
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="date"
                            register={register}
                            control={control}
                            name={`${itemInput.Id}`}
                            label={itemInput.Description}
                            validations={{ required: itemInput.Required }}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            required={itemInput.Required}
                            onSelect={(e) => {
                              console.log('Evento de selecxcion de fecha: ', e);
                              setValue(`${itemInput.Id}`, e);
                              clearErrors(`${itemInput.Id}`);
                            }}
                          />
                        );
                      case 'FECHAPREVIA':
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="date"
                            register={register}
                            control={control}
                            maxDate={new Date()}
                            name={`${itemInput.Id}`}
                            label={itemInput.Description}
                            validations={{ required: itemInput.Required }}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            required={itemInput.Required}
                            onSelect={(e) => {
                              console.log('Evento de selecxcion de fecha: ', e);
                              clearErrors(`${itemInput.Id}`);
                              setValue(`${itemInput.Id}`, e);
                            }}
                            disabled={itemInput.Disabled}
                          />
                        );
                      case 'CHECKBOX':
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="check"
                            control={control}
                            register={register}
                            defaultValue={itemInput.Value}
                            name={`${itemInput.Id}`}
                            label={itemInput.Description}
                            radioValues={itemInput.Options}
                            validations={{ required: true }}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            required={itemInput.Required}
                          />
                        );
                      case 'TEXTAREA':
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="text"
                            control={control}
                            register={register}
                            multiline={true}
                            minRows={3}
                            name={`${itemInput.Id}`}
                            label={itemInput.Description}
                            validations={{ required: itemInput.Required }}
                            defaultValue={itemInput.Value}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            required={itemInput.Required}
                          />
                        );
                      case 'CURRENCY':
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="currency"
                            control={control}
                            register={register}
                            name={`${itemInput.Id}`}
                            defaultValue={itemInput.Value}
                            label={itemInput.Description}
                            validations={{ required: itemInput.Required }}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            required={itemInput.Required}
                            onSelect={(e) => {
                              console.log('Evento CURRENCY: ', e);
                              console.log('Evento CURRENCY : itemInput.Id', itemInput.Id, e);
                              setValue(`${itemInput.Id}`, e);
                              clearErrors(`${itemInput.Id}`);
                            }}
                          />
                        );
                      case 'EMAILMETLIFE':
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="emailMetlife"
                            control={control}
                            register={register}
                            name={`${itemInput.Id}`}
                            label={itemInput.Description}
                            defaultValue={itemInput.Value}
                            validations={{ required: itemInput.Required }}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            required={itemInput.Required}
                          />
                        );
                      case 'EMAIL':
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="email"
                            control={control}
                            register={register}
                            name={`${itemInput.Id}`}
                            label={itemInput.Description}
                            defaultValue={itemInput.Value}
                            validations={{ required: itemInput.Required }}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            required={itemInput.Required}
                          />
                        );
                      case 'CLABE':
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="clabe"
                            control={control}
                            register={register}
                            defaultValue={itemInput.Value}
                            name={`${itemInput.Id}`}
                            label={itemInput.Description}
                            validations={{ required: itemInput.Required }}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            required={itemInput.Required}
                          />
                        );
                      case 'NUMBER':
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="number"
                            control={control}
                            register={register}
                            defaultValue={itemInput.Value}
                            name={`${itemInput.Id}`}
                            label={itemInput.Description}
                            validations={{ required: itemInput.Required }}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            required={itemInput.Required}
                          />
                        );
                      case 'HOUR':
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="hour"
                            register={register}
                            control={control}
                            name={`${itemInput.Id}`}
                            label={itemInput.Description}
                            validations={{ required: itemInput.Required }}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            required={itemInput.Required}
                            disabled={itemInput.Disabled}
                          />
                        );
                      default:
                        return (
                          <InputField
                            key={itemInput.Id}
                            inputType="text"
                            control={control}
                            register={register}
                            name={`${itemInput.Id}`}
                            label={itemInput.Description}
                            validations={{ required: itemInput.Required }}
                            defaultValue={itemInput.Value}
                            error={!!formState.errors[`${itemInput.Id}`]}
                            helperText={formState.errors[`${itemInput.Id}`]?.message}
                            required={itemInput.Required}
                          />
                        );
                    }
                  })}
                </AccordionDetails>
              </Accordion>
            ),
          )}
        {console.log('props.loadingButton', props.loadingButton)}
        <Button
          disabled={
            props.loadingButton != undefined || props.loadingButton != null
              ? !props.loadingButton
              : null
          }
          fullWidth
          variant="contained"
          sx={{ height: 45, px: 10, marginTop: '0.5em', minWidth: 220, maxWidth: 350 }}
          onClick={handleSubmit(props.handleSave)}
        >
          {props.TextButton === undefined || props.TextButton === null
            ? 'Guardar'
            : props.TextButton}
        </Button>
      </FormProvider>
    </Box>
  );
}
