import { fileConstants } from '../constants/index.js';
import {
  getDocumentationInfo,
  getCardDocumentationData,
  getValidation,
  getDocsForApproval,
  getCardsActorData,
  getDocActorInfo,
  getValidationRuleData,
  getCardValidationDocumentationData,
} from '../../utils';

export const FileInfo = (state = {}, action) => {
  switch (action.type) {
    case fileConstants.FILE_INFO_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_INFO_SUCCESS:
      return {
        loading: false,
        items: action.fileInfo.Body,
        itemsDocs: getDocumentationInfo(action.fileInfo.Body),
        itemsDocCard: getCardDocumentationData(action.fileInfo.Body),
        itemsValidation: getValidation(action.fileInfo.Body),
        itemsDocsForApproval: getDocsForApproval(action.fileInfo.Body),
        itemsEquivalencias: getValidationRuleData(action.fileInfo.Body),
        itemsDocCardValidation: getCardValidationDocumentationData(action.fileInfo.Body.Stage),
        error: undefined,
      };
    //break;
    case fileConstants.FILE_INFO_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    //break;
    default:
      return state;
  }
};

export const SearchByUser = (state = {}, action) => {
  switch (action.type) {
    case fileConstants.FILE_SEARCH_USER_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_SEARCH_USER_SUCCESS:
      return {
        loading: false,
        items: action.userSearch.Body,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_SEARCH_USER_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    //break;
    default:
      return state;
  }
};

export const InfoBySearch = (state = {}, action) => {
  switch (action.type) {
    case fileConstants.FILE_SEARCH_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_SEARCH_SUCCESS:
      return {
        loading: false,
        items: action.searchInfo.Body,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_SEARCH_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    //break;
    default:
      return state;
  }
};

export const RecordInfo = (state = {}, action) => {
  switch (action.type) {
    case fileConstants.FILE_RECORD_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_RECORD_SUCCESS:
      return {
        loading: false,
        items: action.recordInf.Body,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_RECORD_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    //break;
    default:
      return state;
  }
};

export const ActorsInfo = (state = {}, action) => {
  switch (action.type) {
    case fileConstants.FILE_ACTORS_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_ACTORS_SUCCESS:
      return {
        loading: false,
        items: action.actors.Body,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_ACTORS_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    //break;
    default:
      return state;
  }
};

export const ActorInfo = (state = {}, action) => {
  switch (action.type) {
    case fileConstants.FILE_ACTOR_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_ACTOR_SUCCESS:
      return {
        loading: false,
        items: action.actor.Body,
        itemsCardActor: getCardsActorData(action.actor.Body),
        itemsDocsActor: getDocActorInfo(action.actor.Body),
        error: undefined,
      };
    //break;
    case fileConstants.FILE_ACTOR_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    //break;
    default:
      return state;
  }
};

export const Folio = (state = {}, action) => {
  switch (action.type) {
    case fileConstants.FILE_FOLIO_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_FOLIO_SUCCESS:
      return {
        loading: false,
        items: action.folio.Body,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_FOLIO_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    //break;
    default:
      return state;
  }
};

export const FolioRemote = (state = {}, action) => {
  switch (action.type) {
    case fileConstants.FILE_FOLIO_REMOTE_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_FOLIO_REMOTE_SUCCESS:
      return {
        loading: false,
        items: action.folio.Body,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_FOLIO_REMOTE_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    //break;
    default:
      return state;
  }
};

export const FileUpdateMetData = (state = {}, action) => {
  switch (action.type) {
    case fileConstants.FILE_UPDATEMETADATA_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_UPDATEMETADATA_SUCCESS:
      return {
        loading: false,
        items: action.folioUpdateData.Body,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_UPDATEMETADATA_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    //break;
    default:
      return state;
  }
};

export const GenerateFolioHiringData = (state = {}, action) => {
  switch (action.type) {
    case fileConstants.FILE_GENERATEFOLIOHIRING_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_GENERATEFOLIOHIRING_SUCCESS:
      return {
        loading: false,
        items: action.folioGenData.Body,
        error: undefined,
      };
    //break;
    case fileConstants.FILE_GENERATEFOLIOHIRING_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    //break;
    default:
      return state;
  }
};

export const OptionsToSelect = (state = {}, action) => {
  switch (action.type) {
      case fileConstants.FILE_OPTIONS_REQUEST:
          return { loading: true, items: undefined, error: undefined }
          
      case fileConstants.FILE_OPTIONS_SUCCESS:
          return { loading: false, items: action.options.Body, error: undefined }
          
      case fileConstants.FILE_OPTIONS_FAILURE:
          return { loading: false, items: undefined, error: action.error }
          
      default:
          return state
  }
}

export const UserCurentData = (state = {}, action) => {
  switch (action.type) {
    case fileConstants.GET_ASIGNATE_CURRENTUSER_REQUEST:
      return {
        loading: true,
        items: undefined,
        error: undefined,
      };
    //break;
    case fileConstants.GET_ASIGNATE_CURRENTUSER_SUCCESS:
      return {
        loading: false,
        items: action.dataUserCurent.Body,
        error: undefined,
      };
    //break;
    case fileConstants.GET_ASIGNATE_CURRENTUSER_FAILURE:
      return {
        loading: false,
        items: undefined,
        error: action.error,
      };
    //break;
    default:
      return state;
  }
};
