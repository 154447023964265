export const BIOMETRICS_REQUEST_PAYLOAD = {
  "solicitud": {
    "idSolicitud": 9,
    "tipoTransaccion": "consulta",
    "idUsuario": 12343,
    "usuarioGeolocalizacion": "40.689247, -74.044502",
    "seccion": [
      "biometricos"
    ],
    "biometricos": [
      {
        "idBiometrico": 1,
        "idSolicitud": ""
      }
    ]
  }
}

export const CONSULTA_CATALOGO_REQUEST_PAYLOAD = {
  //"idCatalogo": "11"
  "claveCatalogo": ""
  // ,"idSubCatalogo": 196
}