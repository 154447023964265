import { Button, Box, styled, Typography, Grid } from '@mui/material';
import { Alert } from 'components/Alert';
import { useDropzone } from 'react-dropzone';
import { convertPdfToB64 } from 'utils';
import { Icon } from '@iconify/react';

const DropZoneContainer = styled(Box)(({ theme }) => ({
  maxHeight: 300,
  border: `dashed 2px ${theme.palette.primary.main}`,
  borderRadius: 10,
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '&:hover': {
    opacity: 0.5,
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: 200,
  },
  height: '40vh',
}));

export const DragAndDrop = ({ title, allowedExtensions, isExcelDocument, onChange }) => {
  const onDrop = (files) => {
    if (files.length === 0) {
      Alert({
        icon: 0,
        title: `El archivo que intenta adjuntar no es válido.`,
      });
      return;
    }
    const extension = files[0]?.name?.split('.').pop()?.toLowerCase();
    console.log(allowedExtensions);
    if (isExcelDocument && extension !== 'xlsx') {
      Alert({
        icon: 0,
        title: `Por favor seleccione un documento .xlsx`,
      });
      return;
    }
    if (allowedExtensions?.includes(extension) || (isExcelDocument && extension === 'xlsx')) {
      convertFileToB64(files[0]);
      return;
    }
    Alert({
      icon: 0,
      title: 'Por favor seleccione un tipo de documento válido .pdf, .png, .jpg ',
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: isExcelDocument
      ? '.xlsx'
      : {
          'image/png': ['.png'],
          'image/jpg': ['.jpg'],
          'application/pdf': ['.pdf'],
        },
  });

  const convertFileToB64 = async (file) => {
    try {
      const splitted = file.name.split('.');
      const extention = splitted[splitted.length - 1];
      const { base64result } = await convertPdfToB64(file);
      onChange(base64result, extention);
    } catch (error) {
      onChange(null, null);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DropZoneContainer {...getRootProps()}>
            <input {...getInputProps()} style={{ display: 'none' }} />
            <Box margin={1} display={'flex'} justifyContent="center">
              <Icon fontSize={50} color="#A3D043" icon="clarity:upload-cloud-line" />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
              }}
            >
              <Typography align="center" variant="body2">
                {title ? title : 'Arrastra tu documento o'}
              </Typography>
              &nbsp;
              <Button
                color="primary"
                variant="outlined"
                sx={{
                  maxWidth: 380,
                  padding: '5px 10px',
                }}
              >
                da clic para seleccionar uno
              </Button>
            </Box>
          </DropZoneContainer>
        </Grid>
      </Grid>
    </Box>
  );
};
