import {
  Button,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select, Step, StepButton, StepConnector, stepConnectorClasses, StepIcon, StepLabel, Stepper,
  useTheme
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {useState} from "react";
import {useStyles} from "../../pages/details/DetailStyles";
import {useSelector} from "react-redux";
import {styled} from '@mui/system';

import recoleccion_azul_vector from '../../assets/StageStepper/Recoleccion/recoleccion_azul.png'
import recoleccion_verde_vector from '../../assets/StageStepper/Recoleccion/recoleccion_verde.png'
import recoleccion_gris_vector from '../../assets/StageStepper/Recoleccion/recoleccion_gris.png'

import motor_decision_azul_vector from '../../assets/StageStepper/MotorDecision/motor_decision_azul.png'
import motor_decision_verde_vector from '../../assets/StageStepper/MotorDecision/motor_decision_verde.png'
import motor_decision_gris_vector from '../../assets/StageStepper/MotorDecision/motor_decision_gris.png'

import validacion_azul_vector from '../../assets/StageStepper/Validacion/validacion_azul.png'
import validacion_verde_vector from '../../assets/StageStepper/Validacion/validacion_verde.png'
import validacion_gris_vector from '../../assets/StageStepper/Validacion/validacion_gris.png'

import formalizacion_azul_vector from '../../assets/StageStepper/Formalizacion/formalizacion_azul.png'
import formalizacion_verde_vector from '../../assets/StageStepper/Formalizacion/formalizacion_verde.png'
import formalizacion_gris_vector from '../../assets/StageStepper/Formalizacion/formalizacion_gris.png'

const stepsArray = [
  {
    stepNumber: 0,
    id: "Recoleccion de información",
    label: "Recolección de información",
    icon: {
      Finished: recoleccion_verde_vector,
      Active: recoleccion_azul_vector,
      Pending: recoleccion_gris_vector,
    }
  },
  {
    stepNumber: 1,
    id: "Motor de decisión",
    label: "Motor de decisión",
    icon: {
      Finished: motor_decision_verde_vector,
      Active: motor_decision_azul_vector,
      Pending: motor_decision_gris_vector,
    }
  },
  {
    stepNumber: 2,
    id: "Validaciones",
    label: "Validación",
    icon: {
      Finished: validacion_verde_vector,
      Active: validacion_azul_vector,
      Pending: validacion_gris_vector,
    }
  },
  {
    stepNumber: 3,
    id: "Formalización",
    label: "Formalización",
    icon: {
      Finished: formalizacion_verde_vector,
      Active: formalizacion_azul_vector,
      Pending: formalizacion_gris_vector,
    }
  }
]

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  display: "inline",

  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#68A913",

    },
  },

  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#68A913",
    },
  },

  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#BABABA",
    borderTopWidth: 2,
    marginLeft: "0.6rem",
    marginRight: "0.6rem",
    marginTop: "1rem",
  },
}));

function findActiveStepIndex(status) {
  const activeStep = stepsArray.find((step) => step.id === status);
  return activeStep ? activeStep.stepNumber : 0;
}

export default function StageStepper() {

  const infoFile = useSelector(state => state.FileInfo)

  const [actualStep, setActualStep] = useState(
    findActiveStepIndex(infoFile.items.Status || infoFile.items.Stage.Status));

  const [activeStep, setActiveStep] = useState(actualStep);

  const handleStepClick = (step) => () => {
    setActiveStep(step);
  }

  console.log("StageStepper:", infoFile.items.Status);

  return (
    <div>
      <Stepper alternativeLabel activeStep={actualStep} connector={<CustomConnector/>}>
        {stepsArray.map((step) => {

          const iconSelection =
            step.stepNumber === activeStep
              ? step.icon.Active
              : step.stepNumber <= actualStep
                ? step.icon.Finished
                : step.icon.Pending;

          return (
            <Step key={step.id}>
              <StepButton
                onClick={handleStepClick(step.stepNumber)}
                icon={<img src={iconSelection} alt={`Icono de ${step.label}`}/>}
                disabled={step.stepNumber > actualStep}
              >
                {step.label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === 0 && <section>Contenido del Paso 1</section>}
      {activeStep === 1 && <section>Contenido del Paso 2</section>}
      {activeStep === 2 && <section>Contenido del Paso 3</section>}
      {activeStep === 3 && <section>Contenido del Paso 4</section>}

    </div>

  );
}
