import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Card, TableHead} from "@mui/material";
import dayjs from "dayjs";

export const CommentsTable = ({ comments }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell>Nota</TableCell>
            <TableCell>Área de Usuario</TableCell>
            <TableCell>ID de Documento de Solicitud</TableCell>
            <TableCell>ID de Solicitud en Gestor Documental</TableCell>
            <TableCell>Usuario</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {comments.map((comentario, index) => (
            <TableRow key={index}>
              <TableCell>{dayjs(comentario.fecha).format("DD-MM-YYYY")}</TableCell>
              <TableCell>{comentario.nota}</TableCell>
              <TableCell>{comentario.areaUsuario}</TableCell>
              <TableCell>{comentario.idDocumentoSolicitudComentario}</TableCell>
              <TableCell>{comentario.idSolicitudGestorDocumental}</TableCell>
              <TableCell>{comentario.usuario}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

