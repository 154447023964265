import { BodySecurityRequestForceEncripted } from '../utils'
import {BodySecurityRequestMultiva} from "../utils/encripted/SecurityMultiva";

export const Config = {
  SetParameters: (data, encryptedBody, pKey, securityData, force = false, isRequestEncrypted = false) => {//Setear parámetros para solicitudes a api ***Método genérico***
    /*añadir parámetros solo si se cambia la estructura del objeto.
    Objéto genérico para todas las solicitudes a cualquier API*/
    if (force) {
      return JSON.stringify(BodySecurityRequestForceEncripted(data))
    }
    else if (isRequestEncrypted)
      return BodySecurityRequestMultiva(data);
    else {
      return {
        Body: data,
        EncryptedBody: encryptedBody,
        PKey: pKey,
        SecurityData: securityData
      }
    }
  },
  // SetParameters: (data, force = false) => {//Setear parámetros para solicitudes a api Encriptacion  ***Método genérico***
  //   /*añadir parámetros solo si se cambia la estructura del objeto.
  //   Objéto genérico para todas las solicitudes a cualquier API*/
  //   if (force) {
  //     return JSON.stringify(BodySecurityRequestForceEncripted(data))
  //   } else {
  //     return {
  //       Body: data
  //     }
  //   }
  // },

  ConfigAuth: () => {//Configuración de solicitud que incluye token de autorización
    return {
      headers: {//Configuración de headers a enviar al consumir cualquier API ***Incluye token de autorización
        // 'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',//JSON
        'Content-Type': 'application/json;charset=UTF-8',//Esperamos un json con configuración UTF-8
        'Authorization': `${sessionStorage.getItem('tokenMc')}`,//Se añade Token   Content-Type: multipart/form-data; boundary=something 
      }
    }
  },
  ConfigAuthMultiva: () => {
    return {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        // token: `${sessionStorage.getItem('multivaToken')}`,
      },
    };
  },
  ConfigAuthFile: () => {//Configuración de solicitud que incluye token de autorización
    return {
      headers: {//Configuración de headers a enviar al consumir cualquier API ***Incluye token de autorización
        // 'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json',//JSON
        'Content-Type': 'undefined',//Esperamos un json con configuración UTF-8
        'Authorization': `${sessionStorage.getItem('tokenMc')}`,//Se añade Token
      }
    }
  },
  ConfigNoAuth: () => {//Configuración de headers a enviar al consumir cualquier API ***solicitud excluyendo token de autorización***
    return {
      headers: {
        'Accept': 'application/json',//JSON
        'Content-Type': 'application/json;charset=UTF-8'//Esperamos un json con configuración UTF-8
      }
    }
  },

  ConfigNoAuthDoc: () => {//Configuración de headers a enviar al consumir cualquier API ***solicitud excluyendo token de autorización***
    return {
      responseType: 'blob',
      headers: {
        'Accept': 'application/json',//JSON
        'Content-Type': 'application/json;charset=UTF-8'//Esperamos un json con configuración UTF-8
      }
    }
  },

  getTokenCurrentUser() {
    return sessionStorage.getItem('tokenMc');
  }
}
