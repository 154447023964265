import { Box } from '@mui/material';
import VisorPDF from 'components/Visor/VisorPDF';
import { DragAndDrop } from 'components/DragAndDrop';

export const DocumentarySlotBody = ({
  fileB64,
  extensionCurrent,
  handleFileChange,
  allowedExtensions,
  fileUrl,
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'flex-start',
          minHeight: '34vh',
        }}
      >
        {fileB64 === null ? (
          <DragAndDrop
            allowedExtensions={allowedExtensions}
            initLoadChange={true}
            IsPhoto={false}
            onChange={handleFileChange}
          />
        ) : (
          <>
            {allowedExtensions?.includes(extensionCurrent) && extensionCurrent === 'pdf' && (
              <Box sx={{ width: '100%' }}>
                <VisorPDF pdfUrl={fileUrl} pdfName={'Document'} height={'50vh'} />
              </Box>
            )}
            {allowedExtensions?.includes(extensionCurrent) &&
              ['png', 'jpg', 'jpeg'].includes(extensionCurrent) && (
                <img style={{ width: '100%', marginTop: '1rem' }} src={fileUrl} alt="experiment" />
              )}
          </>
        )}
      </Box>
    </>
  );
};
