import { msTokenConstants } from '../../constants/msMultiva/msTokenConstants';

export const TokenRHSSO = (state = {}, action) => {
  switch (action.type) {
    case msTokenConstants.TOKEN_RHSSO_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msTokenConstants.TOKEN_RHSSO_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msTokenConstants.TOKEN_RHSSO_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    default:
      return state;
  }
};
