import { FormControl, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";

export const ApproveDocumentForm = ({ onFormDataChange }) => {

  const [statusList, setStatusList] = useState(["Activo", "Inactivo", "Pendiente"]);
  const [formData, setFormData] = useState({
    fechaVigencia: null,
    estatus: "",
    ubicacionFisica: null
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
    onFormDataChange({ ...formData, [name]: value });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      fechaVigencia: date
    });
    onFormDataChange({ ...formData, fechaVigencia: date });
  };

  return (
    <FormControl fullWidth>
      <Typography><strong>Fecha de vigencia *</strong></Typography>
      <DatePicker
        required
        name="fechaVigencia"
        value={formData.fechaVigencia}
        onChange={handleDateChange}
      />
      <Typography><strong>Estatus *</strong></Typography>
      <Select
        displayEmpty
        name="estatus"
        value={formData.estatus}
        onChange={handleChange}
        sx={{ textAlign: 'start' }}
      >
        <MenuItem value='' disabled>
          Selecciona una opción
        </MenuItem>
        {statusList.map(status => (
          <MenuItem key={status} value={status} >
            {status}
          </MenuItem>
        ))}
      </Select>
      <Typography><strong>Ubicación Fisica *</strong></Typography>
      <TextField
        name="ubicacionFisica"
        value={formData.ubicacionFisica}
        onChange={handleChange}
        placeholder={"Rack 20, Caja 40"}
      />
    </FormControl>
  );
};
