const { Box, Button } = require('@mui/material');

export const DocumentarySlotControls = ({ handleUpload, resetFileAndExtension }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <Button
          fullWidth
          variant="contained"
          sx={{ height: 45, mr: 3, px: 10, maxWidth: 300 }}
          onClick={handleUpload}
        >
          Subir
        </Button>
        <Button
          fullWidth
          variant="outlined"
          sx={{ height: 45, px: 10, maxWidth: 300 }}
          onClick={resetFileAndExtension}
        >
          Elegir otro archivo
        </Button>
      </Box>
    </Box>
  );
};
