import { executeSearchConstants } from '../constants'
import { executeSearchRequests } from '../../services'

export const executeSearchActions = {}

executeSearchActions.GetByCodigoPostal = (data) => {
    return dispatch => {
        dispatch(request())
        executeSearchRequests.GetByCodigoPostal(data)
            .then(
                searchData => dispatch(success(searchData)),
                error => dispatch(failure(error.message))
            )
    }

    function request() {
        return { type: executeSearchConstants.SEARCH_REQUEST }
    }

    function success(searchData) {
        return { type: executeSearchConstants.SEARCH_SUCCESS, searchData }
    }

    function failure(error) {
        return { type: executeSearchConstants.SEARCH_FAILURE, error }
    }
}

executeSearchActions.GetBySelectedOption = (data) => {
    return dispatch => {
        dispatch(request())
        executeSearchRequests.GetBySelectedOption(data)
            .then(
                optionData => dispatch(success(optionData)),
                error => dispatch(failure(error.message))
            )
    }

    function request() {
        return { type: executeSearchConstants.SELECTED_OPTION_REQUEST }
    }

    function success(optionData) {
        return { type: executeSearchConstants.SELECTED_OPTION_SUCCESS, optionData }
    }

    function failure(error) {
        return { type: executeSearchConstants.SELECTED_OPTION_FAILURE, error }
    }
}

executeSearchActions.GetByCustomCatalog = (data) => {
    return dispatch => {
        dispatch(request())
        executeSearchRequests.GetByCustomCatalog(data)
            .then(
                searchData => dispatch(success(searchData)),
                error => dispatch(failure(error.message))
            )
    }

    function request() {
        return { type: executeSearchConstants.SEARCH_AUTOCOMPLETE_REQUEST }
    }

    function success(searchData) {
        return { type: executeSearchConstants.SEARCH_AUTOCOMPLETE_SUCCESS, searchData }
    }

    function failure(error) {
        return { type: executeSearchConstants.SEARCH_AUTOCOMPLETE_FAILURE, error }
    }
}