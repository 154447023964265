import { msAuthenticationConstants } from '../../constants/msMultiva/msAuthenticationConstants';

export const GenerateCode2FA = (state = {}, action) => {
  switch (action.type) {
    case msAuthenticationConstants.GENERATE_CODE_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msAuthenticationConstants.GENERATE_CODE_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msAuthenticationConstants.GENERATE_CODE_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    default:
      return state;
  }
};

export const ValidateCode2FA = (state = {}, action) => {
  switch (action.type) {
    case msAuthenticationConstants.VALIDATE_CODE_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msAuthenticationConstants.VALIDATE_CODE_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msAuthenticationConstants.VALIDATE_CODE_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    default:
      return state;
  }
};
