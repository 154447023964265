import { externalConstants } from '../constants';
import { externalRequests } from '../../services'

export const externalActions = {}

externalActions.GetListBranchOffice = (data) => {
  return dispatch => {
    dispatch(request())
    externalRequests.GetListBranchOffice(data)
      .then(
        listBranchData => dispatch(success(listBranchData)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: externalConstants.GET_BRANCH_REQUEST }
  }

  function success(listBranchData) {
    return { type: externalConstants.GET_BRANCH_SUCCESS, listBranchData }
  }

  function failure(error) {
    return { type: externalConstants.GET_BRANCH_FAILURE, error }
  }
}