import React from 'react';
import { DynamicFormWrapper } from './DynamicFormWrapper.jsx';
import { DynamicFormBody } from './DynamicFormBody.jsx';
import { useDynamicForm } from '../../../hooks/useDynamicForm.js';
import { Box, Button } from './dynamicForm.styled.js';

export const DynamicForm = ({
  StageCurrent,
  DataControls,
  loadingButton,
  handleSave,
  TextButton,
}) => {
  const { FormProvider, formMethods, questionsGroups } = useDynamicForm(StageCurrent, DataControls);

  return (
    <Box>
      <FormProvider {...formMethods}>
        <DynamicFormWrapper
          questionsGroups={questionsGroups}
          render={(question, index) => <DynamicFormBody key={question.Id} question={question} />}
        />
        <Button variant="contained" onClick={formMethods.handleSubmit(handleSave)}>
          {TextButton || 'Guardar'}
        </Button>
      </FormProvider>
    </Box>
  );
};
