import React, { useEffect, useState } from 'react';
import {useTheme,Typography, Dialog, Box, styled, IconButton, Button,
    //Select, MenuItem
} from "@mui/material";
import { makeStyles } from '@mui/styles'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
// import { deviceDetect, mobileModel } from "react-device-detect"

// import { mainStyles, theme } from "../../styles/Main.js";
import { blobToBase64 } from "../../utils";
// import { SignalCellularNullOutlined } from '@mui/icons-material';
import {
    CustomNativeCamera, UseImage, CustomAlertHelpDialog
} from "../";
import maskCaptureDocumentImage from '../../assets/maskCaptureDocument.png'
import Comprobante_domicilioImage from '../../assets/Comprobante_domicilio.png'

const ContainerCamera = styled(Box)(({ theme }) => ({
    background: "#FFFFFF",
    margin: "0px",
    marginBottom: '0px',
    [theme.breakpoints.down("sm")]: {
        minHeight: '90vh',
    },
    overflowY: 'hidden !important',
}));

const useStyles = makeStyles((theme) => ({
    insideTextTip: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '45vh',
        padding: '0.2rem',
        width: '100%',
        zIndex: '1000',
        borderRadius: '5px',
        [theme.breakpoints.down("sm")]: {
            top: '40vh',
        },
    },
    insideMask: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        // top: '1vh',
        // padding: '0.2rem',
        width: '100%',
        zIndex: '20',
        borderRadius: '5px',
        /* Para 600px */
        '@media only screen and (max-width: 620px) and (min-width: 501px)': {
            top: '15vh',
        },
        /* Para 480px */
        '@media only screen and (max-width: 500px) and (min-width: 341px)': {
            top: '0px',
        },
        /* Para 320px */
        '@media only screen and (max-width: 340px) and (min-width: 5px)': {
            top: '-15px',
        },
    },
    '&:hover': {
        outline: 'none',
    },
    '&:active': {
        outline: 'none',
    },
    '&:focus': {
        outline: 'none',
    },
    textContent1: {
        textAlign: 'justify',
        fontWeight: 'bold !important',
        width: '100%',
        ['@media only screen and (max-width: 812px)']: {
            width: '100%',
        }
    },
    carouselSection1: {
        marginTop: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ['@media only screen and (max-width: 812px)']: {
            height: '30vh',
            marginTop: '40px',
        }
    },
    imgHelp: { //Help
        height: '45vh',
        ['@media only screen and (max-width: 812px)']: {
            height: '21vh',
        }
    },
    buttonContainerHelp: {
        // display: 'flex',
        // alignContent: 'center',
        width: '100% !important',
        textAlign: 'center',
    },
    imgPreview: { //Help
        height: '97vh',
        ['@media only screen and (max-width: 812px)']: {
            // marginTop: '10px',
            height: '100vh',
            marginBottom: '13%',
        },
        marginBottom: '13%',
        paddingBottom: '3%',
    },
}))

export const FullCameraCapture= (props) => {
    const theme = useTheme()
    const localStyles = useStyles();
    const [photoTaken, setPhotoTaken] = useState(false)
    const [photoPreview, setPhotoPreview] = useState(null)

    const [controlCameraCustom, setControlCameraCustom] = useState(null)
    const [showHelp, setShowHelp] = useState(true)

    useEffect(() => {
        setPhotoPreview(null)
        setControlCameraCustom(null)
        console.log("photoPreview init control: ", photoPreview)
    }, [])


    useEffect(() => {
        console.log("photoPreview gnerate control camera: ", photoPreview)
        if (photoPreview || photoPreview === null) {
            setControlCameraCustom(<CustomNativeCamera FacingMode="environment"
                isDisplay={photoPreview === null ? true : false}
                Mirrored={false}
                width={navigator.userAgent.match(/iPhone/i) ||
                    navigator.userAgent.match(/Android/i) ? 700 : 1100}
                onCapture={blob => {
                    setPhotoPreview(blob)
                }}
                // deviceId={deviceId}
                CaptureInit={photoTaken} />)
        }
    }, [showHelp, photoTaken])

    return <Dialog fullScreen onClose={props.onClose}
        open={props.open}>
        <ContainerCamera>
            {/* {deviceId !== null && devices.length > 1 ?
                <Box sx={{
                    // display: 'flex',
                    display: 'none',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: "center",
                    flexDirection: 'column',
                    alignItems: "center",
                    position: 'fixed',
                    top: 0,
                    backgroundColor: "#FFF",
                    zIndex: 200,
                    height: '7vh',
                    // opacity: '0.5',
                }}>
                    <Typography variant="subtitle2" color="primary" >
                        Si la <b>resolución es baja</b>, cambia de <b>cámara</b>.
                    </Typography>
                    <Select ref={selectDeviceCurrent}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={deviceId}
                        style={{ color: theme.palette.primary.main }}
                        onChange={handleChangeCamera}
                    >
                        {devices?.map((item, index) => {
                            // return <MenuItem style={{ color: theme.palette.primary.main }} value={item.deviceId}>{mobileModel}-{item.label}-{item.deviceId}</MenuItem>
                            return <MenuItem style={{ color: theme.palette.primary.main }} value={item.deviceId}>Cámara {index + 1}</MenuItem>
                            // return <MenuItem style={{ color: theme.palette.primary.main }} value={item.deviceId}>{mobileModel}-{item.label}-Cámara {index + 1}</MenuItem>
                        })
                        }
                    </Select>
                </Box>
                : null} */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: "flex-start",
                alignItems: "center",
                height: '100vh',
            }}>
                {photoTaken == true && photoPreview !== null ? <>
                    {console.log("photoPreview: ", photoPreview)}
                    <img
                        className={localStyles.imgPreview}
                        src={URL.createObjectURL(photoPreview)}
                        alt="experiment" />

                </> : null}
                {/* {console.log("*****************Devide ID:*****************: ", deviceId)} */}
                {controlCameraCustom}
                {/* {console.log("Camara generada: ", controlCameraCustom)} */}
                {(navigator.userAgent.match(/iPhone/i) ||
                    navigator.userAgent.match(/Android/i)) && photoPreview === null ?
                    <Box sx={{
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // border: '2px solid red',
                        backgroundColor: 'transparent !important',
                        height: '88vh',
                        width: '100%',
                        position: 'absolute',
                        top: '-10px',
                        zIndex: 10,
                    }}>
                        <UseImage
                            className={localStyles.docIcon}
                            src={maskCaptureDocumentImage}
                        />
                    </Box> : null}
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: theme.palette.primary.backG,
                bottom: '0px',
                position: 'sticky',
                paddingBottom: '24px',
                zIndex: 120,
            }}>
                {photoTaken &&
                    <>
                        <IconButton title="Recaptura" color="primary"
                            classes={{ root: localStyles.iconApproval }}
                            style={{ marginRigth: '1em !importat', color: theme.palette.primary.main, }}
                            onClick={() => {
                                setPhotoTaken(false)
                                setPhotoPreview(null)
                            }}>Recaptura&nbsp;
                            <ClearIcon color='primary'
                                style={{
                                    color: theme.palette.primary.main,
                                    width: '35px',
                                    height: '35px'
                                }} />
                        </IconButton>
                        &nbsp;&nbsp;&nbsp;
                        <IconButton title="Aceptar" color="primary"
                            classes={{ root: localStyles.iconApproval }}
                            style={{ marginLeft: '1em !importat', color: theme.palette.primary.main, }}
                            onClick={() => {
                                blobToBase64(photoPreview).then(res => {
                                    console.log("blobToBase64-------- Carga WEB----------", res)
                                    let resultReplace = res.replace('data:image/jpeg;base64,', '')
                                    //   setDocument(resultReplace)
                                    setPhotoTaken(false)
                                    setPhotoPreview(null)
                                    props.onSendData(resultReplace)
                                });
                            }}>Aceptar&nbsp;
                            <CheckIcon color='primary'
                                style={{
                                    color: theme.palette.primary.main,
                                    width: '35px',
                                    height: '35px'
                                }} />
                        </IconButton>
                    </>
                }
                {!photoTaken && // showButtonHideOverlay && 
                    <>
                        <IconButton title="Cancelar" color="primary"
                            classes={{ root: localStyles.iconButton }}
                            style={{ marginRigth: '1em !importat', color: theme.palette.primary.main, }}
                            onClick={props.onClose}>Cancelar&nbsp;
                            <ClearIcon style={{
                                color: theme.palette.primary.main,
                                // backgroundColor: '#1E1E1E',
                                width: '35px',
                                height: '35px'
                            }} />
                        </IconButton>
                        &nbsp;&nbsp;&nbsp;
                        <IconButton title="Capturar" color="primary"
                            classes={{ root: localStyles.iconButton }}
                            style={{ marginLeft: '1em !importat', color: theme.palette.primary.main, }}
                            onClick={() => {
                                setPhotoTaken(true)
                            }}>Capturar&nbsp;
                            <PhotoCameraIcon style={{
                                color: theme.palette.primary.main,
                                // backgroundColor: '#1E1E1E',
                                width: '35px',
                                height: '35px'
                            }} />
                        </IconButton>
                    </>
                }
            </Box>
        </ContainerCamera>
        {/* {console.log("props.sectionName***********: ", props.sectionName)} */}
        {showHelp === true &&
            <HelpDialogGeneric
                open={showHelp}
                closeAction={() => {
                    setShowHelp(false)
                }}
                sectionName={props.sectionName}
                DocName={props.DocName}
                capture={props.capture}
                onCapture={() => {
                    setShowHelp(false)
                }}
            />}


    </Dialog >
};

export const HelpDialogGeneric = (props) => {
    const localStyles = useStyles()

    return (
        <CustomAlertHelpDialog closeAction={props.closeAction}
            open={props.open}
            body={
                <div>
                    <div className={localStyles.textContent1}>
                        <Typography variant="subtitle1" align='justify' >
                            A continuación deberás realizar la&nbsp; captura del documento.
                            Asegúrate de enfocar los datos correctamente, evita sombras y reflejos.
                        </Typography>
                    </div>
                    <div className={localStyles.carouselSection1}>
                        <UseImage type='Image3' src={Comprobante_domicilioImage}
                            className={localStyles.imgHelp} />
                    </div>
                </div>
            }
            Buttons={
                <div className={localStyles.buttonContainerHelp} >
                     <Button
                      fullWidth
                      variant="contained"
                      sx={{ height: 45, px: 10 }}
                      onClick={props.onCapture} >
                      Continuar
                    </Button>

                    {/* <mainStyles.CustomDynamicButton
                        background_color={theme.palette.primary.main}
                        text_color='#FFFFFF'
                        border_color={theme.palette.primary.main}
                        text='Continuar'
                        type='button'
                        size={100}
                        className={localStyles.cargarDocsBtn}
                        click={props.onCapture}
                    /> */}
                </div>
            }
        />
    )
}