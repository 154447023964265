// constants.js
export const DOCUMENT_STATUS_APPROVED = 73;
export const DOCUMENT_STATUS_REJECTED = 72;
export const DOCUMENT_STATUS_PENDING = "default";

export const DOCUMENT_STATUS_APPROVED_TOOLTIP_LABEL = "Aprobado";
export const DOCUMENT_STATUS_REJECTED_TOOLTIP_LABEL = "Rechazado";
export const DOCUMENT_STATUS_PENDING_TOOLTIP_LABEL = "Pendiente";

export const UPLOAD_DOCUMENT_MODAL_TITLE = "Subir un documento";
export const UPDATE_DOCUMENT_MODAL_TITLE = "Reemplazar documento actual";

export const VIEW_DOCUMENT_TOOLTIP_LABEL = "Ver documento";
export const UPDATE_DOCUMENT_TOOLTIP_LABEL = "Reemplazar documento actual";
export const UPLOAD_DOCUMENT_TOOLTIP_LABEL = "Subir documento";
