import {useDispatch, useSelector} from "react-redux";
import {Box as FormBox, Button as FormButton} from "../../LEGOS/DynamicForm/dynamicForm.styled";
import React, {Fragment, useEffect, useState} from "react";
import {GenericConfirmationModal} from "../../modals/GenericConfirmationModal";
import {GenericHeaderActions} from "../../GenericHeaderActions";
import {DynamicFormWrapper} from "../../LEGOS/DynamicForm/DynamicFormWrapper";
import {DynamicTable} from "../../LEGOS/DynamicTable";
import {useDynamicForm} from "hooks/useDynamicForm";
import {RequestErrorAlert} from "../../RequestErrorAlert";
import {GenericContainer} from "../../GenericContainer";
import {AccountDetails} from "./AccountDetails";
import {msSolicitudActions} from "store/actions/msMultiva/msSolicitudActions";
import {buildMetadataRequest} from "utils/buildMetadataRequest";
import {fileActions} from "store/actions";
import {useParams} from "react-router-dom";
import {ACCOUNT_SEARCH_PAYLOAD,
  SEARCH_ACCOUNT_DETAILS_PAYLOAD,
  CUSTOMER_UPDATE_ACCOUNT_PAYLOAD} from "./AccountSelectionRequestPayloads";
import {ReturnButton} from "../../Buttons";

export const AccountSelectionForm = () => {

  const [selectedRows, setSelectedRows] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [accountListLoading, setAccountListLoading] = useState(false);
  const [accountDetails, setAccountDetails] = useState({})
  const [showAccountDetails, setShowAccountDetails] = useState()
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [fetchError, setFetchError] = useState(false)
  const infoFile = useSelector((state) => state.FileInfo);
  const solicitudOperacion = useSelector((state) => state.SolicitudOperacion);
  const dispatch = useDispatch();
  const { id } = useParams();

  const { questionsGroups } = useDynamicForm(infoFile.items, null);

  useEffect(() => {
    clearSolicitudData();
  }, []);

  useEffect(() => {
    getAccountListDispatch(idCliente)
  }, []);

  const idCliente = infoFile.items.Metadata
    ? (infoFile.items.Metadata.filter(item => item.Name === "idCliente") || {})[0].Value
    : 0;

  const clearSolicitudData = () => {
    dispatch(msSolicitudActions.SolicitudOperacionClearState());
  }

  const getAccountListDispatch = async (noCliente = 1001172120 ) => {
    ACCOUNT_SEARCH_PAYLOAD.solicitud.c71[0].idCliente = 1011; //Para pruebas 1011
    setAccountListLoading(true);
    const response = await dispatch(msSolicitudActions.SolicitudOperacion(ACCOUNT_SEARCH_PAYLOAD));
    setAccountList(response?.solicitud?.c71 || []);
    setFetchError(!response?.solicitud?.c71);
    setAccountListLoading(false);
  };

  const getAccountDetailsDispatch = async (idAccount) => {
    SEARCH_ACCOUNT_DETAILS_PAYLOAD.solicitud.cuentas[0].cuenta.idCuenta = idAccount; //Para pruebas 1
    const response = await dispatch(msSolicitudActions.SolicitudOperacion(SEARCH_ACCOUNT_DETAILS_PAYLOAD));
    if(response?.solicitud?.cuentas){
      setAccountDetails(response.solicitud?.cuentas[0]?.cuenta ?? {});
      setShowAccountDetails(true)
    }
    setFetchError(!response?.solicitud?.cuentas);
  };

  const getUpdateAccountDispatch = async (idAccount) => {
    SEARCH_ACCOUNT_DETAILS_PAYLOAD.solicitud.cuentas[0].cuenta.idCuenta = idAccount; //Para pruebas 1
    const responseDetails = await dispatch(msSolicitudActions.SolicitudOperacion(SEARCH_ACCOUNT_DETAILS_PAYLOAD));
    if (responseDetails?.solicitud?.cuentas){
      CUSTOMER_UPDATE_ACCOUNT_PAYLOAD.solicitud.cuentas[0].cuenta = responseDetails?.solicitud?.cuentas[0]?.cuenta ?? {};
      const responseUpdate = await dispatch(msSolicitudActions.SolicitudOperacion(CUSTOMER_UPDATE_ACCOUNT_PAYLOAD));
      if (responseUpdate?.solicitud){
        const groupName = infoFile?.items?.StageName?? null;
        const name = "idCuenta";
        const metadataRequest = buildMetadataRequest(id,groupName,name,idAccount);
        dispatch(fileActions.FileUpdateMetadata(metadataRequest));
      }
    }
    setShowConfirmationDialog(false);
  };

  const handleRowSelect = (row) => setSelectedRows(row);

  const handleClickActionRow = (row) => getAccountDetailsDispatch(row.cuenta);

  const handleBackButton = () => setShowAccountDetails(false);

  const handleSaveRequest = () => setShowConfirmationDialog(true);

  const handleCancelDialog = () => setShowConfirmationDialog(false);

  const handleConfirmationDialog = async () => await getUpdateAccountDispatch(selectedRows[0].cuenta);

  console.log("CS_fetchError", fetchError);

  if(!questionsGroups) return null

  return (
    <Fragment>
      <div style={{display: 'flex', maxHeight: '2.5rem'}}>
        <ReturnButton label=" " onHandleClick={handleBackButton} isDisabled={!showAccountDetails}/>
        <GenericHeaderActions
          LabelTitle="Cuentas disponibles"
          onClickConfirm={() => {}}
          ConfirmButtonText="Crear nueva cuenta"
          ConfirmButtonDisabled={selectedRows.length > 0}
        />
      </div>

      {fetchError && <RequestErrorAlert timer={500} />}

      {!showAccountDetails ?
        <FormBox sx={{marginTop:'2rem'}}>
          <GenericContainer loading={accountListLoading} column>
            {accountList.length > 0 &&
              <DynamicFormWrapper
                questionsGroups={questionsGroups.filter((questionGroup) => questionGroup.llave === 626)}
                render={(question) => (
                  <DynamicTable
                    key={question.Id}
                    id={question.Id}
                    headers={question.TableHeaders}
                    rows={accountList}
                    hasCheckbox={question.HasCheckbox}
                    maximumNumberRowsSelect={question.MaximumNumberRowsSelect}
                    onSelectRow={handleRowSelect}
                    onClickActionRow={handleClickActionRow}
                    ActionRowDisabled={solicitudOperacion.loading}
                  />
                )}
              />
            }
            <FormButton
              variant="contained"
              onClick={handleSaveRequest}
              disabled={!selectedRows.length > 0}
            >Guardar Solicitud
            </FormButton>
          </GenericContainer>
          <GenericConfirmationModal
            open={showConfirmationDialog}
            handleCancel={handleCancelDialog}
            handleConfirm={handleConfirmationDialog}
            confirmLabel={"Confirmar"}
          />
        </FormBox>
        : <GenericContainer loading={solicitudOperacion.loading} column>
          <AccountDetails accountDetails={accountDetails}/>
        </GenericContainer>
      }
    </Fragment>
  );
}