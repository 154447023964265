import {Card as MUICard, keyframes, styled} from '@mui/material';

export const Card = styled(MUICard)(({ theme }) => ({
  width: '100%',
  height: '230px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.palette.secondary.light
  }
}));

export const CardContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gap: '1rem',
}));

const fadeInOut = keyframes`
  0% { filter: brightness(1); }
  50% { filter: brightness(0.8); }
  100% { filter: brightness(1); }
`;

export const AccordionContainer = styled('div')(({ theme, isSelected }) => ({
  width: '100%',
  animation: isSelected ? `${fadeInOut} 1.0s` : 'none',
}));