export const msBackOfficeConstants = {
  ALTA_PROCESO_BACK_OFFICE_REQUEST: 'ALTA_PROCESO_BACK_OFFICE_REQUEST',
  ALTA_PROCESO_BACK_OFFICE_SUCCESS: 'ALTA_PROCESO_BACK_OFFICE_SUCCESS',
  ALTA_PROCESO_BACK_OFFICE_FAILURE: 'ALTA_PROCESO_BACK_OFFICE_FAILURE',
  ACTUALIZAR_PROCESO_BACK_OFFICE_REQUEST: 'ACTUALIZAR_PROCESO_BACK_OFFICE_REQUEST',
  ACTUALIZAR_PROCESO_BACK_OFFICE_SUCCESS: 'ACTUALIZAR_PROCESO_BACK_OFFICE_SUCCESS',
  ACTUALIZAR_PROCESO_BACK_OFFICE_FAILURE: 'ACTUALIZAR_PROCESO_BACK_OFFICE_FAILURE',
  CONSULTAR_PROCESO_BACK_OFFICE_REQUEST: 'CONSULTAR_PROCESO_BACK_OFFICE_REQUEST',
  CONSULTAR_PROCESO_BACK_OFFICE_SUCCESS: 'CONSULTAR_PROCESO_BACK_OFFICE_SUCCESS',
  CONSULTAR_PROCESO_BACK_OFFICE_FAILURE: 'CONSULTAR_PROCESO_BACK_OFFICE_FAILURE',
};
