export const UPDATE_DOCUMENT_REQUEST_PAYLOAD = {
  "B64Content": "",
  "FileId": 142462,
  "Extension": "pdf",
  "Metadata": [
    {
      "Name": "idGestorDocumental",
      "Value": "d6ea8560-5648-4a4a-abf4-85b174a2f1be"
    },
    {
      "Name": "idTipoIdentificador",
      "Value": "ID_RFC"
    },
    {
      "Name": "valorIdentificador",
      "Value": "ABCD850817UJ3"
    },
    {
      "Name": "idTipoDocumento",
      "Value": "67891"
    },
    {
      "Name": "idSubtipoDocumento",
      "Value": "54322"
    },
    {
      "Name": "idEstadoDocumento",
      "Value": "73"
    },
    {
      "Name": "nombreDocumento",
      "Value": "Documento de Prueba"
    },
    {
      "Name": "vigente",
      "Value": "true"
    },
    {
      "Name": "fechaFinVigencia",
      "Value": "2024-01-03"
    },
    {
      "Name": "usuarioUltimaModificacion",
      "Value": "user2"
    },
    {
      "Name": "aplicacionUltimaModificacion",
      "Value": "appABC"
    },
    {
      "Name": "metadatosDocumento",
      "Value": "Metadatos de prueba a"
    },
    {
      "Name": "datosUbicacionFisicaExpediente",
      "Value": "Ubicación física de expediente a"
    },
    {
      "Name": "comentarios",
      "Value": "[{\"idDocumentoSolicitudComentario\":null,\"idSolicitudGestorDocumental\":\"IDGESTOR123DOCUMENTAL\",\"nota\":\"Este es un ejemplo de una nota b\",\"usuario\":\"user2\",\"fecha\":\"2024-01-03\",\"areaUsuario\":\"Fabrica\"}]"
    }
  ]
}

export const UPLOAD_DOCUMENT_PAYLOAD = {
  B64Content: "",
  FileId: 142462,
  Extension: 'pdf',
  Metadata: [
    {
      Name: 'idTipoIdentificador',
      Value: 'ID_RFC',
    },
    {
      Name: 'valorIdentificador',
      Value: 'ABCD850817UJ9',
    },
    {
      Name: 'idTipoDocumento',
      Value: '67890',
    },
    {
      Name: 'idSubtipoDocumento',
      Value: '54321',
    },
    {
      Name: 'idEstadoDocumento',
      Value: '73',
    },
    {
      Name: 'nombreDocumento',
      Value: 'Documento de Prueba',
    },
    {
      Name: 'vigente',
      Value: true,
    },
    {
      Name: 'idCicloVidaDocumento',
      Value: '3',
    },
    {
      Name: 'fechaCreacion',
      Value: '2023-12-31',
    },
    {
      Name: 'usuarioCreacion',
      Value: 'user1',
    },
    {
      Name: 'aplicacionCreacion',
      Value: 'appXYZ',
    },
    {
      Name: 'aplicacionUltimaModificacion',
      Value: 'appXYZ',
    },
    {
      Name: 'metadatosDocumento',
      Value: 'Metadatos de prueba',
    },
    {
      Name: 'datosUbicacionFisicaExpediente',
      Value: 'Ubicación física del expediente',
    },
    {
      Name: 'comentarios',
      Value:
        '[{"idDocumentoSolicitudComentario":null,"idSolicitudGestorDocumental":"IDGESTOR123DOCUMENTAL","nota":"Este es un ejemplo de una nota","usuario":"user1","fecha":null,"areaUsuario":"Fabrica"}]',
    },
  ],
};

export const ASSOCIATE_PAYLOAD = {
  folioSolicitud: 66,
  idGestorDocumental: null,
  inicioVigenciaDocumento: '2024-04-10 17:40:42.560',
  finVigenciaDocumento: '2024-04-20 17:40:42.560',
  idHabilitador: "",
  usuarioGeolocalizacion: '19.437550560543052, -99.1911370639357',
  folioDocSolution: '777',
};