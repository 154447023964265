import {Box, Button, Dialog, DialogContent, Grid, Typography} from "@mui/material";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {RejectDocumentModal} from "./RejectDocumentModal";
import {
  ACTUALIZACION_HABILITADORES_REQUEST_PAYLOAD,
  GET_DATA_DOCUMENT_REQUEST_PAYLOAD,
  GET_DOWNLOAD_DOCUMENT_REQUEST_PAYLOAD
} from "../../RequestDocumentationPayloads";
import {msHabilitadoresActions} from "store/actions/msMultiva/msHabilitadoresActions";
import {useDispatch, useSelector} from "react-redux";
import {B64toBlob} from "utils";
import {GenericContainer} from "components/GenericContainer";
import {RequestErrorAlert} from "components/RequestErrorAlert";
import {ApproveDocumentForm} from "./ApproveDocumentForm";
import {DocumentDetails} from "./DocumentDetails";
import dayjs from "dayjs";
import {CommentsTable} from "./CommentsTable";

export const DetailsModal = ({docInfo, isOpen, onClose, refreshData }) => {

  const [showRejectDocumentModal, setShowRejectDocumentModal] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [pdfFile, setPdfFile] = useState()
  const [documentMetadata, setDocumentMetadata] = useState();
  const [approveFormData, setApproveFormData] = useState(null);
  const iframeRef = useRef(null);
  const actualizaHabilitadores = useSelector((state) => state.ActualizaHabilitadores);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllDocumentData();
  }, []);

  const handleRejectModalOpen = () => setShowRejectDocumentModal(true);
  const handleRejectModalClose = () => setShowRejectDocumentModal(false);
  const handleApproveFormDataChange = (data) => setApproveFormData(data);

  const toggleFullScreen = async () => {
    await iframeRef.current.requestFullscreen();
  }

  const isFormButtonDisabled = () => {
    return !approveFormData || !(dayjs(approveFormData.fechaVigencia).isValid() && approveFormData.estatus && approveFormData.ubicacionFisica);
  };

  const handleApproveDocument = async (docInfo) => {
    ACTUALIZACION_HABILITADORES_REQUEST_PAYLOAD.idHabilitador = docInfo.idHabilitador;
    const response = await dispatch(msHabilitadoresActions.ActualizaHabilitadores(ACTUALIZACION_HABILITADORES_REQUEST_PAYLOAD));
    refreshData();
    onClose();
  };

  const handleRejectDocument = async (rejectMessaje) => {
    ACTUALIZACION_HABILITADORES_REQUEST_PAYLOAD.idHabilitador = docInfo.idHabilitador;
    const response = await dispatch(msHabilitadoresActions.ActualizaHabilitadores(ACTUALIZACION_HABILITADORES_REQUEST_PAYLOAD));
    refreshData();
    onClose();
    console.log("CS_RejectMessage",rejectMessaje);
  };

  const getAllDocumentData = async () => {
    try {
      setDetailsLoading(true);
      await Promise.all([
        getDocumentMetadata(),
        getDocumentDownload()
      ]);
      setDetailsLoading(false);
    } catch (error) {
      setFetchError(true);
      onClose();
    }
  }

  const getDocumentDownload = async () => {
    GET_DOWNLOAD_DOCUMENT_REQUEST_PAYLOAD.Metadata[0].Value = docInfo.identificadorDocumento ?? "";
    const response = await dispatch(msHabilitadoresActions.DownloadDocument(GET_DOWNLOAD_DOCUMENT_REQUEST_PAYLOAD));
    const parsedPDF = B64toBlob(response.Body.Result.Metadata[0].Value);
    const pdfURL = URL.createObjectURL(parsedPDF);
    setPdfFile(pdfURL);
  };

  const getDocumentMetadata = async () => {
    const response = await dispatch(msHabilitadoresActions.GetDataDocument(GET_DATA_DOCUMENT_REQUEST_PAYLOAD));
    const parsedMetadata = JSON.parse(response.Body.Result.Metadata[0].Value)
    console.log("CS_ParsedMetadata", parsedMetadata[0]);
    setDocumentMetadata(parsedMetadata[0]);
  };

  console.log("CS_ApproveFormData",approveFormData);

  return (
    <Fragment>
      {fetchError && <RequestErrorAlert timer={1000}/>}

      <Dialog open={isOpen} onClose={onClose} PaperProps={{style: {minWidth: "70%"}}}>
          <GenericContainer loading={detailsLoading} column>
            {(pdfFile && documentMetadata) &&
              <DialogContent sx={{overflowY:'auto'}}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <iframe
                      title="PDF Viewer"
                      src={pdfFile}
                      style={{width: '100%', height: '100%', border: "none"}}
                      ref={iframeRef}
                    />
                  </Grid>
                  {documentMetadata &&
                    <Grid item xs={6}>
                      <DocumentDetails documentMetadata={documentMetadata}/>
                      <hr style={{margin: '20px 0', border: 'none', borderBottom: '2px solid grey'}}/>
                      { docInfo.comentarios && <CommentsTable comments={docInfo.comentarios}></CommentsTable>}
                      <hr style={{margin: '20px 0', border: 'none', borderBottom: '2px solid grey'}}/>
                      <ApproveDocumentForm onFormDataChange={handleApproveFormDataChange}/>
                    </Grid>
                  }
                  <Grid item xs={6}>
                    <Button variant={'contained'} onClick={toggleFullScreen}>Ver documento en pantalla completa</Button>
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'space-around'}}>
                    <Button
                      variant={'contained'}
                      onClick={handleApproveDocument}
                      disabled={actualizaHabilitadores.loading || isFormButtonDisabled() }>
                      Aprobar
                    </Button>
                    <Button variant={'contained'} onClick={handleRejectModalOpen}>Rechazar</Button>
                  </Grid>
                </Grid>
              </DialogContent>
            }
          </GenericContainer>
        <RejectDocumentModal isOpen={showRejectDocumentModal} onClose={handleRejectModalClose} onSendClick={handleRejectDocument}/>
        </Dialog>

    </Fragment>
  );
};
