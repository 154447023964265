import {useDispatch, useSelector} from "react-redux";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {Accordion} from "../../Accordion.jsx";
import {GenericHeaderActions} from "../../GenericHeaderActions";
import {
  CONSULTA_CATALOGO_REQUEST_PAYLOAD,
  CONSULTA_HABILITADORES_REQUEST_PAYLOAD
} from "./RequestDocumentationPayloads";
import {msHabilitadoresActions} from "../../../store/actions/msMultiva/msHabilitadoresActions";
import {msCatalogosActions} from "../../../store/actions/msMultiva/msCatalogosActions";
import {DocCard} from "./DocCard";
import {GenericContainer} from "../../GenericContainer";
import {RequestErrorAlert} from "../../RequestErrorAlert";
import {AccordionContainer, CardContainer} from "./requestDocumentationViewer.styled";
import {DocSummaryTable} from "./DocSummaryTable";
import {ScrollTopButton} from "../../ScrollTopButton";
import {Grid, Typography} from "@mui/material";

export const RequestDocumentationViewer = () => {

  const [documentationData, setDocumentationData] = useState([]);
  const [catalogoTramites, setCatalogoTramites] = useState([]);
  const [fetchError, setFetchError] = useState(false)
  const [documentationInfoLoading, setDocumentationInfoLoading] = useState(false)
  const [selectedAccordion, setSelectedAccordion] = useState(null)
  const dispatch = useDispatch();
  const consultaHabilitadores = useSelector((state) => state.ConsultaHabilitadores);
  const accordionRefs = useRef([]);
  const topRef = useRef(null);
  const tableRef = useRef(null);


  useEffect(() => {
    clearHabilitadoresData();
  }, []);

  useEffect(() => {
    getCataloguesAndDocumentationDispatch();
  }, []);

  // useEffect(() => {
  //   if (consultaHabilitadores?.items)
  //     setDocumentationData(consultaHabilitadores.items)
  // }, [consultaHabilitadores]);

  const idSolicitud = 66;
  const claveCatalogo = "OPERACI";
  const clearHabilitadoresData = () =>  dispatch(msHabilitadoresActions.ConsultaHabilitadoresClearState());

  const getCataloguesAndDocumentationDispatch = async () => {
    setDocumentationInfoLoading(true);
    await getCatalogoTramitesDispatch(claveCatalogo);
    if (catalogoTramites)
      await getDocumentationDataDispatch(idSolicitud);
    setDocumentationInfoLoading(false);
  }

  const getDocumentationDataDispatch = async (idSolicitud) => {
    CONSULTA_HABILITADORES_REQUEST_PAYLOAD.idSolicitud = 66;
    const response = await dispatch(msHabilitadoresActions.ConsultaHabilitadores(CONSULTA_HABILITADORES_REQUEST_PAYLOAD));

    setFetchError(!response);
    setDocumentationData(response?.documentacionSolicitud || []);
  };

  const getCatalogoTramitesDispatch = async (claveCatalogo) => {
    CONSULTA_CATALOGO_REQUEST_PAYLOAD.claveCatalogo = claveCatalogo;
    const response = await dispatch(msCatalogosActions.ConsultaCatalogoIndividual(CONSULTA_CATALOGO_REQUEST_PAYLOAD));
    setFetchError(!response?.catalogo);
    setCatalogoTramites(response?.catalogo?.subCatalogo || []);
  };

  const handleClickActionRow = (row) => {
    const accordionRef = accordionRefs.current[row.accordionIndex];
    setSelectedAccordion(row.accordionIndex)
    setTimeout(() => {
      setSelectedAccordion(null);
    }, 2000);
    accordionRef.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Fragment>

      {fetchError && <RequestErrorAlert timer={500}/>}

      <div ref={(el) => (topRef.current = el)} >
        <Fragment style={{display: 'flex', maxHeight: '2.5rem'}}>
          <GenericHeaderActions
            LabelTitle="Documentación cargada"
            onClickConfirm={() => {
            }}
            ConfirmButtonText="Finalizar"
          />
        </Fragment>
        <GenericContainer loading={documentationInfoLoading} column>
          {documentationData.length > 0 &&
            <DocSummaryTable
              documentationData={documentationData}
              onClickActionRow={handleClickActionRow}
            />}
        </GenericContainer>
      </div>
      <div style={{marginTop: '2.5rem'}}>
        <GenericContainer loading={documentationInfoLoading} column>
          {documentationData.map(({idOperacionTramite, documentacionOperacion}, index) => {
            const tramiteTitle = catalogoTramites.find(({idSubCatalogo}) => idSubCatalogo === idOperacionTramite)?.valor || "Trámite no encontrado en catálogo";
            documentacionOperacion.forEach(documento => documento["accordionIndex"] = index);
            return (
              <AccordionContainer isSelected={selectedAccordion === index} ref={(el) => (accordionRefs.current[index] = el)} key={index}>
                <Accordion title={<Typography>{tramiteTitle}</Typography>} isExpanded={true}>
                  <Grid container spacing={2}>
                    {documentacionOperacion.map(documento =>
                      <Grid item xs='auto' >
                        <DocCard docInfo={documento} key={documento.id} refreshData={getCataloguesAndDocumentationDispatch}/>
                      </Grid>
                    )}
                  </Grid>
                </Accordion>
              </AccordionContainer>
            );
          })}
        </GenericContainer>
      </div>
      <ScrollTopButton parentRef={topRef}/>
    </Fragment>
  );
}