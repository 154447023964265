import { msSimuladoresConstants } from '../../constants/msMultiva/msSimuladoresConstants';

export const SimuladorCredito = (state = {}, action) => {
  switch (action.type) {
    case msSimuladoresConstants.SIMULADOR_REQUEST:
      return { loading: true, items: null, error: null };

    case msSimuladoresConstants.SIMULADOR_SUCCESS:
      return { loading: false, items: action.payload, error: null };

    case msSimuladoresConstants.SIMULADOR_FAILURE:
      return { loading: false, items: null, error: action.error };

    case msSimuladoresConstants.SIMULADOR_CLEAR_STATE:
      return { loading: false, items: null, error: null };

    default:
      return state;
  }
};

export const GuardarSimulacion = (state = {}, action) => {
  switch (action.type) {
    case msSimuladoresConstants.GUARDAR_SIMULACION_REQUEST:
      return { loading: true, status: null, error: null };

    case msSimuladoresConstants.GUARDAR_SIMULACION_SUCCESS:
      return { loading: false, status: action.payload, error: null };

    case msSimuladoresConstants.GUARDAR_SIMULACION_FAILURE:
      return { loading: false, status: null, error: action.error };

    case msSimuladoresConstants.GUARDAR_SIMULACION_CLEAR_STATE:
      return { loading: false, status: null, error: action.error };

    default:
      return state;
  }
};

export const ConsultaXidSolicitud = (state = {}, action) => {
  switch (action.type) {
    case msSimuladoresConstants.CONSULTA_XID_SOLICITUD_REQUEST:
      return { loading: true, items: [], error: null };

    case msSimuladoresConstants.CONSULTA_XID_SOLICITUD_SUCCESS:
      return { loading: false, items: action.payload, error: null };

    case msSimuladoresConstants.CONSULTA_XID_SOLICITUD_FAILURE:
      return { loading: false, items: [], error: action.error };

    default:
      return state;
  }
};

export const ConsultaXidIndividual = (state = {}, action) => {
  switch (action.type) {
    case msSimuladoresConstants.CONSULTA_XID_INDIVIDUAL_REQUEST:
      return { loading: true, items: null, error: null };

    case msSimuladoresConstants.CONSULTA_XID_INDIVIDUAL_SUCCESS:
      return { loading: false, items: action.payload, error: null };

    case msSimuladoresConstants.CONSULTA_XID_INDIVIDUAL_FAILURE:
      return { loading: false, items: null, error: action.error };

    case msSimuladoresConstants.CONSULTA_XID_INDIVIDUAL_CLEAR_STATE:
      return { loading: false, items: null, error: null };

    default:
      return state;
  }
};

export const ImprimirTablaAmortizacion = (state = {}, action) => {
  switch (action.type) {
    case msSimuladoresConstants.IMPRIMIR_TABLA_AMORTIZACION_REQUEST:
      return { loading: true, items: null, error: null };

    case msSimuladoresConstants.IMPRIMIR_TABLA_AMORTIZACION_SUCCESS:
      return { loading: false, items: action.payload, error: null };

    case msSimuladoresConstants.IMPRIMIR_TABLA_AMORTIZACION_FAILURE:
      return { loading: false, items: null, error: action.error };

    default:
      return state;
  }
};
