import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Button,
  CircularProgress,
  Grid,
  TablePagination,
  Typography,
  useTheme,
} from '@mui/material';
import { useStyles } from '../../components/table/TableStyle';
import UploadDocumentsMasive from './UploadBulkDocuments/UploadDocuments';
import { fileMasiveRequests } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { fileMasiveActions } from '../../store/actions';
import UploadDocumentInformation from './RegisterBulkInformation/UploadDocumentInformation';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../utils';
import { CustomLoadingOverlay } from '../../components';

const columnsGroups = [
  { id: 'claveGrpal', label: 'ID identificación', minWidth: 100 },
  { id: 'date', label: 'Nombre de Empleado', minWidth: 100 },
  { id: 'user', label: 'Puesto', minWidth: 100 },
  { id: 'document', label: 'Documento', minWidth: 100 },
  { id: 'action', label: 'Estatus del Documento', minWidth: 100 },
  { id: 'status', label: 'Estatus', minWidth: 100 },
];

export default function TableUsersGroup(props) {
  const theme = useTheme();
  const history = useNavigate();
  const localStyle = useStyles(theme);
  const dispatch = useDispatch();
  const info = useSelector((state) => state.GetByUsuarioRolDetalleGrupo);
  const rolUser = useSelector(
    (state) => state.Authentication.items?.UserLoginData?.SecurityLoginData?.Roles[0]?.Name,
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cargaMasiva, setCargaMasiva] = useState(false);
  const [actualizacionMasiva, setActualizacionMasiva] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusData, setStatusDa] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setLoading(true);
    traerStatus(props.dataGroup);
  }, [props.dataGroup]);

  console.log('data', props.dataGroup);

  const traerStatus = async (data) => {
    try {
      const responseStatus = await fileMasiveRequests.GetByUsuarioGruposStatus({
        BatchUpload_Id: data.Id,
      });
      console.log('responseStatus', responseStatus.Body);
      setStatusDa(responseStatus.Body);

      dispatch(
        fileMasiveActions.GetByUsuarioRolDetalleGrupo({
          BatchUpload_Id: data.Id,
          StatusProceso: responseStatus.Body[0].Status,
        }),
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const statusHRAnalist = statusData?.filter(
    (item) => item.Status === 'Captura de datos por HR Analyst',
  );
  const statusCheckVendor = statusData?.filter(
    (item) => item.Status === 'Carga documental por Background Check Vendor',
  );

  console.log('infoitems', info.items);
  console.log('rolUser', rolUser);

  const statusCheck = (item) => {
    history(`${routes.asignacion}/${item.Code}`);
    console.log('itemitemitem', item);
  };
  if (loading) {
    return (
      <div className={localStyle.spinSection}>
        <CustomLoadingOverlay active={loading} text="Espere un momento por favor..." />
      </div>
    );
  }
  return (
    <div>
      {cargaMasiva === true ? (
        <UploadDocumentsMasive
          setCargaMasiva={setCargaMasiva}
          dataGroup={props.dataGroup}
          users={info?.items}
        />
      ) : actualizacionMasiva === true ? (
        <UploadDocumentInformation
          setActualizacionMasiva={setActualizacionMasiva}
          dataGroup={props.dataGroup}
        />
      ) : (
        <>
          <Grid container>
            <Grid item lg={5} xs={12}>
              <Typography style={{ color: '#153963', fontSize: '22px', fontWeight: 550 }}>
                Grupo: {props.dataGroup.ClaveGrupo}
              </Typography>
            </Grid>
            <Grid
              spacing={2}
              lg={7}
              item
              container
              justifyContent="flex-end"
              alignContent="flex-end"
            >
              <Grid item lg={3} xs={12}>
                <Button
                  style={{
                    height: 38,
                    width: '100%',
                    borderRadius: '25px',
                    fontSize: 16,
                    marginBottom: '1em',
                    fontWeight: 500,
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={() => props.setDataGroup()}
                >
                  Regresar
                </Button>
              </Grid>
              {rolUser === 'Analista RH' && statusHRAnalist?.length > 0 ? (
                <Grid item lg={3} xs={12}>
                  <Button
                    style={{
                      height: 38,
                      width: '100%',
                      borderRadius: '25px',
                      fontSize: 16,
                      marginBottom: '1em',
                      fontWeight: 500,
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => setActualizacionMasiva(true)}
                  >
                    Actualizacion Masiva
                  </Button>
                </Grid>
              ) : rolUser === 'Verificador de antecedentes' && statusCheckVendor?.length > 0 ? (
                <Grid item lg={3} xs={12}>
                  <Button
                    style={{
                      height: 38,
                      width: '100%',
                      borderRadius: '25px',
                      fontSize: 16,
                      marginBottom: '1em',
                      fontWeight: 500,
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => setCargaMasiva(true)}
                  >
                    Carga Masiva
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <TableContainer className={localStyle.container}>
            <Table size="medium" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {columnsGroups.map((column) =>
                    rolUser === 'Analista RH' &&
                    (column.id === 'document' || column.id === 'action') ? null : (
                      <TableCell
                        key={column.id}
                        align="left"
                        className={localStyle.head}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {info?.items
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow className={localStyle.tableRow} onClick={() => statusCheck(row)}>
                      <TableCell align="left">{row.Code}</TableCell>
                      <TableCell align="left">{row.FullName}</TableCell>
                      <TableCell align="left">{row.Puesto}</TableCell>
                      {rolUser === 'Analista RH' ? null : (
                        <>
                          <TableCell align="left">{row.DocumentType}</TableCell>
                          <TableCell align="left">
                            {row.Procesar === true ? (
                              row.DocumentoCargado === true && row.DocumentoApproved === true ? (
                                <span style={{ color: '#0FA958' }}>Aprobado</span>
                              ) : row.DocumentoCargado === true &&
                                row.DocumentoApproved === null ? (
                                <span style={{ color: '#0FA958' }}>Cargado</span>
                              ) : row.DocumentoCargado === true &&
                                row.DocumentoApproved === false ? (
                                <span style={{ color: '#F24E1E' }}>Rechazado</span>
                              ) : row.DocumentoCargado === false ? (
                                <span style={{ color: '#F24E1E' }}>Pendiente de carga</span>
                              ) : null
                            ) : null}
                          </TableCell>
                        </>
                      )}
                      <TableCell align="left">{row.StageDescription}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[0]}
            component="div"
            count={info?.items?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </div>
  );
}
