import { roleConstants } from '../constants/index.js'

export const RoleAll = (state = {}, action) => {
    switch (action.type) {
        case roleConstants.GET_ROLES_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case roleConstants.GET_ROLES_SUCCESS:
            return {
                loading: false,
                items: action.roleData.Body,
                error: undefined
            }
            //break;
        case roleConstants.GET_ROLES_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}