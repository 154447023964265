import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({

    imageNotFound: {
        maxWidth: 300
    },

    container: {
        width: '100%',
        height: '90%'
    },

    //*TABS*
    indicator: {
        backgroundColor: `${theme.palette.selectTab.indicator} !important`,
    },

    selectedTab: {
        fontSize: '17px',
        fontWeight: '500',
        backgroundColor: `${theme.palette.selectTab.info} !important`,
        color: `${theme.palette.selectTab.textColor} !important`,
        border: `solid 1px ${theme.palette.selectTab.border} !important`,
        outline: 'none  !important',
    },

    title: {
        color: `${theme.palette.status.info} !important`,
        fontWeight: 'bold !important',
    },

    titleSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    spinSection: {
        height: '60vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    scroll: {
        overflowX: "hidden",
        overflowY: "auto",
        height: "60vh",
        marginTop: theme.spacing(1)
    },
    scroll1: {
        overflowX: "hidden",
        overflowY: "auto",
        height: "54vh",
        marginTop: theme.spacing(2)
    },


    //*General Info*

    img: {
        height: '120px',
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        borderRadius: "3px",
    },

    table: {
      paddingLeft: '25%',
      width: '100%'
    },

    data: {
        width: "50%",
        height: '70vh',
        // overflow: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    },

    dataTwo: {
        width: "55%",
        flexDirection: 'column',
        display: "flex",
        alignItems: "center",
        height: '70vh',
    },

    dataThree: {
        width: "60%",
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },

    tabWithData: {
        display: 'flex'
    },

    tabWithoutDocs: {
        display: 'flex',
        justifyContent: "center",
    },

    personalInfo: {
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
    },
    docs: {
        color: `${theme.palette.status.info} !important`,
    },

    docStatustable: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        overflowX: "hidden",
        overflowY: "auto",
        height: "60vh",
    },

    mensaje: {
        marginTop: theme.spacing(3),
        width: '60%',
        display: 'flex',
        textAlign: 'center'
    },

    miniContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    },

    //*Actor general info* 

    accepted: {
        color: 'green'
    },
    // *Record File*

    accordion: {
        borderBottom: `solid ${theme.palette.accordion.line} 2px`,
    },

    // *Documentation*

    iconSection: {
        width: '18%',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },

    accordionDetail: {
        display: 'flex !important',
        flexWrap: 'wrap !important'
    },

    // *Biometrics*

    mainMatchInfo: {
        fontSize: 12,
        marginRight: theme.spacing(2)
    },

    biometricTitle: {
        width: '100%',
        display: "flex",
        justifyContent: "space-between"
    },

    biometricScroll: {
        // overflowX: "hidden",
        // overflowY: "auto",
        height: "65vh",
    },

    mainResultSection: {
        display: 'flex',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },

    bioImg: {
        width: '40%',
        height: '60%'
    },

    dactilarImg: {
        width: '30% !important',
        height: '50% !important',
    },

    dactilarSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        height: '100%',
        width: '100%',
        marginTop: theme.spacing(2)
    },

    approved: {
        color: theme.palette.success.main
    },

    rejected: {
        color: theme.palette.error.main
    },

    titleInfoMessage: {
        color: `${theme.palette.primary.main} !important`,
        fontWeight: 'bold !important',
    },

    subTtitleInfoMessage: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },


    imgInfoMessage: {
        width: '30%',
        // marginBottom: theme.spacing(1),
        marginTop: theme.spacing(3),
        borderRadius: "3px",
    },
    acceptFolio: {
        borderRadius: '25px !important',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(3),
        width: '50% !important',
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: '#ffffff !important',
        '&:focus': {
            outline: 'none !important',
        },
        '&:hover': {
            backgroundColor: `${theme.palette.primary.dark} !important`
        }
    },
    textGroup: { 
        color: `${theme.palette.accordion.main} !important`,
    },
    tabRoot: {
        fontSize: '16px',
        fontWeight: '500',
        backgroundColor: `${theme.palette.selectTab.infoDisabled}`,
        color: `${theme.palette.selectTab.textColorDisabled}`,
        border: `solid 1px ${theme.palette.selectTab.infoDisabled}`,
        outline: 'none  !important',
    },
    containerResult: {
        display: 'grid !important',
        gridTemplateColumns: '70% 30%',
        width: '100%',
        ['@media only screen and (max-width: 812px)']: {
            // gridTemplateColumns: '5% 90% 5%',
            gridTemplateColumns: '50% 50%',
        },
        overflow: 'hidden !important',
    },
    leftColumnResult: {
        display: 'flex',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        overflowY: 'none',
        justifyContent: 'center !important',
    },
    rightColumnResult: {
        display: 'flex',
        flexDirection: 'column !important',
        alignItems: 'flex-end !important',
        justifyContent: 'center !important',
        overflowY: 'hidden !important',
    },
    btnViewDoc: {
        border: 'none',
        outline: 'none',
        '&:hover': {
            outline: 'none',
            backgroundColor: 'transparent',
        },
        '&:focus': {
            outline: 'none',
            backgroundColor: 'transparent',
        }
    },
    wid100: {
        width: '100% !important',
    },
    contentButtos: {
        width: '100% !important',
        marginTop: theme.spacing(1),
        textAlign: 'center'
    },
    accordionDetailContent: {
        display: 'flex !important',
        flexWrap: 'wrap !important',
        flexDirection: 'column !important',
        alignItems: 'center !important',
    },
}))