import { makeStyles } from '@mui/styles'
// import { theme } from '../../styles'

export const useStyles = makeStyles((theme) => ({
    inputs: {
        backgroundColor: theme.palette.secondary.light,
        width: '100%',
        borderRadius: '5px',
        margin: theme.spacing(2),
        marginTop: 0,
        padding: 2,
        '&::placeholder': {
            color: theme.palette.secondary.light,
            fontSize: 10
        }

    },
    formContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center !important',
        width: '40%',
        ['@media only screen and (max-width: 600px)']: {
            width: '100% !important',
        },
        //outline: 'solid 1px tomato !important',
    },

    form: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },

    label: {
        alignSelf: 'start',
        fontSize: 12,
        margin: 0,
        color: theme.palette.secondary.contrastText
    },

    instructions: {
        fontSize: 18,
        fontWeight: 700,
        color: theme.palette.userStyle.textInstruccions,
        marginBottom: theme.spacing(3)
    },

    topImage:{
        marginBottom: theme.spacing(6),
        marginTop: 0,
        paddingTop: 0,
        maxHeight: 250
    },

    tempInput: {
        // margin: theme.spacing(2),
        paddingTop: 3,
        padding: 0,
        height: 40,
        border: '1px solid #DADADA',
        // backgroundColor: theme.palette.secondary.light,
        width: '100%',
        borderRadius: '5px'
    },
          
    spinSection:{
        height: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    icon:{
        marginLeft: theme.spacing(1),
    },
    iconMaterial:{
        color: theme.palette.primary.main,
    }
}))
