import React from 'react';
import { DynamicTable } from '../DynamicTable';
import { InputField } from '../../InputField';
import { useFormContext } from 'react-hook-form';
import { InputTypeMappings } from '../../../mappings/InputTypesMappings';

export const DynamicFormBody = ({
  question,
  value,
  rows,
  options,
  handleInputSelect,
  handleFocusSelect,
  handleSelectRow,
  handleAddRow,
  handleDeleteRow,
  handleClickActionRow,
}) => {
  const { formState, control, register } = useFormContext();
  const InputType = InputTypeMappings[question.QuestionType.toUpperCase()];

  switch (question.QuestionType.toUpperCase()) {
    case 'TABLA':
      return (
        <DynamicTable
          key={question.Id}
          id={question.Id}
          headers={question.TableHeaders}
          rows={rows || []}
          hasCheckbox={question.HasCheckbox}
          maximumNumberRowsSelect={question.MaximumNumberRowsSelect}
          onSelectRow={handleSelectRow}
          onAddRow={handleAddRow}
          onDeleteRow={handleDeleteRow}
          onClickActionRow={handleClickActionRow}
        />
      );
    default:
      return (
        <InputField
          inputType={InputType}
          control={control}
          register={register}
          name={`${question.Id}`}
          label={question.Description}
          value={value}
          validations={{ required: question.Required }}
          error={!!formState.errors[`${question.Id}`]}
          helperText={formState.errors[`${question.Id}`]?.message}
          required={question.Required}
          radioValues={options}
          optionsList={options}
          maxDate={InputType === 'date' ? new Date() : null}
          onFocus={handleFocusSelect}
          onSelect={handleInputSelect}
          disabled={question.Disabled}
        />
      );
  }
};
