import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Link,
  Button,
  styled,
  AppBar,
  Container,
  Box,
  Typography,
  useTheme,
  Divider,
  IconButton,
  Hidden,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { UseImage } from './UseImage';
import { useWindowDimensions } from '../utils';
import logoMet from '../assets/login.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ConfirmationSolicitudeModal from './modals/ConfirmationSolicitudeModal';

import Aceptacióndeformatos from './../assets/icons/menuIcons/Aceptacióndeformatos.svg';
import Buscarsolicitud from './../assets/icons/menuIcons/Buscarsolicitud.svg';
import Cargadedocumentos from './../assets/icons/menuIcons/Cargadedocumentos.svg';
import Cargadepólizadeseguros from './../assets/icons/menuIcons/Cargadepólizadeseguros.svg';
import Dashboard from './../assets/icons/menuIcons/Dashboard.svg';
import DictamenVerificación from './../assets/icons/menuIcons/DictamenVerificación.svg';
import Mibandeja from './../assets/icons/menuIcons/Mibandeja.svg';
import Nuevasolicitud from './../assets/icons/menuIcons/Nuevasolicitud.svg';
import Regenerardocumentos from './../assets/icons/menuIcons/Regenerardocumentos.svg';
import Tomarbiométricos from './../assets/icons/menuIcons/Tomarbiométricos.svg';
import VisitaOcular from './../assets/icons/menuIcons/VisitaOcular.svg';

const MenuButton = styled(Link)(({ theme }) => ({
  margin: '2px 0px',
  borderRight: `1px solid ${theme.palette.secondary.main10}`,
  textAlign: 'left',
  fontSize: 14,
  '&:hover': {
    backgroundColor: theme.palette.menuColor.main,
    color: theme.palette.menuColor.text,
  },
  '&<img': {
    width: 25,
    height: 25
  },
  height: '42px',
  // borderRadius: '7px',
  '& .MuiButton-startIcon': {
    marginBottom: 2,
  },
  [theme.breakpoints.down('lg')]: {
    fontSize: 16,
  },
}));

const useStyles = makeStyles(() => ({
  menuIcon: {
    width: 23,
    height: 23,
  }
}));


export const HeaderAppBar = (props) => {
  const [openModal, setOpenModal] = React.useState(false);
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const theme = useTheme();
  const stateAuth = useSelector((state) => state.Authentication);
  const classes = useStyles();

  // let optionsHeader = stateAuth.items?.UserLoginData?.SecurityLoginData?.Applications[0]
  //   ?.Modules[0]?.Functions;
  // let rolUser = stateAuth.items?.UserLoginData?.SecurityLoginData?.Roles[0]
  //   ?.Name

  let optionsHeader = stateAuth.items?.UserLoginData?.SecurityLoginData?.Applications[0]?.Modules;
  let rolUser = stateAuth.items?.UserLoginData?.SecurityLoginData?.Roles[0]?.Name;

  const handleModal = () => {
    setOpenModal((prevState) => !prevState);
  };

  const menuIcons = {
    "Aceptacióndeformatos": Aceptacióndeformatos,
    "Buscarsolicitud": Buscarsolicitud,
    "Cargadedocumentos": Cargadedocumentos,
    "Cargadepólizadeseguros": Cargadepólizadeseguros,
    "Dashboard": Dashboard,
    "DictamenVerificación": DictamenVerificación,
    "Mibandeja": Mibandeja,
    "Nuevasolicitud": Nuevasolicitud,
    "Regenerardocumentos": Regenerardocumentos,
    "Tomarbiométricos": Tomarbiométricos,
    "VisitaOcular": VisitaOcular
  };

  const menuItems = optionsHeader.map((module, index) => {
    const menuItem = {
      id: module.Name.toString(),
      name: module.Name,
      idOrder: index,
      icon: (
        <Box
          style={{ marginLeft: '.8em', marginRight: '.8em', display: 'flex', alignItems: 'center' }}
        >
          <img alt="" className={classes.menuIcon} src={menuIcons[module.Name.toString().replace(/[\/ ]/g, '')]}/>
        </Box>
      ),
      link: '/inbox/placeholder',
      optionsSecundary: module.Functions,
    };
    return menuItem;
  });

  console.log('optionsHeader', optionsHeader);
  console.log('rolUser', rolUser);
  console.log('menuItems', menuItems);

  menuItems[0].link = '/inbox/dashboards';
  menuItems[1].link = '/inbox/homeComponent';
  menuItems[2].link = '/inbox/search';

  const getNavItemActive = (menuLink) => {
    // console.log('locationName', menuLink)
    // console.log('locationName', location.pathname.includes(menuLink))
    return location.pathname.includes(menuLink);
  };

  console.log(rolUser);

  const openWindowModalReport = (name) => {
    console.log('entra aqui');
    let wihe = 'width=' + width + ',height=' + height;
    if (name === 'Contrataciones') {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/METLIFE/1.Contrataciones`,
        '_blank',
        'resizable=1,screenX=1,screenY=1,left=1,top=1,' + wihe,
      );
    } else if (name === 'Contrataciones Mensuales por Etapa') {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/METLIFE/2.Contrataciones%20mensuales%20por%20etapa`,
        '_blank',
        'resizable=1,screenX=1,screenY=1,left=1,top=1,' + wihe,
      );
    } else if (name === 'Contrataciones por Etapa') {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/METLIFE/3.Contrataciones%20por%20etapa`,
        '_blank',
        'resizable=1,screenX=1,screenY=1,left=1,top=1,' + wihe,
      );
    } else if (name === 'Contrataciones por Analista RH') {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/METLIFE/4.Contrataciones%20por%20analista%20RH`,
        '_blank',
        'resizable=1,screenX=1,screenY=1,left=1,top=1,' + wihe,
      );
    } else if (name === 'Préstamos Solicitados') {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/METLIFE/5.Prestamos%20solicitados`,
        '_blank',
        'resizable=1,screenX=1,screenY=1,left=1,top=1,' + wihe,
      );
    } else if (name === 'Préstamos Mensuales por Etapa') {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/METLIFE/6.Prestamos%20mensuales%20por%20etapa`,
        '_blank',
        'resizable=1,screenX=1,screenY=1,left=1,top=1,' + wihe,
      );
    } else if (name === 'Préstamos por Etapa') {
      window.open(
        `https://reportes.docsolutions.com:444/Reports/report/METLIFE/7.Prestamo%20por%20etapa`,
        '_blank',
        'resizable=1,screenX=1,screenY=1,left=1,top=1,' + wihe,
      );
    }
  };

  const itemsOrdenados = menuItems?.sort((a, b) => a.idOrder - b.idOrder);
  const handleOpenModal = (e) => {
    setOpenModal(e);
  };

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: '#FFFFFF',
        color: 'black',
        height: 'auto',
        maxWidth: 1400,
        border: '1px solid white',
        mx: 'auto',
      }}
    >
      <ConfirmationSolicitudeModal open={openModal} onOpenModal={handleOpenModal} />
      <Container maxWidth="xl" style={{ margin: 0, padding: 0, maxWidth: '100%' }}>
        <Box
          disableGutters
          style={{
            p: 0,
            m: 0,
            mr: { xs: 0, sm: 0 },
            ml: { xs: 0, sm: 0, md: 0 },
            [theme.breakpoints.up('lg')]: {
              flexDirection: 'column !important',
              height: '100vh',
            },
            [theme.breakpoints.down('lg')]: {
              flexDirection: 'column !important',
              height: '100vh',
            },
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column !important',
              height: '5vh',
            },
            [theme.breakpoints.down('sm')]: {
              height: '5vh',
            },
          }}
        >
          {/* mobile init */}
          <Hidden only={['lg', 'md', 'xl']}>
            <Box
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Box>
                <IconButton onClick={props.openDrawer} size="large" color="inherit">
                  <MenuIcon />
                </IconButton>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: 1,
                  [theme.breakpoints.down('md')]: {
                    justifyContent: 'end',
                  },
                  [theme.breakpoints.down('sm')]: {
                    justifyContent: 'end',
                    mr: 1,
                  },
                }}
              >
                <Box display={'flex'} justifyContent="center" alignItems={'center'}>
                  <UseImage src={logoMet} type="img" style={{ width: '50%' }} />
                </Box>
              </Box>
            </Box>
          </Hidden>
          {/* mobile finis */}

          {/* web init */}
          <Hidden smDown>
            <Box style={{ justifyContent: 'center', width: '90%', margin: '1em 1em' }}>
              <UseImage src={logoMet} type="img" style={{ width: '50%' }} />
            </Box>
            <Box style={{ margin: '1em 0 0 0', width: '80%' }}>
              <Divider />
            </Box>
            <Box
              style={{
                display: { xs: 'none', md: 'flex' },
                margin: '0.8em 0.8em 0.5em 0.8em', //  marginTop: '.8em', marginBottom: ".5em",
                width: '90%',
              }}
            >
              <Typography variant="h6" style={{ color: '#3F3F3E', fontWeight: 550 }}>
                Menú
              </Typography>
            </Box>
            <Box
              style={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                width: '100%',
                [theme.breakpoints.up('lg')]: {
                  flexDirection: 'column !important',
                },
                [theme.breakpoints.down('lg')]: {
                  flexDirection: 'column !important',
                },
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column !important',
                },
              }}
            >
              <React.Suspense fallback={<div>loading</div>}>
              {itemsOrdenados?.map((item, index) => {
                const menuItem = optionsHeader?.find((mi) => mi.Name === item.id);
                if (menuItem) {
                  return (
                    <div key={index}>
                      {rolUser === 'Empleado' && item.id === 'Generar Solicitudes' ? null : item
                          .optionsSecundary.length === 0 ? (
                        <MenuButton
                          key={index}
                          disabled={item.name}
                          component={item.id === 'Nueva solicitud' ? null : RouterLink}
                          to={item.id === 'Nueva solicitud' ? null : item.link}
                          onClick={item.id === 'Nueva solicitud' ? handleModal : null}
                          sx={{
                            display: 'flex',
                            justifyContent: 'left',
                            alignContent: 'center',
                            alignItems: 'center',
                            textAlign: 'left',
                            // marginLeft: 2,
                            marginTop: '1em',
                            textDecoration: 'none !important',
                            borderBottom: 'none',
                            fontWeight: getNavItemActive(item.link) ? 650 : 400,
                            color: getNavItemActive(item.link) ? '#FFF' : '#000',
                            backgroundColor: getNavItemActive(item.link)
                              ? theme.palette.menuColor.main
                              : 'transparent',
                            '&:hover': {
                              color: '#FFF',
                            },
                            '&:active': {
                              color: '#FFF',
                            },
                            '&:focus': {
                              color: '#FFF',
                            },
                          }}
                        >
                          {item.icon}
                          <span style={{ minWidth: '60px' }}>
                            <Typography> {item.name}</Typography>
                          </span>
                        </MenuButton>
                      ) : (
                        <Accordion key={index}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignContent: 'center',
                              alignItems: 'center',
                              textAlign: 'left',
                              marginLeft: -2,
                              marginTop: '1em',
                              textDecoration: 'none !important',
                            }}
                          >
                            {item.icon}
                            <span style={{ minWidth: '60px' }}>
                              <Typography> {item.name}</Typography>
                            </span>
                          </AccordionSummary>
                          <AccordionDetails>
                            {item.optionsSecundary.map((subItem, subIndex) => (
                              <div key={subIndex}>
                                <MenuButton
                                  key={subIndex}
                                  disabled={subItem.Name}
                                  component={RouterLink}
                                  to={item.link}
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'left',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'left',
                                    marginLeft: 2,
                                    marginTop: '1em',
                                    textDecoration: 'none !important',
                                    borderBottom: 'none',
                                    fontWeight: getNavItemActive(item.link) ? 650 : 400,
                                    color: getNavItemActive(item.link) ? '#FFF' : '#000',
                                    backgroundColor: getNavItemActive(item.link)
                                      ? theme.palette.menuColor.main
                                      : 'transparent',
                                    '&:hover': {
                                      color: '#FFF',
                                    },
                                    '&:active': {
                                      color: '#FFF',
                                    },
                                    '&:focus': {
                                      color: '#FFF',
                                    },
                                  }}
                                >
                                  <span style={{ minWidth: '60px' }}>
                                    <Typography>{`${subItem.Name}`}</Typography>
                                  </span>
                                </MenuButton>
                              </div>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      )}
                      {/*{item?.secundary?.map((subMenu) => (*/}
                      {/*  subMenu.rol && subMenu.rol === rolUser ? (*/}
                      {/*    <Box style={{ borderBottomColor: theme.palette.menuColor.main, marginLeft: '1.5em' }} borderBottom={getNavItemActive(subMenu.link) ? 2 : 0}>*/}
                      {/*      <MenuButton*/}
                      {/*        key={index}*/}
                      {/*        disabled={subMenu.name}*/}
                      {/*        component={RouterLink}*/}
                      {/*        to={subMenu.link}*/}
                      {/*        onClick={() => openWindowModalReport(subMenu.name)}*/}
                      {/*        sx={{*/}
                      {/*          display: "flex",*/}
                      {/*          justifyContent: 'left',*/}
                      {/*          alignContent: "center",*/}
                      {/*          alignItems: 'center',*/}
                      {/*          textAlign: "left",*/}
                      {/*          // marginLeft: '1em',*/}
                      {/*          fontWeight: 400,*/}
                      {/*          color: "#000",*/}
                      {/*          backgroundColor: 'transparent',*/}
                      {/*          textDecoration: 'none !important',*/}
                      {/*          borderBottom: "none",*/}
                      {/*          '&:hover': {*/}
                      {/*            color: '#FFF',*/}
                      {/*          },*/}
                      {/*          '&:active': {*/}
                      {/*            color: '#FFF',*/}
                      {/*          },*/}
                      {/*          '&:focus': {*/}
                      {/*            color: '#FFF',*/}
                      {/*          },*/}
                      {/*        }}*/}
                      {/*      >*/}
                      {/*        <span style={{ minWidth: '60px', textDecoration: 'none !important' }}>*/}
                      {/*          {subMenu.name}*/}
                      {/*        </span>*/}
                      {/*      </MenuButton>*/}
                      {/*    </Box>*/}
                      {/*  ) : null*/}
                      {/*))}*/}
                      {/*{item?.optionsSecundary?.map((subMenu) => (*/}
                      {/*  <Box style={{ borderBottomColor: theme.palette.menuColor.main, marginLeft: '1.5em' }} borderBottom={getNavItemActive(subMenu.link) ? 2 : 0}>*/}
                      {/*    <MenuButton*/}
                      {/*      key={index}*/}
                      {/*      // disabled={subMenu.name}*/}
                      {/*      component={RouterLink}*/}
                      {/*      to={subMenu.link}*/}
                      {/*      style={{*/}
                      {/*        display: "flex",*/}
                      {/*        justifyContent: 'left',*/}
                      {/*        alignContent: "center",*/}
                      {/*        alignItems: 'center',*/}
                      {/*        textAlign: "left",*/}
                      {/*        // marginLeft: '1em',*/}
                      {/*        fontWeight: 400,*/}
                      {/*        color: "#000",*/}
                      {/*        backgroundColor: 'transparent',*/}
                      {/*        textDecoration: 'none !important',*/}
                      {/*        borderBottom: "none",*/}
                      {/*        '&:hover': {*/}
                      {/*          color: '#FFF',*/}
                      {/*        },*/}
                      {/*        '&:active': {*/}
                      {/*          color: '#FFF',*/}
                      {/*        },*/}
                      {/*        '&:focus': {*/}
                      {/*          color: '#FFF',*/}
                      {/*        },*/}
                      {/*      }}*/}
                      {/*    >*/}
                      {/*      <span style={{ minWidth: '60px' }}>*/}
                      {/*        {subMenu.Name}*/}
                      {/*      </span>*/}
                      {/*    </MenuButton>*/}
                      {/*  </Box>*/}
                      {/*))}*/}
                    </div>
                  );
                }
              })}
              </React.Suspense>
            </Box>
          </Hidden>
        </Box>
      </Container>
    </AppBar>
  );
};
