import { TextField as TextF, InputAdornment, Button, Grid, Typography, styled } from "@mui/material";
import { Icon } from '@iconify/react';
import { makeStyles } from '@mui/styles';

const TextField = styled(TextF)(({ theme }) => ({
    height: 40,
    paddingTop: 3,
    padding: 0,
    border: '1px solid #DADADA',
    width: '100% !important',
    borderRadius: '5px !important',
    justifyContent: "center"
}));

const useStyles = makeStyles(() => ({
  mainContainer: {
    margin: "0px !important",
    padding: "10px 5px 20px",
    alignItems: "center",
    height: "fit-content"
  },
  gridContainer: {
    paddingTop: "0px !important",
    display: "flex",
    justifyContent: "center"
  },
  searchButton: {
    margin: "0px !important",
    padding: "0px !important",
  },
  cleanButton: {
    margin: "0px !important",
    padding: "0px !important",
  }
}));

export const GenericHeaderActions = (props) => {
  const {
    onChangeTextField,
    placeholderTextField,
    LabelTitle,
    toolTip,
    onClickConfirm,
    ConfirmButtonStartIcon,
    ConfirmButtonDisabled,
    ConfirmButtonText,
    onClickCancel,
    CancelButtonText
  } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.mainContainer}>
        <Grid item lg={8} md={6} sm={5} xs={12} className={classes.gridContainer}>
          {onChangeTextField && (
            <TextField
              variant='standard'
              margin='none'
              size="normal"
              placeholder={placeholderTextField}
              onChange={onChangeTextField}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position='start' sx={(theme) => ({ marginLeft: theme.spacing(1) })} >
                    <Icon fontSize={18} icon="akar-icons:search" color={'#9E9E9E'} />
                  </InputAdornment>
                )
              }}
            />
          )}
          {LabelTitle && (
            <Typography variant="h6" >{LabelTitle} {toolTip}</Typography>
          )}
        </Grid>
        {onClickConfirm && (
          <Grid item lg={2} md={3} xs={12} className={classes.gridContainer} >
              <Button
                variant="contained"
                sx={{ height: 45, width: "100%", m: 1 }}
                className={classes.searchButton}
                onClick={onClickConfirm}
                startIcon={ConfirmButtonStartIcon}
                disabled={ConfirmButtonDisabled}
                type="submit"
              >
                {ConfirmButtonText || "Buscar"}
              </Button>
          </Grid>
        )}
        {onClickCancel && (
          <Grid item lg={2} md={3} xs={12} className={classes.gridContainer} >
            <Button
              variant="outlined"
              sx={{ height: 45, width: "100%", m: 1 }}
              className={classes.cleanButton}
              onClick={onClickCancel}
            >
              {CancelButtonText || "Limpiar búsqueda"}
            </Button>
          </Grid>
        )}
    </Grid>
  )
};        
