import axios from 'axios';
import { AppUrls, } from './Globals';
import { Config } from './Config';

export const flujoMasivoRequests = {}

flujoMasivoRequests.UploadBatchFile2 = (data) => {
  return axios.post(
    AppUrls.UploadBatchFile2,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

flujoMasivoRequests.InitializeBatchFile = (data) => {
  return axios.post(
    AppUrls.InitializeBatchFile,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

let handleResponse = (response) => {
  console.log('responseresponse', response)
  let respObject = response.data;//BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = { message: respObject.Messages + ' ' + (respObject.TansactionId ? respObject.TansactionId : '') + ' ' + (respObject.statusText ? respObject.statusText : '') };
    let error = (respObject.Message || responseObject);
    console.log(responseObject)
    console.log(error)
    return { error };
  }
  return respObject;
}