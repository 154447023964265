import { msClienteConstants } from '../../constants/msMultiva/msClienteConstants';

export const FilterSearchProcessAccount = (state = {}, action) => {
  switch (action.type) {
    case msClienteConstants.FILTER_SEARCH_PROCESS_ACCOUNT_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msClienteConstants.FILTER_SEARCH_PROCESS_ACCOUNT_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msClienteConstants.FILTER_SEARCH_PROCESS_ACCOUNT_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    case msClienteConstants.FILTER_SEARCH_PROCESS_ACCOUNT_CLEAR_STATE:
      return { loading: false, items: undefined, error: undefined };

    default:
      return state;
  }
};

export const FilterSearchProcessCustomerRFC = (state = {}, action) => {
  switch (action.type) {
    case msClienteConstants.FILTER_SEARCH_PROCESS_CUSTOMER_RFC_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msClienteConstants.FILTER_SEARCH_PROCESS_CUSTOMER_RFC_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msClienteConstants.FILTER_SEARCH_PROCESS_CUSTOMER_RFC_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    case msClienteConstants.FILTER_SEARCH_PROCESS_CUSTOMER_RFC_CLEAR_STATE:
      return { loading: false, items: undefined, error: undefined };

    default:
      return state;
  }
};

export const FilterSearchProcessCustomerCuenta = (state = {}, action) => {
  switch (action.type) {
    case msClienteConstants.FILTER_SEARCH_PROCESS_CUSTOMER_CUENTA_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msClienteConstants.FILTER_SEARCH_PROCESS_CUSTOMER_CUENTA_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msClienteConstants.FILTER_SEARCH_PROCESS_CUSTOMER_CUENTA_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    default:
      return state;
  }
};

export const FilterSearchProcessLending = (state = {}, action) => {
  switch (action.type) {
    case msClienteConstants.FILTER_SEARCH_PROCESS_LENDING_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msClienteConstants.FILTER_SEARCH_PROCESS_LENDING_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msClienteConstants.FILTER_SEARCH_PROCESS_LENDING_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    default:
      return state;
  }
};
