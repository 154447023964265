import { axiosMultivaClient } from './axiosClientConfiguration';
import { Config } from '../Config';
import { BodySecurityResponseMultiva } from '../../utils/encripted/SecurityMultiva';

function handleResponse(response) {
  const respObject = response.data;
  if (respObject.EncryptedBody) {
    return BodySecurityResponseMultiva(respObject); //Decrypted response
  }
  if (respObject.status === -1) {
    const errorMessage = { message: respObject.message };
    return Promise.reject(errorMessage);
  }
  return respObject;
}

function getRequest(url) {
  return axiosMultivaClient.get(url, Config.ConfigAuth()).then(handleResponse);
}

function postRequest(URL, DATA, SIGNAL, AXIOS_HEADERS = Config.ConfigAuth()) {
  return axiosMultivaClient
    .post(URL, Config.SetParameters(DATA, '', '', '').Body, {
      ...AXIOS_HEADERS,
      signal: SIGNAL,
    })
    .then(handleResponse);
}

function putRequest(URL, DATA, SIGNAL, AXIOS_HEADERS = Config.ConfigAuthMultiva()) {
  return axiosMultivaClient
    .put(URL, Config.SetParameters(DATA, '', '', '').Body, {
      ...AXIOS_HEADERS,
      signal: SIGNAL,
    })
    .then(handleResponse);
}

export { getRequest, postRequest, putRequest };
