import { externalConstants } from '../constants/index.js'

export const ListBranchOffice = (state = {}, action) => {
    switch (action.type) {
        case externalConstants.GET_BRANCH_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case externalConstants.GET_BRANCH_SUCCESS:
            return {
                loading: false,
                items: action.folio.Body,
                error: undefined
            }
            //break;
        case externalConstants.GET_BRANCH_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}