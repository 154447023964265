import {useDispatch} from "react-redux";
import {Box as FormBox} from "../DynamicForm/dynamicForm.styled";
import React, {Fragment, useEffect, useState} from "react";
import {DynamicFormWrapper} from "../DynamicForm/DynamicFormWrapper";
import {DynamicTable} from "../DynamicTable";
import {GenericContainer} from "../../GenericContainer";
import {msSolicitudActions} from "store/actions/msMultiva/msSolicitudActions";
import {XM3_REQUEST_PAYLOAD, CONSULTA_CATALOGO_REQUEST_PAYLOAD} from "./XM3ResultsRequestPayload";
import {XM3QuestionGroup} from "./XM3QuestionGroup";
import CancelIcon from "@mui/icons-material/Cancel";
import {Accordion} from "../../Accordion.jsx";
import {msCatalogosActions} from "../../../store/actions/msMultiva/msCatalogosActions";
import {Typography} from "@mui/material";

export const XM3Results = () => {

  const [XM3Data, setXM3Data] = useState([]);
  const [catalogoEstadosXM3, setCatalogoEstadosXM3] = useState([]);
  const [catalogoFiguras, setCatalogoFiguras] = useState([]);
  const [XM3DataLoading, setXM3DataLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    clearSolicitudData();
  }, []);

  useEffect(() => {
    getAllXM3Data();
  }, []);

  const idSolicitud = 9;
  const claveCatalogoEstadosXM3 = "ESTADOXM3";
  const claveCatalogoFiguras = "FIGURAS";

  const clearSolicitudData = () => {
    dispatch(msSolicitudActions.SolicitudOperacionClearState());
  }

  const getAllXM3Data = async () => {
    try {
      setXM3DataLoading(true);
      await Promise.all([
        getXM3DataDispatch(),
        getCatalogoEstadosXM3Dispatch(),
        getCatalogoFigurasDispatch,
      ]);
      setXM3DataLoading(false);
    } catch (error) {
      setFetchError(true);
    }
  }

  const getXM3DataDispatch = async () => {
    XM3_REQUEST_PAYLOAD.solicitud.xm3[0].idSolicitud = 66; //Para pruebas 1011
    const response = await dispatch(msSolicitudActions.SolicitudOperacion(XM3_REQUEST_PAYLOAD));
    setXM3Data(response?.solicitud?.xm3 || []);
    setFetchError(!response?.solicitud?.xm3);
  };

  const getCatalogoEstadosXM3Dispatch = async () => {
    CONSULTA_CATALOGO_REQUEST_PAYLOAD.claveCatalogo = claveCatalogoEstadosXM3;
    const response = await dispatch(msCatalogosActions.ConsultaCatalogoIndividual(CONSULTA_CATALOGO_REQUEST_PAYLOAD));
    setFetchError(!response?.catalogo);
    setCatalogoEstadosXM3(response?.catalogo?.subCatalogo || []);
  };

  const getCatalogoFigurasDispatch = async () => {
    CONSULTA_CATALOGO_REQUEST_PAYLOAD.claveCatalogo = claveCatalogoFiguras;
    const response = await dispatch(msCatalogosActions.ConsultaCatalogoIndividual(CONSULTA_CATALOGO_REQUEST_PAYLOAD));
    setFetchError(!response?.catalogo);
    setCatalogoFiguras(response?.catalogo?.subCatalogo || []);
  };

  XM3QuestionGroup[0].groupTitle = <Typography>{XM3QuestionGroup[0].title}</Typography>
  XM3Data.forEach(row => {
    row["figura"] = catalogoFiguras.find(({idSubCatalogo}) => idSubCatalogo === row.idFigura)?.valor || "Sin resultados";
    row["estatus"] = catalogoEstadosXM3.find(({idSubCatalogo}) => idSubCatalogo === row.idEstatus)?.valor || "Sin resultados";
  });

  return (
    <Fragment>
        <FormBox>
          <GenericContainer loading={XM3DataLoading} column>
            {!fetchError ?
              <DynamicFormWrapper
                questionsGroups={XM3QuestionGroup}
                render={(question) => (
                  <DynamicTable
                    key={question.Id}
                    id={question.Id}
                    headers={question.TableHeaders}
                    rows={XM3Data}
                  />
                )}
              /> :
              <Accordion title={XM3QuestionGroup[0].groupTitle} isExpanded={true}>
                <CancelIcon style={{ color: 'red' }} />
              </Accordion>
            }
          </GenericContainer>
        </FormBox>
    </Fragment>
  );
}