export class MultivaCataloguesAdapter {
  constructor(data) {
    this.data = data;
  }

  adapt() {
    return this.adaptCatalogue(this.data);
  }

  adaptCatalogue(objeto) {
    let adaptedCatalogue = {};
    for (let propiedad in objeto) {
      if (propiedad === 'valor') {
        adaptedCatalogue['Option'] = objeto[propiedad];
      } else if (propiedad === 'idSubCatalogo') {
        adaptedCatalogue['Value'] = objeto[propiedad];
      } else {
        adaptedCatalogue[propiedad] = objeto[propiedad];
      }
      if (Array.isArray(objeto[propiedad])) {
        adaptedCatalogue[propiedad] = objeto[propiedad].map((item) => this.adaptCatalogue(item));
      }
    }
    return adaptedCatalogue;
  }
}