import * as crypto from "crypto-browserify/index.js";
import { prPrivateKey, prPublicKey, prPublicKeyDSC, algorithm } from '../PathPublish.js'

//Configuration
const defaultEncoding = 'base64'
const key = crypto.randomBytes(32)
const initVector = crypto.randomBytes(16)

export function prepareRequest(data, encryptRequest) {

  if (encryptRequest) {
    if (typeof (data) === 'object')
      data = JSON.stringify(data)

    try {
      //Creating nested SecurityData object
      let encdata = {
        KeyB64: key.toString(defaultEncoding),
        IVB64: initVector.toString(defaultEncoding)
      }

      let bodyEncript = CifrarInfo(data);
      let encKeys = generateSecurityData(encdata)
      let pkeyEncript = CifrarInfo(prPublicKeyDSC);

      //Creating encrypted object request
      let objRequest = {
        EncryptedBody: bodyEncript,
        SecurityData: encKeys,
        PKey: pkeyEncript
      }
      // console.log("prepareRequest encripted: ", objRequest)
      return (objRequest)
    }
    catch (error) {
      console.log("Error: (AES encrypt error) - " + error)
      return null
    }
  }
  else {
    let objRequest = { Body: data }
    return (objRequest)
  }
}

export function processRequest(data) {
    if (data.EncryptedBody) {
        try {
            let buffer = Buffer.from(data.SecurityData, defaultEncoding)
            let decrypted = crypto.privateDecrypt({ key: prPrivateKey, padding: crypto.constants.RSA }, buffer).toString()

            let response = {
                Body: JSON.parse(decryptBody(decrypted, data.EncryptedBody)),
                IsOK: data.IsOK,
                Messages: data.Messages
            }
            return response;
        }
        catch (error) {
            console.error("Error: (Decrypt RSA error) - " + error)
        }
    }
    else if (data.Body) {
        return data
    }
    else if (data.Body === undefined || data.Body === null) {
        return data
    }
    else
        return data
}

function CifrarInfo(data) {
    let cipher = crypto.createCipheriv(algorithm, key, initVector)
    let encoded = cipher.update(data, 'utf8', defaultEncoding)
    encoded += cipher.final(defaultEncoding)

    return encoded
}

function generateSecurityData(obj) {
    try {

        let buffer = new Buffer(JSON.stringify(obj));
        let encrypted = crypto.publicEncrypt(prPublicKey, buffer).toString(defaultEncoding)

        return encrypted
    }
    catch (error) {
        console.log("Error: (Encrypt RSA error) - " + error)
        return null
    }
}

function decryptBody(keys, data) {

    let dataKeys = JSON.parse(keys);
    let keyB64 = dataKeys.KeyB64
    let ivB64 = dataKeys.IVB64

    try {

        let iv = Buffer.from(ivB64, defaultEncoding)
        let encryptedText = Buffer.from(data, defaultEncoding)
        let decipher = crypto.createDecipheriv(algorithm, Buffer.from(keyB64, defaultEncoding), iv)
        let decrypted = decipher.update(encryptedText)

        decrypted = Buffer.concat([decrypted, decipher.final()]).toString('utf-8')

        return decrypted
    }
    catch (error) {
        console.log("Error: (Decrypt AES error) - " + error)
        return null
    }
}
