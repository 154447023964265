import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fileActions, validationActions } from '../../store/actions'
import protect from '../../utils/protect'

import {
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
  Typography,
  Grid, useTheme
} from '@mui/material'
import Empty from '../../assets/NoResultMeet.png'
import {
  MainTable,
  UseImage,
  CustomLoadingOverlay, GenericHeaderActions
} from '../../components'

import { useStyles } from './HomeStyles'
import { mainStyles } from '../../theme/mainStyles'
import { Icon } from '@iconify/react'
// import TableUsersSigners from '../BulkLoad/TableUsersSigners'

function HomeComponent(props) {
  const theme = useTheme()
  const localStyles = useStyles(theme)
  const noInfo = mainStyles.noDataSections()
  const [searching, setSearching] = useState("")
  const [search, setSearch] = useState("")
  const [infoItems, setInfoItems] = useState([])

  const info = useSelector(state => state.SearchByUser)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fileActions.GetSearchByUser({ "SearchText": search }))
    dispatch(validationActions.PostFileValidation({}))
  }, [])

  useEffect(() => {
    dispatch(fileActions.GetSearchByUser({ "SearchText": search }))
  }, [search])

  useEffect(() => {
    if (info.items !== undefined) {
      console.log("Items-----------: ", info.items)
      setInfoItems(info.items)
    }
  }, [info])

  return (
    <div>
      <form className={localStyles.form} >
          <GenericHeaderActions onChangeTextField={(event) => {
                                setSearching(event.target.value)
                            }}
                                placeholderTextField="Folio, puesto o nombre"
                                onClickConfirm={(event) => {
                                    event.preventDefault()
                                    setSearch(searching)
                                }}
                                ConfirmButtonText="Buscar"
                                CancelButtonText="Limpiar búsqueda"
                                onClickCancel={() => { setSearch("") }} />
      </form>
      {info.error && <p>{"Error de red favor de actualizar la pagina"}</p>}
      {info.loading ?
        <CustomLoadingOverlay
          active={info.loading} text="Espere un momento por favor..."
        /> : infoItems.length > 0 ? <MainTable data={infoItems} /> :
          <div className={noInfo.noResult}>
            <UseImage src={Empty} type='img' style={{ maxWidth: 300 }} />
            <Typography variant="h6" className={noInfo.h6}>No se encontraron solicitudes</Typography>
          </div>}
    </div>)
}

export default protect(HomeComponent)