import { msSolicitudConstants } from '../../constants/msMultiva/msSolicitudConstants';

export const SolicitudOperacion = (state = {}, action) => {
  switch (action.type) {
    case msSolicitudConstants.OPERACION_ALTA_REQUEST:
      // return { loading: true, items: null, error: null };
      return {
        ...state,
        loading: true,
        error: null
      };

    case msSolicitudConstants.OPERACION_ALTA_SUCCESS:
      // return { loading: false, items: action.payload, error: null };
      return {
        ...state,
        loading: false,
        items: action.payload,
      };

    case msSolicitudConstants.OPERACION_ALTA_FAILURE:
      // return { loading: false, items: null, error: action.error };
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case msSolicitudConstants.OPERACION_ALTA_CLEAR_STATE:
      return { ...state, loading: false, error: null };

    default:
      return state;
  }
};

export const SolicitudAsociacion = (state = {}, action) => {
  switch (action.type) {
    case msSolicitudConstants.OPERACION_ASOCIACION_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msSolicitudConstants.OPERACION_ASOCIACION_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msSolicitudConstants.OPERACION_ASOCIACION_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    case msSolicitudConstants.OPERACION_ASOCIACION_CLEAR_STATE:
      return { loading: false, items: undefined, error: undefined };

    default:
      return state;
  }
};

export const SolicitudConsultaC12 = (state = {}, action) => {
  switch (action.type) {
    case msSolicitudConstants.OPERACION_SOLICITUD_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msSolicitudConstants.OPERACION_SOLICITUD_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msSolicitudConstants.OPERACION_SOLICITUD_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    case msSolicitudConstants.OPERACION_SOLICITUD_CLEAR_STATE:
      return { loading: false, items: undefined, error: undefined };

    default:
      return state;
  }
};
