import { notificationConstants } from '../constants'
import { notificationRequests } from '../../services'

export const notificationActions = {}

notificationActions.GetNewPin = (data) => {
  return dispatch => {
    dispatch(request())
    notificationRequests.GetNewPin(data)
      .then(
        newPin => dispatch(success(newPin)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: notificationConstants.NEW_PIN_REQUEST }
  }

  function success(newPin) {
    return { type: notificationConstants.NEW_PIN_SUCCESS, newPin }
  }

  function failure(error) {
    return { type: notificationConstants.NEW_PIN_FAILURE, error }
  }

}

notificationActions.GetNotificationsUser = (data) => {
  return dispatch => {
    dispatch(request())
    notificationRequests.GetNotificationsUser(data)
      .then(
        notifications => dispatch(success(notifications)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: notificationConstants.GET_NOTIFICATIONS_USER_REQUEST }
  }

  function success(notifications) {
    return { type: notificationConstants.GET_NOTIFICATIONS_USER_SUCCESS, notifications }
  }

  function failure(error) {
    return { type: notificationConstants.GET_NOTIFICATIONS_USER_FAILURE, error }
  }

}