import { Box as MUIBox, Button as MUIButton, styled } from '@mui/material';

export const Box = styled(MUIBox)(({theme}) => ({
  display: 'flex !important',
  flexDirection: 'column !important',
  alignItems: 'center !important',
  position: 'relative',
  width: '100% !important',
  padding: 3,
  overflowY: 'auto',
  '@media only screen and (max-width: 812px)': {
    height: '60vh',
  },
}));

export const Button = styled(MUIButton)(({ theme }) => ({
  width: '100%',
  height: 45,
  px: 10,
  marginTop: '1rem',
  minWidth: 220,
  maxWidth: 350,
}));
