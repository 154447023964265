import { msHabilitadoresConstants } from '../../constants/msMultiva/msHabilitadoresConstants';

export const ConsultaHabilitadores = (
  state = { loading: false, items: [], error: null },
  action,
) => {
  switch (action.type) {
    case msHabilitadoresConstants.CONSULTA_HABILITADORES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case msHabilitadoresConstants.CONSULTA_HABILITADORES_SUCCESS:
      return { loading: false, items: action.payload ?? [], error: null };

    case msHabilitadoresConstants.CONSULTA_HABILITADORES_FAILURE:
      return { loading: false, items: [], error: action.error };

    case msHabilitadoresConstants.CONSULTA_HABILITADORES_CLEAR_STATE:
      return { loading: false, items: undefined, error: undefined };

    default:
      return state;
  }
};

export const ActualizaHabilitadores = (state = {}, action) => {
  switch (action.type) {
    case msHabilitadoresConstants.ACTUALIZA_HABILITADORES_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msHabilitadoresConstants.ACTUALIZA_HABILITADORES_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msHabilitadoresConstants.ACTUALIZA_HABILITADORES_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    default:
      return state;
  }
};

export const AltaHabilitadores = (state = {}, action) => {
  switch (action.type) {
    case msHabilitadoresConstants.ALTA_HABILITADORES_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msHabilitadoresConstants.ALTA_HABILITADORES_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msHabilitadoresConstants.ALTA_HABILITADORES_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    default:
      return state;
  }
};
