import React, { useState, useEffect } from 'react'
import protect from '../../utils/protect'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fileActions } from '../../store/actions'
import {
  Typography,
  Tooltip,
  IconButton,
  useTheme,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import GavelIcon from '@mui/icons-material/Gavel';

import GroupIcon from '../../assets/GroupIcon';
import Apoderado from '../../assets/Apoderado';
import {FullWidthTabs, UseImage} from '../../components'
import { useStyles } from './DetailStyles'
import loadDoc from "../../assets/loadDoc.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ComboCheckbox from "../../components/ComboCheckbox";
import { useDetails } from 'hooks/useDetails';



function DetailsComponent() {
  const theme = useTheme()
  // const mainPage = mainStyles.mainContainer()
  const localStyles = useStyles(theme)
  const { id } = useParams()

  const dispatch = useDispatch()

  const finbeColor = theme.palette.iconPrincButton.main
  const white = '#ffffff';

  const {
    file,
    details,
    actors,
    setFileInfo,
    setActorsInfo,
    setDetailsInfo,
  } = useDetails(id);
  const [selectedActor, setSelectedActor] = useState({})

  const [groupIconColor, setGroupIconColor] = useState(white)
  const [apoderadoColor, setApoderadoColor] = useState(finbeColor)
  const [hover, setHover] = useState(false)

  const dataCheckbox = {
    "Description": "Poderes y Firmas",
    "Name": "PoderesFirmas",
    "Options": [
      {
        "Option":"Poder 1",
        "Value": "P1"
      },
      {
        "Option":"Poder 2",
        "Value": "P2"
      },
      {
        "Option":"Poder 3",
        "Value": "P3"
      },
      {
        "Option":"Poder 4",
        "Value": "P4"
      },
      {
        "Option":"Poder 5",
        "Value": "P5"
      },
      {
        "Option":"Poder 6",
        "Value": "P6"
      }
    ]
  }

  useEffect(() => {
    setFileInfo();
    setActorsInfo();
    setDetailsInfo();
  }, []);

  const changeActor = (actor) => {
    setSelectedActor(actor)
    setGroupIconColor(finbeColor)
  }

  const renderComponent = (actor) => {
    if (actor.TypeName.includes('Solicitante')) {
      return (<IconButton classes={{ root: localStyles.materialIconContainer }}>
        <AccountCircleIcon className={actor.Id === selectedActor.Id ? localStyles.materialSelectedIcon : localStyles.materialIcon} />
      </IconButton>);
    } else if (actor.TypeName.includes('Aval')) {
      return (<IconButton classes={{ root: localStyles.materialIconContainer }}>
        <MonetizationOnIcon className={actor.Id === selectedActor.Id ? localStyles.materialSelectedIcon : localStyles.materialIcon} />
      </IconButton>);
    } else if (actor.TypeName.includes('Accionista')) {
      return (<IconButton classes={{ root: localStyles.materialIconContainer }}>
        <DataUsageIcon className={actor.Id === selectedActor.Id ? localStyles.materialSelectedIcon : localStyles.materialIcon} />
      </IconButton>);
    } else if (actor.TypeName.includes('Legal')) {
      return (<IconButton classes={{ root: localStyles.materialIconContainer }}>
        <GavelIcon className={actor.Id === selectedActor.Id ? localStyles.materialSelectedIcon : localStyles.materialIcon} />
      </IconButton>);
    } else if (actor.TypeName.includes('Apoderado')) {
      return (<Apoderado fill={actor.Id === selectedActor.Id ? white : apoderadoColor} />);
    } else {
      return (<IconButton classes={{ root: localStyles.materialIconContainer }}>
        <AccountCircleIcon className={actor.Id === selectedActor.Id ? localStyles.materialSelectedIcon : localStyles.materialIcon} />
      </IconButton>);
    }
  };

  return (
    <>
      <div className={localStyles.headerOfSection}>
        <Grid container spacing={2} className={localStyles.nameSection}>
          <Grid item lg={1} className={localStyles.profileIconContainer}>
            {actors.length > 0 && Object.keys(selectedActor).length === 0
              ? <div className={localStyles.requestIcon}>
                  <GroupIcon fill={white} />
                </div>
                : actors.length > 0 && Object.keys(selectedActor).length > 0
                ? <Tooltip title='Solicitud'>
                    <div className={localStyles.iconBackToRequest}
                      onClick={() => { window.location.reload() }}
                      onMouseOver={() => { setGroupIconColor(white) }}
                      onMouseOut={() => { setGroupIconColor(finbeColor) }}
                    >
                      <GroupIcon fill={groupIconColor} />
                    </div>
                  </Tooltip>
                : <div className={localStyles.icon}>
                    <p className={localStyles.capital}>{details.initial}</p>
                  </div>
            }
          </Grid>
          <Grid item lg={11}>
            <TableContainer sx={{marginTop: "-1rem"}}>
              <Table>
                <TableBody>

                  <TableRow>

                    <TableCell align="left" style={{
                      borderBottom: "none"}}>
                      <Typography variant="p" className={localStyles.nameField}>
                        {actors.length > 0 ? "Solicitud grupal: " : "Cliente: "}
                        <span className={localStyles.description}>{details.nombreCompletoRazonSocial}</span>
                      </Typography>
                    </TableCell>

                    <TableCell align="left" style={{
                      borderBottom: "none"}}>
                      <Typography variant="p" className={localStyles.nameField}>
                        {`Folio: `}
                        <span className={localStyles.description}>{file.Folio}</span>
                        {file.validation?.FileApproval === true && file.Status?.includes("Finalizado") ? <span className={localStyles.accepted}>/ Solicitud aprobada</span>
                          : file.validation?.FileApproval === false && file.Status?.includes("Cancelado") ? <span className={localStyles.rejected}>/ Solicitud rechazada</span>
                            : <span className={localStyles.description}>{` / ${file.Status}`}</span>
                        }</Typography>
                    </TableCell>

                    <TableCell align="left" style={{
                      borderBottom: "none"}}>
                      <Typography variant="p" className={localStyles.nameField}>
                        {`Etapa: `}
                        <span className={localStyles.description}>{file.StageName}</span>
                      </Typography>
                    </TableCell>

                  </TableRow>

                  <TableRow>

                    <TableCell align="left" style={{
                      borderBottom: "none"}}>
                      <Typography variant="p" className={localStyles.nameField}>
                        {`Status: `}
                        <span className={localStyles.description}>{file.Status}</span>
                      </Typography>
                    </TableCell>

                    <TableCell align="left" style={{
                      borderBottom: "none"}}>
                      <Typography variant="p" className={localStyles.nameField}>
                        {`Tipo de crédito: `}
                        <span className={localStyles.description}>{details.tipoCredito}</span>
                      </Typography>
                    </TableCell>

                    <TableCell align="left" style={{
                      borderBottom: "none"}}>
                      <Typography variant="p" className={localStyles.nameField}>
                        {`Programa: `}
                        <span className={localStyles.description}>{details.programa}</span>
                      </Typography>
                    </TableCell>

                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <div className={localStyles.sectionActors}>
          {actors.length > 0 ?
            actors.map((actor) =>
              <Tooltip title={`${actor.TypeName} ${actor.Name === null ? '' : actor.Name}`}>
                <button
                  className={actor.Id === selectedActor.Id ? localStyles.iconSelectedActor : localStyles.iconActor}
                  onMouseEnter={() => { actor.TypeName.includes('Apoderado') && setApoderadoColor(white) }}
                  onMouseLeave={() => { actor.TypeName.includes('Apoderado') && setApoderadoColor(finbeColor) }}
                  onClick={() => { changeActor(actor) }}>
                  {renderComponent(actor)}
                </button>
              </Tooltip>
            ) : ""}
        </div>
      </div>

      {Object.keys(selectedActor).length > 0
        ? <FullWidthTabs currentActor={selectedActor} actorId={selectedActor.Id} />
        : <FullWidthTabs currentActor={selectedActor} actorId={null} multipleActors={actors} />}
      {/* </div> */}
    </>
  )
}

export default protect(DetailsComponent);

