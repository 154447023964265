import { msBiometriaConstants } from '../../constants/msMultiva/msBiometriaConstants';

export const BiometriaMultiva = (state = {}, action) => {
  switch (action.type) {
    case msBiometriaConstants.BIOMETRIA_REQUEST:
      return { loading: true, items: undefined, error: undefined };

    case msBiometriaConstants.BIOMETRIA_SUCCESS:
      return { loading: false, items: action.payload, error: undefined };

    case msBiometriaConstants.BIOMETRIA_FAILURE:
      return { loading: false, items: undefined, error: action.error };

    default:
      return state;
  }
};
