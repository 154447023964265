import React, { useEffect, useState } from 'react'
import protect from '../../utils/protect'
import { useDispatch, useSelector } from 'react-redux'
import { TextField, Typography, Button, InputAdornment, CircularProgress, Grid, useTheme } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';

import { fileActions } from '../../store/actions'
import TopImage from '../../assets/Busqueda_folio.png'
import noResult from '../../assets/NoResultMeet.png'

import { MainTable, UseImage, CustomLoadingOverlay, GenericHeaderActions } from '../../components'
import { mainStyles } from '../../theme/mainStyles'
import { useStyles } from './SearchStyles'
import { Icon } from '@iconify/react'


function SearchComponent(props) {
    const theme = useTheme()
    const gridContainer = mainStyles.gridStyle()
    const noInfo = mainStyles.noDataSections()
    const localStyles = useStyles(theme)
    const [searching, setSearching] = useState("")
    const [search, setSearch] = useState("")

    const info = useSelector(state => state.InfoBySearch)
    const state = useSelector(state => state)
    console.log('STATE::: ', state)
    console.log('Info first:::', info)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fileActions.GetInfoBySearch({ "SearchText": search }))
    }, [search])

    return (
        <>
            {search === "" ? (
                <div className={gridContainer.centerColumn}>
                    <div className={gridContainer.centerContent}>
                        <UseImage src={TopImage} className={localStyles.topImage} type='img' />
                        <form className={localStyles.formContent} onSubmit={(event) => {
                            event.preventDefault()
                            setSearch(searching)
                        }}>
                            <Typography variant='h6' >Ingresa el folio, puesto o nombre para realizar la búsqueda.</Typography>
                            <TextField
                                variant='standard'
                                margin='none'
                                className={localStyles.inputs}
                                sx={{ margin: '0.5em' }}
                                size="normal"
                                placeholder="Buscar"
                                onChange={(event) => {
                                    setSearching(event.target.value)
                                }}
                                InputProps={
                                    {
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment position='start' className={localStyles.icon}>
                                                <SearchIcon className={localStyles.iconMaterial} />
                                            </InputAdornment>
                                        )
                                    }
                                }
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ height: 45, px: 10 }}
                                type="submit" >
                                Buscar
                            </Button>
                        </form>
                    </div>
                </div >
            ) :
                (
                    // className={mainPage.mainComponent}
                    <div>
                        <form className={localStyles.form} >
                            <GenericHeaderActions onChangeTextField={(event) => {
                                setSearching(event.target.value)
                            }}
                                placeholderTextField="Busca al candidato por folio, puesto o nombre o ingresa la clave del grupo"
                                onClickConfirm={(event) => {
                                    event.preventDefault()
                                    setSearch(searching)
                                }}
                                ConfirmButtonText="Buscar"
                                CancelButtonText="Limpiar búsqueda"
                                onClickCancel={() => {
                                    setSearch(0)
                                    setSearching('')
                                }} />
                        </form>
                        {/* {info.error ?
                            <div className={noInfo.noResult}>
                                <UseImage src={noResult} type='img' style={{ maxWidth: 350 }} />
                                <Typography variant="h6" className={noInfo.h6}>No se encontraron resultados de busqueda</Typography>
                            </div> : null
                        } */}
                        {console.log('info.items?.length', info.items?.length )}
                        {info.loading === true  ?
                            <CustomLoadingOverlay
                                active={info.loading} text="Espere un momento por favor..."
                            /> : info.items?.length > 0 ?
                                <MainTable data={info.items} /> :
                                <div className={noInfo.noResult}>
                                    <UseImage src={noResult} type='img' style={{ maxWidth: 350 }} />
                                    <Typography variant="h6" className={noInfo.h6}>No se encontraron resultados de busqueda</Typography>
                                </div>
                        }

                    </div>
                )
            }
        </>
    )
}

export default protect(SearchComponent)