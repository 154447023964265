import { Box as MUIBox, styled } from '@mui/material';

export const Box = styled(MUIBox)(({ loading, column }) => ({
  display: 'flex',
  flexDirection: column === 'true' ? 'column' : 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '1rem',
  width: '100%',
  minHeight: '50px',
  textAlign: 'center',
  boxShadow: loading === 'true' ? '0px 2px 12px 5px rgba(0,0,0,0.15)' : 0,
}));
