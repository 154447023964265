import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { GenericConfirmationModal } from 'components/modals/GenericConfirmationModal.jsx';
import { ReturnButton } from 'components/Buttons';
import { msSimuladoresActions } from 'store/actions/msMultiva/msSimuladoresActions.js';
import { SIMULATOR_TYPES } from 'mappings/SimulationFormMappings.js';
import { RequestErrorAlert } from 'components/RequestErrorAlert';
import { CustomLoadingOverlay } from 'components/CustomLoadingOverlay';
import { useFormContext } from 'react-hook-form';

export const FormSimulationControls = ({
  isSimulationCalculated,
  simulationType,
  formData,
  onReturn,
}) => {
  const [open, setOpen] = useState(false);
  const [isSimulationSaved, setIsSimulationSaved] = useState(false);
  const loggedUser = useSelector((state) => state.Authentication);
  const infoSolicitud = useSelector((state) => state.SolicitudOperacion);
  const simulationResult = useSelector((state) => state.SimuladorCredito);
  const saveSimulationState = useSelector((state) => state.GuardarSimulacion);
  const { reset } = useFormContext();
  const dispatch = useDispatch();

  const handleCancel = () => {
    setOpen(false);
  };

  const handleReturn = () => {
    dispatch({
      type: 'GUARDAR_SIMULACION_CLEAR_STATE',
    });
    reset();
    onReturn();
  };

  const handleReturnToSimulation = () => {
    if (isSimulationCalculated && !isSimulationSaved) {
      setOpen(true);
      return;
    }
    handleReturn();
  };

  const handleSaveSimulation = () => {
    const {
      edadAnios,
      edadMeses,
      4013: tipo,
      4012: personalidadJuridica,
      4014: categoria,
      4015: programa,
      4016: tipoOperacion,
      ...producInformation
    } = formData;
    const SAVE_SIMULATION_PAYLOAD = {
      idSolicitud: infoSolicitud.items.solicitud.idSolicitud,
      rfc: 'RFCDEPRUEA123',
      jsonEntrada: JSON.stringify({
        tipoSimulador: SIMULATOR_TYPES[simulationType],
        evento: 'simulador',
        usuario: loggedUser?.usuario,
        usuarioGeolocalizacion: '99.0340324, -12.9999999',
        informacionProducto: {
          ...producInformation,
          personalidadJuridica: personalidadJuridica,
          categoria: categoria,
          programa: programa,
          tipoOperacion: tipoOperacion,
        },
      }),
      jsonSalida: JSON.stringify({ ...simulationResult.items }),
      montoMaximo: 411000.0,
      estatus: 'CREDITO APROBADO',
      usuario: loggedUser?.usuario,
    };
    dispatch(msSimuladoresActions.GuardarSimulacion(SAVE_SIMULATION_PAYLOAD))
      .then(() => {
        handleReturn();
        setIsSimulationSaved(true);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        paddingTop: '1.5rem',
      }}
    >
      {saveSimulationState.loading && <CustomLoadingOverlay active={saveSimulationState.loading} />}
      {saveSimulationState.error && (
        <RequestErrorAlert error={saveSimulationState.error} showConfim onHandle={handleReturn} />
      )}
      <GenericConfirmationModal
        title="Aún no has guardado la simulación. ¿Estás de seguro de salir?"
        returnText="Aceptar"
        confirmText="Cancelar"
        open={open}
        handleCancel={handleReturn}
        handleConfirm={handleCancel}
      />
      <ReturnButton onHandleClick={handleReturnToSimulation} />
      <div style={{ display: 'flex', gap: '1rem' }}>
        <Button
          variant="contained"
          disabled={!isSimulationCalculated}
          onClick={handleSaveSimulation}
        >
          Guardar Simulación
        </Button>
      </div>
    </div>
  );
};
