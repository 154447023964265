import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Link, Button, Checkbox, TableCell as MUITableCell } from '@mui/material';
import { InputField } from 'components/InputField';
import { InputTypeMappings } from 'mappings/InputTypesMappings';

export const TableCell = ({
  id,
  header,
  row,
  rowIndex,
  maximumNumberRowsSelect,
  isSelected,
  handleSelectRow,
  onClickActionRow,
  ActionRowDisabled,
}) => {
  const { formState, control, register, clearErrors } = useFormContext() || {};
  const cellInputType = InputTypeMappings[header?.CellType?.toUpperCase()];
  const cellValue = row[header.Name];
  const inputName = `${id}[${rowIndex}].${header.Name}`;

  const renderCellComponent = () => {
    switch (header?.CellType) {
      case 'Url':
        return <Link href={header.Metadata?.Prefix}>{header.Metadata?.Prefix + cellValue}</Link>;
      case 'Boton':
        return (
          <Button variant="contained" onClick={() => onClickActionRow(row)} disabled={ActionRowDisabled}>
            Ver
          </Button>
        );
      case 'TextoPlano':
        return <span>{cellValue}</span>;
      case 'Checkbox':
        return (
          <Checkbox
            checked={isSelected(row)}
            onChange={() => handleSelectRow(row, maximumNumberRowsSelect)}
          />
        );
      default:
        return (
          <InputField
            inputType={cellInputType}
            control={control}
            register={register}
            name={inputName}
            label={header?.Name?.toUpperCase()}
            validations={{ required: header.Required }}
            error={!!formState.errors[id]?.[rowIndex]?.[header.Name]}
            helperText={formState.errors[id]?.[rowIndex]?.[header.Name]?.message}
            required={header.Required}
            radioValues={cellInputType === 'radio' ? header.Options : undefined}
            optionsList={cellInputType === 'select' ? header.Options : undefined}
            maxDate={cellInputType === 'date' ? new Date() : null}
            disabled={cellInputType === 'date' ? header.Disabled : undefined}
            onSelect={() => {
              clearErrors(inputName);
            }}
          />
        );
    }
  };

  return <MUITableCell align="center">{renderCellComponent()}</MUITableCell>;
};
