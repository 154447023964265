import { TableHead, TableRow, TableCell, TableSortLabel, Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

export const TableHeader = ({
  headers,
  hasCheckbox,
  maximumNumberRowsSelect,
  selectedRows,
  numberOfRows,
  order,
  orderBy,
  onHandleSort,
  onSelectAll,
  onAddRow
}) => {
  var handleSortColumn = (headerDescription) => {
    onHandleSort(headerDescription);
  };

  const renderHeader = (header) => {
    const currentSortedColumn = orderBy === header.Description;
    if (header.Sortable) {
      return (
        <TableSortLabel
          active={currentSortedColumn}
          direction={currentSortedColumn ? order : 'asc'}
          onClick={() => handleSortColumn(header.Description)}
        >
          {header.Description}
        </TableSortLabel>
      );
    }
    return header.Description;
  };
  
  return (
    <TableHead>
      <TableRow>
        {hasCheckbox ? (
          <TableCell align="center">
            {!maximumNumberRowsSelect && (
              <Checkbox
                color="primary"
                checked={Object.keys(selectedRows).length === numberOfRows}
                onChange={onSelectAll}
              />
            )}
          </TableCell>
        ) : null}
        {headers.slice(hasCheckbox ? 1 : 0).map((header) => (
          <TableCell key={header.Description} sortDirection={'asc'} align="center">
            {renderHeader(header)}
          </TableCell>
        ))}
        {onAddRow && (
          <TableCell align="center">
            <Button
              color="success"
              variant='contained'
              onClick={onAddRow}
            >
              Añadir
            </Button>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};
