import { userConstants } from '../constants/index.js'
import { getUserEmployes } from '../../utils'

export const ChangePass = (state = {}, action) => {
    switch (action.type) {
        case userConstants.CHANGE_PASSWORD_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case userConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                loading: false,
                items: action.changePass.Body,
                error: undefined
            }
            //break;
        case userConstants.CHANGE_PASSWORD_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}

export const UsersAll = (state = {}, action) => {
    switch (action.type) {
        case userConstants.GETALL_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case userConstants.GETALL_SUCCESS:
            return {
                loading: false,
                items: action.usersData.Body,
                itemsEmployes: getUserEmployes(action.usersData.Body),
                error: undefined
            }
            //break;
        case userConstants.GETALL_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}

export const RegisterUser = (state = {}, action) => {
    switch (action.type) {
        case userConstants.REGISTER_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case userConstants.REGISTER_SUCCESS:
            return {
                loading: false,
                items: action.registerData.Body,
                error: undefined
            }
            //break;
        case userConstants.REGISTER_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}

export const UpdateUser = (state = {}, action) => {
    switch (action.type) {
        case userConstants.UPDATE_REQUEST:
            return {
                loading: true,
                items: undefined,
                error: undefined
            }
            //break;
        case userConstants.UPDATE_SUCCESS:
            return {
                loading: false,
                items: action.updateData.Body,
                error: undefined
            }
            //break;
        case userConstants.UPDATE_FAILURE:
            return {
                loading: false,
                items: undefined,
                error: action.error
            }
            //break;
        default:
            return state
    }
}