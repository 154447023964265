import { Box, styled } from "@mui/material";
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DashboardComponent from "./dashboard/DashboardComponent";
import DetailsComponent from "./details/DetailsComponent";
import SearchComponent from "./search/SearchComponent";
import RegistrationCandidatesComponent from "./RegistrationCandidates/RegistrationCandidatesComponent";
import HomeComponent from "./home/HomeComponent";
import UploadDocumentUsers from "./BulkLoad/RegisterBulkUsers/UploadDocumentUsers";
import HomeMasive from "./BulkLoad/HomeMasive";
import TableUsersSigners from "./TableUsersSigners";
import Reports from "./Reports/Reports";
import RegistrationBulkUsersComponent from "./RegistrationCandidates/RegistrationBulkUsersComponent";

const RootStyle = styled(Box)(({ theme }) => ({
  /* backgroundColor: "lightgreen", */
}));

export const Inbox = (props) => {
  return (
    <RootStyle>
      <Box>
        <Routes>
          <Route index element={<Navigate to="files" />} />
          <Route path="dashboards" element={<DashboardComponent />} />
          <Route path="homeComponent" element={<HomeComponent />} />
          <Route path="homeComponent/masive" element={<HomeMasive />} />
          <Route path="homeComponent/single" element={<HomeComponent />} />
          <Route path="new-file" element={<RegistrationCandidatesComponent />} />
          <Route path="bulkUsers" element={<RegistrationBulkUsersComponent />} />
          <Route path="search" element={<SearchComponent />} />
          <Route path="busqueda/:id" element={<DetailsComponent />} />
          <Route path="asignacion/:id" element={<DetailsComponent />} />
          <Route path="bulkLoadUsers" element={<UploadDocumentUsers />} />
          <Route path="tableUsersSigners" element={<TableUsersSigners />} />
          <Route path="report/prestamos" element={<Reports />} />
          <Route path="report/contrataciones" element={<Reports />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Box>
    </RootStyle>
  );
};
