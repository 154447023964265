import { useEffect } from 'react';
import { useTable } from 'hooks/useTable';
import { useFormContext } from 'react-hook-form';
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell as MUITableCell,
  Button,
} from '@mui/material';
import { TableHeader } from './TableHeader';
import { TableCell } from './TableCell';

export const DynamicTable = ({
  id,
  headers,
  rows,
  hasCheckbox,
  maximumNumberRowsSelect,
  onSelectRow,
  onClickActionRow,
  ActionRowDisabled,
  onDeleteRow,
  onAddRow,
}) => {
  const {
    isSelected,
    handleSort,
    handleSelectAll,
    handleSelectRow,
    order,
    orderBy,
    selectedRows,
  } = useTable(rows);
  const { getValues, setValue } = useFormContext() || {};

  const handleDeleteRow = (rowIndex) => {
    const currentValues = getValues();
    const updatedTableValue = currentValues[id].filter((item, index) => index !== rowIndex);
    setValue(id, updatedTableValue);
    onDeleteRow(rowIndex);
  };

  useEffect(() => {
    //if (!selectedRows.length > 0) return;
    onSelectRow && onSelectRow([...selectedRows]);
  }, [selectedRows]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHeader
          headers={headers}
          hasCheckbox={hasCheckbox}
          maximumNumberRowsSelect={maximumNumberRowsSelect}
          numberOfRows={rows?.length}
          selectedRows={selectedRows}
          onSelectAll={handleSelectAll}
          order={order}
          orderBy={orderBy}
          onHandleSort={handleSort}
          onAddRow={onAddRow}
        />
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex} hover>
              {headers.map((header, headerIndex) => {
                return (
                  <TableCell
                    key={`${rowIndex} - ${headerIndex}`}
                    id={id}
                    header={header}
                    row={row}
                    rowIndex={rowIndex}
                    isSelected={isSelected}
                    handleSelectRow={handleSelectRow}
                    maximumNumberRowsSelect={maximumNumberRowsSelect}
                    onClickActionRow={onClickActionRow}
                    ActionRowDisabled={ActionRowDisabled}
                  />
                );
              })}
              {onDeleteRow && (
                <MUITableCell align="center">
                  <Button color="error" variant='contained' onClick={() => handleDeleteRow(rowIndex)}>
                    Eliminar
                  </Button>
                </MUITableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
