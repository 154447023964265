import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Tooltip,
  MenuItem,
  ListItemText,
  tooltipClasses,
  TextField as InputText,
  FormControlLabel,
  Checkbox,
  Box,
  InputAdornment,
  Autocomplete,
  CircularProgress,
  Typography,
  autocompleteClasses,
  Radio as Rd,
  RadioGroup,
  createFilterOptions,
  useTheme,
} from '@mui/material';
import esLocale from 'date-fns/locale/es';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Controller } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { DatePicker as DPick } from '@mui/x-date-pickers';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import Parser from 'html-react-parser';
import { styled } from '@mui/material/styles';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { makeStyles } from '@mui/styles';
import { NumericFormat, PatternFormat } from 'react-number-format';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { esES } from '@mui/x-date-pickers/locales';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { es } from 'date-fns/locale';

const Tip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[5],
    fontSize: 13,
  },
  [`& .${tooltipClasses.arrow}`]: {
    backgroundColor: 'transparent',
    color: '#fff',
  },
  [`& .${autocompleteClasses.noOptions}`]: {
    backgroundColor: 'red',
  },
}));

const DatePicker = styled(DPick)(({ theme }) => ({
  width: '100%',
}));

const ControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': { fontSize: 14 },
  display: 'inline-flex',
  alignItems: 'center',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '140%',
  fontFamily: '"UtopiaStd-Regular_0"',
  [theme.breakpoints.down('sm')]: {
    fontWeight: 500,
    fontSize: '9px',
    lineHeight: '144%',
  },
}));

const Check = styled(Checkbox)(({ theme }) => ({
  padding: '4px 0',
  paddingLeft: theme.spacing(1),
}));

const Radio = styled(Rd)(({ theme }) => ({
  paddingTop: 4,
  paddingBottom: 4,
  [theme.breakpoints.down('sm')]: {
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: 1,
  },
}));

const TextField = styled(InputText)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    height: 25,
    padding: '10px 15px',
    [theme.breakpoints.down('sm')]: {
      height: 18,
      padding: '8px 10px',
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    fontSize: 14,
    width: '100%',
    '& fieldset legend span': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
      borderRadius: 8,
    },
  },
  '& .MuiInputLabel-root': {
    color: '#000',
    position: 'relative',
    transform: 'none',
    fontSize: '16px',
    marginBottom: 5,
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'auto',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
    // fontFamily: '"Montserrat",sans-serif',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      fontSize: '9px',
      lineHeight: '144%',
    },
  },
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& label.Mui-error': {
    color: '#DB0011 !important',
    // #DB0011
  },
  '& .MuiSelect-select': {
    height: 25,
    '& .notranslate': {
      display: 'block',
      margin: '2.45px 0',
    },
  },
  '& .MuiInputBase-multiline': {
    padding: '0',
  },
  input: {
    '&::placeholder': {
      [theme.breakpoints.down('sm')]: {
        fontWeight: 400,
        fontSize: '10px',
      },
    },
  },
}));

const AutocompleteStyled = styled(Autocomplete)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: '2.5px 5px',
  },
  '& ~ .MuiAutocomplete-popper .MuiAutocomplete-noOptions': {
    fontSize: 14,
  },
}));

const useStylesPhone = makeStyles({
  inputBase: {
    width: '100% !important',
    color: 'inherit',
  },
});

const TextFieldPINOTP = styled(InputText)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    height: 30,
    padding: '10px 15px',
    textAlign: 'center',
    fontSize: '30px',
    letterSpacing: '15px',
    [theme.breakpoints.down('sm')]: {
      // height: 18,
      padding: '8px 10px',
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    fontSize: 14,
    '& fieldset legend span': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
      borderRadius: 8,
    },
  },
  '& .MuiInputLabel-root': {
    color: '#000',
    position: 'relative',
    transform: 'none',
    fontSize: '16px',
    marginBottom: 5,
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'auto',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
    // fontFamily: '"Montserrat",sans-serif',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      fontSize: '9px',
      lineHeight: '144%',
    },
  },
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& label.Mui-error': {
    color: '#DB0011 !important',
    // #DB0011
  },
  '& .MuiSelect-select': {
    height: 30,
    '& .notranslate': {
      display: 'block',
      margin: '2.45px 0',
    },
  },
  '& .MuiInputBase-multiline': {
    padding: '0',
  },
  input: {
    '&::placeholder': {
      [theme.breakpoints.down('sm')]: {
        fontWeight: 400,
        fontSize: '10px',
      },
    },
  },
}));

const regexEmail =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexEmailMetlife =
  /^(?!.*@(metlife|provida))[^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// /^(?!.*@.*metlife)[^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// const regexPhone =
//   "[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}";
const regexRFC = '/^[A-ZÑ&]{3,4}d{6}(?:[A-Zd]{3})?$/';
const regexCURP =
  /^([A-Z]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM](AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[A-Z]{3}[0-9A-Z]\d)$/i;

export const InputField = (props) => {
  const theme = useTheme();
  const location = useLocation();
  const classesPhone = useStylesPhone();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = useCallback(() => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  }, []);

  const {
    inputType,
    iconSpam,
    defaultValue,
    value,
    label,
    multiline,
    minRows,
    sxLabel,
    sxInput,
    optionsList,
    tooltipText,
    control,
    name,
    validations,
    icon,
    posIcon,
    onEnter,
    placeholder,
    onSelect,
    onFocus,
    selectField,
    disabled,
    loading,
    onInputChange,
    open,
    onOpen,
    onClose,
    error,
    helperText,
    isPassword,
    checked,
    radioValues,
    minDate,
    maxDate,
    className,
    refCustom,
    required,
    register,
    regexPhone,
  } = props;

  const getInputType = () => {
    switch (inputType) {
      case 'select':
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue ?? ''}
            rules={validations}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? 'Requerido' : '',
                })}
                error={error}
                helperText={helperText}
                label={label}
                name={name}
                fullWidth
                select
                inputRef={ref}
                disabled={disabled}
                placeholder={placeholder}
                required={required}
                onChange={(e) => {
                  onChange(e);
                  onSelect && onSelect(e);
                }}
                inputProps={{ displayEmpty: true }}
              >
                {/* <MenuItem disabled value="">
                  <span style={{ color: '#9E9E9E' }}>{placeholder}</span>
                </MenuItem> */}
                {optionsList ? (
                  optionsList.map((opt, i) => (
                    <MenuItem key={`${opt?.Option}${i}`} value={opt?.Value}>
                      {opt.Option}
                      {/* {opt.Option?.toUpperCase()} */}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={1}>option</MenuItem>
                )}
              </TextField>
            )}
          />
        );
      case 'check':
        return (
          <ControlLabel
            sx={{
              fontSize: '14px !important',
              mr: 1,
              mt: '0.5px',
              // fontFamily: '"Montserrat",sans-serif',
            }}
            name={name}
            disabled={disabled}
            control={
              <Controller
                control={control}
                name={name}
                defaultValue={defaultValue}
                rules={validations}
                render={({ field: { ref, onChange, value, name, ...restFields } }) => (
                  <Box sx={{ mt: 0.4 }}>
                    <Check
                      {...restFields}
                      name={name}
                      inputRef={ref}
                      checked={value ?? defaultValue}
                      disabled={disabled}
                      required={required}
                      onChange={(e) => {
                        onChange(e);
                        onSelect && onSelect(e);
                      }}
                      size="small"
                    />
                  </Box>
                )}
              />
            }
            label={
              <Box
                color={error ? '#E60D33' : ''}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  ml: 1,
                  mt: 0.4,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '16px',
                  // fontFamily: '"Montserrat",sans-serif',
                  [theme.breakpoints.down('sm')]: {
                    fontWeight: 600,
                    fontSize: '11px',
                    lineHeight: '144%',
                  },
                }}
              >
                {Parser(`${label} ${required ? '*' : ''}`)}
                {tooltipText && (
                  <Tip title={tooltipText} placement="top" arrow enterTouchDelay={0}>
                    <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                  </Tip>
                )}
              </Box>
            }
          />
        );
      case 'radio':
        return (
          <ControlLabel
            sx={{
              fontSize: '14px !important',
              m: '0',
              // fontFamily: '"Montserrat",sans-serif',
              ...(sxInput ?? {}),
              width: '100% !important',
            }}
            disabled={disabled}
            control={
              <Controller
                defaultValue={null}
                control={control}
                name={name}
                rules={validations}
                disabled={disabled}
                render={({ field }) => (
                  <Box sx={{ width: '100%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '140%',
                        [theme.breakpoints.down('sm')]: {
                          fontWeight: 400,
                          fontSize: '10px',
                          lineHeight: '144%',
                        },
                        marginTop: '5px',
                        marginBottom: '5px',
                        color: error ? '#DB0011' : 'inherit',
                      }}
                    >
                      {label ? `${label} ${required ? '*' : ''}` : null}
                      {tooltipText && (
                        <Tip title={tooltipText} placement="top" arrow enterTouchDelay={0}>
                          <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                        </Tip>
                      )}
                    </Box>

                    <RadioGroup {...field}>
                      {radioValues?.map((rv) => (
                        <FormControlLabel
                          key={rv.Option + rv.Value}
                          disabled={disabled}
                          value={rv.Value}
                          control={
                            <Radio
                              required={label ? null : required}
                              disabled={disabled}
                              onChange={(e) => {
                                onSelect && onSelect(e, rv.Value);
                              }}
                              color="primary"
                              size="small"
                            />
                          }
                          label={
                            <Box
                              color={error ? '#E60D33' : ''}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                ml: 1,
                                fontStyle: 'normal',
                                fontWeight: 400,
                                // fontSize: '17px',
                                lineHeight: '16px',
                                // fontFamily: '"Montserrat",sans-serif',
                                [theme.breakpoints.down('sm')]: {
                                  fontWeight: 400,
                                  fontSize: '10px',
                                  lineHeight: '144%',
                                },
                              }}
                            >
                              {rv.Option}
                              {rv.TooltipText && (
                                <Tip
                                  title={rv.TooltipText}
                                  placement="top"
                                  arrow
                                  enterTouchDelay={0}
                                >
                                  <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                                </Tip>
                              )}
                            </Box>
                          }
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                )}
              />
            }
          />
        );
      case 'date':
        //const formattedValue = new Date(value).toLocaleDateString('es-MX'); // Format to dd/mm/yyyy
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            defaultValue={null}
            render={({ field: { ref, onBlur, onChange, ...restFields } }) => (
              <Box sx={{ width: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '140%',
                    [theme.breakpoints.down('sm')]: {
                      fontWeight: 400,
                      fontSize: '10px',
                      lineHeight: '144%',
                    },
                    marginTop: '5px',
                    marginBottom: '5px',
                    color: error ? '#DB0011' : 'inherit',
                  }}
                >
                  {label != undefined && label != null && label != '' ? (
                    <>
                      {`${label} ${required ? '*' : ''}`}
                      {tooltipText && (
                        <Tip title={tooltipText} placement="top" arrow enterTouchDelay={0}>
                          <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                        </Tip>
                      )}
                    </>
                  ) : null}
                </Box>
                <DatePicker
                  // value={value}
                  minDate={minDate}
                  maxDate={maxDate}
                  inputFormat="dd/MM/yyyy"
                  inputProps={{ placeholder: 'dd/mm/aaaa' }}
                  {...restFields}
                  onChange={(e) => {
                    if (e) {
                      const d = new Date();
                      d.setFullYear(e.getFullYear(), e.getMonth(), e.getDate());
                      d.setHours(0);
                      d.setMinutes(0);
                      d.setSeconds(0);
                    }
                    onChange(e);
                    onSelect && onSelect(e);
                  }}
                  inputRef={ref}
                  disabled={disabled}
                  slotProps={{
                    textField: {
                      variant: 'outlined',
                      error: error,
                      helperText: error ? 'Fecha requerida' : '',
                      required: required,
                      onBlur: () => onBlur(),
                      onKeyUp: onEnter,
                    },
                  }}
                />
              </Box>
            )}
          />
        );
      case 'phone':
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            defaultValue={defaultValue}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <Box sx={{ width: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '140%',
                    [theme.breakpoints.down('sm')]: {
                      fontWeight: 400,
                      fontSize: '10px',
                      lineHeight: '144%',
                    },
                    marginTop: '5px',
                    marginBottom: '5px',
                    color: error ? '#DB0011' : 'inherit',
                  }}
                >
                  {`${label} ${required ? '*' : ''}`}
                  {tooltipText && (
                    <Tip title={tooltipText} placement="top" arrow enterTouchDelay={0}>
                      <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                    </Tip>
                  )}
                </Box>

                <PhoneInput
                  {...restFields}
                  country={regexPhone}
                  defaultValue={defaultValue}
                  inputClass={classesPhone.inputBase}
                  enableSearch={true}
                  preferredCountries={['mx', 'co']}
                  // specialLabel={`${label} ${required ? " *" : ""}`}
                  onChange={(value, country, e, formattedValue) => {
                    onChange(value);
                    onSelect && onSelect(value);
                  }}
                  disabled={disabled}
                  value={value}
                  key={name}
                  inputProps={{
                    ref,
                    required: true,
                    // autoFocus: true,
                    inputMode: 'numeric',
                  }}
                />
                {error ? (
                  <span
                    style={{
                      marginLeft: 12,
                      color: '#FF4842',
                    }}
                  >
                    Requerido
                  </span>
                ) : (
                  ''
                )}
              </Box>
            )}
          />
        );
      case 'autocomplete':
        const filter = createFilterOptions();
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validations}
            render={({ field: { ref, onChange, value } }) => (
              <Box sx={{ width: '100%' }}>
                <AutocompleteStyled
                  clearOnBlur
                  ref={ref}
                  handleHomeEndKeys
                  sx={{ p: 0 }}
                  fullWidth
                  disabled={disabled}
                  onChange={(event, newValue) => {
                    if (typeof newValue == 'string') {
                      onChange({
                        Option: newValue,
                      });
                      onSelect && onSelect(newValue);
                    } else if (newValue && newValue.Value) {
                      onChange({
                        Option: newValue.Value,
                      });
                      onSelect && onSelect(newValue.Value);
                    } else {
                      onChange(newValue);
                      onSelect && onSelect(newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    console.log('........options, params..........', options, params);
                    const filtered = filter(options, params);
                    console.log('........filtered..........', filtered);
                    // const { Value,Option } = params;
                    // const isExisting = options.some(
                    //   (itemOption) => Value === itemOption.Option
                    // );
                    // console.log("........isExistings..........",isExisting)
                    // if (Value != "" && !isExisting) {
                    //   filtered.push({
                    //     Value,
                    //     Option: `Agregar "${Value}"`,
                    //   });
                    // }
                    return filtered;
                  }}
                  loadingText="Cargando..."
                  noOptionsText="Sin resultados"
                  clearText="Limpiar"
                  openText="Abrir"
                  closeText="Cerrar"
                  onInputChange={onInputChange}
                  disablePortal={true}
                  loading={loading}
                  open={open}
                  onOpen={onOpen}
                  onClose={onClose}
                  value={value}
                  options={optionsList}
                  getOptionLabel={(option) => {
                    if (typeof option == 'string') {
                      return option;
                    }
                    if (option.Option) {
                      return option.Option;
                    }
                    return option.Value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={name}
                      error={error}
                      placeholder={placeholder}
                      required={required}
                      InputLabelProps={{ sx: sxLabel }}
                      sx={{ ...(sxInput ?? {}) }}
                      label={
                        <>
                          {' '}
                          {label}
                          {iconSpam ? (
                            <span style={{ color: '#C20E30', marginLeft: 3 }}>*</span>
                          ) : null}
                        </>
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <Typography
                      {...props}
                      sx={{ fontSize: '14px !important' /* margin: "0 10px" */ }}
                    >
                      {option.Option}
                    </Typography>
                  )}
                />
                {error ? (
                  <span
                    style={{
                      marginLeft: 12,
                      color: '#FF4842',
                    }}
                  >
                    Requerido
                  </span>
                ) : (
                  ''
                )}
              </Box>
            )}
          />
        );
      case 'email':
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? 'Requerido' : '',
                  validate: (value) => {
                    return (
                      new RegExp(regexEmail).test(value) ||
                      `${label?.toUpperCase()} DATO INVÁLIDO. ${
                        label?.toUpperCase() == 'CORREO ELECTRÓNICO'
                          ? 'EJ. EJEMPLO@EJEMPLO.COM'
                          : ''
                      }`
                    );
                  },
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                name={name}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '140%',
                      [theme.breakpoints.down('sm')]: {
                        fontWeight: 400,
                        fontSize: '10px',
                        lineHeight: '144%',
                      },
                    }}
                  >
                    {label}
                    {/* {iconSpam || required ? (
                      <span style={{ color: "#C20E30", marginLeft: 3 }}>*</span>
                    ) : null} */}
                    {tooltipText && (
                      <Tip title={tooltipText} placement="top" arrow enterTouchDelay={0}>
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    // style: { textTransform: "uppercase" },
                    outline: 'none',
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                  },
                  outline: 'none',
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off',
                  },
                  // inputComponent: TextMaskCustom
                }}
                InputLabelProps={{ sx: sxLabel }}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case 'emailMetlife':
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? 'Requerido' : '',
                  validate: (value) => {
                    return (
                      new RegExp(regexEmailMetlife).test(value) ||
                      `${label?.toUpperCase()} DATO INVÁLIDO. ${
                        label?.toUpperCase() == 'CORREO ELECTRÓNICO'
                          ? 'EJ. EJEMPLO@EJEMPLO.COM'
                          : ''
                      }`
                    );
                  },
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                name={name}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '140%',
                      [theme.breakpoints.down('sm')]: {
                        fontWeight: 400,
                        fontSize: '10px',
                        lineHeight: '144%',
                      },
                    }}
                  >
                    {label}
                    {/* {iconSpam || required ? (
                      <span style={{ color: "#C20E30", marginLeft: 3 }}>*</span>
                    ) : null} */}
                    {tooltipText && (
                      <Tip title={tooltipText} placement="top" arrow enterTouchDelay={0}>
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    // style: { textTransform: "uppercase" },
                    outline: 'none',
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                  },
                  outline: 'none',
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off',
                  },
                  // inputComponent: TextMaskCustom
                }}
                InputLabelProps={{ sx: sxLabel }}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case 'curp':
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? 'Requerido' : '',
                  validate: (value) => {
                    return (
                      new RegExp(regexCURP).test(value) ||
                      `${label} DATO INVÁLIDO. INGRESE 18 CARACTERES.`
                    );
                  },
                  // setValueAs: (value) => value?.toUpperCase(),
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                name={name}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '140%',
                      [theme.breakpoints.down('sm')]: {
                        fontWeight: 400,
                        fontSize: '10px',
                        lineHeight: '144%',
                      },
                    }}
                  >
                    {label}
                    {tooltipText && (
                      <Tip title={tooltipText} placement="top" arrow enterTouchDelay={0}>
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    maxLength: 18,
                    style: { textTransform: 'uppercase' },
                    outline: 'none',
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                  },
                  outline: 'none',
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off',
                  },
                  // inputComponent: TextMaskCustom
                }}
                InputLabelProps={{ sx: sxLabel }}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case 'rfc':
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? 'Requerido' : '',
                  // setValueAs: (value) => value?.toUpperCase(),
                  // validate: (value) => {
                  //   return (new RegExp(regexRFC).test(value)
                  //     || `${label} dato inválido, ingrese los dígitos de su RFC.`
                  //   );
                  // },
                })}
                fullWidth
                error={error}
                helperText={helperText}
                inputRef={ref}
                name={name}
                disabled={disabled}
                onKeyUp={onEnter}
                defaultValue={defaultValue}
                required={required}
                autoComplete="new-password"
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '140%',
                      [theme.breakpoints.down('sm')]: {
                        fontWeight: 400,
                        fontSize: '10px',
                        lineHeight: '144%',
                      },
                    }}
                  >
                    {label}
                    {tooltipText && (
                      <Tip title={tooltipText} placement="top" arrow enterTouchDelay={0}>
                        <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                      </Tip>
                    )}
                  </Box>
                }
                InputProps={{
                  inputProps: {
                    maxLength: 13,
                    style: { textTransform: 'uppercase' },
                    outline: 'none',
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                  },
                  outline: 'none',
                  autoComplete: 'new-password',
                  form: {
                    autoComplete: 'off',
                  },
                }}
                InputLabelProps={{ sx: sxLabel }}
                sx={{ ...(sxInput ?? {}) }}
              />
            )}
          />
        );
      case 'clabe':
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <PatternFormat
                {...restFields}
                fullWidth
                inputRef={ref}
                label={
                  <>
                    {' '}
                    {label}
                    {iconSpam ? <span style={{ color: '#DB0011', marginLeft: 3 }}>*</span> : null}
                  </>
                }
                customInput={TextField}
                displayType="input"
                format="###################"
                mask="_"
              />
            )}
          />
        );
      case 'PostalCode':
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <PatternFormat
                {...restFields}
                fullWidth
                inputRef={ref}
                label={
                  <>
                    {' '}
                    {label}
                    {iconSpam ? <span style={{ color: '#DB0011', marginLeft: 3 }}>*</span> : null}
                  </>
                }
                customInput={TextField}
                displayType="input"
                format="#####"
                mask="_"
              />
            )}
          />
        );
      case 'PIN':
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <PatternFormat
                {...restFields}
                fullWidth
                inputRef={ref}
                label={
                  <>
                    {' '}
                    {label}
                    {iconSpam ? <span style={{ color: '#DB0011', marginLeft: 3 }}>*</span> : null}
                  </>
                }
                onChange={(e) => {
                  let valueReplace = e.target.value?.replace(/-/g, '');
                  // console.log("event PIN target valueReplace: ", valueReplace)
                  onChange(valueReplace);
                  onSelect && onSelect(valueReplace);
                }}
                customInput={TextFieldPINOTP}
                displayType="input"
                format="#-#-#-#-#"
                mask="_"
              />
            )}
          />
        );
      case 'currency':
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <NumericFormat
                {...restFields}
                fullWidth
                inputRef={ref}
                label={
                  <>
                    {' '}
                    <span style={{ color: error ? '#DB0011' : '' }}>{label}</span>
                    {required ? (
                      <span style={{ color: error ? '#C20E30' : '', marginLeft: 3 }}>*</span>
                    ) : null}
                  </>
                }
                onChange={(e) => {
                  let valueReplace = e.target.value?.replace(/[,|$]/g, '');
                  onChange(parseInt(valueReplace));
                  onSelect && onSelect(parseInt(valueReplace));
                }}
                error={error}
                helperText={error ? 'Requerido' : ''}
                customInput={TextField}
                decimalScale={2} // Decimales
                fixedDecimalScale={true}
                thousandSeparator={true} // Separador de miles
                prefix={'$'} // Símbolo de moneda
                value={value}
                disabled={disabled}
              />
            )}
          />
        );
      case 'number':
        return (
          <Controller
            control={control}
            name={name}
            defaultValue={null}
            // defaultValue=""
            rules={validations}
            render={({ field: { ref, ...restFields } }) => (
              <NumericFormat
                disabled={disabled}
                {...restFields}
                value={value}
                fullWidth
                inputRef={ref}
                label={
                  <>
                    {' '}
                    <span style={{ color: error ? '#DB0011' : '' }}>{label}</span>
                    {iconSpam ? <span style={{ color: '#DB0011', marginLeft: 3 }}>*</span> : null}
                  </>
                }
                customInput={TextField}
                decimalScale={0} // Decimales
                fixedDecimalScale={false}
                thousandSeparator={false} // Separador de miles
              />
            )}
          />
        );
      case 'hour':
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={es}>
            <Controller
              control={control}
              name={name}
              rules={validations}
              render={({ field: { ref, onChange, ...restFields } }) => (
                <Box sx={{ width: '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '140%',
                      [theme.breakpoints.down('sm')]: {
                        fontWeight: 400,
                        fontSize: '10px',
                        lineHeight: '144%',
                      },
                      marginTop: '5px',
                      marginBottom: '5px',
                      color: error ? '#DB0011' : 'inherit',
                    }}
                  >
                    {label != undefined && label != null && label != '' ? (
                      <>
                        {`${label} ${required ? '*' : ''}`}
                        {tooltipText && (
                          <Tip title={tooltipText} placement="top" arrow enterTouchDelay={0}>
                            <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                          </Tip>
                        )}
                      </>
                    ) : null}
                  </Box>
                  <Stack spacing={3} width={'100%'}>
                    <LocalizationProvider
                      localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
                    >
                      <MobileTimePicker
                        {...restFields}
                        ref={ref}
                        openTo="hours"
                        onChange={(event) => {
                          console.log('...........Datecurrent........event.....: ', event.$d);
                          const currentDate = event.$d;
                          // console.log("...........currentDate.....: ", currentDate)
                          onChange(currentDate);
                          onSelect && onSelect(currentDate);
                        }}
                        locale={esLocale}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Box>
              )}
            />
          </LocalizationProvider>
        );
      case 'password':
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            defaultValue={defaultValue}
            render={({ field: { ref, onChange, ...restFields }, fieldState }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? 'Requerido' : '',
                  validate: (value) => {
                    const ochocaracteres = /.{8,}/.test(value);
                    const mayymin = /(?:[A-Z])/.test(value) && /(?:[a-z])/.test(value);
                    const numeros = /(?:\d)/.test(value);
                    const noespecial = /[^!?A-Za-z\d]/.test(value);
                    const espacios = /\s/.test(value);
                    console.log(ochocaracteres, mayymin, numeros, noespecial);

                    const valida = ochocaracteres && mayymin && numeros && noespecial;

                    console.log('valida--------:  ', valida);

                    console.log('valida-------espacios-:  -', espacios, '--');
                    // const regex = /^(?=.*[A-Za-z0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+ ]{8,}$/;
                    // return regex.test(value) || 'password invalid'

                    if (valida === false || espacios) {
                      return `Revisa que tu contraseña cumpla lo siguiente: ${
                        espacios ? 'No contenga espacios, ' : ''
                      }
                ${ochocaracteres === false ? '8 caracteres mínimo, ' : ''}
                ${mayymin === false ? 'al menos una letra mayúscula y letras minúsculas, ' : ''}
                ${numeros === false ? 'al menos un número, ' : ''}
                ${noespecial === false ? 'mínimo un caracter especial.' : ''} `;
                    } else {
                      return true;
                    }
                  },
                })}
                type={showPassword ? 'text' : 'password'}
                error={error ?? fieldState.invalid}
                helperText={helperText ?? fieldState.error?.message}
                defaultValue={defaultValue}
                fullWidth
                name={name}
                inputRef={ref}
                multiline={multiline}
                minRows={minRows ?? 1}
                onKeyUp={onEnter}
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                autoComplete="new-password"
                label={
                  label != undefined && label != null && label != '' ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '140%',
                        [theme.breakpoints.down('sm')]: {
                          fontWeight: 400,
                          fontSize: '10px',
                          lineHeight: '144%',
                        },
                      }}
                    >
                      {label}
                      {''}
                      {iconSpam ? <span style={{ color: '#DB0011', marginLeft: 3 }}>*</span> : null}
                      {tooltipText && (
                        <Tip title={tooltipText} placement="top" arrow enterTouchDelay={0}>
                          <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                        </Tip>
                      )}
                    </Box>
                  ) : null
                }
                InputProps={{
                  inputProps: {
                    outlinet: 'none',
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true, sx: sxLabel }}
                sx={{
                  '& $input': {
                    height: multiline ? 'auto' : '25px',
                  },
                  ...(sxInput ?? {}),
                }}
              />
            )}
          />
        );
      case 'text':
      default:
        return (
          <Controller
            control={control}
            name={name}
            rules={validations}
            defaultValue={defaultValue}
            render={({ field: { ref, onChange, ...restFields } }) => (
              <TextField
                {...restFields}
                {...register(name, {
                  required: required ? 'Requerido' : '',
                  // setValueAs: (value) => value?.toUpperCase(),
                })}
                type={isPassword ? 'password' : 'text'}
                error={error}
                value={value}
                defaultValue={defaultValue}
                fullWidth
                name={name}
                inputRef={ref}
                multiline={multiline}
                minRows={minRows ?? 1}
                onFocus={onFocus}
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                helperText={helperText}
                onChange={(e) => {
                  onChange(e);
                  onSelect && onSelect(e);
                }}
                autoComplete="new-password"
                label={
                  label != undefined && label != null && label != '' ? (
                    <Box
                      component="div"
                      sx={{
                        whiteSpace: 'normal',
                        display: 'flex',
                        alignItems: 'center',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        flexWrap: 'wrap',
                        fontSize: '16px',
                        lineHeight: '140%',
                        [theme.breakpoints.down('sm')]: {
                          fontWeight: 400,
                          fontSize: '10px',
                          lineHeight: '144%',
                        },
                      }}
                    >
                      {label}
                      {''}
                      {iconSpam ? <span style={{ color: '#DB0011', marginLeft: 3 }}>*</span> : null}
                      {tooltipText && (
                        <Tip title={tooltipText} placement="top" arrow enterTouchDelay={0}>
                          <InfoOutlinedIcon sx={{ ml: 1, fontSize: 15 }} />
                        </Tip>
                      )}
                    </Box>
                  ) : null
                }
                InputProps={{
                  inputProps: {
                    outlinet: 'none',
                    autoComplete: 'new-password',
                    form: {
                      autoComplete: 'off',
                    },
                  },
                  startAdornment:
                    posIcon == 'start' ? (
                      <InputAdornment position={posIcon}>{icon}</InputAdornment>
                    ) : undefined,
                  endAdornment:
                    posIcon == 'end' ? (
                      <InputAdornment position={posIcon}>{icon}</InputAdornment>
                    ) : undefined,
                }}
                InputLabelProps={{ shrink: true, sx: sxLabel }}
                sx={{
                  '& $input': {
                    height: multiline ? 'auto' : '25px',
                  },
                  ...(sxInput ?? {}),
                }}
              />
            )}
          />
        );
    }
  };

  if (inputType == 'check') {
    return <Box sx={{ display: 'flex', alignItems: 'center' }}>{getInputType()}</Box>;
  }

  return getInputType();
};
