import { postRequest } from '../axiosFunctions';
import {
  CONSULTA_HABILITADORES_PATH,
  ACTUALIZA_HABILITADORES_PATH,
  ALTA_HABILITADORES_PATH,
  ASOCIAR_GESTOR_FOLIO_PATH,
} from '../URLS/msHabilitadores';

const ConsultaHabilitadores = (data, signal) =>
  postRequest(CONSULTA_HABILITADORES_PATH, data, signal);

const ActualizaHabilitadores = (data, signal) =>
  postRequest(ACTUALIZA_HABILITADORES_PATH, data, signal);

const AltaHabilitadores = (data, signal) => 
  postRequest(ALTA_HABILITADORES_PATH, data, signal);

const AsociarGestorConFolio = (data, signal) =>
  postRequest(ASOCIAR_GESTOR_FOLIO_PATH, data, signal);

export const msHabilitadoresRequests = {
  ConsultaHabilitadores,
  ActualizaHabilitadores,
  AltaHabilitadores,
  AsociarGestorConFolio,
};
