export const fileMasiveConstants = {
    FILE_SEARCH_MASIVE_REQUEST: 'FILE_SEARCH_MASIVE_REQUEST',
    FILE_SEARCH_MASIVE_SUCCESS: 'FILE_SEARCH_MASIVE_SUCCESS',
    FILE_SEARCH_MASIVE_FAILURE: 'FILE_SEARCH_MASIVE_FAILURE',

    FILE_SEARCH_MASIVE_DEATILS_GROUP_REQUEST: 'FILE_SEARCH_MASIVE_DEATILS_GROUP_REQUEST',
    FILE_SEARCH_MASIVE_DEATILS_GROUP_SUCCESS: 'FILE_SEARCH_MASIVE_DEATILS_GROUP_SUCCESS',
    FILE_SEARCH_MASIVE_DEATILS_GROUP_FAILURE: 'FILE_SEARCH_MASIVE_DEATILS_GROUP_FAILURE',

    FILE_SEARCH_STATUS_MASIVE_REQUEST: 'FILE_SEARCH_STATUS_MASIVE_REQUEST',
    FILE_SEARCH_STATUS_MASIVE_SUCCESS: 'FILE_SEARCH_STATUS_MASIVE_SUCCESS',
    FILE_SEARCH_STATUS_MASIVE_FAILURE: 'FILE_SEARCH_STATUS_MASIVE_FAILURE',

    UPLOAD_DOCUMENTS_MASIVE_REQUEST: 'UPLOAD_DOCUMENTS_MASIVE_REQUEST',
    UPLOAD_DOCUMENTS_MASIVE_SUCCESS: 'UPLOAD_DOCUMENTS_MASIVE_SUCCESS',
    UPLOAD_DOCUMENTS_MASIVE_FAILURE: 'UPLOAD_DOCUMENTS_MASIVE_FAILURE',

    GET_USERS_METADATA_MASIVE_REQUEST: 'GET_USERS_METADATA_MASIVE_REQUEST',
    GET_USERS_METADATA_MASIVE_SUCCESS: 'GET_USERS_METADATA_MASIVE_SUCCESS',
    GET_USERS_METADATA_MASIVE_FAILURE: 'GET_USERS_METADATA_MASIVE_FAILURE',

    UPLOAD_METADATA_USERS_MASIVE_REQUEST: 'UPLOAD_METADATA_USERS_MASIVE_REQUEST',
    UPLOAD_METADATA_USERS_MASIVE_SUCCESS: 'UPLOAD_METADATA_USERS_MASIVE_SUCCESS',
    UPLOAD_METADATA_USERS_MASIVE_FAILURE: 'UPLOAD_METADATA_USERS_MASIVE_FAILURE',
}