import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CircularProgress, Box, Typography, Button,
} from '@mui/material'
import { fileActions, productActions } from '../../store/actions'
import protect from '../../utils/protect'
import {
  FormDynamic,
  Alert, InformationalMessageComponent, CustomLoadingOverlay,
} from '../../components'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'

function RegistrationCandidatesComponent(props) {
  const navigation = useNavigate()
  const [listControls, setListControls] = useState(null)
  const [isRegister, setIsRegister] = useState(null)
  const [dataCurrent, setDataCurrent] = useState(null)
  const [loadingButton, setLoadingButton] = useState(true)


  const productData = useSelector(state => state.ProductHiringData)
  const generateFolioData = useSelector(state => state.GenerateFolioHiringData)
  const dispatch = useDispatch()

  useEffect(() => {
    console.log()
    dispatch(productActions.GetProducts(""))
  }, [])

  useEffect(() => {
    if (productData.items !== undefined) {
      setListControls(productData.items[0])
      // navigation('/inbox/search')
      console.log("productData--- Items-----------: ", productData.items)
    }
    else if (productData.error !== undefined) {
      console.log(productData.error)
      // AlertCustom({
      //   icon: 0,
      //   title: productData.error,
      // });
    }
  }, [productData])

  useEffect(() => {
    if (generateFolioData.items !== undefined && isRegister) {
      console.log("generateFolioData--- Items-----------: ", generateFolioData)
      setDataCurrent(generateFolioData.items)
      setListControls(null)
      setIsRegister(false)
      setLoadingButton(!loadingButton)
      Alert({
        icon: "success",
        text: "Registro correcto.",
      });
      navigation('/inbox/homeComponent')

      // dispatch(productActions.GetProductHiring(""))
    } else if (generateFolioData.error !== undefined && isRegister) {
      setIsRegister(false)
      setLoadingButton(!loadingButton)
      Alert({
        icon: 0,
        text: generateFolioData.error,
      });
    }
  }, [generateFolioData])


  const handleSaveFile = async (formData, e) => {
    console.log("formData, e**********: ", formData, e)

    let newListMetdata = []
    listControls.QuestionDefinitions.forEach((itemMetadata, i) => {
      if (itemMetadata.Name !== 'Primer Nombre' && itemMetadata.Name !== 'Segundo Nombre' &&
        itemMetadata.Name !== 'Primer Apellido' && itemMetadata.Name !== 'Segundo Apellido' &&
        itemMetadata.Name !== 'Correo electrónico' && itemMetadata.Name !== 'Teléfono') {
        newListMetdata.push({
          GroupName: itemMetadata.QuestionGroup,
          Name: itemMetadata.Name,
          Value: formData[itemMetadata.Id],
        })
      }
    })

    console.log("newListMetdata: ", newListMetdata)
    console.log("listControls: ", listControls.QuestionDefinitions?.find(item=> item.Name == 'Primer Nombre')?.Id)
    let requestData = {
      Name: formData[listControls.QuestionDefinitions?.find(item=> item.Name == "Primer Nombre")?.Id],
      SecondName: formData[listControls.QuestionDefinitions?.find(item=> item.Name == "Segundo Nombre")?.Id],
      FatherLastName: formData[listControls.QuestionDefinitions?.find(item=> item.Name == "Primer Apellido")?.Id],
      MotherLastName: formData[listControls.QuestionDefinitions?.find(item=> item.Name == "Segundo Apellido")?.Id],
      Email: formData[listControls.QuestionDefinitions?.find(item=> item.Name == "Correo electrónico")?.Id],
      PhoneNumber: formData[listControls.QuestionDefinitions?.find(item=> item.Name == "Teléfono de Contacto")?.Id],//formData["Teléfono"],
      Metadata: newListMetdata?.length > 0 ? newListMetdata : null,
      ProductId: productData.items[0].Id,
    }

    console.log("requestData: ", requestData)
    dispatch(fileActions.GenerateFolioHiring(requestData))
    setLoadingButton(!loadingButton)
    setIsRegister(true)
  }

  const handleResset = (e) => {
    console.log("Ejecuta action resset:")
    setDataCurrent(null)
    Alert({
      icon: "success",
      text: "Usuario activado.",
    });
    dispatch(productActions.GetProductHiring(""))
  }

  console.log(listControls, 'listControls')

  return (
    <div >
      {/* {dataCurrent !== undefined && dataCurrent !== null ?
        <InformationalMessageComponent actionResset={handleResset} Data={dataCurrent} /> : null} */}

      {productData.loading ?
        <CustomLoadingOverlay
          active={productData.loading} text="Espere un momento por favor..."
        /> :
        listControls !== null ?
          <>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                variant="contained"
                startIcon={<Icon fontSize={25} icon="ph:users-three" />}
                sx={{ height: 45, px: 10, maxWidth: 300 }}
                onClick={() => { navigation('/inbox/bulkUsers') }} >
                Registro masivo
              </Button>
            </Box>
            <FormDynamic TextButton="Registrar" loadingButton={loadingButton} DataControls={listControls} handleSave={handleSaveFile} />
          </>
          : null
      }
    </div>)
}

export default protect(RegistrationCandidatesComponent)