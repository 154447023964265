import React from 'react';
import { Accordion } from '../../Accordion.jsx/index.jsx';
import { constPathRoot } from '../../../utils/index.js';
import { useLocation } from 'react-router';

export const DynamicFormWrapper = ({ questionsGroups, render, loading, isExpanded }) => {
  const path = useLocation();

  const getIsQuestionGroupVisible = (questionGroup) => {
    const isVisible = questionGroup.questions.some((question) => question.Visible);
    return !isVisible && path !== `${constPathRoot.pathRoot}/inbox/new-file`;
  };

  return (
    <>
      {questionsGroups.map((questionGroup) => {
        if (getIsQuestionGroupVisible(questionGroup)) return null;
        return (
          <Accordion key={questionGroup.llave} title={questionGroup.groupTitle} isExpanded={isExpanded} >
            {questionGroup.questions.map((question, index) => (
              <React.Fragment key={question.Id}>{render(question, index)}</React.Fragment>
            ))}
          </Accordion>
        );
      })}
    </>
  );
};
