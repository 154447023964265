// los headers que sean check y radio son para seleccionar registros de la tabla en esencia
export const headersConfiguration = {
  Headers: [
    {
      Description: '',
      Name: 'Check',
      Sortable: true,
      CellType: 'Checkbox',
      Required: true,
      Sequence: 2,
      Metadata: {},
      AdditionalData: {
        MaximumNumberRowsSelect: 2,
      },
    },
    {
      Description: 'COMBO',
      Name: 'Combo',
      Sortable: true,
      CellType: 'Combo',
      Required: true,
      Sequence: 2,
      Metadata: {
        Value: 'Licenciatura',
      },
      Options: [
        {
          Option: 'Licenciatura',
          Value: 'Licenciatura',
        },
        {
          Option: 'Posgrado',
          Value: 'Posgrado',
        },
        {
          Option: 'Preparatoria',
          Value: 'Preparatoria',
        },
      ],
    },
    {
      Description: 'URL',
      Name: 'Url',
      Sortable: true,
      CellType: 'Url',
      Required: true,
      Sequence: 1,
      Metadata: {
        Prefix: 'https://sitioweb.com?id=',
      },
    },
    {
      Description: 'TEXTO PLANO',
      Name: 'Texto plano',
      Sortable: true,
      CellType: 'Texto plano',
      Required: false,
      Sequence: 4,
    },
    {
      Description: 'TEXTO',
      Name: 'Texto',
      Sortable: true,
      CellType: 'Texto',
      Required: true,
      Sequence: 4,
    },
    {
      Description: 'CURRENCY',
      Length: 100,
      Name: 'Currency',
      CellType: 'Currency',
      RegExp: '^\\$[0-9]{1,3}(?:,?[0-9]{3})*(?:\\.[0-9]{2})?$',
      Required: true,
      Sequence: 5,
    },
    {
      Description: 'FECHA',
      Name: 'Fecha',
      Sortable: false,
      CellType: 'Fecha',
      Required: true,
      Sequence: 4,
      Disabled: false,
    },
    {
      Description: 'BOTON',
      Name: 'Button',
      Sortable: false,
      CellType: 'Button',
      Required: true,
      Sequence: 4,
      Disabled: false,
    },
  ],
  Sequence: 7,
};

const responseClient = {
  "responseCliente": [
    {
      "CPDomNac": "20263", // Para domicilio
      "CURP": "XXXX010101XXXXXX01", // Se ocupa
      "RFC": "XXXX010101XXX", // Se ocupa
      "calleEmpNac": "CALLE EMPRESA 1001144598", //Para domicilio
      "ciudadDomNac": "AGUASCALIENTES", // Para domicilio
      "noCliente": "1001144598", // Num cliente
      "noCuenta": "00008445435", // Num cuenta
      "nombreEmpresa": "NOMBRE EMPRESA 1001144598", // Razon social?
      "personalidadJuridica": "1", //Juridica 1 = P. Fisica?
      "primerNombre": "1ER NOMBRE 1001144598",
      "segundoNombre": "",
      "apellidoPaterno": "AP PATERNO 1001144598",
      "apellidoMaterno": "AP MATERNO 1001144598",

    },
    {
      "CPDomNac": "20264", // Para domicilio
      "CURP": "XXXX010101XXXXXX02", // Se ocupa
      "RFC": "XXXX010102XXX", // Se ocupa
      "calleEmpNac": "CALLE EMPRESA 1001144598", //Para domicilio
      "ciudadDomNac": "AGUASCALIENTES", // Para domicilio
      "noCliente": "1001144599", // Num cliente
      "noCuenta": "00008445436", // Num cuenta
      "nombreEmpresa": "NOMBRE EMPRESA 1001144599", // Razon social?
      "personalidadJuridica": "0", //Juridica 1 = P. Fisica?
      "primerNombre": "1ER NOMBRE 1001144598",
      "segundoNombre": "",
      "apellidoPaterno": "AP PATERNO 1001144598",
      "apellidoMaterno": "AP MATERNO 1001144598",

    },
    {
      "CPDomNac": "20265", // Para domicilio
      "CURP": "XXXX010101XXXXXX03", // Se ocupa
      "RFC": "XXXX010103XXX", // Se ocupa
      "calleEmpNac": "CALLE EMPRESA 1001144598", //Para domicilio
      "ciudadDomNac": "AGUASCALIENTES", // Para domicilio
      "noCliente": "1001144600", // Num cliente
      "noCuenta": "00008445437", // Num cuenta
      "nombreEmpresa": "NOMBRE EMPRESA 1001144600", // Razon social?
      "personalidadJuridica": "1", //Juridica 1 = P. Fisica?
      "primerNombre": "1ER NOMBRE 1001144598",
      "segundoNombre": "",
      "apellidoPaterno": "AP PATERNO 1001144598",
      "apellidoMaterno": "AP MATERNO 1001144598",

    },
  ]
};


let rows = responseClient.responseCliente.map(obj => {
  return [null, obj.personalidadJuridica,
    obj.CURP,
    obj.RFC,
    (obj.apellidoPaterno+" "+obj.apellidoMaterno+" "+obj.primerNombre+" "+obj.segundoNombre),
    obj.nombreEmpresa,
    (obj.calleEmpNac+", CP. "+obj.CPDomNac),
    obj.noCliente,
    obj.noCuenta,
    ]
});

console.log("Row values:",rows);


export const rowsConfiguration = {
  // rows: headersConfiguration.Headers.map((header) => {
  //   if (header.CellType === 'Texto plano' || header.CellType === 'Url') return 'value1';
  //   return null;
  // }),
  // Rows: [
  //   [null, null, null, null, null, null, null, null, null],
  //   [null, null, null, null, null, null, null, null, null],
  //   [null, null, null, null, null, null, null, null, null],
  //   [null, null, null, null, null, null, null, null, null],
  //   // [null, null, 'value1', 'value1', null, null, null, null],
  //   // [null, null, 'value1', 'value1', null, null, null, null],
  //   // [null, null, 'value1', 'value1', null, null, null, null],
  //   // [null, null, 'value1', 'value1', null, null, null, null],
  // ],

  Rows: rows
};

// const TableValues = rows.map((row) => {

// })
