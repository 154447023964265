import { combineReducers } from 'redux';

import { Authentication } from './AuthReducer'; //Importar reducer
import {
  FileInfo,
  SearchByUser,
  InfoBySearch,
  RecordInfo,
  ActorsInfo,
  ActorInfo,
  Folio,
  FolioRemote,
  FileUpdateMetData,
  GenerateFolioHiringData,
  OptionsToSelect,
  UserCurentData,
} from './FileReducer';
import {
  GetByUsuarioRolDetalleGrupo,
  GetByUsuarioGruposStatus,
  GetByUsuarioRolBusquedaPortalGrupos,
  UploadBatchDocumentProcess,
  GetUsersInformationMetadadaMasive,
  UploadUsersInformationMetadadaMasive,
} from './FileMasiveReducer';
import { UploaBatchFile2Data, InitializeBatchFileData } from './FlujoMasivoReducer';
import { BinnacleInfo, ContinueWorkFlowData } from './WorkFlowReducer';
import {
  FileValidation,
  DocumentValidation,
  catpchaValidation,
  PinValidation,
  DocExternalVal,
  RegisGeolocationData,
  CustomValidationFile,
  ProcessValRulesData,
} from './ValidationReducer';
import {
  Document,
  BioDocument,
  DocumentUpdateMetData,
  UploadSections,
  CargaDocWithSecData,
  RegisterMetadaData,
  OcrData,
  GetDocumentsGroupByRol,
} from './DocumentReducer';
import { ProductRemote, ProductHiringData } from './ProductReducer';
import { ChangePass, UsersAll, RegisterUser, UpdateUser } from './UserReducer';
import { BiometricData } from './BiometricReducer';
import { NewPin, GetNotificationsUser } from './NotificationReducer';
import { RoleAll } from './RoleReduce';
import { ListBranchOffice } from './ExternalReducer';
import { SearchData, SelectOptionData, CustomCatalogData } from './ExecuteSearchReducer';
import { GenerateCode2FA, ValidateCode2FA } from './msMultiva/msAuthenticationReducer';
import {
  AltaDocumento,
  ModificarDocumento,
  ConsultarDocumentoAtributo,
  DescargarDocumento,
} from './msMultiva/msGestorDocumentalReducer';
import {
  FilterSearchProcessAccount,
  FilterSearchProcessCustomerRFC,
  FilterSearchProcessCustomerCuenta,
  FilterSearchProcessLending,
} from './msMultiva/msClienteReducer';
import { ConsultaCatalogos } from './msMultiva/msCatalogosReducer';
import {
  ConsultaHabilitadores,
  ActualizaHabilitadores,
  AltaHabilitadores,
} from './msMultiva/msHabilitadoresReducer';
import { TokenRHSSO } from './msMultiva/msTokenReducer';
import {
  AltaProcesoBackOffice,
  ActualizarProcesoBackOffice,
  ConsultaProcesoBackOffice,
} from './msMultiva/msBackOfficeReducer';
import {
  SimuladorCredito,
  GuardarSimulacion,
  ConsultaXidSolicitud,
  ConsultaXidIndividual,
  ImprimirTablaAmortizacion,
} from './msMultiva/msSimuladoresReducer';
import {
  SolicitudOperacion,
  SolicitudAsociacion,
  SolicitudConsultaC12
} from './msMultiva/msSolicitudReducer';
import { BiometriaMultiva } from './msMultiva/msBiometriaReducer';

const rootReducer = combineReducers({
  Authentication,
  FileInfo,
  SearchByUser,
  InfoBySearch,
  RecordInfo,
  ActorsInfo,
  ActorInfo,
  Folio,
  FolioRemote,
  BinnacleInfo,
  FileValidation,
  DocumentValidation,
  catpchaValidation,
  PinValidation,
  DocExternalVal,
  Document,
  BioDocument,
  ProductRemote,
  ChangePass,
  BiometricData,
  NewPin,
  RegisGeolocationData,
  RoleAll,
  ListBranchOffice,
  UsersAll,
  RegisterUser,
  UpdateUser,
  CustomValidationFile,
  FileUpdateMetData,
  ContinueWorkFlowData,
  ProcessValRulesData,
  DocumentUpdateMetData,
  ProductHiringData,
  GenerateFolioHiringData,
  OptionsToSelect,
  CargaDocWithSecData,
  UploadSections,
  RegisterMetadaData,
  OcrData,
  SearchData,
  UserCurentData,
  GetByUsuarioRolBusquedaPortalGrupos,
  GetByUsuarioRolDetalleGrupo,
  GetByUsuarioGruposStatus,
  UploadBatchDocumentProcess,
  GetUsersInformationMetadadaMasive,
  UploadUsersInformationMetadadaMasive,
  GetDocumentsGroupByRol,
  GetNotificationsUser,
  SelectOptionData,
  CustomCatalogData,
  UploaBatchFile2Data,
  InitializeBatchFileData,
  /**** Multiva reducers *****/
  GenerateCode2FA,
  ValidateCode2FA,
  AltaDocumento,
  ModificarDocumento,
  ConsultarDocumentoAtributo,
  DescargarDocumento,
  FilterSearchProcessAccount,
  FilterSearchProcessCustomerRFC,
  FilterSearchProcessCustomerCuenta,
  FilterSearchProcessLending,
  ConsultaCatalogos,
  ConsultaHabilitadores,
  ActualizaHabilitadores,
  AltaHabilitadores,
  TokenRHSSO,
  AltaProcesoBackOffice,
  ActualizarProcesoBackOffice,
  ConsultaProcesoBackOffice,
  SimuladorCredito,
  GuardarSimulacion,
  ConsultaXidSolicitud,
  ConsultaXidIndividual,
  ImprimirTablaAmortizacion,
  SolicitudOperacion,
  SolicitudAsociacion,
  SolicitudConsultaC12,
  BiometriaMultiva,
});

export default rootReducer;
