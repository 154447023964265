import { useSelector } from 'react-redux';
import { useDynamicForm } from 'hooks/useDynamicForm';

export const SimulationContext = ({ children }) => {
  const infoFile = useSelector((state) => state.FileInfo);
  const { FormProvider, formMethods, questionsGroups } = useDynamicForm(infoFile.items, null);

  return (
    <FormProvider {...formMethods} questionsGroups={questionsGroups}>
      {children}
    </FormProvider>
  );
};
