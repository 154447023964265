import { Button, Grid, Typography, Box, styled, CircularProgress, IconButton } from '@mui/material';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Link } from 'react-router-dom';

export const Buttons = (props) => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      sx={{
        mt: `${props.marginTop == undefined ? 10 : props.marginTop}px`,
        mb: `${props.marginBottom == undefined ? 1 : props.marginBottom}px`,
      }}
    >
      <Grid
        item
        xs={12}
        sm={8}
        sx={{ marginTop: '2px', textAlign: 'center', paddingTop: '2px !important', zIndex: 100 }}
      >
        <Button
          fullWidth
          variant="contained"
          sx={{ height: 45, px: 10, maxWidth: 300 }}
          onClick={props.onClickConfirm}
          disabled={props.disabledConfirm}
        >
          {props.ConfirmButtonText == undefined ? 'Continuar' : props.ConfirmButtonText}
        </Button>
      </Grid>
      {props.onClickCancel != undefined ? (
        <Grid
          item
          xs={12}
          sm={8}
          sx={{ marginTop: '2px', textAlign: 'center', paddingTop: '2px !important', zIndex: 100 }}
        >
          <Button
            fullWidth
            variant="outlined"
            sx={{ height: 45, px: 10, maxWidth: 300 }}
            onClick={props.onClickCancel}
            disabled={props.disabledCancel}
          >
            {props.CancelButtonText == undefined ? 'Cancelar' : props.CancelButtonText}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};
export const LoadingButton = ({ loading, children, onHandleClick }) => {
  const handleClick = () => {
    onHandleClick();
  };
  return (
    <Button onClick={handleClick} variant="contained" size="large" disabled={loading}>
      {loading && (
        <CircularProgress
          color="inherit"
          size="1.4rem"
          sx={{
            marginRight: '0.5rem',
          }}
        />
      )}
      {children}
    </Button>
  );
};
export const ReturnButton = ({ onHandleClick, label, isDisabled }) => {
  const handleClick = () => {
    onHandleClick();
  };

  return (
    <Button
      variant="contained"
      sx={{ display: 'flex', gap: '0.5rem', padding: '4px 2rem' }}
      onClick={handleClick}
      disabled={isDisabled}
    >
      <KeyboardReturnIcon />
      {label || 'Simulación'}
    </Button>
  );
};

export const DownloadButton = ({ children, documentName, b64 }) => {
  const handleDownloadTablaAmortizacion = () => {
    const linkSource = `data:application/pdf;base64,${b64}`;
    const downloadLink = document.createElement('a');
    const fileName = `${documentName}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return (
    <Button variant="outlined" onClick={handleDownloadTablaAmortizacion}>
      {children}
    </Button>
  );
};

export const LinkButton = (props) => {
  return (
    <Box
      sx={{
        p: 1,
        textAlign: props.textAlign == undefined ? 'right' : props.textAlign,
        width: '90%',
        zIndex: 100,
        mb: `${props.marginBottom == undefined ? 1 : props.marginBottom}px`,
      }}
    >
      <Link style={{ textDecoration: 'none' }} to={props.LinkButtonUrl}>
        <Typography variant="body2" color="primary" sx={{ width: '100%', textAlign: 'center' }}>
          {props.LinkButtonText}
        </Typography>
      </Link>
    </Box>
  );
};

export const ButtonsLined = (props) => {
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      sx={{
        mt: `${props.marginTop == undefined ? 10 : props.marginTop}px`,
        mb: `${props.marginBottom == undefined ? 1 : props.marginBottom}px`,
      }}
    >
      <Grid item xs={6} sm={6} sx={{ zIndex: 100 }}>
        <Button
          fullWidth
          variant="contained"
          sx={{ height: 35, px: 5 }}
          onClick={props.onClickConfirm}
          disabled={props.disabledConfirm}
        >
          {props.ConfirmButtonText == undefined ? 'Continuar' : props.ConfirmButtonText}
        </Button>
      </Grid>
      {props.onClickCancel != undefined ? (
        <Grid item xs={6} sm={6} sx={{ zIndex: 100 }}>
          <Button
            fullWidth
            variant="outlined"
            sx={{ height: 35, px: 5 }}
            onClick={props.onClickCancel}
            disabled={props.disabledCancel}
          >
            {props.CancelButtonText == undefined ? 'Cancelar' : props.CancelButtonText}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

export const GenericButtonList = styled(Box)(({ theme }) => ({
  width: '100% !important',
  padding: '16px',
  gap: '16px',
  minHeight: '56px',
  background: theme.palette.primary.lightContrast,
  border: `1px solid ${theme.palette.primary.contrastText}`,
  borderRadius: '8px',
  marginTop: '16px',
  '&:focus': {
    outline: 'none !important',
    background: theme.palette.primary.main80,
    border: `1px solid ${theme.palette.primary.main80}`,
  },
  '&:hover': {
    outline: 'none !important',
    background: theme.palette.primary.main60,
    border: `1px solid ${theme.palette.primary.main60}`,
  },
}));
