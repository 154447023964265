export const DocSummaryTableQuestionGroup = [
  {
    "title": "Resumen Documentación",
    "llave": 0,
    "questions": [
      {
        "Id": 0,
        "Name": "Resumen Documentación",
        "Description": "Resumen Documentación",
        "QuestionType": "Tabla",
        "DataType": "string",
        "Length": 0,
        "Required": false,
        "Sequence": 1,
        "QuestionGroupId": 624,
        "QuestionGroup": "Resumen Documentación",
        "TableHeaders": [
          {
            "Name": "subTipoDocumentoDesc",
            "Description": "Subtipo de documento",
            "CellType": "TextoPlano",
            "Length": 10,
            "Sequence": 1,
            "Required": false,
            "Sortable": true
          },
          {
            "Name": "tipoDocumentoDesc",
            "Description": "Nombre del documento",
            "CellType": "TextoPlano",
            "Length": 10,
            "Sequence": 2,
            "Required": false,
            "Sortable": true
          },
          {
            "Name": "esObligatorio",
            "Description": "¿Es obligatorio?",
            "CellType": "TextoPlano",
            "Length": 10,
            "Sequence": 3,
            "Required": false,
            "Sortable": true
          },
          {
            "Name": "fechaVigencia",
            "Description": "Fecha de vigencia",
            "CellType": "TextoPlano",
            "Length": 10,
            "Sequence": 4,
            "Required": false,
            "Sortable": true
          },
          {
            "Name": "estadoDocumento",
            "Description": "Estado",
            "CellType": "TextoPlano",
            "Length": 10,
            "Sequence": 5,
            "Required": false,
            "Sortable": true
          },
          {
            "Name": "Ver",
            "Description": "Detalles",
            "CellType": "Boton",
            "Length": 10,
            "Sequence": 6,
            "Required": false,
            "Sortable": true
          },
        ],
        "GroupName": "Resumen Documentación",
        "DefaultValue": null,
        "Visible": true,
        "Captured": null,
      }
    ]
  }
];