import { prepareRequest, processRequest } from './Encription'
import { prepareRequestMultiva, processRequestMultiva } from './SymetricEncription'

import { constEncripted } from '../'

export function BodySecurityRequest(data) {

    if (!constEncripted.encriptedBody) {
        if (data === null || data === '') {
            let objRequest = {
                Body: "",
                EncryptedBody: "",
                SecurityData: "",
                PKey: ""
            }
            return (objRequest)
        }
    }
    return prepareRequest(data, constEncripted.encriptedBody);
}

export function BodySecurityResponse(data) {
    if (constEncripted.encriptedBody) {
        return processRequest(data);
    } else {
        return data;
    }
}

export function BodySecurityRequestForceEncripted(data) {
    // console.log("BodySecurityRequestForceEncripted initial")
    return prepareRequest(data, true);
}

export function BodySecurityResponseForceEncripted(data) {
    // console.log("BodySecurityResponseForceEncripted finish")
    return processRequest(data);
}
