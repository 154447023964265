const { Typography } = require('@mui/material');

const getReadableColorStatus = (status) => {
  switch (status) {
    case false:
      return '#F20000';
    case true:
    case 1:
      return '#28a745';
    default:
      return '#FFD233';
  }
};

const getReadableStatus = (status) => {
  switch (status) {
    case false:
      return 'Rechazado';
    case true:
      return 'Cargado y verificado';
    case 1:
      return 'Capturado';
    default:
      return 'Pendiente de carga';
  }
};

export const DocumentarySlotDetail = ({ name, required, isCaptured }) => (
  <>
    <Typography variant="h6">
      {name}
      {required && <span style={{ color: '#F43E3E' }}> *</span>}
    </Typography>
    &nbsp; - &nbsp;
    <Typography variant="h6" style={{ color: isCaptured ? 'green' : 'orange' }}>
      {isCaptured ? 'CAPTURADO' : 'PENDIENTE'}
    </Typography>
    {/* <Typography variant="h6" style={{ color: getReadableColorStatus(docItem.IsRejected) }}>
      {getReadableStatus(docItem.IsRejected)}
    /<Typography> */}
  </>
);
