import { TextField , styled } from '@mui/material';

export const InputField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    height: 25,
    padding: '10px 15px',
    [theme.breakpoints.down('sm')]: {
      height: 18,
      padding: '8px 10px',
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    fontSize: 14,
    width: '100%',
    '& fieldset legend span': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
      borderRadius: 8,
    },
  },
  '& .MuiInputLabel-root': {
    color: '#000',
    position: 'relative',
    transform: 'none',
    fontSize: '16px',
    marginBottom: 5,
    marginTop: 5,
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'auto',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
    // fontFamily: '"Montserrat",sans-serif',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 400,
      fontSize: '9px',
      lineHeight: '144%',
    },
  },
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
  '& label.Mui-error': {
    color: '#DB0011 !important',
    // #DB0011
  },
  '& .MuiSelect-select': {
    height: 25,
    '& .notranslate': {
      display: 'block',
      margin: '2.45px 0',
    },
  },
  '& .MuiInputBase-multiline': {
    padding: '0',
  },
  input: {
    '&::placeholder': {
      [theme.breakpoints.down('sm')]: {
        fontWeight: 400,
        fontSize: '10px',
      },
    },
  },
}));
