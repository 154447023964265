import React, { useEffect, useState } from 'react'
import { Box , useTheme} from '@mui/material'
import { makeStyles } from '@mui/styles'

import { constUrlGraficas, useWindowDimensions } from '../../utils'
import protect from '../../utils/protect'
// import { mainStyles } from '../../styles'

const useStyles = makeStyles((theme) => ({
  dashboard: {
    width: '100%',
    height: '100%'
  },
}));

function DashboardComponent(props) {
  const theme = useTheme()
  const { height, width } = useWindowDimensions();
  const [urlCharts, setUrlCharts] = useState(null);

  const mainPage = useStyles(theme)

  useEffect(() => {
    // console.log("matches: ",width,matches)
    let urlGenerate = `${constUrlGraficas.ambient}?reference=${"aa"}&token=${sessionStorage.token}`;
    console.log("url: ", urlGenerate)
    setUrlCharts(urlGenerate)
  }, []);

  return (
    <>
      <Box style={{ width: "100%", height: "80vh" }}>

      <iframe className={mainPage.dashboard}
            frameborder="0" src="https://app.powerbi.com/view?r=eyJrIjoiNGM2YjkxMmItZTdhOS00YmMxLWIzYmUtODM4MzU3NjA5Y2FmIiwidCI6ImVjZWI4OWU3LTk0NTctNGE0ZS1iODVmLThkYTU1M2QyZjhiOSIsImMiOjR9" allowFullScreen></iframe>
       
        {/* {urlCharts ?
          <object data={urlCharts} width="100%" height={height - 150}></object>
          :null} */}
      </Box>
    </>)
}

export default protect(DashboardComponent)