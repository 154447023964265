import React from "react";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Typography,
  styled,
} from "@mui/material";
import { Icon } from "@iconify/react";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from "react-router-dom";

const DrawerStyled = styled(Box)(({ theme }) => ({
  width: 290,
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const menuItems = [
  {
    name: "Reporte",
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="tabler:report-analytics" />
      </Box>
    ),
    link: "/inbox/dashboards",
  },
  {
    name: "Asignaciones",
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="majesticons:document-award-line" />
      </Box>
    ),
    link: "/inbox/homeComponent",
    secundary: [
      {
        name: "Asignación individual",
        link: "/inbox/homeComponent/single",
      },
      {
        name: "Asignación masiva",
        link: "/inbox/homeComponent/masive",
      },
    ],
  },
  {
    name: "Alta Candidatos",
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="heroicons:users" />
      </Box>
    ),
    link: "/inbox/new-file",
  },
  {
    name: "Buscar",
    icon: (
      <Box
        style={{
          marginLeft: ".8em",
          marginRight: ".8em",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon fontSize={25} icon="akar-icons:search" />
      </Box>
    ),
    link: "/inbox/search",
  },
];

export const Drawer = (props) => {
  const navigate = useNavigate();

  const cerrarSesion = () => {
    console.log('CIERRASESION')
    localStorage.clear()
    sessionStorage.clear()
    sessionStorage.removeItem('tokenMc')
    localStorage.removeItem('versionApp');
    navigate("/auth/login");
  };

  const closeDrawer = (item) => {
    navigate(item.link);
    props.onClose();
  };

  return (
    <MuiDrawer open={props.open} onClose={props.onClose}>
      <DrawerStyled>
        <Box style={{ p: "30px 20px 5px 20px" }}>
          <Box style={{ pl: 1, textAlign: "center" }}>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Avatar
                style={{
                  color: "primary.mainLyrics",
                  backgroundColor: "primary.main",
                  fontWeight: 600,
                  width: 100,
                  height: 100,
                  fontSize: 40,
                  mt: 0.7,
                }}
                alt={sessionStorage.userName}
                src="/static/images/avatar/2.jpg"
              />
            </Box>
            <Typography variant="subtitle1" style={{ mt: 3 }} color="primary">
              {sessionStorage.userName}
            </Typography>
            <Typography variant="subtitle1" style={{ mt: 2 }}>
              {sessionStorage.Rol}
            </Typography>
          </Box>
          <List>
            {menuItems.map((menuItems) => (
              <ListItem
                button
                key={menuItems.name}
                onClick={() => closeDrawer(menuItems)}
              >
                <ListItemIcon style={{ minWidth: 40 }}>
                  {menuItems.icon}
                </ListItemIcon>
                <ListItemText
                  style={{ color: "secondary.main" }}
                  primary={menuItems.name}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box sx={{ p: "5px 20px 5px 20px", mt: "auto" }}>
          <List>
            <ListItem button onClick={() => cerrarSesion()}>
              <ListItemIcon sx={{ minWidth: 40 }}>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText
                sx={{ color: "secondary.main" }}
                primary="Cerrar sesión"
              />
            </ListItem>
          </List>
        </Box>
      </DrawerStyled>
    </MuiDrawer>
  );
};
