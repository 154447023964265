export const CUSTOMER_SEARCH_PAYLOAD= {
  solicitud: {
    idSolicitud: 117,
    tipoTransaccion: "consulta",
    idUsuario: 12343,
    usuarioGeolocalizacion: "40.689247, -74.044502",
    seccion: [
      "c61"
    ],
    c61: ""
  }
}

export const SEARCH_CUSTOMER_DETAILS_PAYLOAD = {
  solicitud: {
    idSolicitud: 116,
    tipoTransaccion: "consulta",
    idUsuario: 12343,
    usuarioGeolocalizacion: "40.689247, -74.044502",
    seccion: [
      "cliente"
    ],
    cliente: {
      idCliente: ""
    }
  }
};

export const CUSTOMER_REQUEST_BIND_PAYLOAD = {
  solicitud: {
    idSolicitud: 116,
    idEstatus: 1,
    idEtapa: 1,
    idSubetapa: 1,
    idPersonalidadJuridica: 1,
    idTipoTramite: 4,
    idOperacionSolicitud: 4,
    idUsuario: 12343,
    usuario: "multiva",
    idDao: 3,
    idArea: 5,
    idRol: 7,
    idSucursal: 9,
    idSubDireccionGeneral: 7,
    idDireccionGeneral: 8,
    tipoTransaccion: "alta",
    folioDocsolution: "HGJGHJ4564564",
    usuarioGeolocalizacion: "40.689247, -74.044502",
    seccion: [
      "solicitudRelacionCliente"
    ],
    solicitudRelacionCliente: {
      idCliente: "",
      idSolicitud: 116
    }
  }
}
