import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({

    //Modal Approved

    modalApproval: {
        width: '500px !important',
        display: 'flex !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
    },

    modalCancel: {
        display: 'flex !important',
        flexDirection: 'column !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
    },

    modalActionApproval: {
        width: '500px !important',
        display: 'flex !important',
        justifyContent: 'center !important',
        alignItems: 'center !important'
    },

    modalText: {
        marginTop: theme.spacing(2),
        width: '60% !important',
        fontSize: 14,
        color: '#000000 !important'
    },

    textFieldArea: {
        width: '400px',
        display: 'flex',
        justifyContent: 'center',
    },

    line: {
        height: '50px',
        width: '80%',
        alignSelf: 'center',
        borderBottom: `solid 1px ${theme.palette.secondary.contrastText}`
    },

    //Modal Visual - Actor M Visual

    modalVisual: {
        display: 'flex',
        justifyContent: 'center',
        height: '80vh'
    },

    modalVisualNoData: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '80vh'
    },

    button: {
        color: `${theme.palette.primary.main} !important`,
        fontWeight: 'bold !important',
        '&:focus': {
            outline: 'none !important',
        },
    },

    closeIcon: {
        alignSelf: 'flex-end !important',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

    modalPdf: {
        width: '50%',
        backgroundColor: 'rgba(42, 42, 46, 1) !important',
        margin: '1.5em !important',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center'
    },

    pdfNoData: {
        width: '96%',
        height: '90%',
        // display: 'flex',
        // justifyContent: 'center',
        // alignItems: 'center'        
        backgroundColor: 'rgba(42, 42, 46, 1) !important',
        margin: '1em !important',
    },

    modalInfo: {
        width: '50%',
        marginTop: '1.5em !important'
    },

    scroll: {
        overflowX: "hidden",
        overflowY: "auto",
        height: "65vh",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },

    information: {
        width: '46%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },

    name: {
        fontSize: 16,
        fontWeight: 'bold !important'
    },

    title: {
        fontWeight: 'bold !important',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },

    mainDoctitle: {
        fontWeight: 'bold !important',
        // marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
        color: "#FFFFFF",
        // marginLeft: theme.spacing(2),
    },

    iconSection: {
        marginTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    icon: {
        width: '130px',
        height: '130px',
        padding: 'none',
        margin: 'none'
    },
    p: {
        fontSize: '12px',
        textAlign: 'center',
        paddingLeft: '3px',
        paddingRight: '3px',
        marginBottom: theme.spacing(1),
    },

    lineVisual: {
        height: '5px',
        width: '100%',
        alignSelf: 'center',
        borderBottom: `solid 2px ${theme.palette.secondary.contrastText}`
    },

    groupSection: {
        width: '95%',
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(2)
    },

    groupTitle: {
        fontSize: '19px !important',
        fontWeight: 'bold !important',
        color: theme.palette.status.info
    },

    dataValue: {
        display: 'flex !important',
        flexWrap: 'wrap !important',
        whiteSpace: 'normal !important',
        width: '100% !important',
        overflowX: 'auto !important'
    },

    //BioVisual y MiniBioVisual

    bioImg: {
        width: '56% !important',
        // height: '90% !important'
    },

    miniSelfie: {
        width: '56% !important',
        // height: '65% !important'
    },

    miniImg: {
        width: '56% !important',
        // height: '60% !important'
    },

    bioModal: {
        display: 'flex !important',
        flexDirection: 'column !important',
        // justifyContent: 'center !important',
        alignItems: 'center !important',
    },

    miniBioModal: {
        display: 'flex !important',
        flexDirection: 'column !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        width: '40vw',
    },

    titleModal: {
        alignSelf: 'flex-start !important',
        fontWeight: 'bold !important',
        fontSize: 20,
        marginBottom: theme.spacing(3),
    },

    fileModal: {
        width: '70%',
        height: 'auto',
    },
    modalEstatusProcesando: {
        marginTop: theme.spacing(3),
        width: '95%',
        fontSize: 15,
        textAlign: 'center',
        wordBreak: 'break-all',
        marginBottom: theme.spacing(1),
        color: '#B8144F',
    },
    modalEstatusProcesandoSucces: {
        marginTop: theme.spacing(3),
        width: '95%',
        fontSize: 15,
        textAlign: 'center',
        wordBreak: 'break-all',
        marginBottom: theme.spacing(1),
        color: '#4caf50',
    },
    modalBioInfo: {
        width: '95%',
        fontSize: 15,
        textAlign: 'center',
        wordBreak: 'break-all',
    },
    modalBioInfo1: {
        width: '95%',
        fontSize: 15,
        // textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        wordBreak: 'break-all',
        textAlign: 'left',
    },

    eachData: {
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
        marginBottom: theme.spacing(2)
    },

    metadaSection: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: theme.spacing(2)
    },

    lineBio: {
        height: '5px',
        width: '80%',
        alignSelf: 'center',
        borderBottom: `solid 1px ${theme.palette.secondary.contrastText}`
    },

    resultTitle: {
        fontWeight: 'bold !important',
        fontSize: 20,
        alignSelf: 'center !important',
        color: theme.palette.status.info,
        marginTop: theme.spacing(1),
    },

    resultInfoSection: {
        width: '100% ! important',
        display: 'flex !important',
        flexDirection: 'column !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
    },

    txtToRead: {
        textAlign: 'center !important',
    },
    imgProcesandoVideo: {
        width: '20% !important'
    },
    imgProcesandoVideo1: {
        width: '43% !important',
        padding: '1.5em !important'
    },
    informationTables: {
        width: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    containerResult: {
        display: 'grid !important',
        gridTemplateColumns: '70% 30%',
        ['@media only screen and (max-width: 812px)']: {
            // gridTemplateColumns: '5% 90% 5%',
            gridTemplateColumns: '50% 50%',
        },
        overflow: 'hidden !important',
    },
    leftColumnResult: {
        display: 'flex',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        overflowY: 'none',
        justifyContent: 'center !important',
    },
    rightColumnResult: {
        display: 'flex',
        flexDirection: 'column !important',
        alignItems: 'flex-end !important',
        justifyContent: 'center !important',
        overflowY: 'hidden !important',
    },
    overFlowModal: {
        overflow: 'hidden !important',
        '&MuiPaper-root MuiDialog-paper MuiDialog-paperScrollPaper': {
            overflow: 'hidden !important',
        }
    },
    containPdf: {
        minHeight: "68vh",
        textAlign: 'center'
    },
}))

export const customHelpDialogStyles = makeStyles((theme) => ({
    ContainerDialog: {
        display: 'grid !important',
        height: '100vh !important',
        gridTemplateColumns: '20% 60% 20%',
        // eslint-disable-next-line
        ['@media only screen and (max-width: 812px)']: {
            // gridTemplateColumns: '5% 90% 5%',
            gridTemplateColumns: '100%',
        },
        gridAutoRows: '100vh !important',
        overflowY: 'hidden !important',
        backgroundColor: 'rgba(245, 245, 245, 0.3)',
    },
    CenterDialog: {
        display: 'flex',
        flexDirection: 'column !important',
        alignItems: 'center !important',
        height: 'minmax(100vh, auto)',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '0',  /* Remove scrollbar space */
            background: 'transparent',  /* Optional: just make scrollbar invisible */
        },
        overflowX: 'none',
    },
    centerContentDialog: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '60%',
        position: 'relative',
        ['@media only screen and (max-width: 812px)']: {
            width: '90%',
        }
    },
    leftColumnDialog: {
        //outline: 'solid 1px tomato !important',
        overflowY: 'none',
        backgroundColor: '#f22',
        ['@media only screen and (max-width: 812px)']: {
            backgroundColor: '#ffffff',
            display: 'none',
        },
    },
    rightColumnDialog: {
        overflowY: 'none',
        backgroundColor: '#f24',
        ['@media only screen and (max-width: 812px)']: {
            backgroundColor: '#ffffff',
            display: 'none',
        },
    },
    title: {
        fontWeight: 600,
        width: '100%',
        // paddingLeft: theme.spacing(1),
        // fontSize: 24,
    },
    close: {
        alignSelf: 'flex-end !important',
        marginTop: '3px !important',
        marginRight: '3px !important',
        width: 13,
        height: 13
    },
    //ToolTip
    closeText: {
        alignSelf: 'flex-end',
        marginRight: theme.spacing(3),
        marginTop: theme.spacing(3),
        '&&:focus': {
            outline: 'none'
        }
    },

    closeIconNew: {
        alignSelf: 'flex-end !important',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: '100%',
    },
    modalEditable: {
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        width: "100%",
        minHeight: "80vh",
    },
}))