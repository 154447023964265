import { msHabilitadoresConstants } from '../../constants/msMultiva/msHabilitadoresConstants';
import { msHabilitadoresRequests } from '../../../services/msMultiva/msHabilitadores';
import { documentRequests } from 'services';

const {
  CONSULTA_HABILITADORES_REQUEST,
  CONSULTA_HABILITADORES_SUCCESS,
  CONSULTA_HABILITADORES_FAILURE,
  CONSULTA_HABILITADORES_CLEAR_STATE,
  ACTUALIZA_HABILITADORES_REQUEST,
  ACTUALIZA_HABILITADORES_SUCCESS,
  ACTUALIZA_HABILITADORES_FAILURE,
  ALTA_HABILITADORES_REQUEST,
  ALTA_HABILITADORES_SUCCESS,
  ALTA_HABILITADORES_FAILURE
} = msHabilitadoresConstants;

function request(constantName) {
  return { type: msHabilitadoresConstants[constantName] };
}

function success(constantName, data) {
  return { type: msHabilitadoresConstants[constantName], payload: data };
}

function failure(constantName, error) {
  return { type: msHabilitadoresConstants[constantName], error };
}

function clear(constantName) {
  return { type: msHabilitadoresConstants[constantName] };
}

export const msHabilitadoresActions = {};

msHabilitadoresActions.ConsultaHabilitadores = (data, signal = null) => {
  return async (dispatch) => {
    dispatch(request(CONSULTA_HABILITADORES_REQUEST));
    try {
      const response = await msHabilitadoresRequests.ConsultaHabilitadores(data, signal);
      dispatch(success(CONSULTA_HABILITADORES_SUCCESS, response.documentacionSolicitud));
      return response;
    } catch (error) {
      dispatch(failure(CONSULTA_HABILITADORES_FAILURE, error.message));
    }
  };
};

msHabilitadoresActions.ConsultaHabilitadoresClearState = () => {
  return (dispatch) => {
    dispatch(clear(CONSULTA_HABILITADORES_CLEAR_STATE));
  };
};

  // msHabilitadoresActions.ActualizaHabilitadores = (data, signal = null) => {
//   return (dispatch) => {
//     dispatch(request(ACTUALIZA_HABILITADORES_REQUEST));
//     msHabilitadoresRequests
//       .ActualizaHabilitadores(data, signal)
//       .then((response) => dispatch(success(ACTUALIZA_HABILITADORES_SUCCESS, response)))
//       .catch((error) => {
//         dispatch(failure(ACTUALIZA_HABILITADORES_FAILURE, error.message));
//       });
//   };
// };

msHabilitadoresActions.ActualizaHabilitadores = (data, signal = null) => {
  return async (dispatch) => {
    dispatch(request(ACTUALIZA_HABILITADORES_REQUEST));
    try {
      const response = await msHabilitadoresRequests.ActualizaHabilitadores(data, signal);
      dispatch(success(ACTUALIZA_HABILITADORES_SUCCESS, response.documentacionSolicitud));
      return response;
    } catch (error) {
      dispatch(failure(ACTUALIZA_HABILITADORES_FAILURE, error.message));
    }
  };
};

msHabilitadoresActions.AltaHabilitadores = (data, signal = null) => {
  return (dispatch) => {
    dispatch(request(ALTA_HABILITADORES_REQUEST));
    msHabilitadoresRequests
      .AltaHabilitadores(data, signal)
      .then((response) => dispatch(success(ALTA_HABILITADORES_SUCCESS, response)))
      .catch((error) => {
        dispatch(failure(ALTA_HABILITADORES_FAILURE, error.message));
      });
  };
};

msHabilitadoresActions.UploadAndAssociate = (data, dataAssociate, signal) => {
  return async (dispatch) => {
    dispatch(request(CONSULTA_HABILITADORES_REQUEST));
    try {
      const response = await documentRequests.UploadMultivaDocument(data);
      await msHabilitadoresRequests.AsociarGestorConFolio({
        ...dataAssociate,
        idGestorDocumental: response.Body.Result.DocumentManagerId,
      });
      const habilitadoresResponse = await msHabilitadoresRequests.ConsultaHabilitadores({
        idSolicitud: 66,
      });
      dispatch(
        success(CONSULTA_HABILITADORES_SUCCESS, habilitadoresResponse.documentacionSolicitud),
      );
      return response;
    } catch (error) {
      dispatch(failure(CONSULTA_HABILITADORES_FAILURE, error.message));
    }
  };
};

msHabilitadoresActions.UploadAndAssociateWithoutRefresh = (data, dataAssociate, signal) => {
  return async (dispatch) => {
    dispatch(request(CONSULTA_HABILITADORES_REQUEST));
    try {
      const response = await documentRequests.UploadMultivaDocument(data);
      await msHabilitadoresRequests.AsociarGestorConFolio({
        ...dataAssociate,
        idGestorDocumental: response.Body.Result.DocumentManagerId,
      });
      dispatch(
        success(CONSULTA_HABILITADORES_SUCCESS, response.Body),
      );
      return response;
    } catch (error) {
      dispatch(failure(CONSULTA_HABILITADORES_FAILURE, error.message));
    }
  };
};

msHabilitadoresActions.UpdateDocument = (data, signal = null) => {
  return async (dispatch) => {
    dispatch(request(CONSULTA_HABILITADORES_REQUEST));
    try {
      const response = await documentRequests.UpdateMultivaDocument(data);
      dispatch(success(CONSULTA_HABILITADORES_SUCCESS, response.Body));
      return response;
    } catch (error) {
      dispatch(failure(CONSULTA_HABILITADORES_FAILURE, error.message));
    }
  };
};

msHabilitadoresActions.DownloadDocument = (data, signal = null) => {
  return async (dispatch) => {
    dispatch(request(CONSULTA_HABILITADORES_REQUEST));
    try {
      const response = await documentRequests.DownloadMultivaDocument(data);
      dispatch(success(CONSULTA_HABILITADORES_SUCCESS, response.Body));
      return response;
    } catch (error) {
      dispatch(failure(CONSULTA_HABILITADORES_FAILURE, error.message));
    }
  };
};

msHabilitadoresActions.GetDataDocument = (data, signal = null) => {
  return async (dispatch) => {
    dispatch(request(CONSULTA_HABILITADORES_REQUEST));
    try {
      const response = await documentRequests.GetDataMultivaDocument(data);
      dispatch(success(CONSULTA_HABILITADORES_SUCCESS, response.Body));
      return response;
    } catch (error) {
      dispatch(failure(CONSULTA_HABILITADORES_FAILURE, error.message));
    }
  };
};

