
export const buildMetadataRequest = (id, groupName, name, value)=>{

  let ListMetdata = [];
  ListMetdata.push({
    GroupName: groupName,
    Name: name,
    Value: value,
    ApprovedConfidence: null,
    Confidence: null,
    MetadataType: null,
    ValueType: null,
  });
  console.log('ListMetdata: ', ListMetdata);
  let Request = {
    ObjectId: id,
    Metadata: ListMetdata,
    ContinueWorkFlow: true,
  };
  console.log('Request Update metadata**********************: ', Request);

  return Request;

}
