import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { msSolicitudActions } from 'store/actions/msMultiva/msSolicitudActions';
import { GenericConfirmationModal } from './GenericConfirmationModal';

export default function ConfirmationSolicitudeModal({ open, onOpenModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setloading] = useState(false);
  const infoFolio = useSelector((state) => state.Folio);
  const infoSolicitud = useSelector((state) => state.SolicitudOperacion);

  const handleConfirm = async () => {
    setloading(true);
    const CREATE_REQUEST_MULTIVA_PAYLOAD = {
      solicitud: {
        idEstatus: 1,
        idEtapa: 1,
        idSubetapa: 1,
        idPersonalidadJuridica: 1,
        idTipoTramite: 1,
        idOperacionSolicitud: 1,
        idSubDireccionRegional: 1,
        idDireccionRegional: 1,
        idEstatusVencimiento: 1,
        idAreaAsignada: 1,
        idUsuarioAsignado: 1,
        tipoTransaccion: 'alta',
        idUsuario: 1,
        usuario: '1',
        idDao: 1,
        idArea: 1,
        idRol: 1,
        idSucursal: 1,
        idSubDireccionGeneral: 1,
        idDireccionGeneral: 1,
        folioDocsolution: '1',
        usuarioGeolocalizacion: '40.689247, -74.044502',
        seccion: ['solicitud'],
      },
    };
    dispatch(msSolicitudActions.CreateRequestMultivaOnboarding(CREATE_REQUEST_MULTIVA_PAYLOAD))
      .then((response) => {
        onOpenModal(false);
        setloading(false);
        navigate(`/inbox/asignacion/${response.Body?.Result.Id}`);
      })
      .catch((error) => setloading(false));
  };

  const handleCancel = () => {
    onOpenModal(false);
    dispatch(msSolicitudActions.SolicitudOperacionClearState());
    navigate('/inbox/homeComponent');
  };

  return (
    <GenericConfirmationModal
      title="¿Estas seguro de crear una nueva solicitud?"
      open={open}
      handleCancel={handleCancel}
      handleConfirm={handleConfirm}
      loading={loading}
      error={infoSolicitud.error || infoFolio.error}
    />
  );
}
