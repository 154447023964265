import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

export const RequestErrorAlert = ({
  text,
  icon,
  timer,
  error,
  showCancel = false,
  showConfim = false,
  onHandle,
}) => {
  const navigate = useNavigate();
  const showAlert = useCallback(() => {
    Swal.fire({
      text: text || 'Servicios no disponibles, intente más tarde',
      icon: icon || 'error',
      timer: timer || null,
      showCancelButton: showCancel,
      showConfirmButton: showConfim,
      allowOutsideClick: false,
      confirmButtonText: 'Regresar',
    }).then((result) => {
      if (!result.isConfirmed) return;
      onHandle ? onHandle() : navigate('/inbox/homeComponent');
    });
  }, [text, timer, icon, showCancel, showConfim, onHandle, navigate]);

  useEffect(() => {
    showAlert();
  }, [error, showAlert]);
  return null;
};
