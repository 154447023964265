import {Box, Button, Dialog, DialogContent, Grid, TextField, Typography} from "@mui/material";
import React, {useState} from "react";

export const RejectDocumentModal = ({ isOpen, onClose, onSendClick }) => {

  const [rejectionReason, setRejectionReason] = useState("");

  const handleSendClick = () => {
    onSendClick(rejectionReason);
  };

  console.log("CS_RejectReason",rejectionReason);

  return (
    <Dialog open={isOpen} onClose={onClose} PaperProps={{ style: { minHeight: "40%", minWidth: "30%" }}}>
      <DialogContent>
        <Grid container direction="column" spacing={2} style={{ height: "100%" }} overflow={'scroll'}>
          <Grid item style={{ flex: 1 }}>
            <Typography align="center"> <strong>Motivo de rechazo</strong></Typography>
          </Grid>
          <Grid item style={{ flex: 8 }}>
            <TextField
              fullWidth
              InputProps={{
              rows: 10,
              multiline: true,
              inputComponent: 'textarea'}}
              placeholder="Motivo de rechazo"
              onChange={(e) => setRejectionReason(e.target.value)}
            />

          </Grid>
          <Grid item style={{ flex: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-around'}}>
              <Button variant={"contained"} onClick={onClose}>Cancelar</Button>
              <Button variant={"contained"} onClick={handleSendClick} disabled={!rejectionReason}>Enviar</Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

    </Dialog>

  );
};
