export const BiometricsQuestionGroup = [
  {
    "title": "Resultados Biométricos",
    "llave": 0,
    "questions": [
      {
        "Id": 0,
        "Name": "Resultados Biométricos",
        "Description": "Resultados Biométricos",
        "QuestionType": "Tabla",
        "DataType": "string",
        "Length": 0,
        "Required": false,
        "Sequence": 1,
        "QuestionGroupId": 624,
        "QuestionGroup": "Coincidencias",
        "TableHeaders": [
          {
            "Name": "idSolicitud",
            "Description": "ID Solicitud",
            "CellType": "TextoPlano",
            "Length": 10,
            "Sequence": 2,
            "Required": false,
            "Sortable": true
          },
          {
            "Name": "numeroCliente",
            "Description": "Numero Cliente",
            "CellType": "TextoPlano",
            "Length": 10,
            "Sequence": 3,
            "Required": false,
            "Sortable": true
          },
          {
            "Name": "rfc",
            "Description": "RFC",
            "CellType": "TextoPlano",
            "Length": 10,
            "Sequence": 4,
            "Required": false,
            "Sortable": true
          },
          {
            "Name": "figura",
            "Description": "ID Figura",
            "CellType": "TextoPlano",
            "Length": 10,
            "Sequence": 5,
            "Required": false,
            "Sortable": true
          },
          {
            "Name": "estatus",
            "Description": "ID Estatus",
            "CellType": "TextoPlano",
            "Length": 10,
            "Sequence": 6,
            "Required": false,
            "Sortable": true
          },
          {
            "Name": "fechaConsulta",
            "Description": "Fecha Consulta",
            "CellType": "TextoPlano",
            "Length": 10,
            "Sequence": 7,
            "Required": false,
            "Sortable": true
          },
          {
            "Name": "folioConsultaGib",
            "Description": "Folio Consulta Gib",
            "CellType": "TextoPlano",
            "Length": 10,
            "Sequence": 8,
            "Required": false,
            "Sortable": true
          },
          {
            "Name": "porcentajeCoincidencia",
            "Description": "Porcentaje Coincidencia",
            "CellType": "TextoPlano",
            "Length": 10,
            "Sequence": 9,
            "Required": false,
            "Sortable": true
          },
          {
            "Name": "resultado",
            "Description": "ID Resultado",
            "CellType": "TextoPlano",
            "Length": 10,
            "Sequence": 10,
            "Required": false,
            "Sortable": true
          }
        ],
        "GroupName": "Resultados Biométricos",
        "DefaultValue": null,
        "Visible": true,
        "Captured": null,
        "HasCheckbox": false,
      }
    ]
  }
];