import { InputField as TextField } from 'components/InputField/inputField.styled.js';
import { Accordion } from 'components/Accordion.jsx/index.jsx';
import { DynamicTable } from 'components/LEGOS/DynamicTable/index.jsx';
import {
  COMISIONES_TABLES_QUESTION_IDS,
  AMORTIZACION_TABLES_QUESTION_IDS,
  COMISION_TABLE_QUESTION_GROUP_KEY,
  AMORTIZACION_TABLE_QUESTION_GROUP_KEY,
  RESULT_SIMULATIONS_KEYS,
} from 'mappings/SimulationFormMappings.js';

export const ResultsFormSimulation = ({ questionsGroups, simulationType, simulationResult }) => {
  const renderTableWithSimulationRows = (configuration, rows) => {
    const { Id, TableHeaders, HasCheckbox, MaximumNumberRowsSelect } = configuration;
    return (
      <DynamicTable
        key={Id}
        id={Id}
        headers={TableHeaders}
        rows={rows}
        hasCheckbox={HasCheckbox}
        maximumNumberRowsSelect={MaximumNumberRowsSelect}
      />
    );
  };

  if (!simulationResult) return null;

  return (
    <>
      {questionsGroups
        .filter((questionGroup) => questionGroup.llave === 739)
        // .filter((questionGroup) => questionGroup.llave === RESULT_SIMULATIONS_KEYS[simulationType])
        .map((questionGroup) => (
          <Accordion key={questionGroup.llave} title={questionGroup.groupTitle} isExpanded>
            {questionGroup.questions.map((question) => (
              <TextField
                key={question.Id}
                label={question.Description}
                name={question.Name}
                value={simulationResult[question.DataType]}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                disabled
              />
            ))}
          </Accordion>
        ))}

      {questionsGroups
        .filter(({ llave }) => llave === COMISION_TABLE_QUESTION_GROUP_KEY.Key)
        .map(({ llave, groupTitle, questions }) => (
          <Accordion key={llave} title={groupTitle} isExpanded>
            {questions
              .filter(({ Id }) => Id === COMISIONES_TABLES_QUESTION_IDS[simulationType])
              .map((configurationComisionesTable) =>
                renderTableWithSimulationRows(
                  configurationComisionesTable,
                  simulationResult.comisionesSeguros,
                ),
              )}
          </Accordion>
        ))}

      {questionsGroups
        .filter(({ llave }) => llave === AMORTIZACION_TABLE_QUESTION_GROUP_KEY.Key)
        .map(({ llave, groupTitle, questions }) => (
          <Accordion key={llave} title={groupTitle} isExpanded>
            {questions
              .filter(({ Id }) => Id === AMORTIZACION_TABLES_QUESTION_IDS[simulationType])
              .map((configurationAmortizacionTable) =>
                renderTableWithSimulationRows(
                  configurationAmortizacionTable,
                  simulationResult.tablaAmortizacion,
                ),
              )}
          </Accordion>
        ))}
    </>
  );
};
