import { Alert } from '../../components';
import { flujoMasivoConstants } from '../constants';
import { flujoMasivoRequests } from '../../services'

export const flujoMasivoActions = {}

flujoMasivoActions.UploadBatchFile2 = (data) => {
  return dispatch => {
    dispatch(request())
    flujoMasivoRequests.UploadBatchFile2(data)
      .then(
        dataExcel => dispatch(success(dataExcel)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: flujoMasivoConstants.UPLOAD_BATCHFILE_REQUEST }
  }

  function success(dataExcel) {
    console.log(dataExcel);
    return { type: flujoMasivoConstants.UPLOAD_BATCHFILE_SUCCESS, dataExcel }
  }

  function failure(error) {
    return { type: flujoMasivoConstants.UPLOAD_BATCHFILE_FAILURE, error }
  }
}

flujoMasivoActions.InitializeBatchFile = (data) => {
  console.log('INICIAMASIVO', data)
  return dispatch => {
    dispatch(request())
    flujoMasivoRequests.InitializeBatchFile(data)
      .then(
        dataFile => dispatch(success(dataFile)),
        error => dispatch(failure(error.message))
      )
  }

  function request() {
    return { type: flujoMasivoConstants.INITIALIZATE_BATCHFILE_REQUEST }
  }

  function success(dataFile) {
    console.log(dataFile);
    return { type: flujoMasivoConstants.INITIALIZATE_BATCHFILE_SUCCESS, dataFile }
  }

  function failure(error) {
    return { type: flujoMasivoConstants.INITIALIZATE_BATCHFILE_FAILURE, error }
  }
}