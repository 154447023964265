import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DynamicFormWrapper } from '../../DynamicForm/DynamicFormWrapper.jsx';
import { msSimuladoresActions } from 'store/actions/msMultiva/msSimuladoresActions.js';
import { Box } from './formsSimulations.styled.js';
import { Button } from '@mui/material';
import { SIMULATOR_TYPES } from 'mappings/SimulationFormMappings.js';
import { ResultsFormSimulation } from './SimulationResults.jsx';
import { FormSimulationControls } from './SimulationControls.jsx';
import { DynamicFormBody } from '../../DynamicForm/DynamicFormBody.jsx';
import { getAgeInYears, getAgeInMonths } from 'utils/tools.js';
import { getQuestionGroupForSimulationType } from 'utils/LegosUtils/Simulation';
import { useFormContext } from 'react-hook-form';
import { RequestErrorAlert } from 'components/RequestErrorAlert.jsx';
import { CustomLoadingOverlay } from 'components/CustomLoadingOverlay.js';

export const FormSimulation = ({ simulationType, onReturn }) => {
  const [rows, setRows] = useState([]);
  const [, setUpdateForm] = useState();
  const [isSimulationCalculated, setIsSimulationCalculated] = useState(false);
  const simulationState = useSelector((state) => state.SimuladorCredito);
  const loggedUser = useSelector((state) => state.Authentication);
  const dispatch = useDispatch();
  const { questionsGroups, setValue, getValues, handleSubmit, reset } = useFormContext();

  const handleCalculateSimulation = (formData) => {
    const {
      edadAnios,
      edadMeses,
      4012: personalidadJuridica,
      4013: tipo,
      4014: categoria,
      4015: programa,
      4016: tipoOperacion,
      ...producInformation
    } = formData;
    const SIMULATOR_TYPE = SIMULATOR_TYPES[simulationType];
    const CALCULATE_SIMULATION_PAYLOAD = {
      tipoSimulador: SIMULATOR_TYPE,
      evento: 'simulador',
      usuario: loggedUser?.usuario,
      usuarioGeolocalizacion: '99.0340324, -12.9999999',
      informacionProducto: {
        ...producInformation,
        personalidadJuridica: personalidadJuridica,
        categoria: categoria,
        programa: programa,
        tipoOperacion: tipoOperacion,
      },
    };
    if (SIMULATOR_TYPE === 3) {
      CALCULATE_SIMULATION_PAYLOAD.informacionProducto.tipoOperacion = 243;
      CALCULATE_SIMULATION_PAYLOAD.informacionProducto.programa = 197;
      CALCULATE_SIMULATION_PAYLOAD.informacionProducto.personalidadJuridica = 138;
      CALCULATE_SIMULATION_PAYLOAD.informacionProducto.mayorPlazoCreditosRenovar = parseInt(
        formData.mayorPlazoCreditoRenovar,
      );
      CALCULATE_SIMULATION_PAYLOAD.informacionProducto.periodoCredito = parseInt(
        formData.periodoCredito,
      );
      CALCULATE_SIMULATION_PAYLOAD.informacionProducto.plazoCredito = 236; //modificar en onboarding
    }
    dispatch(msSimuladoresActions.SimuladorCredito(CALCULATE_SIMULATION_PAYLOAD));
    setIsSimulationCalculated(true);
  };

  const handleSelectCombo = (e) => {
    if (e.target.name === 'numeroCuentasReportar') {
      setRows([
        [null, null],
        [null, null],
        [null, null],
      ]);
    }
  };

  const handleSelectDate = (date) => {
    setValue('edadAnios', getAgeInYears(date));
    setValue('edadMeses', getAgeInMonths(date));
    setUpdateForm({});
  };

  const onSelectMap = {
    Combo: handleSelectCombo,
    FechaPrevia: handleSelectDate,
  };

  const handleReturn = () => {
    onReturn();
    reset();
  };

  const render = (question) => {
    const onSelect = onSelectMap[question.QuestionType];
    return (
      <DynamicFormBody
        key={question.Id}
        question={question}
        options={question.Options}
        rows={rows}
        headers={question.TableHeaders}
        value={getValues(question.Id)}
        handleInputSelect={onSelect}
      />
    );
  };

  useEffect(() => () => dispatch({ type: 'SIMULADOR_CLEAR_STATE' }), [dispatch]);

  if (!questionsGroups.length) return null;

  return (
    <Box>
      {simulationState.loading && <CustomLoadingOverlay active={simulationState.loading} />}
      {simulationState.error && (
        <RequestErrorAlert error={simulationState.error} showConfim onHandle={handleReturn} />
      )}
      <FormSimulationControls
        isSimulationCalculated={isSimulationCalculated}
        simulationType={simulationType}
        formData={getValues()}
        onReturn={handleReturn}
      />
      <h5>Simulación {simulationType}</h5>
      <DynamicFormWrapper
        questionsGroups={getQuestionGroupForSimulationType(questionsGroups, simulationType)}
        render={render}
      />
      <Button variant="contained" onClick={handleSubmit(handleCalculateSimulation)}>
        Calcular Simulación
      </Button>
      <ResultsFormSimulation
        questionsGroups={questionsGroups}
        simulationType={simulationType}
        simulationResult={simulationState?.items?.responseSimulador}
      />
    </Box>
  );
};
