import {Dialog, DialogContent, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {msHabilitadoresActions} from "../../../../../store/actions/msMultiva/msHabilitadoresActions";
import {DocumentarySlotControls} from "../../../../DocumentarySlot/DocumentarySlotControls";
import {DocumentarySlotBody} from "../../../../DocumentarySlot/DocumentarySlotBody";
import {useDispatch, useSelector} from "react-redux";
import {B64toBlob} from "../../../../../utils";
import {
  UPLOAD_DOCUMENT_PAYLOAD,
  ASSOCIATE_PAYLOAD,
  UPDATE_DOCUMENT_REQUEST_PAYLOAD
} from "./UploadModalPayloads";
import {
  DOCUMENT_STATUS_APPROVED,
  DOCUMENT_STATUS_PENDING,
  DOCUMENT_STATUS_REJECTED, UPDATE_DOCUMENT_MODAL_TITLE, UPLOAD_DOCUMENT_MODAL_TITLE
} from "../../RequestDocumentationConstants";
import {buildMetadataRequest} from "utils/buildMetadataRequest";
import {fileActions} from "store/actions";
import {useParams} from "react-router-dom";

export const UploadModal = ({ isOpen, onClose, idHabilitador, statusDocument, refreshData}) => {

  const [fileB64, setFileB64] = useState(null);
  const [fileUrl, setfileUrl] = useState(null);
  const [extensionCurrent, setExtensionCurrent] = useState('');
  const [allowedExtensions, setAllowedExtensions] = useState(['pdf', 'png']);
  const { id } = useParams();
  const infoFile = useSelector((state) => state.FileInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fileB64) {
      const mimeType =
        extensionCurrent === '.pdf' ? 'application/pdf' : `image/${extensionCurrent}`;
      const blob = B64toBlob(fileB64, mimeType);
      const blobUrl = URL.createObjectURL(blob);
      setfileUrl(blobUrl);
    }
  }, [fileB64, extensionCurrent]);

  const handleUploadDocument = async (fileB64, extension, idHabilitador) => {
    UPLOAD_DOCUMENT_PAYLOAD.B64Content = fileB64;
    ASSOCIATE_PAYLOAD.idHabilitador = idHabilitador;
    return await dispatch(msHabilitadoresActions.UploadAndAssociateWithoutRefresh(UPLOAD_DOCUMENT_PAYLOAD, ASSOCIATE_PAYLOAD));
  };

  const handleUpdateDocument = async (fileB64, extension) => {
    UPDATE_DOCUMENT_REQUEST_PAYLOAD.B64Content = fileB64;
    return await dispatch(msHabilitadoresActions.UpdateDocument(UPDATE_DOCUMENT_REQUEST_PAYLOAD));
  };

  const handleUpload = async () => {
    const chargeResponse = statusDocument === DOCUMENT_STATUS_APPROVED ?
      await handleUpdateDocument(fileB64, extensionCurrent) :
      await handleUploadDocument(fileB64, extensionCurrent, idHabilitador);

    const documentManagerId = chargeResponse?.Body?.Result?.DocumentManagerId;
    if (documentManagerId) {
      const groupName = infoFile?.items?.StageName ?? null;
      dispatch(fileActions.FileUpdateMetadata(buildMetadataRequest(id, groupName, "idGestorDocumental", documentManagerId)));
    }
    resetFileAndExtension();
    refreshData();
    onClose();
  };

  const handleFileChange = (fileB64Response, extension) => {
    if (!fileB64Response) return;
    setFileB64(fileB64Response);
    setExtensionCurrent(extension);
  };

  const resetFileAndExtension = () => {
    setFileB64(null);
    setExtensionCurrent(null);
  };

  const modalTitle = statusDocument === (DOCUMENT_STATUS_APPROVED || DOCUMENT_STATUS_REJECTED) ? UPDATE_DOCUMENT_MODAL_TITLE : UPLOAD_DOCUMENT_MODAL_TITLE
  console.log("CS_StatusDocument",statusDocument);

  return (
    <Dialog open={isOpen} onClose={onClose} PaperProps={{style: {maxHeight: "70%", minWidth: "40%"}}}>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', }}>
        <Typography>{modalTitle}</Typography>
        {fileB64 && (
          <DocumentarySlotControls
            handleUpload={handleUpload}
            resetFileAndExtension={resetFileAndExtension}
          />
        )}
        <DocumentarySlotBody
          fileB64={fileB64}
          extensionCurrent={extensionCurrent}
          handleFileChange={handleFileChange}
          allowedExtensions={allowedExtensions}
          fileUrl={fileUrl}
        />
      </DialogContent>
    </Dialog>
  );
};
