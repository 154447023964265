import { useState } from 'react';

const useTable = (rows) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const isSelected = (row) =>
    selectedRows.findIndex((selectedRow) => selectedRow === row) !== -1;

  const handleSort = (selectedColumn) => {
    const isAsc = orderBy === selectedColumn && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(selectedColumn);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(rows);
      return;
    }
    setSelectedRows([]);
  };

  const handleSelectRow = (row, maximumNumberRowsSelect) => {
    const copySelectdRows = [...selectedRows];
    const indexOfRow = copySelectdRows.findIndex(
      (selectedRow) => selectedRow.idSimulador === row.idSimulador,
    );
    if (maximumNumberRowsSelect && copySelectdRows.length >= maximumNumberRowsSelect) {
      if (indexOfRow === -1) return;
      copySelectdRows.splice(indexOfRow, 1);
    } else {
      copySelectdRows.findIndex((selectedRow) => selectedRow.idSimulador === row.idSimulador);
      indexOfRow === -1 ? copySelectdRows.push(row) : copySelectdRows.splice(indexOfRow, 1);
    }
    setSelectedRows(copySelectdRows);
  };

  return {
    isSelected,
    handleSort,
    handleSelectAll,
    handleSelectRow,
    order,
    orderBy,
    selectedRows,
  };
};

export { useTable };
