import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box, Typography, Button, Grid, IconButton, Tooltip
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form';
import { flujoMasivoActions } from '../../store/actions'
import {
    UseImage,
    Alert, CustomLoadingOverlay, UploadDcumentsControl
} from '../../components'
import img from '../../assets/DownloadExcel.png';
import plantillaExcel from '../../assets/PlantillaCargaMasiva_MetLife.xlsx'
import { TableDataUsersMasive } from './TableDataUsersMasive';

export default function RegistrationBulkUsersComponent() {
    const dispatch = useDispatch();
    const formMethods = useForm({ mode: "onChange" });
    const navigation = useNavigate();
    const [loadView, setloadView] = useState(false);
    const [dataUsersTable, setDataUsersTable] = useState([]);
    const dataUsers = useSelector((state) => state.UploaBatchFile2Data)


    let handleFileChange = async (FileContent, fileB64Response, extension) => {
        try {
            dispatch(flujoMasivoActions.UploadBatchFile2({
                "FileContentB64": fileB64Response
            }))

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (!!dataUsers.items) {
            console.log('DATOS', dataUsers)
            setloadView(false);
            setDataUsersTable(dataUsers?.items?.Result)
        }
        if (dataUsers?.error) {
            setloadView(false);
        }
    }, [dataUsers])

    const sendDataMasive = () => {
        const batchUploadIds = dataUsers?.items?.Grupo.BatchUpload_Id;
        console.log('batchUploadIds', [batchUploadIds]);
        dispatch(flujoMasivoActions.InitializeBatchFile({
            BatchUpload_Ids: [batchUploadIds]
        }));
        Alert({
            icon: "success",
            title: "Proceso iniciado correctamente",
        });
        setloadView(false);
        navigation('/inbox/homeComponent')
    }

    useEffect(() => {
        setDataUsersTable();
    }, [])

    return (
        <>
            {loadView === true ?
                <CustomLoadingOverlay
                    active={loadView} text="Estamos procesando sus datos..."
                />
                : null}
            {dataUsersTable?.length > 0 ? (
                <Grid>
                    <TableDataUsersMasive data={dataUsersTable} groupData={dataUsers.items.Grupo} />
                    <Grid container display={'flex'} justifyContent='center' spacing={2}>
                        <Grid item lg={3} xs={12}>
                            <Button style={{
                                height: 38,
                                width: '100%',
                                margin: '1em',
                                borderRadius: '25px',
                                fontSize: 16,
                                fontWeight: 500
                            }}
                                disabled={dataUsersTable.filter((item) => item.State === false)?.length === 0 ? false : true}
                                variant='contained' color="primary" onClick={() => sendDataMasive()}>
                                Enviar
                            </Button>
                        </Grid>
                        <Grid item lg={3} xs={12}>
                            <Button style={{
                                height: 38,
                                width: '100%',
                                margin: '1em',
                                borderRadius: '25px',
                                fontSize: 16,
                                fontWeight: 500
                            }} variant='outlined' color="primary" onClick={() => setDataUsersTable([])}>
                                Editar archivo
                            </Button>
                        </Grid>
                    </Grid>
                </Grid >
            ) : (
                <Grid container alignContent='center' alignItems='center' justifyContent='center'>
                    <Grid item lg={10} xs={12}>
                        <Box textAlign={'left'}>
                            <Typography variant='subtitle'>
                                Puedes registrar 1 o más candidatos cargando tu archivo .xlxs con los datos de los candidatos, en caso de no contar con un dato dejar en blanco el campo correspondiente.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={1} xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: "flex-end" }}>
                            <Tooltip title="Descargar el template" placement="top">
                                <a href={plantillaExcel} target="_blank" rel="noopener noreferrer" download="Plantilla Carga Masiva.xlsx">
                                    <IconButton xs={{ width: 60 }}>
                                        <UseImage src={img} type='img' />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        </Box>
                    </Grid>
                    <Grid item lg={10} xs={12}>
                        <FormProvider {...formMethods}>
                            <UploadDcumentsControl
                                Excel={true}
                                Title={'Arrastre su documento con extensión .xlsx o'}
                                onChange={handleFileChange}
                            />
                        </FormProvider>
                    </Grid>
                    <Grid lg={5} xs={12}>
                        <Box display={'flex'} justifyContent='center'>
                            <Button style={{
                                height: 38,
                                width: '100%',
                                margin: '1em',
                                borderRadius: '25px',
                                fontSize: 16,
                                fontWeight: 500
                            }} variant='outlined' color="primary" onClick={() => navigation('/inbox/new-file')}>
                                Cancelar
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            )
            }
        </>
    )
}
