import { SIMULATION_GROUPS_KEYS } from 'mappings/SimulationFormMappings.js';
import { SUPPLEMENTARY_DATA_GROUPS_KEYS } from 'mappings/SupplementaryDataFormMappings';

export const transformQuestions = (questions) => {
  return questions.map((question) => {
    if (question.Description === 'Edad (Años)') {
      return { ...question, Id: 'edadAnios', Disabled: true };
    }
    if (question.Description === 'Edad (Meses)') {
      return { ...question, Id: 'edadMeses', Disabled: true };
    }
    return {
      ...question,
      Id: question.DataType ? question.DataType : `${question.Id}`,
      DataType: '',
    };
  });
};

export const transformQuestions2 = (questions) => {
  return questions.map((question) => {
    return {
      ...question,
      Id: `${question.Id}`,
      DataType: '',
    };
  });
};

export const getQuestionGroupForSimulationType = (questionsGroups, simulationType) => {
  const targetKeys = SIMULATION_GROUPS_KEYS[simulationType] || [];
  const filteredGroups = questionsGroups.filter((questionGroup) =>
    targetKeys.includes(questionGroup.llave),
  );
  const transformedGroups = filteredGroups.map((group) => ({
    ...group,
    questions: transformQuestions(group.questions),
  }));
  return transformedGroups;
};

export const getQuestionGroupForDatosComplementarios = (questionsGroups, supplementaryData) => {
  const targetKeys = SUPPLEMENTARY_DATA_GROUPS_KEYS[supplementaryData] || [];
  const filteredGroups = questionsGroups.filter((questionGroup) =>
    targetKeys.includes(questionGroup.llave),
  );
  const transformedGroups = filteredGroups.map((group) => ({
    ...group,
    questions: transformQuestions2(group.questions),
  }));
  return transformedGroups;
};
