import React from 'react';
import { LoadingButton } from '../Buttons';
import { Alert, Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

export const GenericConfirmationModal = ({
  title,
  returnText,
  confirmText,
  open,
  handleCancel,
  handleConfirm,
  loading,
  error,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiDialog-paper': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '100%',
            maxWidth: '700px',
            height: '250px',
            padding: '1rem',
            textAlign: 'center',
          },
          '& .MuiDialogContent-root': {
            flex: 'none',
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">{title || '¿Estas seguro de continuar?'}</DialogTitle>
      <DialogActions>
        <Button onClick={handleCancel} variant="outlined" size="large">
          {returnText || 'Regresar'}
        </Button>
        <LoadingButton
          onHandleClick={handleConfirm}
          variant="contained"
          size="large"
          loading={loading}
        >
          {confirmText || 'Crear Nueva Solicitud'}
        </LoadingButton>
      </DialogActions>
      {error && (
        <Alert severity="error" sx={{ width: '70%', color: 'red' }}>
          Ocurrio un error, intente nuevamente o contacte a soporte.
        </Alert>
      )}
    </Dialog>
  );
};
