import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fileActions } from '../../store/actions';
import { msSolicitudActions } from "store/actions/msMultiva/msSolicitudActions";
import { DETAILS_REQUEST_PAYLOAD } from './DetailsRequestPayload';

export const useDetails = (id) => {
    const infoFile = useSelector(state => state.FileInfo);
    const infoActors = useSelector(state => state.ActorsInfo);
    const infoDetails = useSelector(state => state.SolicitudConsultaC12);

    const dispatch = useDispatch();

    const [file, setFile] = useState({});
    const [actors, setActors] = useState([]);
    const [details, setDetails] = useState({});

    const setFileInfo = () => dispatch(fileActions.GetFileInfo(id));
    const setActorsInfo = () => dispatch(fileActions.GetActorsInfo(id));
    const setDetailsInfo = () => dispatch(msSolicitudActions.SolicitudConsultaC12(DETAILS_REQUEST_PAYLOAD(id)));

    useEffect(() => {
      if (infoFile.items !== undefined) {
        setFile({
          ...infoFile.items,
          validation: infoFile.itemsValidation,
        });
      }
    }, [infoFile, id]);

    useEffect(() => {
      if (infoActors.items !== undefined) {
        setActors(infoActors.items)
      }
    }, [infoActors, id]);

    useEffect(() => {
      if (infoDetails.items !== undefined) {
        setDetails({
          ...infoDetails.items.solicitud.c12,
          initial: infoDetails.items.solicitud.c12.primerNombre?.split("")[0],
          etapa: infoDetails.items.solicitud.c12.idEtapa,
          personalidadJuridica: infoDetails.items.solicitud.c12.idPersonalidadJuridica,
          programa: infoDetails.items.solicitud.c12.idPrograma,
          subEtapa: infoDetails.items.solicitud.c12.idSubetapa,
          tipo: infoDetails.items.solicitud.c12.idTipoCredito,
          tipoCredito: infoDetails.items.solicitud.c12.idTipoCredito,
          tipoTramite: infoDetails.items.solicitud.c12.idTipoTramite
        })
      }
    }, [infoDetails, id]);

    return {
      file,
      actors,
      details,
      setFileInfo,
      setActorsInfo,
      setDetailsInfo
    }
};
