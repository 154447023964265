import { msCatalogosConstants } from '../../constants/msMultiva/msCatalogosConstants';
import { msCatalogosRequests } from '../../../services/msMultiva/msCatalogos';
import { MultivaCataloguesAdapter } from 'adapters/MultivaCaloguesAdapter';

const { CONSULTA_CATALOGOS_REQUEST, CONSULTA_CATALOGOS_SUCCESS, CONSULTA_CATALOGOS_FAILURE } =
  msCatalogosConstants;

function request(constantName) {
  return { type: msCatalogosConstants[constantName] };
}

function success(constantName, data) {
  return {
    type: msCatalogosConstants[constantName],
    payload: data,
  };
}

function failure(constantName, error) {
  return { type: msCatalogosConstants[constantName], error };
}

export const msCatalogosActions = {};

msCatalogosActions.ConsultaCatalogos = (data, signal = null) => {
  return async (dispatch) => {
    dispatch(request(CONSULTA_CATALOGOS_REQUEST));
    try {
      const responses = await Promise.all([
        msCatalogosRequests.ConsultaCatalogos({ claveCatalogo: 'PERJUR' }, signal),
        msCatalogosRequests.ConsultaCatalogos({ claveCatalogo: 'TIPO01' }, signal),
      ]);
      const catalogues = responses.map((response) => {
        return new MultivaCataloguesAdapter({
          ...response.catalogo,
        }).adapt();
      });
      dispatch(success(CONSULTA_CATALOGOS_SUCCESS, catalogues));
    } catch (error) {
      dispatch(failure(CONSULTA_CATALOGOS_FAILURE, error.message));
    }
  };
};

msCatalogosActions.ConsultaCatalogoIndividual = (data, signal = null) => {
  return async (dispatch) => {
    dispatch(request(CONSULTA_CATALOGOS_REQUEST));
    try {
      const response = await msCatalogosRequests.ConsultaCatalogos(data, signal);
      dispatch(success(CONSULTA_CATALOGOS_SUCCESS, response));
      return response;
    } catch (error) {
      dispatch(failure(CONSULTA_CATALOGOS_FAILURE, error.message));
    }
  };
};
