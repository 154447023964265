//The difference between ActotMVisual and ModalVisual are the conditions to show the approbal buttons
import React, { useState, useEffect } from 'react'
import { paths } from '../../utils'
import { UseImage } from '../index';

import { useDispatch, useSelector } from 'react-redux'
import { documentActions, validationActions } from '../../store/actions'

import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  Box,
  Card, useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './ModalStyles'
import { mainStyles } from '../../theme/mainStyles'

import { CustomAlert } from '../CustomAlert';
import { addSpaceToString } from './modalsFunctions';

import ine from '../../assets/id.svg'
import passport from '../../assets/passport.svg'
import curp from '../../assets/CURP.svg'
import comprobante from '../../assets/ComprobanteGen.svg'
import consentimiento from '../../assets/consentimiento.svg'
import contrato from '../../assets/contrato.svg'
import privacidad from '../../assets/privacidad.svg'
import ingresos from '../../assets/ingresos.svg'
import nom from '../../assets/NOM.svg'
import ubicacion from '../../assets/ubicacion.svg'
import docGenerico from '../../assets/docGenerico.svg'
import evAudio from '../../assets/evAudio.svg'
import evVideo from '../../assets/evVideo.svg'
import frames from '../../assets/frames.svg'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import loadDoc from '../../assets/loadDoc.svg'
import CancelIcon from '@mui/icons-material/Cancel';
import { CustomLoadingOverlay, AlertConfirm } from '../index';

export function ActorMVisual({ docName, idDoc, metaData, docVal, validation, docsApproved, actor, extDocVal, refresh }) {
  const theme = useTheme()
  const localStyles = useStyles(theme)
  const cardStyle = mainStyles.cards()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false);
  const [approved, setApproved] = useState(false)
  const [extApproved, setExtApproved] = useState(false)
  const [groups, setGroups] = useState([])
  const [openDocFileApproval, setOpenDocFileApproval] = useState(false)
  const [openDocFileRejection, setOpenDocFileRejection] = useState(false)
  const [openExtApproval, setOpenExtApproval] = useState(false)
  const [openExtRejection, setOpenExtRejection] = useState(false)
  const [comment, setComment] = useState('')

  const document = useSelector(state => state.Document)
  const docValidate = useSelector(state => state.DocumentValidation)
  const extDocValidation = useSelector(state => state.DocExternalVal) // to validate docs without affecting the file
  const currentDocId = docsApproved.filter(currentDoc => currentDoc === idDoc)

  let path = window.location.pathname

  const itIncludes = (oneString) => {
    if (oneString.includes("INE / IFE")) {
      return ine
    } else if (
      oneString.includes("PASAPORTE") ||
      oneString.includes("pasaporte") ||
      oneString.includes("Pasaporte")
    ) {
      return passport
    } else if (
      oneString.includes("curp") ||
      oneString.includes("CURP")
    ) {
      return curp
    } else if (
      (oneString.includes("consentimiento") ||
        oneString.includes("Consentimiento")) &&
      (oneString.includes("INE") ||
        oneString.includes("IFE"))) {
      return consentimiento
    } else if (
      oneString.includes("contrato")
      || oneString.includes("Contrato")) {
      return contrato
    } else if (
      oneString.includes("privacidad") ||
      oneString.includes("Privacidad")) {
      return privacidad
    } else if (
      oneString.includes("nom") ||
      oneString.includes("NOM") ||
      oneString.includes("norma") ||
      oneString.includes("Nom") ||
      oneString.includes("Expediente") ||
      oneString.includes("expediente")
    ) {
      return nom
    } else if (
      oneString.includes("TELMEX") ||
      oneString.includes("Telmex") ||
      oneString.includes("CFE") ||
      oneString.includes("cfe") ||
      oneString.includes("domicilio") ||
      oneString.includes("Domicilio")) {
      return comprobante
    } else if (
      oneString.includes("Ingresos") ||
      oneString.includes("INGRESOS") ||
      oneString.includes("ingresos")) {
      return ingresos
    } else if (
      oneString.includes("Ubicación") ||
      oneString.includes("ubicación")) {
      return ubicacion
    } else if (
      oneString.includes('Evidencia Video') ||
      oneString.includes('Evidencia video')) {
      return evVideo
    } else if (
      oneString.includes('Video frame')) {
      return frames
    } else if (
      oneString.includes('Audio') ||
      oneString.includes('audio')) {
      return evAudio
    } else {
      return docGenerico
    }
  }

  useEffect(() => {
    if (openDocFileApproval) {
      AlertConfirm(
        {
          icon: "warning",
          html: <Box>
            <Typography
              textAlign={"center"}
              variant="body1"
              sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
            >
              ¿Estás seguro de aprobar el documento?
            </Typography>
          </Box>,
          okbtntext: "Aceptar",
          notbtntext: "Cancelar",
        },
        (result) => {
          if (result.isConfirmed) {
            sendApproval()
          } else if (result.dismiss === "cancel" && result.isDismissed) {

          }
          setOpenDocFileApproval(false)
        }
      );
    }
  }, [openDocFileApproval])

  useEffect(() => {
    if (openExtApproval) {
      AlertConfirm(
        {
          icon: "warning",
          html: <Box>
            <Typography
              textAlign={"center"}
              variant="body1"
              sx={{ mt: 1, textAlign: "center", maxWidth: 400, mx: "auto" }}
            >
              ¿Estás seguro de aprobar el documento?
            </Typography>
          </Box>,
          okbtntext: "Aceptar",
          notbtntext: "Cancelar",
        },
        (result) => {
          if (result.isConfirmed) {
            sendExtApproval()
          } else if (result.dismiss === "cancel" && result.isDismissed) {

          }
          setOpenExtApproval(false)
        }
      );
    }
  }, [openExtApproval])

  //MODAL
  const handleClickOpen = () => {
    setOpen(true);
    dispatch(documentActions.GetDocument(idDoc))
    console.log('print id doc*********: ', idDoc)
  };

  const handleClose = () => {
    setOpen(false);
    setComment('')
  };

  //Reject or accept document
  const sendApproval = () => {
    setApproved(true)
    dispatch(validationActions.PostDocumentValidation({
      Document_Id: idDoc,
      Approved: true,
      Comments: "",
      ValidateFileComplete: false
    }))
    handleClose()
  }

  const sendRejection = () => {
    setApproved(true)
    dispatch(validationActions.PostDocumentValidation({
      Document_Id: idDoc,
      Approved: false,
      Comments: comment,
      ValidateFileComplete: false
    }))
    handleClose()
  }

  useEffect(() => {
    const getGroups = metaData.map((data) => {
      return data.GroupName
    }).reduce((a, b) => (a.includes(b) ? a : [...a, b])
      , []).map((grp) => {
        return {
          grupo: grp,
          info: metaData.filter((grpJ) => grp === grpJ.GroupName)
        }
      })
    setGroups(getGroups)

    if (docValidate.error !== undefined && approved) {
      alert(docValidate.error)
    }
    if (docValidate.items !== undefined && approved) {
      // window.location.reload()
      refresh()
      handleClose()
      console.log('se aprobo el doc')
    }

  }, [docValidate])

  //Ext Doc Validation
  const sendExtApproval = () => {
    setExtApproved(true)
    dispatch(validationActions.SendDocExternalVal({
      Document_Id: idDoc,
      Approved: true,
      Comments: "",
      ValidateFileComplete: false
    }))
    handleClose()
  }

  const sendExtRejection = () => {
    setExtApproved(true)
    dispatch(validationActions.SendDocExternalVal({
      Document_Id: idDoc,
      Approved: false,
      Comments: comment,
      ValidateFileComplete: false
    }))
    handleClose()
  }

  useEffect(() => {
    if (extDocValidation.error !== undefined && extApproved) {
      alert(extDocValidation.error)
    }
    if (extDocValidation.items !== undefined && extApproved) {
      //window.location.reload()
      refresh()
      handleClose()
    }
  }, [extDocValidation])

  return (
    <div>
      <Card className={cardStyle.card} onClick={handleClickOpen} >
        <div className={localStyles.iconSection}>
          {docVal === null
            ? <UseImage src={loadDoc} type='img' className={cardStyle.loadDocIcon} />
            : docVal ? <CheckCircleIcon style={{ color: "#28A745" }} />
              : docVal === false
                ? <CancelIcon style={{ color: "red" }} />
                : ''}
        </div>

        <UseImage src={itIncludes(docName)} className={localStyles.icon} type='img' />

        <Typography className={localStyles.p}>
          {docName}
        </Typography>
      </Card>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg">
        <CloseIcon style={{ color: "#898B8D" }} className={localStyles.closeIcon} onClick={handleClose} />
        {/* <Typography variant="h6" className={localStyles.mainDoctitle}><b>
          {docName}</b>
        </Typography> */}
        <div className={metaData.length > 0 ? localStyles.modalVisual : localStyles.modalVisualNoData}>
          <div className={metaData.length > 0 ? localStyles.modalPdf : localStyles.pdfNoData}>
            <Typography variant="h6" className={localStyles.mainDoctitle}>
              {docName}
            </Typography>
            {document.loading
              ? <CustomLoadingOverlay
                active={document.loading} text="Espere un momento por favor..."
              /> :
              document.extension === '.mp4' ?
                <video className="videoClass" width="100%" height="90%" controls autoplay loop poster="fotograma.png">
                  <source src={document.items} type={document.type} />
                  <p>Tu navegador no soporta HTML5</p>
                </video> : <object width="100%" height="100%" data={document.items} type="application/pdf"> </object>}

          </div>

          <div className={localStyles.modalInfo}>
            {metaData.length > 0 &&
              (
                <div className={localStyles.content}>
                  <Typography variant="h6" className={localStyles.title}>Metadatos</Typography>
                  <div className={localStyles.scroll}>
                    {groups.map((grp) => (
                      <div className={localStyles.groupSection}>
                        <Typography variant="p" className={localStyles.groupTitle}>{grp.grupo}</Typography>
                        <div className={localStyles.lineVisual}></div>
                        {grp.info.map((data) => (
                          <div className={localStyles.information}>
                            <Typography className={localStyles.name}>{data.Name}</Typography>
                            {grp.grupo == 'Reconocimiento facial' && data.Name.includes('Face')
                              ? <Typography className={localStyles.dataValue}>{addSpaceToString(data.Value)}</Typography>
                              : <Typography className={localStyles.dataValue}>{data.Value}</Typography>}
                          </div>

                        ))}
                      </div>

                    ))}
                  </div>
                </div>
              )
            }

            { // I am hidding this conditions just for the Demo made at the beginning of Oct 2021
              // path.includes(paths.folio)
              validation.ShowSelectApproval === true && docVal === null
                // && validation.ApprovalInDocument === "1"
                // && validation.ActorVal?.Id === actor.Id
                // && currentDocId[0] === idDoc
                ? <DialogActions className={metaData.length > 0 ? cardStyle.docModalbuttonSection : cardStyle.noMetaDataButtonSection}>
                  <Button
                    variant="contained"
                    sx={{ height: 45, px: 10, maxWidth: 300 }}
                    onClick={() => { setOpenDocFileApproval(true) }} >
                    Aprobar documento
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ height: 45, px: 10, maxWidth: 300 }}
                    onClick={() => { setOpenDocFileRejection(true) }} >
                    Rechazar documento
                  </Button>
                </DialogActions>
                : ""
            }
            {docVal === null && extDocVal === true
              ? <DialogActions className={metaData.length > 0 ? cardStyle.docModalbuttonSection : cardStyle.noMetaDataButtonSection}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ height: 45, px: 10, maxWidth: 300 }}
                  onClick={() => { setOpenExtApproval(true) }} >
                  Aprobar documento
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ height: 45, px: 10, maxWidth: 300 }}
                  onClick={() => { setOpenExtRejection(true) }} >
                  Rechazar documento
                </Button>
              </DialogActions>
              : ""
            }

            <CustomAlert
              closeAction={() => {
                setOpenDocFileRejection(false)
                setComment('')
              }}
              open={openDocFileRejection}
              title='Rechazar Documento'
              body='Especifica el motivo de rechazo del documento.'
              leftButtonText='Cancelar'
              leftButtonAction={() => {
                setOpenDocFileRejection(false)
                setComment('')
              }}
              rightButtonText='Aceptar'
              comments={true}
              valueComment={comment}
              commentPlaceHolder='Motivo de rechazo'
              setValueComment={setComment}
              rightButtonAction={sendRejection} //I am hidding this conditions just for the Demo made at the beginning of Oct 2021
            // rightButtonAction={() => { console.log('Demo version Reject no backEnd available yet') }}
            />

            <CustomAlert
              closeAction={() => {
                setOpenExtRejection(false)
                setComment('')
              }}
              open={openExtRejection}
              title='Rechazar Documento'
              body='Especifica el motivo de rechazo del documento.'
              leftButtonText='Cancelar'
              leftButtonAction={() => {
                setOpenExtRejection(false)
                setComment('')
              }}
              rightButtonText='Aceptar'
              comments={true}
              valueComment={comment}
              commentPlaceHolder='Motivo de rechazo'
              setValueComment={setComment}
              rightButtonAction={sendExtRejection}
            />

          </div>
        </div>

      </Dialog>

    </div>
  )
}

