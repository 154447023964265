import { postRequest } from '../axiosFunctions';
import {
  SIMULADORES_PATH,
  CONSULTA_XID_PATH,
  CONSULTA_XID_INDIVIDUAL_PATH,
  GUARDAR_SIMULACION_PATH,
  IMPRIMIR_TABLA_AMORTIZACION_PATH
} from '../URLS/msSimuladores';

const SimuladorCredito = (data, signal) => postRequest(SIMULADORES_PATH, data, signal);

const ConsultaXidSolicitud = (data, signal) => postRequest(CONSULTA_XID_PATH, data, signal);

const ConsultaXidIndividual = (data, signal) => postRequest(CONSULTA_XID_INDIVIDUAL_PATH, data, signal);

const GuardarSimulacion = (data, signal) => postRequest(GUARDAR_SIMULACION_PATH, data, signal);

const ImprimirTablaAmortizacion = (data, signal) => postRequest(IMPRIMIR_TABLA_AMORTIZACION_PATH, data, signal);

export const msSimuladoresRequests = {
  SimuladorCredito,
  ConsultaXidSolicitud,
  GuardarSimulacion,
  ConsultaXidIndividual,
  ImprimirTablaAmortizacion
};
