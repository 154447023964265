import axios from 'axios';
import { AppUrls } from './Globals';
import { Config } from './Config';

export const fileRequests = {};

fileRequests.GetFileInfo = (data) => {
  return axios
    .post(AppUrls.GetFileInfo, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponse);
};

fileRequests.GetSearchByUser = (data) => {
  console.log('buscandoooooooo');
  return axios
    .post(AppUrls.GetSearchByUser, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponse);
};

fileRequests.GetInfoBySearch = (data) => {
  return axios
    .post(AppUrls.GetInfoBySearch, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponse);
};

fileRequests.GetRecordInfo = (data) => {
  return axios
    .post(AppUrls.GetRecordInfo, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponse);
};

fileRequests.GetActorsInfo = (data) => {
  return axios
    .post(AppUrls.GetActorsInfo, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponse);
};

fileRequests.GetActorInfo = (data) => {
  return axios
    .post(AppUrls.GetActorInfo, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponse);
};

fileRequests.GenerateFolio = (data) => {
  return axios
    .post(AppUrls.GenerateFolio, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponse);
};

fileRequests.GenerateFolioRemote = (data) => {
  return axios
    .post(AppUrls.GenerateFolioRemote, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponse);
};

fileRequests.FileUpdateMetadata = (data) => {
  return axios
    .post(AppUrls.FileUpdateMetadata, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponse);
};

fileRequests.SetOptionsToSelect = (data) => {
  return axios.post(
    AppUrls.SetOptionsToSelect,
    Config.SetParameters(data, '', '', ''),
    Config.ConfigAuth()
  ).then(handleResponse)
}

fileRequests.GenerateFolioHiring = (data) => {
  return axios
    .post(AppUrls.GenerateFolioHiring, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponse);
};

fileRequests.AsignateCurrentUser = (data) => {
  return axios
    .post(AppUrls.AsignateCurrentUser, Config.SetParameters(data, '', '', ''), Config.ConfigAuth())
    .then(handleResponse);
};

let handleResponse = (response) => {
  console.log('response', response);
  let respObject = response.data; //BodySecurityResponse(response.data);
  if (!respObject.IsOK) {
    let responseObject = {
      message:
        respObject.Messages +
        ' ' +
        (respObject.TansactionId ? respObject.TansactionId : '') +
        ' ' +
        (respObject.statusText ? respObject.statusText : ''),
    };
    let error = respObject.Body || responseObject;
    return Promise.reject(error);
  }
  return respObject;
};
