import { msCatalogosConstants } from '../../constants/msMultiva/msCatalogosConstants';

export const ConsultaCatalogos = (state = {}, action) => {
  switch (action.type) {
    case msCatalogosConstants.CONSULTA_CATALOGOS_REQUEST:
      return { loading: true, items: null, error: null };

    case msCatalogosConstants.CONSULTA_CATALOGOS_SUCCESS:
      return { loading: false, items: action.payload, error: null };

    case msCatalogosConstants.CONSULTA_CATALOGOS_FAILURE:
      return { loading: false, items: null, error: action.error };

    case msCatalogosConstants.CONSULTA_CATALOGOS_CLEAR_STATE:
      return { loading: null, items: null, error: null };

    default:
      return state;
  }
};
